<template>
    <v-container class="d-flex ">
        <BaseCameraCaptureVue
            v-if="hasCamera"
            ref="cameraCapture"
            @capturedImages="getImages"
        ></BaseCameraCaptureVue>
        <v-badge
            v-if="!!images && images.length > 0"
            :content="'+'+(images.length).toString()"
            color="teal darken-1"
            offset-x="20"
            offset-y="50"
            bottom
            bordered
        ></v-badge>
        <FileUploadComponentVue
            v-if="hasFile"
            style="display:inline-block; transform: translateY(-10px);"
            ref="fileUpload"
            id="attachment"
            @uploadedData="uploadedData"
            @reset="clearFileUpload"
        ></FileUploadComponentVue>
        <BaseFileViewerComponent
            :access="true"
            :payloadIds="[]"
            :payloadId="0"
            :getFilesPath="'cashAdvanceGetFiles'"
            :deleteFilesPath="''"
            :module="'accounting'"
            :folder="'cash_advance_files'"
            :returnData="true"
            :localFiles="uploadedFiles"
            @files="processFiles"
            @return="insertUpload"
            ref="clearFiles"
        ></BaseFileViewerComponent>
        <v-dialog  v-model="progressDialog" max-width="500" height="100" persistent>
            <v-card flat>
                <v-card-title>
                    <p class="font-weight-medium"  style="font-size:12px">Saving Data...</p>
                </v-card-title>
                <v-card-text class="w-100">
                    <v-row class="w-100 mx-auto">
                        <v-col
                            cols="12"
                        >
                            <v-progress-linear
                                class="mx-auto"
                                v-model="chunksPercentage"
                                height="15"
                            >
                                <strong>{{ chunksPercentage }}%</strong>
                            </v-progress-linear>
                            <p class="font-weight-light align-right"  style="font-size:10px">{{ itemSave  }} / {{ toProcessFiles.length }} are saved</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        
    </v-container>
    
       


</template>
<script>
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
import BaseFileViewerComponent from "@/views/main/Shared/BaseFilesViewerComponent.vue"
import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue"
import { mapGetters } from 'vuex'
export default{
    props:['model','hasCamera','hasFile'],
    components: {
        FileUploadComponentVue,
        BaseFileViewerComponent,
        BaseCameraCaptureVue
    },
    data(){
        return{
            progressDialog:false,
            uploadedImages: [],
            images: [],
            compiledFiles: [],
            uploadedFiles: [],
            viewUploadedFiles: [],
            saveFiles: [],
            chunksPercentage: 0,
            itemSave: 0,
            progressDialog: false,
            toProcessFiles:[]
        }
    },
    computed:{
        ...mapGetters(['GET_RELATED_TRANSACTION_ID','GET_FILE_TRANSACTION_MODEL'])
    },
    methods:{
        async saveFileDetails(id){
            let chunkedFiles = this.chunkArray(this.saveFiles, 1)
            let chunkedImages = this.chunkArray(this.uploadedImages, 1)
            await this.saveData(chunkedFiles, chunkedImages, id);
        },
        chunkArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async saveData(chunkedFiles, chunkedImages, id) {
            this.toProcessFiles = [...chunkedFiles, ...chunkedImages]
            let count = 0;
            this.progressDialog = true;
            const chunksLength = this.toProcessFiles.length;
            if (this.toProcessFiles.length != 0) {
                for (const chunk of this.toProcessFiles) {
                    this.chunksPercentage = Math.round((count / chunksLength) * 100);
                    let payload = {
                        file: chunk,
                        m_id: id,
                        um:this.GET_FILE_TRANSACTION_MODEL,
                    }
                    await this.$store.dispatch('saveFiles', payload).then(response => {
                        if (response.data.message == 'success') {
                            this.itemSave += chunk.length
                            count ++;
                            if (this.itemSave == this.toProcessFiles.length) {
                                this.chunksPercentage = 100;
                                this.progressDialog = false;
                                this.loadButton = false
                                this.itemSave = 0;
                                this.$refs.clearFiles.$emit('clear-files');
                                Object.assign(this.$data, this.$options.data.call(this));
                            }
                        } else {
                            swal.fire('Error','- Error in saving file details', 'error');
                            this.progressDialog = false;
                            this.itemSave = 0;
                            this.loadButton = false
                        }
                    });
                }
            }
        },
        insertUpload(file){
            if (!!file) this.viewUploadFiles = file
        },
        async processFiles(files){
            files.forEach(e=>{
                this.compiledFiles.push(e.localFileState)
            })
            let fileData = []
            for (let i = 0; i < this.compiledFiles.length; i++) {
                let element = this.compiledFiles[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: 1,
                        })
                        resolve()
                    }
                })
            }
            this.saveFiles = fileData
        },
        clearFileUpload(confirm){
            if (confirm) {
                this.uploadedFiles = []
            }
            this.uploadedFiles = []
            this.$refs.fileUpload.reset()
        },
        uploadedData(data){
            let dataToProcess = {
                attachments: []
            }
            if (this.viewUploadedFiles != null && this.viewUploadedFiles.length > 0) {
                data.attachments.forEach(a=>{
                    let isDuplicate = this.viewUploadedFiles.some(e=>{
                        return e.name.concat(`.${e.extension}`) === a.name
                    })
                    if (isDuplicate) {
                        swal.fire('Duplicate Files Detected!', '', 'warning')
                        return
                    } else {
                        dataToProcess.attachments.push(a)
                    }
                })
            } else {
                dataToProcess.attachments = data.attachments
            }
            this.uploadedFiles = dataToProcess
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);
            this.uploadedImages = []
            this.images.forEach(e=>{
                e.upload_type = 2,
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1],
                            upload_type: element.upload_type,
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
    },
    watch:{
        GET_RELATED_TRANSACTION_ID:{
            handler(val){
                if(val){
                    this.$store.dispatch('getModel',this.GET_FILE_TRANSACTION_MODEL)
                }
                if(this.compiledFiles.length > 0){
                    this.saveFileDetails(val);
                }
            },
            deep:true
        },
    }
}
</script>