<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-row class="pa-1">
                <v-col>
                    <v-btn @click="addDialog()">
                        <v-icon>mdi-plus</v-icon>Add
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-end align-center mr-3">
                    <a href="#" style="color: white" class="ml-1 badge badge-all" @click="selectActive(-1);"> all </a>
                    <a href="#" style="color: white" class="ml-1 badge badge-success" @click="selectActive(1);"> active </a>
                    <a href="#" style="color: white" class="ml-1 badge badge-danger" @click="selectActive(0);"> not active </a>
                </v-col>
            </v-row>
            
            <v-row class="pa-1">
                <v-spacer />
                <v-col cols="2">
                    <v-text-field
                        v-model="dataTable.search"
                        label="Search Report Category Name"
                        append-icon="mdi-magnify"
                        @keydown.enter="searchInput()"
                        @click:append="searchInput()"
                        clearable
                    >

                    </v-text-field>
                </v-col>
            </v-row>

            <v-card-text>
                <v-data-table
                    :items="dataTable.report_category"
                    :headers="dataTable.headers"
                    :options.sync="dataTable.options"
                    :search="dataTable.search"
                    :server-items-length="dataTable.totalItems"
                    :loading="dataTable.loading"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions,
                    }"
                    dense
                >
                    <template v-slot:[`item.active`]="{ item }">
                        <span class="badge badge-yes" v-if="item.active == 1">{{ 'Yes' }}</span>
                        <span class="badge badge-no" v-if="item.active == 0">{{ 'No' }}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn icon text @click="viewReportCategory('View',item)" color="orange" small>
                            <v-icon small >mdi-eye</v-icon>
                        </v-btn>
                        <v-btn icon text @click="editReportCategory('Edit',item)" color="primary" small>
                            <v-icon small >mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon text @click="deleteReportCategory(item)" color="red" small>
                            <v-icon small >mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            itemsPerPageOptions: [5, 10, 25, 50, 100],
            dataTable: {
                report_category: [],
                headers: [
                    { text: 'Code', sortable: false, align: 'left', value: 'code' },
                    { text: 'Report Category Name', sortable: false, align: 'left', value: 'title' },
                    { text: 'Active', sortable: false, align: 'left', value: 'active' },
                    { text: 'Action', sortable: false, align: 'center', value: 'action' },
                ],
                options: {},
                loading: false,
                totalItems: 0,
                search: '',
            },
            selected_active: -1,
            userAccess: [],
        }
    },

    computed: {
        ...mapGetters([
            'GET_REPORT_MODULE',
            'GET_REFRESH_CATEGORY_REPORT',
            'USER_ACCESS'
        ]),
    },

    methods: {
        addDialog() {
            if(!this.userAccess.includes('add_category')) {
                return swal.fire('Error!','- No Access','error')
            }

            this.$store.commit('DIALOG', true)
            this.$store.commit('ACTION', 'Submit')
        },

        getReportCategory() {
            this.dataTable.report_category = []
            this.dataTable.loading = true
            this.dataTable.totalItems = 0
            this.$store.dispatch('reportsGet', {
                page:                       this.dataTable.options.page,
                itemsPerPage:               this.dataTable.options.itemsPerPage,
                search:                     this.dataTable.search,
                active:                     this.selected_active,
                url:                        'getReportCategoryModule',
            }).then(response => {
                this.dataTable.report_category  = response.data.data.data
                this.dataTable.totalItems       = response.data.data.total
                this.dataTable.loading          = false
                this.$store.commit('REFRESH_CATEGORY_REPORT', false)
            })
        },

        viewReportCategory(action, item) {
            this.$store.commit('ACTION', action)
            this.$store.commit('REPORT_CATEGORY_VIEW', item)
            this.$store.commit('DIALOG', true)
        },

        editReportCategory(action, item) {
            if(!this.userAccess.includes('edit_category')) {
                return swal.fire('Error!','- No Access','error')
            }

            this.$store.commit('ACTION', action)
            this.$store.commit('REPORT_CATEGORY_VIEW', item)
            this.$store.commit('DIALOG', true)
        },

        searchInput() {
            this.dataTable.options.page = 1;
            this.getReportCategory({ query: this.dataTable.search });
        },

        selectActive(active) {
            this.selected_active = active

            this.getReportCategory()
        },

        deleteReportCategory(item) {
            swal.fire({
                title:'Delete',
                text:'- Are you sure you want to Delete?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('reportsModulePost',{
                        url: 'deleteReportCategoryModule',
                        id: item.report_category_id,
                        route: item.route,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.getReportCategory()
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                    })
                }
            })
        }
    },

    watch: {
        'dataTable.options': {
            handler(val) {
                this.getReportCategory()
            },
            deep: true,
        },

        GET_REFRESH_CATEGORY_REPORT: {
            handler(val) {
                if(val) {
                    this.getReportCategory()
                }
            }
        },

        USER_ACCESS: {
            handler(val) {
                this.userAccess = val.map(e => e.actions_code)
            }
        },
    },

    mounted() {
        this.getReportCategory()
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    }

}   
</script>

<style>

</style>