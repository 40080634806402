<template>
    <div>
        <!-- <add-bank-component :bankType="bankType" /> -->

        <v-card>
            <v-card-title>
                <v-row align="center" class="pa-3">
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="filter"
                            :items="filtering_items"
                            item-text="text"
                            item-value="value"
                            outlined
                            dense
                            :multiple="nonTypeTab"
                            :small-chips="nonTypeTab"
                            :deletable-chips="nonTypeTab"
                            clearable
                            :label="nonTypeTab ? 'Type' : 'Signed/ Unassigned'"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-btn class="mb-1" @click="getAllBanks()">
                            Filter
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="2">
                        <v-text-field
                            v-model="search"
                            label="Search by Bank Code/ Name"
                            single-line
                            hide-details
                            @keypress.enter="searchInput()"
                        >
                            <template #append>
                                <v-btn small icon text @click="searchInput()" >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="banks"
                    :search="search"
                    :items-per-page="itemsPerPage"
                    :server-items-length="totalItems"
                    :options.sync="options"
                    :loading="!loading"
                    loading-text="Loading Please wait..."
                    disable-sort
                >
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="paragraph">{{ item.description }}</div>
                    </template>
                    <template v-slot:[`item.address`]="{ item }">
                        <div class="paragraph">{{ item.address }}</div>
                    </template>
                    <template v-slot:[`item.bank_types`]="{ item }">
                        <span v-for="(row, i) in item.bank_types" :key="i" class="pa-1">
                            <v-chip x-small color="primary">{{ row }}</v-chip>
                        </span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon class="btn-action" small @click="viewBank(item.id)">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" v-if="userAccess.edit">
                            <v-icon class="btn-action" small @click="editBank(item.id)">mdi-square-edit-outline</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon class="btn-action" small @click="deleteBank(item.id)">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="viewBankDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Bank Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewBankDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-4 pb-4">
                    <div><span>Code: </span><span>{{ code }}</span></div>
                    <div><span>Name: </span><span>{{ name }}</span></div>
                    <!-- <div><span>Account Number:</span><span>{{ account_number }}</span></div> -->
                    <div><span>Description: </span><span>{{ description }}</span></div>
                    <div><span>Address: </span><span>{{ address }}</span></div>
                    <div><span>Allowed Payments: </span><span>{{ payments }}</span></div>
                    <div><span>Account Assigned: </span><span>{{ account_name }}</span></div>
                    <div><span>Opening Balance: </span><span>{{ opening_balance }}</span></div>
                    <div><span>Balance Date: </span><span>{{ balance_date }}</span></div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import AddBankComponent from '../BanksComponent/AddBankComponent.vue';
import swal from 'sweetalert2';

export default {
    data () {
        return {
            actionResult : {
                model : 'banks',
                action : ''
            },
            loaded: false,
            banks: [],
            itemsPerPageOptions: [5, 10 ,15, 20],
            itemsPerPage: 5,
            search: '',
            headers: [
                { text: 'Code', value: 'code' },
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Address', value: 'address' },
                { text: 'Account', value: 'account_name' },
                { text: 'Actions', value: 'action', align: 'center' },
            ],
            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },
            loading: true,

            viewBankDialog: false,
            bankDetails: null,
            code: '',
            name: '',
            description: '',
            address: '',
            viewBankId: '',
            payments: [],
            account_name: '',
            opening_balance: '',
            balance_date: '',
            // account_number: '',
            coa_id: 0,
            filtering_items: [
                { text: 'UNASSIGNED BANKS', value: 'is' },
                { text: 'SIGNED BANKS', value: 'is not' },
            ],
            filter: null,
            options:{},
            totalItems:0,
            closed_account_filter: 0
        }
    },
    components: {
        // AddBankComponent
    },
    props: [
        'bankType',
    ],
    mounted() {
        // this.getAllBanks();
        this.$store.dispatch('getPaymentTypes')
        this.$store.dispatch('getCOASelection')
        this.$store.dispatch('getCompaniesDropdown');
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.$store.dispatch('getAllSelectUsers')
    },
    computed: {
        successAddEdit() {
            return this.$store.state.accounting.bank.successAddEdit;
        },
        bankId() {
            return this.$store.state.accounting.bank.bankId;
        },
        ...mapGetters([
            'USER_ACCESS',
            'GET_PAYMENT_TYPES',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_ALL_USERS_SELECT'
        ]),
        nonTypeTab(){
            return ['OB', 'CLOSED', 'ALL'].includes(this.bankType)
        }
    },
    methods:{
        clearFields() {
            this.code = "";
            this.name = "";
            this.description = "";
            this.address = "";
            this.viewBankId = "";
            this.payments = [];
            this.account_name = "";
            this.opening_balance = "";
            this.balance_date = "";
            // this.account_number = "";
        },
        getAllBanks(){
            this.loading = false;
            this.banks = [];
            this.totalItems = 0;
            let payload = {
                url: 'getBanks',
                filter: this.filter,
                search: this.search,
                page: this.options.page,
                items_per_page: this.options.itemsPerPage,
                type:this.bankType,
                closed_account:this.closed_account_filter
            }
            this.$store.dispatch('banksGet',payload).then(response => {
                this.loading = true
                this.banks = response.data.data
                this.totalItems = response.data.total
            })
        },
        viewBank(id){
            this.viewBankId = id;
            this.viewBankDialog = true;
        },
        closeViewBankDialog() {
            this.clearFields();
            this.viewBankDialog = false;
        },
        getBankDetails() {
            let payload = {
                url: `getBankDetails/${this.viewBankId}`,
            }
            this.$store.dispatch('banksGet',payload).then(response=>{
                this.bankDetails = response.data;
            });
        },
        editBank(id){
            if (this.bankType != 'OB') {
                this.$store.commit('bankSetId', id);
            } else {
                this.$store.commit('bankSetIdForOpeningBalance', id);
            }
        },
        deleteBank(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url: 'deleteBank',
                        bankId: id
                    }
                    this.$store.dispatch('banksPost',payload).then(response => {
                        if(response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.getAllBanks();
                            swal.fire('', response.data.msg, 'success');
                        }
                    })
                }
            });
        },
        searchInput(){
            this.options.page = 1;
            this.getAllBanks();
        }
    },
    watch: {
        successAddEdit() {
            if(this.successAddEdit) {
                this.getAllBanks();
            }
        },
        bankId() {
            if(!!this.bankId) {
                this.$store.commit('bankDialogAction', 'edit');
                if (this.bankType != 'OB') {
                    this.$store.commit('bankShowAddEditDialog');
                } else {
                    this.$store.commit('bankOBShowAddEditDialog');
                }
            }
        },
        viewBankId() {
            if(!!this.viewBankId) {
                this.getBankDetails();
            }
        },
        bankDetails() {
            if(!!this.bankDetails) {
                const bank = this.bankDetails;
                this.code = bank.code;
                this.name = bank.name;
                this.description = bank.description;
                this.address = bank.address;
                this.account_name = bank.account_name;
                this.opening_balance = parseFloat(bank.opening_balance).toFixed(2);
                this.balance_date = bank.balance_date;
                // this.account_number = bank.account_number;

                let payment_ids = bank.bank_payment_types.map(e=>{return e.payment_type_id})
                // let payment_
                // payment_ids.forEach(e=>{
                //     if(this.GET_PAYMENT_TYPES)
                // })
                this.GET_PAYMENT_TYPES.forEach(e=>{
                    payment_ids.forEach(x=>{
                        if(x == e.id){
                            this.payments.push(e.name) 
                        }
                    })
                })

                this.payments = this.payments.toString();
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        },
        options:{
            handler(val){
                this.getAllBanks()
            }
        },
        bankType:{
            handler(val){
                if(val == 'OB' || val == 'ALL' || val == 'CLOSED'){
                    this.headers = [
                        { text: 'Code', value: 'code' },
                        { text: 'Name', value: 'name' },
                        { text: 'Account', value: 'account_name' },
                        { text: 'Type', value: 'bank_types', align: 'center' },
                        { text: 'Actions', value: 'action', align: 'center' },
                    ]
                    this.filtering_items = [
                        { text: 'AR', value: 'AR' },
                        { text: 'AP', value: 'AP' },
                    ]
                    this.filter = []
                    if (val == 'CLOSED') {
                        this.closed_account_filter = 1
                    } else {
                        this.closed_account_filter = 0
                    }
                } else {
                    this.headers = [
                        { text: 'Code', value: 'code' },
                        { text: 'Name', value: 'name' },
                        { text: 'Description', value: 'description' },
                        { text: 'Address', value: 'address' },
                        { text: 'Account', value: 'account_name' },
                        { text: 'Actions', value: 'action', align: 'center' },
                    ]
                    this.filtering_items = [
                        { text: 'UNASSIGNED BANKS', value: 'is' },
                        { text: 'SIGNED BANKS', value: 'is not' },
                    ]
                    this.filter = ''
                    this.closed_account_filter = 0
                }
                    this.getAllBanks();
                    this.$forceUpdate()
            },
            immediate:true,
            deep:true,
        },
    },
};
</script>
<style scoped>
.paragraph {
    white-space: pre !important;
}
</style>
