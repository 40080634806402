<template>
    <v-form ref="form" v-model="valid" class="overflow-x-hidden" >
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Leave Request {{this.$route.name == 'hrshowleaverequest' ? getEmployeeDetailsfromDropdown() : '' }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pa-4" fluid>
                    <v-row>
                        <v-col cols="3">
                            <FileUploadComponent v-if="actions != 'View'" @uploadedData="uploadedData" ref="fileUpload" @reset="clearFileUpload" />
                            <BaseFileViewer v-if="actions != 'Submit'" :leave_request_id="GET_HR_LEAVES_DATA_VIEW.id"></BaseFileViewer>
                            <v-menu
                                v-if="Array.isArray(this.GET_HR_LEAVES_DATA_VIEW.leave_request_history) && this.GET_HR_LEAVES_DATA_VIEW.leave_request_history.length"
                                bottom
                                :close-on-content-click="false"
                                max-width="400"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        style="opacity:1; margin-bottom: 15px;"
                                        text
                                        fab
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>
                                            mdi-history
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item style="max-width: 400px; overflow:auto;">
                                        <!-- <v-list-item-action> -->
                                            <v-container>
                                                <v-card-title class="px-0 pt-0">Leave Request History</v-card-title>

                                                <v-card-text>
                                                    <v-row v-for="(history, i) in history_data_keys" :key="i">
                                                        <v-col>
                                                            <span>
                                                                {{ history.text }}: {{ leave_request_history[history.key] }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-container>
                                        <!-- </v-list-item-action> -->
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="3" v-if="GET_HR_EMPLOYEE_LEAVE_WITH_PAY.pro_rated === 1">
                            <v-checkbox
                                v-model="form.advanced"
                                @click="triggerLeaveAdvanced()"
                                :true-value="1"
                                :false-value="0"
                                :disabled="disabled_view || !form.total_days ? true : false"
                                label="Advanced Leave"
                                dense
                            >
                            </v-checkbox>
                        </v-col>
                        <v-col cols="2" v-if="actions != 'Submit' && this.form.leave_type_id == -1 && (this.hr_id == this.USERACCOUNT_LOAD.department_id)">
                            <!-- <v-text-field
                                v-model="form.pro_rated_balance"
                                :disabled="disabled_view"
                                :rules="errors.required"
                                label="Pro Rated Balance"
                                required
                                outlined
                                dense
                                clearable
                            ></v-text-field> -->
                            <v-checkbox
                                v-model="form.with_pay"
                                :true-value="1"
                                :false-value="0"
                                :disabled="disabled_view"
                                label="With Pay"
                                dense
                            >
                            </v-checkbox>
                            <!-- <v-checkbox
                                v-model="form.with_pay"
                                :true-value="1"
                                :false-value="0"
                                :disabled="disabled_view || (form.employee_id ? false : true)"
                                @click="checkEmployeeLeaveWithPay(1)"
                                label="Without Pay"
                                dense
                            >
                            </v-checkbox> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="4" v-if="checkLeaveTypeCode('UD') === this.form.leave_type_id || checkLeaveTypeCode('HD') === this.form.leave_type_id">
                            <v-autocomplete
                                v-model="form.leave_type_used_on_id"
                                :items="selection.leave_type_used_on"
                                :rules="errors.required"
                                :disabled="!form.total_days || disabled_view ? true : false"
                                @change="validateTotalDaysUDHD(form.leave_type_used_on_id)"
                                label="Leave Type Used On"
                                item-text="text"
                                item-value="value"
                                outlined
                                auto-select-first
                                dense
                                required
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="form.date_filed_menu"
                                transition="scale-transition"
                                ref="menu"
                                min-width="auto"
                                max-width="290px"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.date_filed"
                                        :disabled="disabled_view"
                                        :rules="errors.required"
                                        v-on="on"
                                        required
                                        label="Date Of Filing"
                                        outlined
                                        dense
                                        clearable
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="form.date_filed"
                                    :rules="errors.required"
                                    required
                                    no-title
                                    scrollable
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="pa-0" v-if="$route.name != 'hrshowleaverequest'">
                        <v-col cols="12">
                             <v-autocomplete
                                v-model="form.employee_id"
                                @input="checkEmployeeLeaveWithPay(1), checkLeaveRequestDates()"
                                :items="selection.employee_lists"
                                :rules="errors.required"
                                :disabled="disabled_view"
                                :loading="GET_HR_LOADING.employee_list"
                                label="Employee Name"
                                item-text="full_name"
                                item-value="value"
                                outlined
                                auto-select-first
                                dense
                                required
                            >
                            </v-autocomplete>
                        </v-col>

                    </v-row>
                    <v-row class="pa-0">
                        <v-col :cols="actions != 'Submit' && this.form.leave_type_id == -1 && (this.hr_id == this.USERACCOUNT_LOAD.department_id) ? 8: 12" class="pb-3">
                            <v-autocomplete
                                v-model="form.leave_type_id"
                                :items="selection.leave_types"
                                :rules="errors.required"
                                :disabled="disabled_view"
                                @input="validateTotalDays()"
                                label="Leave Type"
                                item-text="text"
                                item-value="value"
                                outlined
                                auto-select-first
                                dense
                                required
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4" v-if="actions != 'Submit' && this.form.leave_type_id == -1 && (this.hr_id == this.USERACCOUNT_LOAD.department_id)">
                            <v-autocomplete
                                v-model="form.to_be_used_leave_type_id"
                                :items="selection.leave_types"
                                :rules="errors.required"
                                :disabled="disabled_view"
                                @input="validateTotalDaysOthers()"
                                label="Leave Type To Be Used"
                                small-chips
                                item-text="text"
                                item-value="value"
                                outlined
                                auto-select-first
                                dense
                                required
                            >
                            </v-autocomplete>
                        </v-col>

                    </v-row>

                    <v-row v-if="form.leave_type_id == -1" class="d-flex align-center pb-2 mt-0">
                        <v-col>
                            <v-textarea
                                v-model="form.others"
                                :height="50"
                                :rules="errors.required"
                                :disabled="disabled_view"
                                label="Please Specify"
                                type="number"
                                outlined
                                no-resize
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <!-- <v-row class="mt-0" v-if="checkLeaveType()">
                        <v-col>
                            <v-datetime-picker
                                v-model="form.inclusive_date[0]"
                                :text-field-props="{
                                    readonly: true,
                                    outlined: true,
                                    dense: true,
                                    hideDetails: true,
                                    appendIcon: 'mdi-calendar'
                                }"
                                :date-picker-props="{
                                    'allowed-dates': allowedDates
                                }"
                                :disabled="disabled_view || dateDisable()"
                                :allowed-dates="allowedDates"
                                ref="date_from"
                                label="Inclusive Dates From"
                                class="mb-2"
                                date-format="yyyy-MM-dd"
                                time-format="HH:mm"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon>mdi-calendar-range</v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock-time-eight</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                        <v-col>
                            <v-datetime-picker
                                v-model="form.inclusive_date[1]"
                                :text-field-props="{
                                    readonly: true,
                                    outlined: true,
                                    dense: true,
                                    hideDetails: true,
                                    appendIcon: 'mdi-calendar'
                                }"
                                :date-picker-props="{
                                    'allowed-dates': allowedDates
                                }"
                                :disabled="disabled_view || dateDisable()"
                                ref="date_to"
                                label="Inclusive Dates To"
                                date-format="yyyy-MM-dd"
                                time-format="HH:mm"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon>mdi-calendar-range</v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock-time-eight</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-row> -->
                    <v-row class="mt-0" v-if="checkLeaveType()">
                        <v-col :cols="checkCode() ? '4' :'6'">
                            <v-menu
                                v-model="form.inclusive_date_menu"
                                transition="scale-transition"
                                ref="menu"
                                min-width="auto"
                                max-width="290px"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.inclusive_date"
                                        :disabled="disabled_view"
                                        :rules="errors.required"
                                        required
                                        v-on="on"
                                        label="Inclusive Dates (YYYY-MM-DD)"
                                        outlined
                                        dense
                                        clearable
                                    ></v-text-field>
                                </template>
                                 <v-card flat elevation="0" :rounded="false"
                                    style="border-radius: 0px !important;"
                                >
                                    <v-row no-gutters class="ml-2">
                                        <v-col cols="3" v-for="(leave_type, i) in GET_LEAVE_TYPE_SELECTION" :key="i">
                                            <v-chip
                                                :color="leave_type.color"
                                                x-small>
                                                {{ leave_type.value === -1 ? 'OTH': leave_type.code }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-date-picker
                                    v-model="form.inclusive_date"
                                    :rules="errors.required"
                                    :allowed-dates="allowedDates"
                                    :events="leaveRequestEvents"
                                    @click:date="closeDateMenu()"
                                    required
                                    no-title
                                    scrollable
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="checkCode() ? '4' :'6'">
                            <v-menu
                                v-model="form.time_from_menu"
                                :disabled="disabled_view || form.inclusive_date.length == 0"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.time_from"
                                        :disabled="disabled_view"
                                        :readonly="form.inclusive_date.length == 0"
                                        label="Time From"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                        flat
                                    ></v-text-field>
                                </template>
                                <v-time-picker format="24hr"
                                    v-model="form.time_from"
                                    @click:minute="form.time_from_menu = false, computeTotalDays(), validateTotalDaysForHour(), validateTotalDaysUDHD(form.leave_type_used_on_id)"
                                    :min="schedule_for_the_day.time_in"
                                    :max="schedule_for_the_day.time_out"
                                    scrollable
                                    >
                                </v-time-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="4" v-if="checkCode()">
                            <v-menu
                                v-model="form.time_to_menu"
                                :disabled="disabled_view || form.inclusive_date.length == 0"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.time_to"
                                        :readonly="disabled_view || form.inclusive_date.length == 0"
                                        label="Time To"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                        flat
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="form.time_to"
                                    @click:minute="form.time_to_menu = false, computeTotalDays(), validateTotalDaysForHour(), validateTotalDaysUDHD(form.leave_type_used_on_id)"
                                    :min="form.time_from"
                                    :max="schedule_for_the_day.time_out"
                                    :allowed-hours="form.allowed_hours_to"
                                    :allowed-minutes="form.allowed_minutes_to"
                                    format="24hr"
                                    scrollable
                                >
                                </v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0" v-else>
                        <v-col>
                            <v-menu
                                v-model="form.inclusive_date_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                ref="menu"
                                min-width="auto"
                                max-width="290px"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRangeText"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="clearDates()"
                                        :disabled="disabled_view"
                                        :rules="errors.required"
                                        required
                                        label="Inclusive Dates (YYYY-MM-DD)"
                                        outlined
                                        dense
                                        clearable
                                    ></v-text-field>
                                </template>
                                <v-card flat elevation="0" :rounded="false"
                                    style="border-radius: 0px !important;"
                                >
                                    <v-row no-gutters class="ml-2">
                                        <v-col cols="3" v-for="(leave_type, i) in GET_LEAVE_TYPE_SELECTION" :key="i">
                                            <v-chip
                                                :color="leave_type.color"
                                                x-small>
                                                {{ leave_type.value === -1 ? 'OTH': leave_type.code }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-date-picker
                                    v-model="form.inclusive_date"
                                    :rules="errors.required"
                                    :allowed-dates="allowedDates"
                                    :events="leaveRequestEvents"
                                    @input="form.others === null ? computeTotalDaysBasedOnRange() : computeTotalDaysBasedOnRangeOthers()"
                                    :color="checkDateColor()"
                                    style="border-radius: 0px !important;"
                                    required
                                    flat
                                    no-title
                                    range
                                    scrollable
                                >

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex align-center pa-0">
                        <v-col>
                            <v-text-field
                                v-model="form.total_days"
                                :disabled="disabled_view"
                                label="Total Days"
                                background-color="grey lighten-2"
                                type="number"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pa-0 mt-2">
                        <v-col>
                            <v-textarea
                                v-model="form.reason"
                                :disabled="disabled_view"
                                :height="50"
                                :rules="errors.required"
                                label="Reason"
                                required
                                no-resize
                                outlined
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="pa-0" v-if="GET_HR_LEAVES_DATA_VIEW.status == 4">
                        <v-col>
                            <v-textarea
                                v-model="form.reject_reason"
                                :disabled="disabled_view"
                                label="Reject Reason"
                                height="85"
                                outlined
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="pa-0" v-if="GET_HR_LEAVES_DATA_VIEW.status == 5">
                        <v-col>
                            <v-textarea
                                v-model="form.cancel_reason"
                                :disabled="disabled_view"
                                label="Cancel Reason"
                                height="85"
                                outlined
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex justify-content-end pa-0">
                        <v-col cols="3" class="mr-3" >
                            <v-text-field
                                v-model="form.days_w_pay"
                                :disabled="disabled_view"
                                label="Day w/pay"
                                type="number"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="mr-3">
                            <v-text-field
                                v-model="form.days_wo_pay"
                                :disabled="disabled_view"
                                label="Day w/o pay"
                                type="number"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="checkLeaveTypeCode('UD') === this.form.leave_type_id || checkLeaveTypeCode('HD') === this.form.leave_type_id">
                            <v-text-field
                                v-model="form.leave_type_used_on_day_w_pay"
                                :disabled="disabled_view"
                                label="Used On Day w/pay"
                                type="number"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1  pb-2">
                    <v-col cols="12" >
                        <v-row>
                            <v-col>
                                <v-btn
                                    v-if="ACTION != 'View'"
                                    @click="
                                    ACTION == 'Submit'? addUpdateLeaveRequest('add') : addUpdateLeaveRequest('update')"
                                    :disabled="disabled_view"
                                    :loading="loading.button"
                                >
                                    <span>{{ actions }}</span>
                                </v-btn>
                            </v-col>
                            <span class="pr-12">&nbsp;</span>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="ml-5" v-if="ACTION == 'View'">
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status == 1 && (userAccess.includes('approve_leave_request') || USERACCOUNT_LOAD.manager == 1)"
                            @click="changeLeaveRequestStatus('approve')"
                            :disabled="!isApproveButtonLoaded"
                            :loading="loading.button"

                        >
                            <span>Approve</span>
                        </v-btn>
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status == 2 && (userAccess.includes('confirm_leave_request') || USERACCOUNT_LOAD.manager == 1)"
                            @click="changeLeaveRequestStatus('confirm')"
                            :disabled="!isApproveButtonLoaded"
                            :loading="loading.button"

                        >
                            <span>Confirm</span>
                        </v-btn>
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status != 4 && GET_HR_LEAVES_DATA_VIEW.status != 3 && GET_HR_LEAVES_DATA_VIEW.status != 5  && (userAccess.includes('reject_leave_request') || USERACCOUNT_LOAD.manager == 1)"
                            @click="openRejectDialog('reject')"
                            :loading="loading.button"
                            class="ml-3"
                            color="red"
                        >
                            <span>Reject</span>
                        </v-btn>
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status != 5 && GET_HR_LEAVES_DATA_VIEW.reused == 0"
                            @click="openRejectDialog('cancel')"
                            class="ml-3"
                            color="red"
                        >
                            <span>Cancel</span>
                        </v-btn>
                        <v-btn
                            v-if="GET_HR_LEAVES_DATA_VIEW.status == 5 && GET_HR_LEAVES_DATA_VIEW.reused == 0"
                            @click="changeLeaveRequestStatus('reuse')"
                            :loading="loading.button"
                            color="#9eb5d9"
                        >
                            <span>Reuse</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="leaveRejection.dialog"
            width="40%"
            persistent
        >
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                        <span class="headline">{{ status.charAt(0).toUpperCase() + status.slice(1) }} Reason</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeRejectDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />
                <v-card-text>
                    <v-row class="mt-4">
                        <v-col>
                            <v-textarea
                                v-model="form[status + '_reason']"
                                label="Reason"
                                height="85"
                                outlined
                                required
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn
                            @click="changeLeaveRequestStatus(status)"
                            :loading="loading.button"
                            class="float-center"
                            >
                            <span>Submit</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="override.headers"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
        <!-- <LeaveRequestVerificationComponentViewVue/> -->
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import FileUploadComponent from '@/views/main/Shared/FileUploadComponent.vue'
import BaseFileViewer from "@/views/main/layouts/dialogs/HR/LeaveManagement/LeaveRequestFileViewerComponent.vue"
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";
// import LeaveRequestVerificationComponentViewVue from '@/views/main/layouts/dialogs/HR/LeaveManagement/LeaveRequestVerificationComponentView.vue';

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Cost is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {
                leave_type_id:'',
                inclusive_date: [],
                total_days: '',
                reason: '',
                others: null,
                isOthers: false,
                with_pay: 1,
                leaveFiles: [],
                date_filed_menu: false,
                time_from_menu: false,
                time_from: null,
                time_to_menu: false,
                time_to: null,
                date_filed: this.$dayjs().format('YYYY-MM-DD'),
                time_to_limit: null,
                half_day_hours: 0,
                reused: 0
            },
            leave_type_selection:[],
            selection:{
                leave_types: [],
                employee_lists: [],
                leave_type_used_on: []
            },
            component_dispatches: [
                {
                    loading_key: 'leave_type',
                    dispatch_name: 'getLeaveTypeSelection'
                },
                {
                    loading_key: 'employee_list',
                    dispatch_name: 'getEmployeeDataDropdown'
                },
                {
                    loading_key: 'department_list',
                    dispatch_name: 'getDepartment'
                },
            ],
            viewed_count: 0,
            userAccess: [],
            loading:{
                button: false
            },
            employee_schedule: {},
            includedDates: [],
            includedDatesOthers: [],
            withHoursTypeIDS: [],
            schedule_for_the_day: {},
            leaveRejection:{
                dialog: false
            },
            checked_department_ids: [],
            isApproveButtonLoaded: false,
            leaveRequestDatesOnly: [],
            leaveRequestDates: [],
            hr_id: '',
            settings:{
                maximum_leave_total_days: 0,
                sick_leave_total_days_attachment: 0,
            },
            status: '',
            pro_rated: 0,
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ["HR", "IT"],
                override_authenticated: false,
                headers: 'Advanced Leave'
            },
            leave_request_history: {},
            history_data_keys: [
                {text: 'Leave Type', key: 'leave_type_name'},
                {text: 'Leave Date', key: 'leave_dates'},
                {text: 'Total Days', key: 'total_days'},
                {text: 'Cancelled Date', key: 'cancelled_at'},
                {text: 'Cancel Reason', key: 'cancel_reason'},
                {text: 'Days w/pay', key: 'days_with_pay'},
                {text: 'Days wo/pay', key: 'days_without_pay'},
            ],
            loading:{
                button: false
            }
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            'GET_DEPARTMENT_ROLE_LOAD',
			"GET_HR_LEAVES_DATA_VIEW",
            "GET_HR_LEAVES_NEW_DATA",
            "DIALOGS",
            "GET_LEAVE_TYPE_SELECTION",
            "GET_HR_DISPATCH",
            "GET_EMPLOYEE_DATA",
            "USER_ACCESS",
            "USERACCOUNT_LOAD",
            "GET_HR_EMPLOYEE_LEAVE_WITH_PAY",
            "GET_SHOW_EMPLOYEE_SCHEDULES",
            "GET_HR_LEAVE_REQUEST_APPROVABLE",
            "GET_HR_LEAVE_REQUEST_DATES_BY_LEAVE_TYPE",
            "GET_HR_LEAVE_REQUEST_DATES_ONLY",
            "GET_HR_LOADING",
            "GET_LIST_DEPARTMENT",
            "GET_YEAR_RANGE"
        ]),
        actions() {
            // this.viewed_count = 0
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                if(this.DIALOGS && this.viewed_count < 2 && this.$route.name == 'hrshowleaverequest'){
                    this.form.employee_id = this.GET_HR_LEAVES_DATA_VIEW.id;
                    this.getEmployeeSchedule();
                    this.checkEmployeeLeaveWithPay(1);
                    this.checkLeaveRequestDates();
                    this.viewed_count++
                }
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getLeaveRequestData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getLeaveRequestData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.inclusive_date.join(' - ')
        },
    },
    components:{
        FileUploadComponent,
        BaseFileViewer,
        ConfirmOverrideComponentVue,
        // LeaveRequestVerificationComponentViewVue,
    },
    async mounted() {
        await this.$store.dispatch("credentials");
        await this.getAllDropdowns();
        await this.checkDepartmentAccess();
        await this.getLeaveSettings();
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.getYearRange('YEAR_RANGE', 2, 2);
    },
    methods: {
        initialForm() {
            this.valid = true;
            this.form = {
                leave_type_id:'',
                inclusive_date_menu: false,
                inclusive_date: [],
                total_days: '',
                reason: '',
                others: null,
                isOthers: false,
                with_pay: 1,
                leaveFiles: [],
                date_filed_menu: false,
                time_from_menu: false,
                time_from: null,
                time_to_menu: false,
                time_to: null,
                date_filed: this.$dayjs().format('YYYY-MM-DD'),
                pro_rated: 0,
                cancel_reason: '',
                time_to_limit: null,
                half_day_hours: 0,
                reused: 0,
            };
        },
        height(width) {1
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.viewed_count = 0
            this.$store.commit("DIALOG", false);
            this.$store.commit("HR_LEAVES_DATA_VIEW", {});
            this.$store.commit("HR_LEAVE_REQUEST_APPROVABLE", false);
            this.$store.commit("HR_LEAVE_REQUEST_DATES_ONLY", []);
            this.$store.commit("HR_LEAVE_REQUEST_DATES_BY_LEAVE_TYPE", []);
            this.leaveRequestDates = [];
            this.leaveRequestDatesOnly = [];
            this.includedDates = [];
            this.includedDatesOthers = [];
        },
        async addUpdateLeaveRequest(action = 'add') {
            this.$refs.form.validate();
            // if(this.withHoursTypeIDS.includes(this.form.leave_type_id)){
            //     this.form.inclusive_date.forEach((date, index) => {
            //         this.form.inclusive_date[index] = this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss');
            //     });
            // }
            // if(this.form.days_w_pay < 1 && this.form.days_wo_pay < 1){
            //     Swal.fire(
            //         "Day with and without pay must not be 0!",
            //         "",
            //         "warning"
            //     )
            //     return false
            // }else{
                if(action == 'add'){
                    if(this.form.total_days >= this.settings.sick_leave_total_days_attachment && this.checkLeaveTypeCode('SL') == this.form.leave_type_id){
                        if(Array.isArray(this.form.leaveFiles) && this.form.leaveFiles.length == 0){
                            Swal.fire(
                                `Sick Leave is more than or equal to ${this.settings.sick_leave_total_days_attachment} days!`,
                                "Please upload file attachments including Medical Certificate!",
                                "info"
                            )
                            return false
                        }
                    }
                    else if(this.checkLeaveTypeCode('SPL') == this.form.leave_type_id){
                        if(Array.isArray(this.form.leaveFiles) && this.form.leaveFiles.length == 0){
                            Swal.fire(
                                `File Attachments Required!`,
                                "Please upload file attachments including Solo Parent ID/Certificate!",
                                "info"
                            )
                            return false
                        }
                    }
                }
                this.submitLeaveRequestNow(action);
            // }
        },
        submitLeaveRequestNow(action){
            this.loading.button = true;
            this.form.route = this.$route.name;
            let leave_type_swal_text = '';
            let inclusive_date_swal_text = this.form.inclusive_date.length > 0 ? '' : 'Inclusive Dates';
            let employee_swal_text = this.form.employee_id ? '' : 'Employee Name';
            let reason_swal_text = this.form.reason ? '' : 'Reason';
            let other_leave_swal_text = this.form.others ? '' : 'Other Leave Specify';

            let array_text =
            [
                leave_type_swal_text,
                inclusive_date_swal_text,
                reason_swal_text,
                employee_swal_text,
                other_leave_swal_text
            ]
            let filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
            if(this.form.leave_type_id == -1){
                if (this.form.others && this.form.inclusive_date.length > 0 && this.form.reason) {
                    this.$store.dispatch(this.GET_HR_DISPATCH[action], this.form).then(response=>{
                        Swal.fire({
                            title: response.icon.toUpperCase(),
                            html: response.message,
                            icon: response.icon
                        }).then((action)=>{
                            if(this.$route.name == 'hrshowleaverequest' && response.icon == 'success'){
                                // if(action.isConfirmed){
                                //     this.$store.commit('HR_VERIFICATION_LEAVE_REQUEST_ID', response.id)
                                //     this.openVerificationCodeDialog();
                                // }
                            }
                        })
                        this.loading.button = false;
                    })
                } else {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: 'warning',
                        allowOutsideClick: false,
                    });
                    this.loading.button = false;
                }
            }else{
                if (this.form.leave_type_id && this.form.inclusive_date.length > 0 && this.form.reason && this.form.employee_id) {
                    this.$store.dispatch(this.GET_HR_DISPATCH[action], this.form).then(response=>{
                        Swal.fire({
                            title: response.icon.toUpperCase(),
                            html: response.message,
                            icon: response.icon
                        }).then((action)=>{
                            if(this.$route.name == 'hrshowleaverequest' && response.icon == 'success'){
                                // if(action.isConfirmed){
                                //     this.$store.commit('HR_VERIFICATION_LEAVE_REQUEST_ID', response.id)
                                //     this.openVerificationCodeDialog();
                                // }
                            }
                        })
                        this.viewed_count = 0;
                        this.$store.commit("DIALOG", false);
                        this.loading.button = false;
                    })
                } else {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: 'warning',
                        allowOutsideClick: false,
                    });
                    this.loading.button = false;
                }
            }
        },
        async getLeaveRequestData() {
            this.withHoursTypeIDS = this.selection.leave_types.filter(obj => obj.with_manual === 1).map(filteredObj => filteredObj.value);
            this.form.inclusive_date_menu = false;
            this.form.date_filed_menu     = false;

            // if(this.GET_HR_LEAVES_DATA_VIEW.with_pay == 1 && this.viewed_count == 0){
            //     this.callCheckerDispatch(this.GET_HR_LEAVES_DATA_VIEW.employee_id)
            // }

            if(this.DIALOGS && this.viewed_count < 2){
                // this.form                          = this.GET_HR_LEAVES_DATA_VIEW;
                this.form.id                    = this.GET_HR_LEAVES_DATA_VIEW.id;
                this.form.date_filed            = this.GET_HR_LEAVES_DATA_VIEW.date_filed;
                this.form.leave_request_files   = this.GET_HR_LEAVES_DATA_VIEW.leave_request_files;
                this.form.employee_id           = parseInt(this.GET_HR_LEAVES_DATA_VIEW.employee_id);

                this.form.leave_type_id     = this.GET_HR_LEAVES_DATA_VIEW.others === null ? parseInt(this.GET_HR_LEAVES_DATA_VIEW.leave_type_id) : -1;
                this.form.to_be_used_leave_type_id = parseInt(this.GET_HR_LEAVES_DATA_VIEW.leave_type_id);

                this.form.reason                = this.GET_HR_LEAVES_DATA_VIEW.reason;
                this.form.status                = this.GET_HR_LEAVES_DATA_VIEW.status;
                this.form.reject_reason         = this.GET_HR_LEAVES_DATA_VIEW.reject_reason;
                this.form.cancel_reason         = this.GET_HR_LEAVES_DATA_VIEW.cancel_reason;
                this.form.pro_rated             = this.GET_HR_LEAVES_DATA_VIEW.pro_rated;
                this.form.isOthers              = this.GET_HR_LEAVES_DATA_VIEW.others == null ? 0 : 1;
                this.form.others                = this.GET_HR_LEAVES_DATA_VIEW.others;
                this.form.with_pay              = this.GET_HR_LEAVES_DATA_VIEW.with_pay;
                this.form.reused                = this.GET_HR_LEAVES_DATA_VIEW.reused;
                this.form.advanced              = this.GET_HR_LEAVES_DATA_VIEW.advanced;
                this.form.leave_type_used_on_id              = this.GET_HR_LEAVES_DATA_VIEW.leave_type_used_on_id;
                this.form.leave_type_used_on_day_w_pay              = this.GET_HR_LEAVES_DATA_VIEW.leave_type_used_on_day_w_pay;

                if( this.viewed_count === 0){
                    // if(this.GET_HR_LEAVES_DATA_VIEW.with_pay == 1 && this.viewed_count === 0){
                    this.checkEmployeeLeaveWithPay()
                    await this.checkLeaveRequestDates()
                    await this.getEmployeeSchedule()
                }

                // if(this.withHoursTypeIDS.includes(this.form.leave_type_id)){
                //     this.form.inclusive_date        = this.$dayjs(this.GET_HR_LEAVES_DATA_VIEW.inclusive_date_from).format('YYYY-MM-DD');
                //     this.form.time_from = this.$dayjs(this.GET_HR_LEAVES_DATA_VIEW.inclusive_date_from).format('HH:mm:ss');
                //     this.form.time_to = this.$dayjs(this.GET_HR_LEAVES_DATA_VIEW.inclusive_date_to).format('HH:mm:ss');

                //     let dayName = this.$dayjs(this.form.inclusive_date[0]).format('dddd');
                //     this.schedule_for_the_day = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayName)
                // }else{
                if(this.withHoursTypeIDS.includes(this.form.leave_type_id)){
                    this.form.inclusive_date        = this.GET_HR_LEAVES_DATA_VIEW.inclusive_date;
                    this.form.time_from             = this.GET_HR_LEAVES_DATA_VIEW.time_from;
                    this.form.time_to               = this.GET_HR_LEAVES_DATA_VIEW.time_to;

                    let dayName                     = this.$dayjs(this.form.inclusive_date[0]).format('dddd');
                    this.schedule_for_the_day       = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayName)
                    this.form.days_w_pay            = (1 - this.GET_HR_LEAVES_DATA_VIEW.total_days).toFixed(2);
                    this.form.days_wo_pay           = this.GET_HR_LEAVES_DATA_VIEW.days_without_pay;

                    // this.validateTotalDaysUDHD(this.GET_HR_LEAVES_DATA_VIEW.leave_type_used_on_id);
                }else{
                    this.form.inclusive_date        = this.GET_HR_LEAVES_DATA_VIEW.inclusive_dates;
                    this.computeTotalDaysBasedOnRange();
                    this.form.days_w_pay            = this.GET_HR_LEAVES_DATA_VIEW.days_with_pay;
                    this.form.days_wo_pay           = this.GET_HR_LEAVES_DATA_VIEW.days_without_pay;
                }
                this.form.total_days                = this.GET_HR_LEAVES_DATA_VIEW.total_days;
                this.viewed_count++

                this.advanceProRatedLeave(this.GET_HR_LEAVES_DATA_VIEW.advanced);

                if(this.ACTION == 'View'){
                    this.checkIfApprovable();
                }
                this.leave_request_history = this.GET_HR_LEAVES_DATA_VIEW.leave_request_history[0];
            }
        },
        async getAllDropdowns(){
            await this.component_dispatches.forEach(e =>{
                this.$store.commit('HR_LOADING',{key: e.loading_key, value: true});
                this.$store.dispatch(e.dispatch_name).then((res)=>{

                }).catch((err)=>{
                    Swal.fire(
                        "Error!",
                        "",
                        "error"
                    )
                    console.log(err)
                    this.$store.commit('HR_LOADING',{key: e.loading_key, value: false});
                });
            });
        },
        uploadedData(data) {
            let that = this;
            if (!data) {
                this.form.leaveFiles = [];
                return false
            }
            this.form.leaveFiles = [];

            data.attachments.forEach((file, index)=>{

                const blobFile = new Blob([file], { type: file.type });
                var reader  = new FileReader();
                reader.readAsDataURL(blobFile);
                reader.onloadend = function () {
                    that.form.leaveFiles.push(reader.result)
                }
            })
            // this.form.files = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.leaveFiles = [];
            }
            this.form.leaveFiles = [];
            this.$refs.fileUpload.reset();
        },
        changeLeaveRequestStatus(status){
            if(!['cancel', 'reject' ].includes(status)){
                if(!this.GET_HR_LEAVE_REQUEST_APPROVABLE){
                    Swal.fire({
                        title: "Insufficient Leave Balance!",
                        text: "Remaining days will be saved as Day with out pay.",
                        icon: "info",
                        showConfirmButton: true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText: 'Confirm',
                        showCloseButton: true,
                    }).then(async (result) =>{
                        if(result.isConfirmed){
                            this.loading.button = true;
                            // this.computeTotalDaysBasedOnRange();
                            this.form.days_wo_pay = this.form.total_days;
                            this.form.days_w_pay = 0.00;
                            let payload = {
                                id: this.GET_HR_LEAVES_DATA_VIEW.employee_id,
                                leave_type_id: this.GET_HR_LEAVES_DATA_VIEW.leave_type_id,
                                total_leave: this.form.days_with_pay ?? 0,
                            }
                            await this.$store.dispatch('checkLeaveIfApprovable', payload).then(res =>{
                                this.loading.button = false;
                            }).catch((error)=>{
                                Swal.fire(
                                    "Error",
                                    "",
                                    "error"
                                )
                                console.log(error)
                                this.loading.button = false;
                            });
                            Swal.fire(
                                "Day with pay changed!",
                                "",
                                "success"
                            )
                        }
                    })
                    // this.checkIfApprovable();
                }else{
                    this.triggerChangeStatusNow(status);
                }
            }else{
                if(!this.form[this.status + '_reason']){
                    Swal.fire(
                        `Please input ${this.status} reason!`,
                        "",
                        "warning"
                    )
                    return false;
                }else{
                    this.triggerChangeStatusNow(status);
                }
            }
        },
        triggerChangeStatusNow(status){
            Swal.fire({
                text: `Are you sure you want to ${status} leave request?`,
                icon:'question',
                showConfirmButton: true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let payload = {
                        id: this.GET_HR_LEAVES_DATA_VIEW.id,
                        status: status,
                        reject_reason: this.form.reject_reason,
                        cancel_reason: this.form.cancel_reason,
                        days_with_pay: this.form.days_w_pay,
                        days_without_pay: this.form.days_wo_pay,
                        monthly_pro_rated_balances: this.form.monthly_pro_rated_balances
                    }
                    this.$store.dispatch('changeLeaveRequestStatus', payload).then((res)=>{
                        Swal.fire(
                            "Success",
                            "",
                            "success"
                        )
                        this.viewed_count = 0;
                        this.$store.commit('DIALOG', false);
                    }).catch((err)=>{
                        console.log(err)
                        Swal.fire(
                            "Error",
                            "",
                            "error"
                        )
                        this.$store.commit('DIALOG', false);
                        this.viewed_count = 0;
                    })
                }
            })
        },
        checkEmployeeLeaveWithPay(from_switch = 0){
            this.$store.commit('HR_LOADING',{key: 'leave_type', value: true});
            // if(this.form.with_pay == 0 && this.form.employee_id){
            this.includedDates = [];
            if(this.form.employee_id){
                this.callCheckerDispatch(this.form.employee_id)
                if(from_switch == 1){
                    if(this.$refs.date_from && this.$refs.date_to){
                        this.$refs.date_from.clearHandler();
                        this.$refs.date_to.clearHandler();
                    }
                    // this.form.inclusive_date = [];
                    // this.form.total_days = ''
                }
            }else{
                this.setDefaultLeaveTypeSelection();
                return false
            }
        },
        callCheckerDispatch(employee_id, advanced = 0){
            let payload = {
                id: employee_id,
                advanced: advanced,
                total_days: this.form.total_days
            }
            this.$store.dispatch('checkLeaveWithPay', payload).then(async (res)=>{
                if(res.leaves.length > 0){
                    this.selection.leave_types.forEach((x, i)=>{
                        let with_pay_leave = res.leaves.find((e)=> e.value == x.value);
                        let default_data = this.GET_LEAVE_TYPE_SELECTION.find((r) => r.value == x.value);
                        if(with_pay_leave){
                            x.balance = with_pay_leave.balance
                            x.text = with_pay_leave.text
                        }else{
                            x.balance = 0
                            x.text = default_data.text
                        }
                    })
                    this.selection.leave_type_used_on = this.selection.leave_types.filter((x) => x.balance > 0);

                    this.$store.commit('HR_LOADING',{key: 'leave_type', value: false});
                    this.validateTotalDays();
                    // this.selection.leave_types = res.leaves;
                }else if (!res.exist){
                    this.$store.commit('HR_LOADING',{key: 'leave_type', value: false});
                    // Swal.fire(
                    //     'No Leave with Pay Available!',
                    //     '',
                    //     'warning'
                    // )
                    // this.form.with_pay = 1;
                }

                if (res.no_pro_rated_balance == 1){
                    Swal.fire(
                        "No more advanced leave to use!",
                        "",
                        "warning"
                    )
                    this.form.advanced = 0;
                }
                this.form.pro_rated                      = res.pro_rated;
                this.form.monthly_pro_rated_balances     = res.monthly_pro_rated_balances;

            }).catch((error)=>{
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
                this.$store.commit('HR_LOADING',{key: 'leave_type', value: false});
                console.log(error)
            })
        },
        async setDefaultLeaveTypeSelection(){
            this.$store.dispatch('getLeaveTypeSelection').then((res)=>{
                this.selection.leave_types = this.GET_LEAVE_TYPE_SELECTION;
            })
        },
        async getEmployeeSchedule() {
            this.includedDates = [];
            this.includedDatesOthers = [];
            let payload = {
                employee_id: this.form.employee_id,
            }
            await this.$store.dispatch('showEmployeeSchedule', payload).then((response) => {
                if (response != 'No Schedule Found!') {
                    this.employee_schedule = response
                    let days = this.employee_schedule.employee_schedule_details.map(e=>{
                        return e.day
                    })
                    this.GET_YEAR_RANGE.forEach((x) => {
                        let currentMonth = this.$dayjs().year(x).startOf('year'); // Start of the year
                        for (let m = 0; m < 12; m++) { // Iterate over all 12 months
                            let daysInMonth = currentMonth.daysInMonth(); // Get the number of days in the current month
                            for (let i = 0; i < daysInMonth; i++) {
                                let currentDate = currentMonth.clone().add(i, 'day'); // Clone the currentMonth and add the day
                                let currentDateFormatted = currentDate.format('YYYY-MM-DD');

                                if (days.includes(currentDate.format('dddd')) &&
                                    (!this.GET_HR_LEAVE_REQUEST_DATES_ONLY.includes(currentDateFormatted))) {
                                    this.includedDates.push(currentDateFormatted);
                                }

                                this.includedDatesOthers.push(currentDateFormatted);
                            }
                            currentMonth = currentMonth.add(1, 'month');
                        }
                    });

                }else{
                    Swal.fire(
                        response,
                        "",
                        "warning"
                    )
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        allowedDates(val){
            return this.includedDates.includes(val);
        },
        validateTotalDays(){
            let form1 = {}
            if(this.form.leave_type_id && this.form.inclusive_date.length == 2){
            // if(this.form.with_pay == 0 && this.form.leave_type_id){
                if(this.GET_HR_EMPLOYEE_LEAVE_WITH_PAY.leaves){
                    form1 = this.form
                    this.form = {}
                    this.form = form1
                    let leave_details = this.GET_HR_EMPLOYEE_LEAVE_WITH_PAY.leaves.find((x)=> x.value == this.form.leave_type_id)
                    let w_pay_temp = 0;
                    let wo_pay_temp = 0;
                    if(leave_details){
                        if(this.form.total_days == leave_details.balance){
                            w_pay_temp = leave_details.balance.toFixed(2)
                        }else if (this.form.total_days <= leave_details.balance){
                            w_pay_temp = parseFloat(this.form.total_days).toFixed(2)

                        }
                        else if (this.form.total_days > leave_details.balance){
                            w_pay_temp = leave_details.balance.toFixed(2)
                        }
                        if(w_pay_temp > leave_details.leave_type_balance){
                            w_pay_temp = leave_details.leave_type_balance.toFixed(2);
                        }
                        wo_pay_temp = (this.form.total_days - w_pay_temp);
                        let isPositive = Math.sign(w_pay_temp);
                        if(isPositive == -1){
                            w_pay_temp = 0.00;
                        }
                        this.form.days_w_pay = w_pay_temp
                        this.form.days_wo_pay = wo_pay_temp

                    }else{
                        this.form = form1
                        this.form.days_w_pay = 0.00;
                        // this.form.days_wo_pay = 0.00;
                        this.form.days_wo_pay = parseFloat(this.form.total_days).toFixed(2);
                    }
                }
            }
        },
        dateDisable(){
            if(this.form.isOthers == 1){
                if(this.form.employee_id){
                    return false
                }
                return true
            }else{
                if(this.form.leave_type_id && this.form.employee_id){
                    return false
                }
            }
            return true
        },
        checkLeaveType(){
            if(this.form.leave_type_id){
                if(this.withHoursTypeIDS.includes(this.form.leave_type_id)){
                    return true
                }
            }
            return false
        },
        clearDates(){
            this.form.inclusive_date = [];
            this.form.total_days = '';
            this.form.time_to = null;
            this.form.time_from = null;
            this.form.days_w_pay = 0.00;
            this.form.days_wo_pay = 0.00;
        },
        closeDateMenu(){
            let dayName = this.$dayjs(this.form.inclusive_date[0]).format('dddd');
            this.schedule_for_the_day = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayName)
            if(this.checkLeaveTypeCode('UD') == this.form.leave_type_id){
                this.form.time_to = this.schedule_for_the_day.time_out
            }else{
                this.form.time_to = null;
            }
        },
        computeTotalDays(){
            if(this.checkLeaveTypeCode('UD') == this.form.leave_type_id){
                this.form.time_to = this.schedule_for_the_day.time_out
            }
            let hours_to_work_day = this.schedule_for_the_day.hours_to_work_day - this.employee_schedule.break_duration
            this.form.half_day_hours = (hours_to_work_day / 2) + parseFloat(this.employee_schedule.break_duration);
            this.form.time_to_limit = this.$dayjs(this.form.time_from, 'HH:mm').add(this.form.half_day_hours, 'hour').format('HH:mm');
            let limit_split = this.form.time_to_limit.split(":");
            this.form.allowed_hours_to = [ parseInt(limit_split[0])];
            this.form.allowed_minutes_to = [ parseInt(limit_split[1])];
            if(this.form.time_to){
                let timeFormat = 'HH:mm';
                let leaveStart = this.$dayjs(this.form.time_from, timeFormat);
                let leaveEnd = this.$dayjs(this.form.time_to, timeFormat);

                // let start = this.$dayjs(this.schedule_for_the_day.time_in, "HH:mm");
                // let end = this.$dayjs(this.schedule_for_the_day.time_out, "HH:mm");

                // let leavestart = this.$dayjs.max(leaveStart, start);
                // let leaveend = this.$dayjs.min(leaveEnd, end);

                let leaveStartEndDiff = leaveEnd.diff(leaveStart, 'minute');
                const roundedDiffInMin = Math.floor(leaveStartEndDiff / 30) * 30;
                if(leaveStartEndDiff % 30 !== 0){
                     Swal.fire({
                       icon: 'info',
                       title: 'Leave Hours should be divisible by 30 mins',
                       text: 'The excess minutes will not be counted.',
                   });
                }
                let totalDaysInHours = roundedDiffInMin / 60;
                // this.form.total_days = totalDaysInHours - this.employee_schedule.break_duration;
                if(totalDaysInHours == this.schedule_for_the_day.hours_to_work_day){
                    this.form.total_days = 1;
                }else{
                    this.form.total_days = (totalDaysInHours - parseFloat(this.employee_schedule.break_duration)) / hours_to_work_day;
                    this.form.total_days = parseFloat(this.form.total_days).toFixed(2)
                }
            }
        },
        computeTotalDaysBasedOnRange(){
            if(this.form.inclusive_date.length == 2){
                let dateRange = this.getDatesFromRange(this.form.inclusive_date[0], this.form.inclusive_date[1], "array");
                let total_days = 0;
                dateRange = dateRange.filter(x => this.includedDates.includes(x)).map(filteredObj => filteredObj);
                dateRange.forEach((e, i)=>{
                    let dayNameFrom = this.$dayjs(e).format('dddd');
                    let schedule_for_day = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayNameFrom)
                    let hours_to_work_day = schedule_for_day.hours_to_work_day - this.employee_schedule.break_duration

                    let timeFormat = 'HH:mm';
                    let leaveStart = this.$dayjs(schedule_for_day.time_in, timeFormat);
                    let leaveEnd = this.$dayjs(schedule_for_day.time_out, timeFormat);

                    let start = this.$dayjs(schedule_for_day.time_in, "HH:mm");
                    let end = this.$dayjs(schedule_for_day.time_out, "HH:mm");

                    let leavestart = this.$dayjs.max(leaveStart, start);
                    let leaveend = this.$dayjs.min(leaveEnd, end);

                    let leaveStartEndDiff = leaveend.diff(leavestart, 'minute');
                    let totalDaysInHours = leaveStartEndDiff / 60;
                    let totalDaysLeaved = this.employee_schedule.schedule_type_id != 3 ? totalDaysInHours - this.employee_schedule.break_duration : 8;

                    totalDaysLeaved = totalDaysLeaved / hours_to_work_day;
                    total_days += totalDaysLeaved;
                })
                this.form.total_days = parseFloat(total_days).toFixed(2);
                this.validateTotalDays();
                this.checkTotalDaysVSMaxDays();
            }
        },
        openRejectDialog(status){
            this.status = status;
            this.leaveRejection.dialog = true
        },
        closeRejectDialog(){
            this.leaveRejection.dialog = false
            this.status = '';
        },
        checkDepartmentAccess(){
            let payload ={
                access: this.USER_ACCESS.map(e => e.actions_code)
            }
            this.$store.dispatch('checkDepartmentAccess', payload).then(response=>{
                this.checked_department_ids = response;
                    let payload = {
                        access_department_ids: this.checked_department_ids
                    }
                    this.$store.dispatch('getEmployeeDataDropdown', payload).then(response => {
                    })
            })
        },
        async checkIfApprovable(){
            let payload = {
                id: this.GET_HR_LEAVES_DATA_VIEW.employee_id,
                leave_type_id: this.GET_HR_LEAVES_DATA_VIEW.leave_type_id,
                total_leave: this.GET_HR_LEAVES_DATA_VIEW.days_with_pay,
            }
            await this.$store.dispatch('checkLeaveIfApprovable', payload).then((res)=>{
                this.isApproveButtonLoaded = true;
            }).catch((err)=>{
                console.log(err)
            })
        },
        leaveRequestEvents (date) {
            let color = ''
            this.leaveRequestDates.forEach(e => {
                if (e.dates.indexOf(date) !== -1) {
                    color = e.color;
                }
            });
            if (this.GET_HR_LEAVE_REQUEST_DATES_ONLY.includes(date)) return [ color ]
            return false
        },
        async checkLeaveRequestDates(){
            let payload = {
                id: this.ACTION == 'Submit' ? this.form.employee_id : this.GET_HR_LEAVES_DATA_VIEW.employee_id,
                leave_type_id: this.form.leave_type_id,
                action: this.ACTION
            }
            await this.$store.dispatch('getLeaveRequestDatesByLeaveType',payload)
        },
        checkDateColor(){
            if(this.form.leave_type_id && this.GET_LEAVE_TYPE_SELECTION){
                let color = '';
                color = this.GET_LEAVE_TYPE_SELECTION.find((x) => x.value == this.form.leave_type_id);
                if(color){
                    return color.color
                }
            }
        },
        validateTotalDaysOthers(){
            let form1 = {}

            if(this.form.inclusive_date.length == 2){
                // if(this.form.with_pay == 0 && this.form.leave_type_id){
                form1 = this.form
                this.form = {}
                if(this.GET_HR_EMPLOYEE_LEAVE_WITH_PAY.leaves){
                    this.form = form1
                    let leave_details = this.GET_HR_EMPLOYEE_LEAVE_WITH_PAY.leaves.find((x)=> x.value == this.form.to_be_used_leave_type_id)
                    let w_pay_temp = 0;
                    let wo_pay_temp = 0;

                    if(leave_details){
                        if(this.form.total_days == leave_details.balance){
                            w_pay_temp = leave_details.balance.toFixed(2)
                        }else if (this.form.total_days <= leave_details.balance){
                            w_pay_temp = this.form.total_days.toFixed(2)
                        }
                        else if (this.form.total_days > leave_details.balance){
                            w_pay_temp = leave_details.balance.toFixed(2)
                        }
                        wo_pay_temp =  (this.form.total_days - leave_details.balance) > 0 ? parseFloat((this.form.total_days - leave_details.balance)).toFixed(2) : 0.00;

                        Object.assign(this.form,{
                            days_w_pay: w_pay_temp,
                            days_wo_pay: (wo_pay_temp),
                        })
                        if(wo_pay_temp == 0){
                            this.form.advanced = 0;
                        }

                    }else{
                        this.form = form1
                        this.form.days_w_pay = 0.00;
                        this.form.days_wo_pay = parseFloat(this.form.total_days).toFixed(2);
                    }
                }
            }
        },
        validateTotalDaysUDHD(leave_type_to_used_on){
            if(leave_type_to_used_on){
                this.validateTotalDaysForHour();
                if(this.form.inclusive_date){
                    // if(this.form.with_pay == 0 && this.form.leave_type_id){
                    if(this.GET_HR_EMPLOYEE_LEAVE_WITH_PAY.leaves){
                        let leave_details = this.GET_HR_EMPLOYEE_LEAVE_WITH_PAY.leaves.find((x)=> x.value == this.form.leave_type_used_on_id)
                        let w_pay_temp = 0;
                        let wo_pay_temp = 0;
                        let leave_type_used_on_day_w_pay = 0

                        if(leave_details){
                            // if(form1.total_days == leave_details.balance){
                            //     w_pay_temp = leave_details.balance.toFixed(2)
                            // }else if (form1.total_days <= leave_details.balance){
                            //     w_pay_temp = parseFloat(form1.total_days).toFixed(2)
                            // }
                            // else if (form1.total_days > leave_details.balance){
                            //     w_pay_temp = leave_details.balance.toFixed(2)
                            // }
                            // wo_pay_temp =  (form1.total_days - leave_details.balance) > 0 ? parseFloat((form1.total_days - leave_details.balance)).toFixed(2) : 0.00;

                            if(leave_details.balance !== 0){
                                w_pay_temp = (1 - this.form.days_wo_pay).toFixed(2);
                                wo_pay_temp = this.form.total_days - leave_details.balance < 0 ? 0 : parseFloat(this.form.total_days - leave_details.balance).toFixed(2);
                                leave_type_used_on_day_w_pay = ((1 - this.form.days_w_pay) - wo_pay_temp).toFixed(2)
                                // wo_pay_temp = (w_pay_temp + leave_type_used_on_day_w_pay) - this.form.days_wo_pay ? (w_pay_temp + leave_type_used_on_day_w_pay) - this.form.days_wo_pay: 0;
                            }
                            Object.assign(this.form,{
                                days_w_pay: w_pay_temp,
                                days_wo_pay: wo_pay_temp,
                                leave_type_used_on_day_w_pay: leave_type_used_on_day_w_pay
                            })
                        }else{
                            this.form.days_w_pay = 0.00;
                            this.form.days_wo_pay = parseFloat(this.form.total_days).toFixed(2);
                        }
                    }
                }
            }else{

            }
        },
        computeTotalDaysBasedOnRangeOthers(){
            if(this.form.inclusive_date.length == 2){
                let dateRange = this.getDatesFromRange(this.form.inclusive_date[0], this.form.inclusive_date[1], "array");
                let total_days = 0;
                dateRange = dateRange.filter(x => this.includedDatesOthers.includes(x)).map(filteredObj => filteredObj);
                dateRange.forEach((e, i)=>{
                    let dayNameFrom = this.$dayjs(e).format('dddd');
                    let schedule_for_day = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayNameFrom)
                    let hours_to_work_day = schedule_for_day.hours_to_work_day - this.employee_schedule.break_duration

                    let timeFormat = 'HH:mm';
                    let leaveStart = this.$dayjs(schedule_for_day.time_in, timeFormat);
                    let leaveEnd = this.$dayjs(schedule_for_day.time_out, timeFormat);

                    let start = this.$dayjs(schedule_for_day.time_in, "HH:mm");
                    let end = this.$dayjs(schedule_for_day.time_out, "HH:mm");

                    let leavestart = this.$dayjs.max(leaveStart, start);
                    let leaveend = this.$dayjs.min(leaveEnd, end);

                    let leaveStartEndDiff = leaveend.diff(leavestart, 'minute');
                    let totalDaysInHours = leaveStartEndDiff / 60;

                    let totalDaysLeaved = totalDaysInHours - this.employee_schedule.break_duration;

                    totalDaysLeaved = totalDaysLeaved / hours_to_work_day;
                    total_days += totalDaysLeaved;
                })
                this.form.total_days = total_days;
                this.validateTotalDaysOthers();
                this.checkTotalDaysVSMaxDays();
            }
        },
        validateTotalDaysForHour(){
            if(this.form.time_from && this.form.time_to){
                this.form.days_w_pay = (1 - this.form.total_days).toFixed(2)
                this.form.days_wo_pay = (1 - this.form.days_w_pay).toFixed(2)
                if(this.checkLeaveTypeCode('HD') != this.form.leave_type_id){
                    if(this.form.total_days == 0.50){
                        Swal.fire({
                            title: "Total days declared are for Half Day!",
                            text: "Leave type selected will be changed.",
                            icon: "info",
                            allowOutsideClick: false,

                        }).then((res)=>{
                            if(res.isConfirmed){
                                this.form.leave_type_id = this.checkLeaveTypeCode('HD');
                            }
                        })
                    }
                }
            }
        },
        checkCode(){
            let checker = this.GET_LEAVE_TYPE_SELECTION.find((e) => e.value === this.form.leave_type_id).code
            if(checker == 'UD'){
                return false
            }
            return true
        },
        getLeaveSettings(){
            let payload = {
                settings_type: 'leave'
            }
            this.$store.dispatch('getSettingsByModule', payload).then((res)=>{
                Object.keys(this.settings).forEach((key)=>{
                    this.settings[key] = parseInt(res.find((x)=> x.parameter == key).value)
                })
            })
        },
        checkLeaveTypeCode(dropdown_code){
            if(!_.isEmpty(this.GET_LEAVE_TYPE_SELECTION)){
                let code = this.GET_LEAVE_TYPE_SELECTION.find((x)=> x.code == dropdown_code).value
                return code;
            }
            return 0;
        },
        checkTotalDaysVSMaxDays(){
            if(this.USERACCOUNT_LOAD.manager == 0){
                if(this.form.total_days > this.settings.maximum_leave_total_days){
                    Swal.fire({
                        title:"Exceeded Maximum Days to File!",
                        text: `Employee can only file ${this.settings.maximum_leave_total_days.toFixed(2)} days!`,
                        icon: "warning"
                    })
                    this.clearDates();
                }
            }
        },
        async triggerLeaveAdvanced(){
            if(this.form.advanced){
                // this.advanceProRatedLeave(1)
                this.override.override_dialog = true
            }else{
                this.advanceProRatedLeave(0);
            }
        },
        closeConfirmOverride(user_id, authenticated = false, ) {
            if (authenticated) {
                this.advanceProRatedLeave(1);
            }
            if(!authenticated){
                this.form.advanced = 0;
            }
            this.override.override_dialog = false;
        },
        advanceProRatedLeave(advanced){
            this.callCheckerDispatch(this.form.employee_id, advanced)
        },
        getEmployeeDetailsfromDropdown(){
            let details = this.GET_EMPLOYEE_DATA.find((x) => x.value == this.form.employee_id)
            if(details){
                return `For ${details.full_name}`;
            }
            return null
        },
        openVerificationCodeDialog(){
            this.$store.commit('HR_VERIFICATION_DIALOG', true);
        }
    },
    watch: {
        // GET_HR_SETTINGS_NEW_DATA_TRIGGER: {
        //     handler(val) {
        //         if (val) {
        //             this.closeDialog();
        //             this.$store.commit("DIALOG", false);
        //             this.$store.commit("HR_SETTINGS_NEW_DATA_TRIGGER", false);
        //             this.initialForm();
        //         }
        //     },
        // },
        DIALOGS: {
            handler(val) {
                if (val) {
                    this.initialForm();
                    if(this.$refs.fileUpload){
                        this.clearFileUpload();
                    }
                    this.setDefaultLeaveTypeSelection();
                }else{
                    if(this.$refs.date_from && this.$refs.date_to){
                        this.$refs.date_from.clearHandler();
                        this.$refs.date_to.clearHandler();
                    }
                    this.isApproveButtonLoaded = false
                    this.$store.commit('HR_EMPLOYEE_LEAVE_WITH_PAY', [])
                }
            },
        },
        'form.inclusive_date':{
            deep:true,
            handler(val){
                if(this.DIALOGS){
                    if(val.length == 2){
                        this.form.inclusive_date_menu = false;
                        let inclusiveDate = [];

                        // Loop through the array and format each date
                        val.forEach((date) => {
                            let formattedDate = this.$dayjs(date).format("YYYY-MM-DD");
                            inclusiveDate.push(formattedDate);
                        });


                        // If leave type is with manual hours
                        if(this.withHoursTypeIDS.includes(this.form.leave_type_id)){
                            let dateRange = this.getDatesFromRange(this.form.inclusive_date[0], this.form.inclusive_date[1], "array");

                            if(dateRange.length == 1){
                                let dayNameFrom = this.$dayjs(val[0]).format('dddd');
                                let dayNameTo = this.$dayjs(val[1]).format('dddd');

                                let schedule_for_day = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayNameFrom)
                                let hours_to_work_day = schedule_for_day.hours_to_work_day - this.employee_schedule.break_duration

                                let timeFormat = 'HH:mm';
                                let leaveStart = this.$dayjs(this.form.inclusive_date[0], timeFormat);
                                let leaveEnd = this.$dayjs(this.form.inclusive_date[1], timeFormat);
                                let start = this.$dayjs(schedule_for_day.time_in, "HH:mm");
                                let end = this.$dayjs(schedule_for_day.time_out, "HH:mm");

                                let leavestart = this.$dayjs.max(leaveStart, start);
                                let leaveend = this.$dayjs.min(leaveEnd, end);

                                let leaveStartEndDiff = leaveend.diff(leavestart, 'minute');
                                let totalDaysInHours = leaveStartEndDiff / 60;

                                let totalDaysLeaved = totalDaysInHours - this.employee_schedule.break_duration;

                                totalDaysLeaved = totalDaysLeaved / hours_to_work_day;

                                this.form.total_days = totalDaysLeaved.toFixed(2);
                                this.checkTotalDaysVSMaxDays();
                            }else{
                                let total_days = 0;
                                dateRange = dateRange.filter(x => this.includedDates.includes(x)).map(filteredObj => filteredObj);
                                dateRange.forEach((e, i)=>{
                                    let dayNameFrom = this.$dayjs(e).format('dddd');
                                    let schedule_for_day = this.employee_schedule.employee_schedule_details.find((x)=> x.day == dayNameFrom)
                                    let hours_to_work_day = schedule_for_day.hours_to_work_day - this.employee_schedule.break_duration

                                    let timeFormat = 'HH:mm';
                                    let leaveStart = this.$dayjs(inclusiveDate.includes(e) ? this.form.inclusive_date[0] : schedule_for_day.time_in, timeFormat);
                                    let leaveEnd = this.$dayjs(inclusiveDate.includes(e) ? this.form.inclusive_date[1] : schedule_for_day.time_out, timeFormat);

                                    let start = this.$dayjs(schedule_for_day.time_in, "HH:mm");
                                    let end = this.$dayjs(schedule_for_day.time_out, "HH:mm");

                                    let leavestart = this.$dayjs.max(leaveStart, start);
                                    let leaveend = this.$dayjs.min(leaveEnd, end);

                                    let leaveStartEndDiff = leaveend.diff(leavestart, 'minute');
                                    let totalDaysInHours = leaveStartEndDiff / 60;

                                    let totalDaysLeaved = totalDaysInHours - this.employee_schedule.break_duration;

                                    totalDaysLeaved = totalDaysLeaved / hours_to_work_day;
                                    total_days += totalDaysLeaved;

                                })
                                this.form.total_days = total_days;
                                this.checkTotalDaysVSMaxDays();
                            }

                        }else{
                            // let startDate = this.$dayjs(this.form.inclusive_date[0]);
                            // let endDate = this.$dayjs(this.form.inclusive_date[1]);

                            // const daysDifference = endDate.diff(startDate, 'day', true);
                            // this.form.total_days = daysDifference.toFixed(2);
                            // let startDate = this.$dayjs(this.form.inclusive_date[0]).startOf('day');
                            // let endDate = this.$dayjs(this.form.inclusive_date[1]).endOf('day');

                            // const hoursDifference = endDate.diff(startDate, 'hour', true);
                            // const daysDifference = hoursDifference / 24;

                            // this.form.total_days = daysDifference.toFixed(2);
                            if(this.ACTION == 'Submit'){
                                // this.computeTotalDaysBasedOnRange()
                            }
                        }

                        // Checking for Leave Balance with pay of employee vs Total Days of Leave Request
                        // if(this.ACTION == 'Submit'){
                        //    this.validateTotalDays()
                        // }

                        if(val[0] > val[1]){
                            Swal.fire("Leave Date Range Not Valid","","warning")
                            this.form.inclusive_date = [];
                            this.form.total_days = 0.00;
                            this.form.days_w_pay = 0.00;
                            this.form.days_wo_pay = 0.00;
                        }
                    }
                }
            }
        },
        GET_LEAVE_TYPE_SELECTION:{
            handler(val){
                this.$store.commit('HR_LOADING',{key: 'leave_type', value: false});
                this.selection.leave_types = val
            }
        },
        GET_EMPLOYEE_DATA:{
            handler(val){
                this.$store.commit('HR_LOADING',{key: 'employee_list', value: false});
                this.selection.employee_lists = val
            }
        },
        // 'form.isOthers': {
        //     handler(val){
        //         if(val){
        //             this.form.leave_type_id = '';
        //             this.form.others = '';
        //         }else{
        //             this.form.leave_type_id = this.GET_HR_LEAVES_DATA_VIEW.leave_type_id;
        //         }
        //     }
        // },
        'form.leave_type_id':{
            handler(val){
                // if(this.ACTION == 'Submit'){
                    this.withHoursTypeIDS = this.selection.leave_types.filter(obj => obj.with_manual === 1).map(filteredObj => filteredObj.value);

                    if(this.$refs.date_from && this.$refs.date_to){
                        this.$refs.date_from.clearHandler();
                        this.$refs.date_to.clearHandler();
                    }
                    // if(this.withHoursTypeIDS.includes(val)){
                    //     this.clearDates();
                    // }
                // }
            }
        },
        // 'form.to_be_used_leave_type_id':{
        //     handler(val){
        //         this.validateTotalDaysOthers()
        //     }
        // },
        'form.employee_id':{
            deep: true,
            async handler(val){
                if(this.ACTION == 'Submit' && val){
                    this.getEmployeeSchedule()
                }
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
        GET_HR_LEAVES_DATA_VIEW:{
            deep: true,
            handler(val){
                // if(val.with_pay == 1 && this.viewed_count === 0){
                //     this.callCheckerDispatch(this.GET_HR_LEAVES_DATA_VIEW.employee_id)
                // }
            }
        },
        GET_HR_LEAVE_REQUEST_DATES_BY_LEAVE_TYPE:{
            handler(val){
                this.leaveRequestDates = [];
                this.leaveRequestDatesOnly = [];

                let temp = []
                temp = val.filter((x) => x.dates.length > 0);
                this.leaveRequestDates = val.filter((x) => x.dates.length > 0);
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                 this.hr_id = val.find((x) => x.code == 'HR-A').value;
            }
        },
        'form.total_days':{
            handler(val){

            }
        }
    },
};
</script>
<style scoped>
.camera {
    display: block;
    width: 300px;
    margin: 0 auto;
    background-color: black;
}

.picture {
    display: block;
    width: 335px;
    height: 335px;
}
canvas {
    display: block;
    width: 100%;
    height: 100%;
}
.upload-file {
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
}
</style>
