<template>
<div class="tabs-body-color">

    <div class="container">
        <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <!-- <v-btn data-modal-id="addTypeModal" v-on:click="showAddType()">
                        <i class="fas fa-plus"></i>Add
                    </v-btn> -->
                    <!-- <buttons class="ml-3"
                            :action="buttons.actions.create"
                            :button_icon="buttons.icons.create"
                            :color="buttons.colors.create"
                            @click="showAddType()"
                        >
                    </buttons> -->
                    <div class="row my-1">
                        <div class="">
                            <div class="btn-toolbar">
                                <v-btn class="" @click="showAddType()" v-if="userAccess.includes('discountType_create')">
                                    <v-icon>mdi-plus</v-icon>
                                    Add
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Types Table -->
            <TypesTableComponentVue :userAccess="userAccess" :itemTypesActionResult="action" @showEditModal="showEditModal"></TypesTableComponentVue>
        </div>
    </div>

    <v-app id="addEditTypeModal">
        <v-dialog v-model="add_edit_type_dialog" persistent max-width="500" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="headline">Discount Type Information</span>
                        </v-card-title>
                    </v-col>
                    
                    <v-col cols="1" class="pull-right-2 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeDialog">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-0"></v-divider>
                <v-card-text>
                    <form class="form-horizontal px-4" action="/itemTypes" method="post" v-on:submit.prevent="onSubmit">
                        <v-row>
                            <v-col cols="12">
                                <label for="name" class="float-left control-label col-form-label font-weight-medium">Name: <span v-if="action != 'View'" class="red--text">*</span></label>
                                <input type="text" class="form-control" id="name" v-model="name" required :readonly="action == 'View'">
                                <span v-for="errors in errors.name" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>
                        </v-row>

                        <v-row class="mb-2">
                            <v-col cols="12">
                                <label for="code" class="float-left control-label col-form-label font-weight-medium">Code: <span v-if="action != 'View'" class="red--text">*</span></label>
                                <input type="text" class="form-control" id="code" v-model="code" required :readonly="action == 'View'">
                                <span v-for="errors in errors.code" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-switch
                                    v-model="is_active"
                                    :readonly="action == 'View'"
                                    label="active"
                                    color="primary"
                                    input-value="1"
                                ></v-switch>
                            </v-col>
                        </v-row>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewType()" v-if="action=='Add'" :loading="this.loading">Submit</v-btn>
                                <v-btn  v-on:click="editType()" v-else-if="action=='Edit'" :loading="this.loading">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
    
</div>
</template>

<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import TypesTableComponentVue from '../tables/TypesTableComponent.vue';
    // import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
    // import buttons_components from '@/assets/js/components-js.js';
    import swal from 'sweetalert2';
    import {mapGetters} from 'vuex';
    export default {
        mixins: [SharedFunctionsComponentVue],
        data(){
            return {
                editTypeId: '',
                add_edit_type_dialog: false,
                code:'',
                name: '',
                is_active: '',
                errors: [],
                action: '',
                actionResult : {
                    model : 'Type',
                    action : ''
                },
                userAccess: [],
                loading: false,
                // buttons:buttons_components.buttons,
            }
        },
        components: {
            TypesTableComponentVue,
            // buttons,
            // buttons_components,
        },
        computed:{
            ...mapGetters([
                'GET_SHOW_ITEM_TYPES',
                'USER_ACCESS'
            ])
        },
        async mounted() {
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        document.addEventListener('keydown', this.handleKeydown)
        },  
        beforeDestroy(){
            document.removeEventListener('keydown', this.handleKeydown)
        },
        methods: {
            handleKeydown(event){
                this.closeDialogByEsc(event, this.closeDialog)
            },
            closeDialog(){
                this.add_edit_type_dialog = false; 
                this.resetFields(); 
                this.$emit('closeModal');
            },
            showAddType(){
                this.add_edit_type_dialog = true
                this.action = 'Add'
                this.is_active = 1
            },
            addNewType(){
                this.errors = []
                this.loading = true;
                if (!this.code.trim() || !this.name.trim()) {
                    let emptyField = !this.code.trim() ? 'Code' : "Name";
                    swal.fire("", `-${emptyField} is required.`, "info")
                    return
                }
                let payload = {
                    code: this.code,
                    name:   this.name,
                    active: this.is_active,
                }
                this.$store.dispatch('storeItemTypes',payload).then(response => {
                    this.resetFields()
                    this.add_edit_type_dialog = false
                    this.$store.dispatch('getAllItemTypes');
                    this.action = 'success'
                    swal.fire("", "Add Type Successful!", "success");
                    this.loading = false;
                }).catch(error => {
                    swal.fire("", "Add Type Failes!", "error");
                    this.errors = error.response.data.errors;
                    this.loading = false;
                });
            },
            showEditModal(data){
                this.action = data.action
                this.editTypeId = data.id

                if(this.editTypeId != null && this.editTypeId > 0){
                    
                    this.$store.dispatch('showItemTypes',this.editTypeId).then(response => {              
                        let data = this.GET_SHOW_ITEM_TYPES

                        this.name                       = data.name
                        this.code                       = data.code
                        this.is_active                  = data.active
                        this.add_edit_type_dialog       = true
                    }).catch(error => {

                    });
                }
            },
            editType(){
                this.errors = []
                if (!this.code.trim() || !this.name.trim()) {
                    let emptyField = !this.code.trim() ? 'Code' : "Name";
                    swal.fire("", `-${emptyField} is required.`, "info")
                    return
                }
                swal.fire({
                    text: `Are you sure you want to proceed?`,
                    icon:'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        this.loading = true;
                        let payload = {
                            type_id:this.editTypeId,
                            name:   this.name,
                            code: this.code,
                            active: this.is_active,
                        }
                        this.$store.dispatch('updateItemTypes',payload).then(response => {
                            this.resetFields()
                            this.add_edit_type_dialog = false
                            this.editTypeId           = 0
                            this.action               = 'success'
                            swal.fire("", "Edit Type Successful!", "success");
                            this.$store.dispatch('getAllItemTypes');
                            this.loading = false;
                        }).catch(error => {
                            swal.fire("", "Edit Type Failed!", "error");
                            this.errors = error.response.data.errors;
                            this.loading = false;
                            this.action = 'Edit'
                        });
                    }
                });
            },
            resetFields(){
                this.errors              = []
                this.name                = ''
                this.code                = ''
                this.is_active           = 1
            },

        },
        watch: {
            USER_ACCESS:{
                handler(val){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            },
        }
    };
</script>
<style>
    .v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none;
    }
</style>