<template>
  	<v-form ref="form" v-model="valid" lazy-validation>
    	<v-card>
			<v-card-title class="d-flex justify-content-between pa-0">
				<h4 class="mx-3">{{ actions }}  {{ GET_CHECKLIST_DIALOG_TITLE }}</h4>
				<v-btn text @click="closeDialog()" fab><v-icon>mdi-close-circle</v-icon></v-btn>
			</v-card-title>
			<v-divider/>

			<v-card-text>
				<v-row class="pl-3 pr-3">
					<v-col lg="6">
						<v-text-field 
                            v-model="checklist.fillables.name" 
                            label="Name" 
                            @keydown.enter="actions == 'Add' ? submit() : update()"  
                            outlined 
                            dense
                            :readonly="disabled_view"
						>
						</v-text-field>
					</v-col>

                    <v-col lg="6">
                        <v-text-field 
                            v-model="checklist.fillables.code" 
                            label="Code" 
                            :readonly="disabled_view" 
                            outlined 
                            dense
                        >
                        </v-text-field>
                    </v-col>

                    <v-col lg="12">
                        <v-text-field 
                            v-model="checklist.fillables.description" 
                            label="Description" 
                            :readonly="disabled_view" 
                            outlined 
                            dense
                        >
                        </v-text-field>
                    </v-col>
				</v-row>
			</v-card-text>
			
			<v-card-actions v-if="this.ACTION != 'View'" class="d-flex justify-content-end">
				<v-btn @click="actions == 'Add' ? submit() : update()" small><v-icon> mdi-plus </v-icon> {{ actions }} </v-btn>
			</v-card-actions>

    	</v-card>
  	</v-form>
</template>

<script>

import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
			disabled_view: false,
            valid: false,
			checklist:{
				fillables:{
					name: '',
                    code: '',
                    description: '',
				},
				loaded:true
			}
        }
    },

    methods: {
        
		clearData(){
			this.checklist.fillables.name = '';
			this.checklist.fillables.code = '';
			this.checklist.fillables.description = '';
		},

        closeDialog() {
			this.clearData()
            this.$store.commit("DIALOG", false);
            this.$store.commit('CHECKLIST_DIALOG_VIEW', {})
        },
		
		getChecklistData(){
			this.checklist.fillables.id                 = this.GET_CHECKLIST_DIALOG_VIEW.id
			this.checklist.fillables.name      		    = this.GET_CHECKLIST_DIALOG_VIEW.name
			this.checklist.fillables.code      		    = this.GET_CHECKLIST_DIALOG_VIEW.code
			this.checklist.fillables.description        = this.GET_CHECKLIST_DIALOG_VIEW.description
		},

		submit(){
            Swal.fire({
                title:'Add',
                text:'- Are you sure you want to Submit?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.add[0],{
                        url:this.GET_CHECKLIST_DISPATCH.add[1],
                        ...this.checklist
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.closeDialog()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                        this.closeDialog()
                    })
                }
            })
        },

		update(){
			Swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch(this.GET_CHECKLIST_DISPATCH.update[0],{
                        url:this.GET_CHECKLIST_DISPATCH.update[1],
                        fillables: this.checklist.fillables,
						id: this.GET_CHECKLIST_DIALOG_VIEW.id
                    }).then(response=>{
						Swal.fire('Success!','','success')
						this.closeDialog()
                        this.$store.commit('REFRESH', true)
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
						this.closeDialog()
                    })
                }
            })
		},
    },

    computed:{
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
            'GET_CHECK_LIST_TABLE',
            'GET_CHECK_LIST',
            'GET_CHECK_LISTS_SELECTION',
            'GET_CHECKLIST_DIALOG_TITLE',
            'ACTION',
			'GET_CHECKLIST_DISPATCH',
			'GET_CHECKLIST_DIALOG_VIEW'
        ]),

		actions(){
            if(this.ACTION == 'Add'){
				this.disabled_view = false;

                return 'Add'
            } else if(this.ACTION == 'Edit'){
				this.disabled_view = false;

				this.getChecklistData();
                return 'Edit' 
            } else if(this.ACTION == 'View'){
				this.disabled_view = true;

				this.getChecklistData();
                return 'View' 
			} else {
                this.ACTION
            }
			
        }
    },

    watch:{
         DIALOGS:{
            handler(val){
                // this.actions = val
            }
        }
    },  
}
</script>

<style scoped>

</style>