<template>
    <v-container class="tabs-body-color p-0">
        <v-btn class="my-2" @click="dialog('Add','')" v-if="userAccess.includes('create')">
            <v-icon>mdi-plus</v-icon> Add
        </v-btn>
        <v-card elevation="0" rounded="0" class="p-3">
            <div class="w-25">
                <v-row class="p-2">
                    <v-col cols="6">
                        <v-autocomplete
                        outlined
                        dense
                        label="Range"
                        v-model="form.rangeMoney"
                        :items="GET_ALL_RANGE"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                        outlined
                        dense
                        label="Filter"
                        v-model="form.rangeType"
                        :items="rangeTypes"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </div>
            
            <v-card-title class="d-flex justify-content-end align-items-end p-0 m-0">
                <div class="w-25 p-0 m-0 d-flex justify-content-center align-items-center">
                    <v-text-field v-model="filters.search" label="Search" single-line hide-details @keydown.enter="search()"></v-text-field>
                    <div class="pt-5">
                        <v-icon @click="search()">mdi-magnify</v-icon>
                    </div>
                </div>
            </v-card-title>
            <v-data-table
                :headers="form.headers"
                :items="form.items"
                :loading="loading"
                :server-items-length="form.totalItems"
                :option.sync="filters.options"
                loading-text="Loading...Please wait"
                class="w-100"
                dense
                :page.sync="form.page"
                :items-per-page.sync="form.itemsPerPage"
                disable-sort
            >
                <template v-slot:[`item.range`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.range | currency }}
                    </span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                        <v-btn text icon color="orange" @click="dialog('View',item)" v-if="userAccess.includes('view')">
                            <v-icon class="btn-action" small>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" @click="dialog('Edit',item)" v-if="userAccess.includes('edit')">
                            <v-icon class="btn-action" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" @click="deleteApprover(item)" v-if="userAccess.includes('delete')">
                            <v-icon class="btn-action" small>mdi-delete</v-icon>
                        </v-btn>
                    </span>
                </template>

            </v-data-table>
            <v-dialog v-model="APPROVER_DIALOG" width="30%" style="align-items: center;" persistent>
                <AdministrativeApproverViewComponent v-if="dialogType !== ''" />
            </v-dialog>

        </v-card>
    </v-container>
    
</template>
  
<script>
    import { mapGetters } from 'vuex';
    import swal from "sweetalert2";
    import AdministrativeApproverViewComponent from '@/views/main/modules/Admin/PurchaseOrder/AdministrativeApproverViewComponent.vue';
    export default {
        components: {
        AdministrativeApproverViewComponent,
    },
    data() {
        return {
            dialogType: '',
            dialogVisible: false,
            form: {
                items:[],
                headers: [
                    {text: 'Name', align: 'left', value: 'fullname'},
                    {text: 'Job Title', align: 'left', value: 'job_title'},
                    {text: 'Division', align: 'left', value: 'division'},
                    {text: 'Range', align: 'right', value: 'range'},
                    {text: 'Actions', align: 'center', value: 'action'},
                ],
                page: 1,
                itemsPerPage: 10,
                totalItems: 0,
                rangeType: 0,
                rangeMoney: 0,
            },
            filters: {
                search: '',
                option: {},
            },
            rangeTypes: [
                { text: 'Above', value: '1' },
                { text: 'Specific', value: '2' },
                { text: 'Below', value: '3' },
            ],
            userAccess:[],
            loading: false
        }
    },
    mounted() {
        this.$store.dispatch("getAllRange");
        this.$store.dispatch(
        "checkAccessUser",
        this.$router.currentRoute.params.id
        );
        this.list();
    },
    computed: {
        ...mapGetters([
            "USER_ACCESS",
            "APPROVER_DIALOG",
            "ACTION",
            "SELECTED",
            "GET_ALL_RANGE",
            "USERACCOUNT_LOAD",
            "SELECTED_TAB"
        ]),
    },
    methods: {
        async list(){
            this.form.items = [];
            this.loading = true;
            await this.$store.dispatch("EnterAdministrativeApproverGet",{
                url: "getAllAdministrativeApprover",
                page: this.form.page,
                itemsPerPage: this.form.itemsPerPage,
                search: this.filters.search,
                rangeType: this.form.rangeType,
                rangeMoney: this.form.rangeMoney,
                tabs: this.SELECTED_TAB,
                department_id: this.USERACCOUNT_LOAD.president === 1 || this.USERACCOUNT_LOAD.super_admin === 1 ? '' : this.USERACCOUNT_LOAD.department_id,
            }).then(response => {
                this.form.items = response.data.data.data.map( item => (
                    {
                        id: item.id,
                        value: item.value,
                        user: item.user,
                        job_title: item.job_title,
                        fullname: item.fullname,
                        division: item.division,
                        range: item.range,
                    }));
                    this.form.totalItems = response.data.total;
                    this.loading = false;
            }).catch(error => {
                console.error("Error fetching Administrative Approver:", error)
                this.loading = false;
            });
        },
        dialog(action, item){
            this.$store.commit('APPROVER_DIALOG', true);
            this.$store.commit('ACTION', action);
            this.$store.commit('SELECTED',item.user);
            this.dialogType = action;
            this.dialogVisible = true;
        },
        deleteApprover(item){
            swal.fire({
            icon: "warning",
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "#397373",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("EnterRepairTypeGet", {
                    url: 'get-administrative-approver-delete/' + item.id,
                    }).then(response=>{
                        this.$store.dispatch("getAllRange");
                        this.list();
                        swal.fire('Success', '', 'success');
                    });
                }
            });
        },
        search(){
            this.form.page = 1;
            this.list();
        }
    },
    watch: {
        APPROVER_DIALOG: {
            handler(val){
                if(!val){
                    this.list();
                }
            },
            deep: true
        },
        'filters.option': {
            handler(val){
                this.list();
            }
        },
        'form.page'(val){
            this.list(val, this.form.itemsPerPage);
        },
        'form.itemsPerPage'(val){
            this.list(this.form.page, val);
        },
        'form.rangeType' (val){
            if (val && this.form.rangeMoney) this.list(this.form.rangeType, val);
        },
        'form.rangeMoney' (val){
            if (val && this.form.rangeType) this.list(this.form.rangeMoney, val);
        },
        USER_ACCESS:{
            handler(val){
            if(val != "fail") this.userAccess = val.map(e=>e.actions_code);
            }
        },
        SELECTED_TAB: {
            handler(val){
                if(val){
                    this.list();
                }
            }
        }
        
    }
}
</script>
  