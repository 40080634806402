<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="w-100 d-flex pl-0 mr-4">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-btn class="ml-1 mb-2 ml-auto" @click="exportItemConsumables()">
                            <v-icon>mdi-file-export</v-icon>Export
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <AdminItemConsumableTableComponent v-if="GET_ADMIN_TAB_IS_ACTIVE == isActive_tab"></AdminItemConsumableTableComponent>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import AdminItemConsumableTableComponent from '@/views/main/modules/Admin/AdminItemConsumable/AdminItemConsumableTableComponent.vue';
import { mapGetters } from 'vuex'
import Swal from "sweetalert2";
export default {
    components: {
        AdminItemConsumableTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_TAB_IS_ACTIVE','GET_ADMIN_ITEMS_COLLECTIONS'])
    },
    mounted() {
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getStoreBranchesWithoutAccess');
        this.$store.dispatch('getAdminLocationSelection');
    },
    methods: {
        exportItemConsumables() {
            this.$store.commit('EXPORT_ITEM_CONSUMABLES', true);
        },
        AddDialog(){
            if(this.GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted' && this.GET_ADMIN_ITEMS_COLLECTIONS.length == 0){
                Swal.fire({
                    title:'please select items'
                })
                return false;
            }
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        }
    }
};
</script>
