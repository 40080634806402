<template>
  <v-container>
    <v-row class="py-3">
      <v-btn v-if="userAccess.create" @click="adddialogs()" >
        <v-icon>mdi-plus</v-icon>Add
      </v-btn>
    </v-row>
    <v-row>
      <add-unit-baklas-table></add-unit-baklas-table>
    </v-row>
      <batch-addtion-dialog></batch-addtion-dialog>
  </v-container>
</template>

<script>
import AddUnitBaklasTable from "@/views/main/modules/Services/Views/BaklasComponent/AddUnitBaklasTable.vue";
import batchAddtionDialog from "@/views/main/layouts/dialogs/Service/BaklasAddUnitDialog.vue";
import { mapGetters } from 'vuex'

export default {
  components: { 
    AddUnitBaklasTable,
    batchAddtionDialog
  },
  data(){
    return {
      action: "add",
      selectedAction: "",
      userAccess: {
          create: false,
      },
    }
  },
  methods: {
    adddialogs() {
      this.$store.commit("ADD_UNIT_DIALOG", {
        action: {
          type: 'add',
          button_text: 'Submit',
          title_text: 'Add Baklas New Unit'
        },
        dialogs:{
          add_unit_dialog: true,
        },
        data:{},
        reload_table: false
      });
    },
 
  },
  computed:{
    ...mapGetters([
        'GET_ADD_UNIT',
        'USER_ACCESS'
    ]),
  },
  mounted(){
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
  },
  watch:{
    USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
  }

}
</script>

<style>

</style>