<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row class="mb-2">
                            <v-col cols="2">
                                <v-menu
                                    v-model="menus.month_range"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290"
                                    offset-y
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filter.month_range"
                                            v-on="on"
                                            class="center-align-text"
                                            append-icon="mdi-calendar"
                                            label="Month Range"
                                            clearable
                                            outlined
                                            readonly
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="filter.month_range"
                                        type="month"
                                        @change="closeMonthRangeMenu()"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col sm="12" md="1" v-if="filter.with_no_of_late_range">
                                <v-text-field
                                    v-model="filter.actual_no_of_late_range[0]"
                                    class="d-inline-block w-100 small"
                                    background-color="white"
                                    label="Late Min"
                                    type="number"
                                    min="0"
                                    hide-details
                                    outlined
                                    dense
                                    @blur="validateLateRange()"
                                ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="1" v-if="filter.with_no_of_late_range">
                                <v-text-field
                                    v-model="filter.actual_no_of_late_range[1]"
                                    class="d-inline-block w-100 small"
                                    background-color="white"
                                    label="Late Max"
                                    type="number"
                                    min="0"
                                    hide-details
                                    outlined
                                    dense
                                    @blur="validateLateRange()"
                                ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="2">
                                <v-checkbox
                                    v-model="filter.with_no_of_late_range"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                >
                                    <template v-slot:label>
                                        <span>No Of Late Range</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2" class="d-flex justify-content-end">
                                <v-btn small @click="getEmployeeForMemoTardiness(); datatable.options.page = 1;" :loading="datatable.loading">Filter</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.department_ids"
                                    :items="dropdowns.departments_lists"
                                    label="Department"
                                    auto-select-first
                                    deletable-chips
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
								    @input="filterJobsByLevelAndDept()"
                                    @click:clear="filterJobsByLevelAndDept()"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.job_level_ids"
                                    :items="dropdowns.job_level_lists"
                                    label="Job Level"
                                    auto-select-first
                                    deletable-chips
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
								    @input="filterJobsByLevelAndDept()"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-autocomplete
                                    v-model="filter.job_title_ids"
                                    :items="dropdowns.position_lists"
                                    label="Position"
                                    auto-select-first
                                    deletable-chips
                                    small-chips
                                    clearable
                                    multiple
                                    outlined
                                    required
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="filter.search"
                                    label="Search Employee ID/Name"
                                    clearable
                                    dense
                                    @change="getEmployeeForMemoTardiness()"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        disable-sort
                        fixed-header
                        dense
                        @update:page="getEmployeeForMemoTardiness()"
                        @update:items-per-page="getEmployeeForMemoTardiness()"
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom color="#f69f1a">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text icon color="orange"
                                            v-if="userAccess.includes('view')"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon
                                            class="btn-action"
                                            small
                                            @click="viewTimekeepingRecords(item)"
                                        >mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View Timekeeping Record</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            loaded: false,
            search: "",
            filter:{
                department_ids: [],
                position_ids: [],
                month_range: this.$dayjs().format('YYYY-MM'),
                search: '',
                job_level_ids: [],
                with_no_of_late_range: 0,
                actual_no_of_late_range: [0, 0],
            },

            datatable:{
                headers:
                    [
                        { text: 'Employee ID', align: 'start', value: 'employee_id_display' },
                        { text: 'Name', align: 'start', value: 'employee_full_name' },
                        { text: 'Department', align: 'start', value: 'department_name' },
                        { text: 'Position', align: 'start', value: 'position_name' },
                        { text: 'Level Name', align: 'start', value: 'level_name' },
                        { text: 'No. Of Late', align: 'end', value: 'no_of_late' },
                        { text: 'Total Late in Hours', align: 'end', value: 'total_late_in_hours' },
                        { text: 'Total Late in Minutes', align: 'end', value: 'total_late_in_minutes' },
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable: false,
            },

            menus:{
                month_range: false
            },

            userAccess: [],

            dropdowns:{
                departments_lists: [],
                position_lists: [],
                job_level_lists: [],
            },

            selected_status: '',
            isDepartmentView: 0,
            component_dispatches:[
                'getDepartment',
                'getEmployeeJobTitleSelection',
                'getJobLevelSelection'
			],
            monthSelection: [],
        }
    },
    async mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        await this.getAllDropdowns();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_HR_DISPATCH',
            'GET_LIST_DEPARTMENT',
            'GET_JOB_TITLE_SELECTION',
            'GET_JOB_LEVEL_SELECTION'
        ]),
        monthRange(){
            return this.filter.month_range.join(' - ')
        }
    },
    methods:{
        async getEmployeeForMemoTardiness(){
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page                : this.datatable.options.page,
                itemsPerPage        : this.datatable.options.itemsPerPage,
                search              : this.filter.search,
                sortBy              : this.datatable.options.sortBy,
                sortDesc            : this.datatable.options.sortDesc,
                status              : this.selected_status,
                selected_status     : this.selected_status,
                url                 : this.GET_HR_DISPATCH.get,
                ...this.filter,
            };
            await this.$store.dispatch('hrUrlGet', payload).then((response) => {
                let temp_data = response.data.data;
                temp_data = Object.values(temp_data).map((item)=>item)
                if(response.pending_payroll_month == 1){
                    Swal.fire({
                        title: "Payroll on selected month is not yet completed!",
                        text: "Please select other month.",
                        icon: "info"
                    });
                }
                this.datatable.items                    = Array.isArray(response.data.data) ? response.data.data : temp_data;
                this.datatable.totalItems               = response.data.total;
                this.loading.datatable                  = false;
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        viewTimekeepingRecords(item){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
            this.$store.dispatch('getTimekeepingRecordEmployeeID', {
                id: item.id
            }).then((res)=>{
                // let payload = {
                //     employee_ids: this.GET_TIMEKEEPING_RECORD_EMPLOYEE_ID,
                //     id: item.id
                // }
                // this.$store.dispatch('showTimekeepingRecordDetails', payload).then((response)=>{
                //     this.$store.commit("TIMEKEEPING_RECORD_VIEW", response);
                // })
            }).catch((err)=>{
                console.log(err);
                Swal.fire(
                    "Network Error",
                    "",
                    "error"
                )
            })
            // this.$store.commit("TIMEKEEPING_RECORD_VIEW", item);
        },
        closeEmployeeFilterDialog(){
            this.filter.dialog = false;
            this.resetFilters()
        },
        async getAllDropdowns(){
            await this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
        },
        resetFilters(){
            Object.assign(this.filter, {
                // dialog: false,
                department_ids:[],
                areas: [],
                port: null,
                host_ids: [],
                with_inactive: 0,
                client_ids: [],
                contract_type_ids: [],
            })
        },
        closeMonthRangeMenu(){
            this.menus.month_range = false;
        },
        filterJobsByLevelAndDept(){
            if(this.filter.job_level_ids.length > 0 || this.filter.department_ids.length > 0){
                let filteredJobsByLevel = this.GET_JOB_TITLE_SELECTION.filter(obj=>{
                    if(this.filter.department_ids.length > 0 && this.filter.job_level_ids.length > 0){
                        return this.filter.job_level_ids.includes(obj.job_level_id) && this.filter.department_ids.includes(obj.department_id)
                    }
                    else if(this.filter.job_level_ids.length > 0){
                        return this.filter.job_level_ids.includes(obj.job_level_id)
                    }
                    else if(this.filter.department_ids.length > 0){
                        return this.filter.department_ids.includes(obj.department_id)
                    }else{
                        return obj
                    }
                })
                this.dropdowns.position_lists = filteredJobsByLevel;
            }else{
                this.dropdowns.position_lists = this.GET_JOB_TITLE_SELECTION;
            }
		},
        resetLateRange(){
            this.filter.actual_no_of_late_range = [0, 0];
        },
        validateLateRange(){
            if(this.filter.actual_no_of_late_range.length == 2){
                if((this.filter.actual_no_of_late_range[0] > this.filter.actual_no_of_late_range[1]) || (this.filter.actual_no_of_late_range[1] < this.filter.actual_no_of_late_range[0])){
                    Swal.fire(
                        "No Of Late Range Not Valid",
                        "",
                        "warning"
                    ).then((confirm)=>{
                        if(confirm.isConfirmed){
                            this.resetLateRange();
                        }
                    })
                }
            }
        }
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.userAccess = this.USER_ACCESS.map(e=> e.actions_code);
                }
            }
        },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.departments_lists = val
            }
        },
        GET_JOB_TITLE_SELECTION:{
            handler(val){
                this.dropdowns.position_lists = val
            }
        },
        GET_JOB_LEVEL_SELECTION:{
            handler(val){
                this.dropdowns.job_level_lists = val
            }
        },
    }
}
</script>
<style scoped>
    .selected {
        opacity: 100%;
    }

    .non-selected {
        opacity: 40%;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

</style>
