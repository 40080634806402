<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">{{ACTION == 'Submit' ? 'ADD' : ACTION.toUpperCase()}}
                ACCOUNTABILITY
                {{ form.emp_id ? 'FOR ' + employee_selection_data.find(e=>e.value == form.emp_id).text : '' }}</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-container class="overflow-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-row>
                                <v-col cols="12" >
                                    <v-text-field
                                        v-model="form.accountability_num"
                                        label="AS#"
                                        outlined
                                        dense
                                        :disabled="ACTION == 'View'"
                                        @keydown="limitNumberInput($event, 4)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                               <v-col cols="12">
                                    <v-autocomplete
                                        v-model="form.warehouse_id"
                                        :items="GET_ADM_WAREHOUSE_CODE_DROPDOWN"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        outlined
                                        label="Inventory"
                                        required
                                        dense
                                        :disabled="ACTION != 'Submit' && ACTION != 'Update'"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" >
                                    <v-autocomplete
                                        v-model="form.department_id"
                                        :items="GET_LIST_DEPARTMENT"
                                        :search-input.sync="search_department"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        outlined
                                        label="Department"
                                        required
                                        :disabled="ACTION != 'Submit' && ACTION != 'Update'"
                                        dense
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 pt-2">
                                <v-col cols="12"  >
                                    <!-- {{ employee_selection_data }} -->
                                    <v-autocomplete
                                        v-model="form.emp_id"
                                        :items="employee_selection_data"
                                        auto-select-first
                                        outlined
                                        label="Employee Name"
                                        required
                                        dense
                                        :disabled="ACTION != 'Submit' && ACTION != 'Update'"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 pt-2">
                                <v-col cols="12"  >
                                    <v-autocomplete
                                        v-model="form.company_id"
                                        :items="GET_PV_COMPANIES_SELECTION"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        outlined
                                        label="Company"
                                        required
                                        :disabled="(ACTION != 'Submit' && ACTION != 'Update') || !form.warehouse_id"
                                        dense
                                        @change="insertStore()"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 pt-2">
                                <v-col cols="12"  >
                                    <v-autocomplete
                                        v-model="form.store_branch_id"
                                        :items="GET_PV_STORE_BRANCHES_SELECTION"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        outlined
                                        label="Branch"
                                        required
                                        :disabled="(ACTION != 'Submit' && ACTION != 'Update') || !form.company_id"
                                        dense
                                        @change="insertCompany(form.store_branch_id)"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 pt-2">
                                <v-col cols="12"  >
                                    <v-autocomplete
                                    v-model="form.location"
                                    :items="GET_ADMIN_LOCATION"
                                    item-text="text"
                                    item-value="value"
                                    auto-select-first
                                    outlined
                                    label="Location"
                                    required
                                    :disabled="(ACTION != 'Submit' && ACTION != 'Update')"
                                    dense
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <!-- <v-row class="pa-0 pt-2">
                                <v-col cols="12" v-if="ACTION == 'Submit' && GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted'">
                                    <span style="font-size: 16px; font-style: bold;">{{'Set All Employee of Items: '}}</span>
                                   <v-autocomplete
                                        v-model="form.setting_emp_id"
                                        :items="employee_selection_data2"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        outlined
                                        label="Employee Name"
                                        required
                                        :disabled="ACTION != 'Submit'"
                                        dense
                                    ></v-autocomplete>
                                </v-col>
                            </v-row> -->
                            <!-- <v-divider></v-divider>
                            <h6>For Turnover</h6>
                            <v-row class="pa-0 pt-2">
                                <v-col>
                                    <v-text-field
                                        v-model="form.purpose"
                                        dense
                                        outlined
                                        label="Purpose"
                                        :disabled="disabled_view"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 pt-2">
                                <v-col>
                                    <v-textarea
                                        v-model="form.audit_findings"
                                        dense
                                        outlined
                                        label="Audit Findings"
                                        :disabled="disabled_view"
                                    ></v-textarea>
                                </v-col>
                            </v-row> -->
                        </v-col>
                        <v-col cols="12" sm="9" class="pt-0">
                                <!-- <v-row class="d-flex justify-content-end">
                                <v-col cols="12" sm="4">
                                    <v-text-field small dense outlined label="Asset Tag # / Scan QR"v-model="form.asset_tag_scan" :disabled="disabled_view">SCAN MODE</v-text-field>
                                    <input type="submit" class="d-none" @click="addItem()">
                                </v-col>
                            </v-row> -->
                            <v-card elevation="0"  height="400">
                                <v-card  class="overflow-auto pa-0" height="370" elevation="0">
                                    <v-simple-table fixed-header>
                                        <thead class="position-sticky">
                                            <tr v-if="GET_ADMIN_TAB_IS_ACTIVE=='accountability'">
                                                <th colspan="3">
                                                    <span class="d-flex">
                                                        <FileUploadComponentV2
                                                            v-if="actions == 'Update' && GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.printed == 1"
                                                            ref="fileUpload"
                                                            id="attachment"
                                                            :fileIndex="0"
                                                            :onFileChange="uploadedData"
                                                            @reset="clearFileUpload"
                                                        />
                                                        <BaseFilesViewerComponentV2
                                                            v-if="actions !== 'Submit'"
                                                            ref="clearFiles"
                                                            :payloadId="GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.id ? GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.id : null"
                                                            :getFilesDispatch="'getAdminAccountabilityFiles'"
                                                            :deleteFileDispatch="'deleteAdminAccountabilityFiles'"
                                                            :module="'admin'"
                                                            :folder="'accountability_file_uploads'"
                                                            :localFiles="uploadedFiles[0]"
                                                            :fileIndex="0"
                                                            :onLocalFileDelete="onLocalFileDelete"
                                                            :viewing="actions == 'View'"
                                                        />
                                                    </span>
                                                </th>
                                                <th colspan="1" class="d-flex justify-content-end" v-if="ACTION == 'Submit'">
                                                    <!-- <v-checkbox
                                                        v-model="form.low_value_asset_checkbox"
                                                        label="Low Value Asset"
                                                    >
                                                    </v-checkbox> -->
                                                </th>
                                                <th v-if="form.low_value_asset_checkbox == true"
                                                    colspan="3" class="pa-0 pr-1">
                                                    <v-autocomplete
                                                        v-model="form.low_value_asset_lists"
                                                        :items="GET_LOW_VALUE_ASSETS_SELECTION"
                                                        item-text="text"
                                                        item-value="value"
                                                        auto-select-first
                                                        outlined
                                                        label="Admin Items"
                                                        required
                                                        :disabled="disabled_view || !form.department_id"
                                                        dense
                                                        clearable
                                                        @change="addItem"
                                                    >
                                                    </v-autocomplete>
                                                </th>
                                                <th v-else colspan="2">
                                                    <v-text-field
                                                        v-model="form.asset_tag_scan"
                                                        small
                                                        dense
                                                        background-color="white"
                                                        outlined
                                                        label="Asset Tagging / Scan QR"
                                                        :disabled="disabled_view"
                                                    >
                                                        SCAN MODE
                                                    </v-text-field>
                                                <input type="submit" class="d-none" @click="addItem"> </th>
                                            </tr>
                                            <tr>
                                                <th  style="background: #F69F1A;" width="15%">ASSET TAGGING</th>
                                                <th  style="background: #F69F1A;" width="20%">ITEM NAME</th>
                                                <th  style="background: #F69F1A;" width="20%">PREVIOUS ACCOUNTABILITY NO.</th>
                                                <th  style="background: #F69F1A;" width="30%" v-if="form.item_list[0]?.emp_id">EMPLOYEE NAME</th>
                                                <th  style="background: #F69F1A;" width="20%">CONDITION</th>
                                                <th  style="background: #F69F1A;">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,i) in form.item_list" :key="i">
                                                <td class="pa-2">
                                                    <span>{{ data.code }}</span>
                                                </td>
                                                <td class="pa-2">
                                                    <span>{{ data.name }}</span>
                                                </td>
                                                <td class="pa-2">
                                                    <span>{{ data.previous_accountability_num }}</span>
                                                </td>
                                                <td class="pa-2" v-if="data.emp_id">
                                                    <v-autocomplete
                                                        v-model="data.emp_id"
                                                        :items="employee_selection_data2"
                                                        item-value="value"
                                                        item-text="text"
                                                        auto-select-first
                                                        outlined
                                                        required
                                                        :disabled="disabled_view"
                                                        dense
                                                    />
                                                </td>
                                                <td class="pa-2">
                                                    <v-autocomplete
                                                        v-if="
                                                            // data.admin_asset_condition_id ||
                                                            data.previous_accountability_num
                                                        "
                                                        v-model="data.admin_asset_condition_id"
                                                        :items="GET_ADMIN_ASSET_CONDITION_SELECTION"
                                                        item-value="value"
                                                        item-text="text"
                                                        auto-select-first
                                                        outlined
                                                        required
                                                        :disabled="disabled_view"
                                                        dense
                                                        :rules="[v => !!v || '']"
                                                    />
                                                </td>
                                                <!-- <td class="pt-2 tex">
                                                    <v-textarea v-if="actions != 'View'" v-model="data.remarks" outlined rows="2" auto-grow style="font-size:12px" :disabled="disabled_view"></v-textarea>
                                                    <span v-if="actions == 'View'">{{ data.remarks }}</span>
                                                </td> -->
                                                <td>
                                                    <!-- <FileUploadComponentV2
                                                        v-if="actions != 'View'"
                                                        ref="fileUpload"
                                                        id="attachment"
                                                        :fileIndex="i"
                                                        :onFileChange="uploadedData"
                                                        @reset="clearFileUpload"
                                                    />
                                                    <BaseFilesViewerComponentV2
                                                        ref="clearFiles"
                                                        :payloadId="data.item_id ? data.item_id : null"
                                                        :getFilesDispatch="'getAdminAccountabilityFiles'"
                                                        :deleteFileDispatch="'deleteAdminAccountabilityFiles'"
                                                        :module="'admin'"
                                                        :folder="'accountability_file_uploads'"
                                                        :localFiles="uploadedFiles[i]"
                                                        :fileIndex="i"
                                                        :onLocalFileDelete="onLocalFileDelete"
                                                        :viewing="actions == 'View'"
                                                    /> -->
                                                    <!-- <div
                                                        v-if="actions != 'View'"
                                                        style="margin-top: 20px;"
                                                    >
                                                        <v-layout row class="mx-0">
                                                            <v-flex lg2 class="ml-2">
                                                                <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                                            </v-flex>
                                                            <v-flex v-if="!!images[i] && images[i].length > 0" lg3 class="mr-2 mt-1 ">
                                                                <span>Images Count: {{ images[i].length }}</span>
                                                            </v-flex>
                                                            <v-flex v-if="!!images[i] && images[i].length > 0">
                                                                <v-btn @click="clearCameraCapture()">Clear</v-btn>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div> -->
                                                    <v-tooltip top v-if="actions != 'View'">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-on="on"
                                                                elevation="0"
                                                                fab
                                                                icon
                                                                small
                                                                color="error"
                                                                @click="deleteItem(i)"
                                                            ><v-icon small>mdi-delete</v-icon></v-btn>
                                                        </template>
                                                        <span>Remove Asset</span>
                                                    </v-tooltip>
                                                <!-- <v-btn text icon color="red" @click="deleteItem(i)" :disabled="disabled_view">
                                                    <v-icon class="btn-action" small>mdi-delete</v-icon >
                                                </v-btn> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-card>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
            <v-row class="ma-1">
                <v-col cols="12" >
                    <v-btn
                        v-if="actions != 'View'"
                        class="float-center"
                        @click="createOrUpdateAccountability()"
                        :disabled="disabled_view"
                    >
                        <span>{{ actions }}</span>
                    </v-btn>
                    <v-btn
                        v-if="actions != 'Submit' && actions != 'Update' && GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.admin_category_id == 2"
                        class="float-center"
                        @click="renderPDF()"
                    >
                        <span>Print</span>
                    </v-btn>
                    <!-- <v-btn
                        v-if="actions != 'Submit' && actions != 'Update' && displayTurnOverPrintButton"
                        class="float-center"
                        @click="renderTurnOverPDF()"
                    >
                        <span>Print Turnover</span>
                    </v-btn> -->
                    <v-btn
                        v-if="actions == 'View' && GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.item_category == 2"
                        class="float-center"
                        @click="renderAdminReleaseFormPDF()"
                    >
                        <span>Print ARF</span>
                    </v-btn>
                    <v-btn
                        v-if="actions == 'View' && GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.admin_category_id == 3"
                        class="float-center"
                        @click="renderLowValueAssetAccountability()"
                    >
                        <span>Print LVAA</span>
                    </v-btn>
                </v-col>
            </v-row>
            </v-card-actions>
                <!-- <adminTemplateComponent></adminTemplateComponent> -->
                <AccountabilitySheetTemplate/>
                <AdminReleaseFormTemplateComponentV2/>
                <LowValueAssetAccountabilityFormTemplate/>
        </v-card>
    </v-form>
</template>
    <script>
import QrcodeVue from "qrcode.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import AccountabilityTurnoverFormPrintComponent from "@/views/main/layouts/PrintTemplate/AccountabilityTurnoverFormPrintComponent.vue";
import AdminReleaseFormTemplateComponentV2 from "@/views/main/layouts/PrintTemplate/AdminReleaseFormTemplateComponentV2.vue";
import LowValueAssetAccountabilityFormTemplate from "@/views/main/layouts/PrintTemplate/LowValueAssetAccountabilityFormTemplate.vue";
import images from '../../../../../assets/ingcoph_bg/ingcoph_logo_alt.jpg'
import AccountabilitySheetTemplate from '@/views/main/layouts/PrintTemplate/AccountabilitySheetTemplate.vue';
import FileUploadComponentV2 from '@/views/main/Shared/FileUploadComponentV2.vue';
import BaseFilesViewerComponentV2 from "@/views/main/Shared/BaseFilesViewerComponentV2.vue";
import BaseCameraCaptureVue from "@/views/main/modules/HR/Base/BaseCameraCapture.vue";
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [
        AccountabilityTurnoverFormPrintComponent,
        SharedFunctionsComponentVue,
    ],
    props:['boundType'],
    data() {
        return {
            file_index: 0,
            valid:true,
            form: {
                emp_id: "",
                location: "",
                admin_category_id: 2,
                item_list:[],
                item_trash:[],
                previous_asset:[],
                department_id:'',
                asset_tag:'',
                asset_tag_scan:'',
                accountability_id:'',
                purpose:'',
                audit_findings:'',
                low_value_asset_checkbox: false,
                item_category: '',
                setting_emp_id: '',
                vendor_id: '',
                low_value_asset_lists: [],
                accountability_num: '',
                company_id: '',
                store_branch_id: '',
                warehouse_id: '',

            },
            employee_selection_data:[],
            employee_selection_data2:[{
                'value':0,
                'text':'GENERAL ACCOUNTABILITY',
                'department_id':0
            }],
            search_employee:'',
            search_department:'',
            action:'',
            disabled_view: false,
            disabled_employee_dropdown: false,
            errors: {
                emp_id: [(v) => !!v || "Employee Name is required"],
                location: [(v) => !!v || "Location is required"],
            },
            uploadedFiles: [],
            images: [],
            uploadedImages: [],
            clonedStores: [],
        };
    },
    components: {
        QrcodeVue,
        // adminTemplateComponent,
        AccountabilitySheetTemplate,
        FileUploadComponentV2,
        BaseFilesViewerComponentV2,
        BaseCameraCaptureVue,
        AdminReleaseFormTemplateComponentV2,
        LowValueAssetAccountabilityFormTemplate,
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "DIALOGS",
            "GET_ADMIN_LOCATION",
            "GET_EMPLOYEE_DATA_SELECTION",
            "GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS",
            "GET_NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE",
            "GET_ADMIN_DISPATCH",
            "GET_LIST_DEPARTMENT",
            "GET_ADMIN_TAB_IS_ACTIVE",
            "GET_ADMIN_ITEMS_COLLECTIONS",
            "GET_ADMIN_ASSET_CONDITION_SELECTION",
            "GET_ADMIN_WAREHOUSE_ITEMS_SELECTION_CONSUMABLES",
            'GET_PRINT_ADMIN_ARF',
            "SELECTED_TAB",
            "GET_LOW_VALUE_ASSETS_SELECTION",
            "GET_PV_COMPANIES_SELECTION",
            "GET_PV_STORE_BRANCHES_SELECTION",
            "GET_ADM_WAREHOUSE_CODE_DROPDOWN"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                let that = this;
                if(this.GET_ADMIN_TAB_IS_ACTIVE == 'unaccounted' || this.GET_ADMIN_TAB_IS_ACTIVE == 'fixed-assets'){
                    this.GET_ADMIN_ITEMS_COLLECTIONS.forEach(e=>{
                        this.findItemsFromAssetsList(e);
                    })
                }
                this.action = this.GET_ADMIN_DISPATCH.add
                    return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.action = this.GET_ADMIN_DISPATCH.update;
                this.getEditAccountability()
                return "Update";
            } else if (this.ACTION == "View") {
                this.getEditAccountability()
                this.disabled_view = true;
                return "View";
            }
        },
        displayTurnOverPrintButton() {
            const filtered = this.form.item_list.filter(item => !!item.admin_asset_condition_id);

            if (filtered.length > 0) return true;
            return false;
        }
    },
    mounted() {
        this.addFileArray();
        this.$store.dispatch('getWarehouseItemSelectionsConsumables');
    },
    methods: {
        async imageLoader(imagePath){
            let image = await this.loadImage(imagePath);
            this.$store.commit('COMPANY_IMAGE', image);
        },
        addFileArray() {
            this.uploadedFiles.push({});
            this.images.push({});
            this.uploadedImages.push({});
        },
        getEditAccountability(){
            this.form.item_list = [];
            this.form.accountability_num = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.as_num
            this.form.emp_id = parseInt(this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.emp_id)
            this.form.location = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.location_id
            this.form.item_list = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.admin_accountabilityitem
            this.form.warehouse_id = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.warehouse_id
            this.form.company_id = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.company_id
            this.form.store_branch_id = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.store_branch_id
            this.imageLoader(this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.image_path)
            this.uploadedFiles = [];
            this.images = [];
            this.uploadedImages = [];

            this.form.item_list.forEach(() => {
                this.addFileArray();
            });

            this.form.accountability_id = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.id;
            this.form.department_id = this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.department;
            this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.department_name = this.search_department

        },
        addItem(e){
            let that = this;
            if(this.form.low_value_asset_checkbox == true){
                this.$store.dispatch('getSelectedLowValueAsset', {
                    url: 'get-selected-low-value-asset',
                    admin_item_id: this.form.low_value_asset_lists
                }).then((response)=>{
                    this.form.admin_category_id = 2;
                    this.form.low_value_asset_lists = response.data
                    let employeeIndex = 0;
                    this.form.low_value_asset_lists.forEach(e => {
                        let currentEmployee = this.employee_selection_data2[employeeIndex];
                        employeeIndex = employeeIndex + 1;
                        this.form.item_list.push({
                            item_id:'',
                            asset_num:e.id,
                            code:e.asset_code,
                            name:e.name,
                            accountability_num:e.accountability_num,
                            previous_accountability_num:e.previous_accountability_num?e.previous_accountability_num:'',
                            previous_admin_accountability_id:e.previous_admin_accountability_id?e.previous_admin_accountability_id:'',
                            admin_asset_condition_id:e.admin_asset_condition_id?e.admin_asset_condition_id:'',
                            remarks:'',
                            admin_item_id: e.admin_item_id,
                            category_type: e.category_type,
                            emp_id: currentEmployee.value,
                            vendor_id: e.vendor_id,
                            warehouse_item_id: e.warehouse_item_id,
                            admin_item_id: e.admin_item_id,
                        })
                    })
                });
            }else{
                e.preventDefault();
                let splited_ = that.form.asset_tag_scan.split(":");
                let asset_code = splited_.length > 1 ?splited_[1].split(" ")[1]:that.form.asset_tag_scan
                this.findItemsFromAssetsList(asset_code);
            }
        },
        findItemsFromAssetsList(asset_code){
            let that = this;
            this.form.admin_category_id = 2;
            if(that.form.asset_tag_scan != ' ' && that.form.asset_tag_scan != '' || this.GET_ADMIN_ITEMS_COLLECTIONS.length > 0 && !that.form.consumable_checkbox) {
                that.$store.dispatch('findAssets',asset_code.asset_code).then((response)=>{
                    response.forEach(e=>{
                        if (that.form.item_list.length > 0) {
                            let ifexist = that.form.item_list.find(asset_num=>{
                                return asset_num.asset_num == e.asset_num;
                            });
                            that.form.asset_tag_scan = ''
                            // if(ifexist){
                            //     Swal.fire({
                            //         title:'Warning',
                            //         text:'Asset Tagging already on the list',
                            //         icon:'warning'
                            //     })
                            //     return false;
                            // }
                        }
                        // if(e.accountability_num){
                        //     let employee =''
                        //     that.GET_EMPLOYEE_DATA_SELECTION.filter(element=>{
                        //         if(element.value == e.emp_id){
                        //             employee = element.text;
                        //         }
                        //     });
                        //     Swal.fire({
                        //         title:'Warning',
                        //         icon:'warning',
                        //         html:`this item is already from <span style="font-weight:bold"> ${employee} </span> are you sure you want to proceed?`,
                        //         footer: '<span style="font-size:12px; color:red; font-style:italic">Note: once you '+ that.actions.toLowerCase() + '. The asset no. will automatically be deleted from the previous owner.</span>',
                        //         confirmButtonText:'Yes',
                        //         cancelButtonText: "No",
                        //         showCancelButton: true,
                        //         allowOutsideClick: false,
                        //         confirmButtonColor: "#397373",
                        //         cancelButtonColor: "#397373",
                        //     }).then((result)=>{
                        //         if (result.isConfirmed) {
                        //             that.form.previous_asset.push({
                        //                 previous_asset_code:e.asset_code,
                        //                 previous_asset_num:e.accountability_num
                        //             })
                        //             that.form.item_list.push({
                        //                 item_id:'',
                        //                 asset_num:e.asset_num,
                        //                 code:e.code,
                        //                 name:e.name,
                        //                 accountability_num:e.accountability_num,
                        //                 previous_accountability_num:'',
                        //                 admin_asset_condition_id:'',
                        //                 remarks:e.remarks,
                        //                 admin_item_id: e.admin_item_id,
                        //                 category_type: e.category_type,
                        //                 vendor_id: e.vendor_id,
                        //                 warehouse_item_id: e.admin_warehouse_item_stocks_id
                        //             })
                        //             that.form.asset_tag_scan = ''

                        //             this.addFileArray();
                        //         }
                        //     })
                        // } else {
                        //     that.form.asset_tag_scan = ''
                        //     that.form.previous_asset = []
                        //     that.form.item_list.push({
                        //         item_id:'',
                        //         asset_num:e.asset_num,
                        //         code:e.code,
                        //         name:e.name,
                        //         accountability_num:e.accountability_num,
                        //         previous_accountability_num:'',
                        //         admin_asset_condition_id:'',
                        //         remarks:'',
                        //         admin_item_id: e.admin_item_id,
                        //         category_type: e.category_type,
                        //         vendor_id: e.vendor_id,
                        //         warehouse_item_id: e.admin_warehouse_item_stocks_id
                        //     })

                        //     this.addFileArray();
                        // }
                        that.form.asset_tag_scan = ''
                        that.form.previous_asset = []
                        that.form.item_list.push({
                            item_id:'',
                            asset_num:e.asset_num,
                            code:e.code,
                            name:e.name,
                            accountability_num:e.accountability_num,
                            previous_accountability_num:e.previous_accountability_num?e.previous_accountability_num:'',
                            previous_admin_accountability_id:e.previous_admin_accountability_id?e.previous_admin_accountability_id:'',
                            admin_asset_condition_id:e.admin_asset_condition_id?e.admin_asset_condition_id:'',
                            remarks:'',
                            admin_item_id: e.admin_item_id,
                            category_type: e.category_type,
                            vendor_id: e.vendor_id,
                            warehouse_item_id: e.admin_warehouse_item_stocks_id
                        })

                        this.addFileArray();
                    })
                });
            }
        },
        renderPDF(){
            this.$store.commit('PRINT_ADMIN_DETAIL',{});
            this.$store.commit('PRINT_ADMIN_DETAIL',this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS);
            this.$store.commit('PRINT_ADMIN',true);
        },
        createOrUpdateAccountability(){
            Swal.fire({
                title: 'Are you sure you want to '+this.ACTION+'?',
                showCancelButton:true,
                reverseButtons:true,
                icon:'question'
            }).then(action=>{
                if(action.isConfirmed){
                    if(this.boundType){
                        Object.assign(this.form,{inbound_outbound_type_id:this.boundType,type: this.SELECTED_TAB})
                    }
                    let checker = [
                        [([2, 12].includes(this.form.warehouse_id) && !this.form.department_id),'- Please select Department'],
                        [!this.form.company_id,'- Please select Company'],
                        [!this.form.store_branch_id,'- Please select Store Branch'],
                        [!this.form.location,'- Please select Location'],
                    ]
                    if(checker.find(e=>e[0] == true)){
                        Swal.fire('Error!',`${checker.find(e=>e[0] == true)[1]}`,'error')
                        return
                    }

                    let invalidItems = false;
                    let assetIndex = 0;

                    this.form.item_list.forEach((item, index) => {
                        if ((item.admin_asset_condition_id || (this.ACTION == 'Submit' && item.accountability_num)) && !item.admin_asset_condition_id) {
                            assetIndex = index;
                            return invalidItems = true;
                        }
                    });

                    if (invalidItems)
                        return Swal.fire({
                            html: `Asset "${this.form.item_list[assetIndex].name}" have invalid input.`,
                            icon: "warning",
                            allowOutsideClick: false,
                        });

                    this.form.item_list.forEach((item, index) => {
                        if (
                            (item.admin_asset_condition_id || (this.ACTION == 'Submit' && item.accountability_num)) &&
                            !this.uploadedFiles[index].files
                        ) {
                            assetIndex = index;
                            return invalidItems = true;
                        }
                    });

                    // if (invalidItems)
                    //     return Swal.fire({
                    //         html: `Asset "${this.form.item_list[assetIndex].name}" requires file a attachment.`,
                    //         icon: "warning",
                    //         allowOutsideClick: false,
                    //     });

                    if (this.form.emp_id || this.form.emp_id == 0) {
                        this.$store.dispatch(this.action,{
                            ...this.form,
                            file: this.uploadedFiles.map(element => element.files),
                            image: this.uploadedImages,
                        }).then(() => {
 
                        });
                    } else {
                        Swal.fire({
                            html: `Fill Required Fields <br> (${filtered})`,
                            icon: "warning",
                            allowOutsideClick: false,
                        });
                    }
                }
            })

        },
        deleteItem(i){
            this.form.item_trash.push({
                id:this.form.item_list[i].item_id ? this.form.item_list[i].item_id : this.form.item_list[i].consumable_item_id,
                name:this.form.item_list[i].name,
                code:this.form.item_list[i].code,
            });
            this.form.item_list.splice(i,1);
        },
        uploadedData(data, index){
            if (!data) {
                this.uploadedFiles[index] = [];
            }
            this.uploadedFiles[index] = data;
            this.$forceUpdate();
        },
        onLocalFileDelete(index, fileIndex) {
            if (index == -1) return;

            this.uploadedFiles[fileIndex].attachments.splice(index, 1);
            this.uploadedFiles[fileIndex].files.splice(index, 1);
            this.$forceUpdate();
        },
        clearFileUpload(index){
            this.uploadedFiles[index] = [];
        },
        async processFiles(files){
            this.uploadedFiles[this.file_index].attachments = []
            files.forEach(e=>{
                this.uploadedFiles[this.file_index].attachments.push(
                    e.localFileState
                )
            })
            let fileData = []
            for(let i = 0; i < this.uploadedFiles[this.file_index].attachments.length; i++) {
                const element = this.uploadedFiles[this.file_index].attachments[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                await new Promise((resolve) => {
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1]
                        })
                        resolve()
                    }
                })
            }
            this.uploadedFiles[this.file_index] = fileData

            // let payload = {
            //     url: 'upload-accountability-files',
            //     file: this.uploadedFiles,
            //     image: this.uploadedImages,
            // };

            // return Swal.fire({
            //     title: "Uploading...",
            //     showConfirmButton: false,
            //     didOpen: () => {
            //         Swal.showLoading();
            //         this.$store.dispatch('jobOrderPost', payload).then(response => {
            //             console.log(response);
            //             Swal.close();
            //             Swal.fire('Success!','','success')
            //         }).catch(err=>{
            //             Swal.close();
            //             Swal.fire('Error','','error')
            //         });
            //     }
            // });
        },
        getImages(dataUrls) {
            this.images = dataUrls.map(dataUrl => {
                try {
                    if (!dataUrl.startsWith('data:image/jpeg;base64,')) {
                        throw new Error('Invalid data URL format');
                    }

                    const base64String = dataUrl.split(',')[1];
                    const byteString = atob(base64String);
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        uint8Array[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
                    const filename = `captured-image-${timestamp}.jpg`;
                    const file = new File([blob], filename, { type: 'image/jpeg' });
                    return file;
                } catch (error) {
                    console.error('Failed to convert base64 to file:', error);
                    return null;
                }
            }).filter(file => file !== null);

            this.uploadedImages = []
            this.images.forEach(e=>{
                this.uploadedImages.push(e)
            })
            let fileData = []
            for (let i = 0; i < this.uploadedImages.length; i++) {
                const element = this.uploadedImages[i]
                let reader = new FileReader()
                reader.readAsDataURL(element)
                new Promise((resolve)=>{
                    reader.onloadend = function () {
                        fileData.push({
                            name: element.name,
                            type: element.type,
                            data: reader.result.split(',')[1]
                        })
                        resolve()
                    }
                })
            }
            this.uploadedImages = fileData
        },
        clearCameraCapture(){
            this.$refs.cameraCapture.reset();
            this.images = {};
        },
        closeDialog() {
            if(this.ACTION == "Update" || this.ACTION == "View"){
                // this.$store.commit("EDIT_ADMIN_ACCOUNTABILITY_DETAILS", {});
                // this.resetFields();
            }
            if(this.ACTION == "Submit"){
                this.$store.commit("EDIT_ADMIN_ACCOUNTABILITY_DETAILS", {});
            }
            this.uploadedFiles = [];
            this.images = [];
            this.uploadedImages = [];
            this.$store.commit("DIALOG", false);
            this.resetFields();
        },
        resetFields(){
            this.$store.commit('ADMIN_ITEMS_COLLECTIONS',[]);
            this.form = {
                emp_id: "",
                location: "",
                item_list:[],
                item_trash:[],
                previous_asset:[],
                admin_category_id: 2,
                department_id:'',
                asset_tag:'',
                asset_tag_scan:'',
                accountability_id:'',
                purpose:'',
                audit_findings:'',
                low_value_asset_checkbox: false,
                company_id: '',
                store_branch_id: '',
                warehouse_id: '',
            }
            this.disabled_employee_dropdown = false
            this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS.item_category = ''
        },
        renderTurnOverPDF() {
            this.$store.dispatch('printTurnOverForm', {id: this.form.accountability_id}).then(response => {
                this.printAccountabilityTurnoverForm(response);
            });
        },
        renderAdminReleaseFormPDF() {
            this.$store.commit('PRINT_ADMIN_ARF', true);
            this.$store.commit('PRINT_ADMIN_ARF_DETAIL',{});
            this.$store.commit('PRINT_ADMIN_ARF_DETAIL',this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS);
        },
        renderLowValueAssetAccountability() {
            this.$store.commit('LOW_VALUE_ASSET_PRINT', true);
            this.$store.commit('LOW_VALUE_ASSET_PRINT_DETAIL',{});
            this.$store.commit('LOW_VALUE_ASSET_PRINT_DETAIL',this.GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS);
        },
        async getEmployeeByDepartment(id){
            let ids = [];
            ids.push(id)
            await this.$store.dispatch('getEmployeeDataDropdown', {
                department_ids: ids,
            }).then(response => {
                this.employee_selection_data = response
                this.employee_selection_data2 = response
                this.employee_selection_data.unshift({value: 0, text: 'GENERAL ACCOUNTABILITY'});
            })
        },
        async getBranches(company_id = null){
            try{
                this.clonedStores = await this.getStoreBranches(company_id)
            } catch (error) {
                console.error(error)
            }
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
            this.$store.dispatch('getAdminLocationSelection', {store_branch_id: storeId})
        },
        insertStore(){
            this.form.store_branch_id = this.branchItems[0].value
        },
    },
    watch: {
        'form.department_id':{
            async handler(val){
                if(val){
                    this.disabled_employee_dropdown = false
                    this.employee_selection_data = []
                    this.employee_selection_data2 = []
                    this.getEmployeeByDepartment(val)
                    if(this.form.low_value_asset_checkbox == false){
                        this.form.emp_id = '';
                    }else{
                        this.form.emp_id = 0;
                    }
                }
            }
        },
        GET_NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE:{
            handler(val){
                if(val){
                    this.$store.commit('ACTION', '');
                    this.$store.commit("DIALOG", false);
                    this.resetFields();
                    Swal.fire("Success!", "Accountability Save", "success");
                    this.$store.commit("NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE", false);
                }
            }
        },
        DIALOGS: {
            handler(val) {
                if(this.ACTION == "Submit" ) {
                    // this.resetFields()
                }
            },
        },
        'form.setting_emp_id':{
            handler(val){
                if (val) {
                    this.form.item_list.forEach(item => {
                        item.emp_id = val;
                    });
                }
            },
        },
        'form.emp_id':{
            handler(val){
                if (val == 0) {
                    this.disabled_employee_dropdown = true
                    // this.employee_selection_data = [{
                    //     'value':0,
                    //     'text':'COMMON ASSET',
                    //     'department_id':0
                    // }]
                }
            },
        },
        GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS:{
            handler(val){
                if(val.admin_accountabilityitem.some(e => e.category_type == 3)){
                    this.form.low_value_asset_checkbox = true
                }
            }
        },
        'form.warehouse_id':{
            handler(val){
                if(val){
                    if([2, 12].includes(val)){
                        this.form.company_id = '';
                        this.form.store_branch_id = '';
                        this.form.location = '';
                        this.$store.dispatch("getPvCompanies", {code: ['ING', 'WAD', 'IPHT', 'IPHT8']}).then((response)=>{
                        });
                    }else{
                        this.form.company_id = '';
                        this.form.store_branch_id = '';
                        this.form.location = '';
                        this.$store.dispatch("getPvCompanies", {not_code: ['ING', 'WAD', 'IPHT', 'IPHT8']}).then((response)=>{
                        });
                    }
                    this.getBranches();
                }
            }
        },
        'form.company_id':{
            handler(val){
                if(val){
                    this.getBranches(val);
                }
            }
        },
    },
};
</script>
<style scoped>
.red-text {
    color: red;
}

.actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
