<template>
    <div class="pa-4">
        <v-card class="pa-2" flat>
            <v-card-title>
                <v-layout>
                    <v-spacer />
                    <v-flex lg3>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="RD# / Company Name"
                            single-line
                            hide-details
                            clearable
                            @keypress.enter="getAllRrs()"
                            @click:clear="search='';getAllRrs()"
                        />
                    </v-flex>
                </v-layout>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="receivingReportHeaders"
                    :items="rrLists"
                    :loading="loading"
                    :no-data-text="noDataText"
                    :server-items-length="pagination.totalItems"
                    :options.sync="pagination.options"
                >
                <template v-slot:item.customer_name="{ item }">
                    <td class="px-0">{{ customerName(item) }}</td>
                </template>

                <template v-slot:item.date="{ item }">
                    <td class="px-0">{{ date(item) }}</td>
                </template>

                <template v-slot:item.printed="{ item }">
                    <td class="px-0">{{ item.printed == 1 ? 'Yes' : 'No' }}</td>
                </template>

                <template v-slot:item.created_at="{ item }">
                    <td class="px-0">{{ agingDate(item.created_at) }}</td>
                </template>

                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showRepairFormDetails(item)">mdi-eye</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <ReceivingReportDialogComponentVue :showRrDetailsDialog="showRrDetails" :rrId="rrId" @successPrint="successPrint" @closeRrDialog="closeRrDialog" />
    </div>
</template>

<script>
import ReceivingReportDialogComponentVue from './ReceivingReportDialogComponent.vue';
import ServiceFunctionsComponentVue from '../../Shared/ServiceFunctionsComponent.vue';
import { mapGetters } from 'vuex';
export default {
    mixins: [ServiceFunctionsComponentVue],
    data(){
        return {
            receivingReportHeaders: [
                { text: 'RD#', value: 'rr_number' },
                { text: 'Pullout No', value: 'pullout_number' },
                { text: 'Company Name', value: 'customer.company_name' },
                { text: 'Customer Name', value: 'customer_name' },
                { text: 'Delivered By', value: 'delivered_by' },
                { text: 'Received By', value: 'received_by' },
                { text: 'Date', value: 'date' },
                { text: 'Printed', value: 'printed' },
                { text: 'Days', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            rrLists: [],
            loading: true,
            noDataText: '',
            showRrDetails: false,
            rrId: '',
            search: '',
            printSuccess: false,
            pagination:{
                options:{},
                totalItems:0
            }
        }
    },
    props: ['reloadTable', 'state'],
    mounted: function () {
        this.getAllRrs();
    },
    computed:{
        ...mapGetters([
            'GET_ALL_RRF'
        ])
    },
    components: {
        ReceivingReportDialogComponentVue
    },
    watch: {
        reloadTable() {
            if(this.reloadTable) {
                this.getAllRrs();
            }
        },
        state() {
           this.getAllRrs();
        },
        'pagination.options':{
            handler(val){
                this.getAllRrs(val.page)
            },
            deep:true
        },
    },
    methods: {
        getAllRrs(page = 1) {
            this.loading = true
            this.rrLists = []
            this.$store.dispatch('getAllRrf',{
                    page:page,
                    itemsPerPage:this.pagination.options.itemsPerPage,
                    search:this.search
            }).then(response => {
                this.rrLists = response.data.data;
                this.loading = false
                this.pagination.totalItems = response.data.total
            });
        },
        customerName(item) {
            if(!!item) {
                let fullName = [];

                if(item.customer_id == 275) {
                    if(!!item.sc_customer) {
                        if(!!item.sc_customer.first_name) {
                            fullName.push(item.sc_customer.first_name);
                        }

                        if(!!item.sc_customer.middle_name) {
                            fullName.push(item.sc_customer.middle_name);
                        }

                        if(!!item.sc_customer.last_name) {
                            fullName.push(item.sc_customer.last_name);
                        }
                        return fullName.toString().replaceAll(',', ' ');
                    }
                }
            }
            return '';
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss')
            }
            return '';
        },
        showRepairFormDetails(item) {
            if(!!item) {
                this.rrId = item.id;
                this.showRrDetails = true;
            }
        },
        closeRrDialog() {
            this.showRrDetails = false;
            this.rrId = '';
            this.printSuccess = false;
        },
        successPrint(confirm) {
            if(confirm) {
                this.getAllRrs();
            }
        },
    }
}
</script>

<style>

</style>
