<template >
  <v-form>
      <!-- Table -->
    <v-container fluid style="display:none;">
      <div>
        <table id="item_details_accountability">
          <thead>
            <tr>
              <th>ITEM DETAILS</th>
              <th>ITEM CODE /</th>
              <th>PURCHASE / DEPRECIATED PRICE</th>
              <th>REMARKS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data" :key="row">
              <td>{{ row.name }}</td>
              <td>{{ row.code }}</td>
              <td></td>
              <td>{{ row.remarks }}</td>
            </tr>
            <tr>
              <td colspan="6" class="red-text">***** NOTHING FOLLOWS *****</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <v-col cols="6">
          <v-btn
          class="float-center"
          @click="renderPDF()"
          >
            <span>Print</span>
          </v-btn>
        </v-col>  -->
    </v-container>
  </v-form>

</template>
<script>
import { mapGetters } from 'vuex';
import GILL from '@/assets/fonts/Gil_normal';
export default {
  mixins:[GILL],
  data() {
      return {
          info: {
              logo : "",
              name : "",
              deptLoc : "",
              typeOfAcct : "",
              transferOfAcct : "",
              newAs : "",
              reference_as : "",
              prepared_by : "",
              checked_by : "",
              noted_by : "",
              asNo : "",
              prepared_by_data : "",
              checked_by_data : "",
              noted_by_data : "",
              prepared_by_position : "",
              checked_by_position : "",
              noted_by_position : "",
              signature_name : "",
              acknowledgement : "",
              acknowledgement_text : "",
              sheetVersion : "",
              sheetCopy : "",

          },
          sheet_data: {
              name:"",
              date:null,
              dept:"",
              location:"",
              dept_loc:"",
          },
          copy: {
              sheet_copy_1: "",
              sheet_copy_2: "",
              sheet_copy_3: "",
          },
          headers: ["ITEM DETAILS", "ITEM CODE/s", "PURCHASE / DEPRECIATED PRICE", "REMARKS"],
          data: [],
      }
  },
  computed:{
      ...mapGetters(['GET_PRINT_ACCOUNTABILITY','GET_PRINT_ADMIN_DETAIL']),
  },
  mounted(){

  },
  methods: {
      async renderPDF(){
          let doc = await new this.$jspdf({
                      orientation: 'p',
                      unit: 'mm',
                      format: 'letter'
                  });


                  this.sheet_data.name = this.GET_PRINT_ADMIN_DETAIL.full_name?this.GET_PRINT_ADMIN_DETAIL.full_name:'GENERAL ACCOUNTABILITY'
                  this.sheet_data.date = this.$dayjs().format('YYYY-MM-DD');
                  this.sheet_data.dept = 'IT' + ' Department'
                  this.sheet_data.location = this.GET_PRINT_ADMIN_DETAIL.location
                  this.sheet_data.dept_loc = this.GET_PRINT_ADMIN_DETAIL.department_name+" / "+this.GET_PRINT_ADMIN_DETAIL.location


                  this.copy.sheet_copy_1 = 'Employee'
                  this.copy.sheet_copy_2 = '201 File '
                  this.copy.sheet_copy_3 = 'Admin File'


                  this.info.logo             = 'ingcoph-logo'
                  this.info.name             = 'Name of Recipient:'
                  this.info.deptLoc          = 'Department / Location :'
                  this.info.typeOfAcct       = 'Type of Accountability'
                  this.info.transferOfAcct   = 'Transfer of Accountability'
                  this.info.newAs            = 'New AS'
                  this.info.reference_as     = `Reference AS No's`

                  this.info.prepared_by      = 'Prepared by:'
                  this.info.checked_by       = 'Checked by:'
                  this.info.noted_by         = 'Noted by:'
                  this.info.asNo             = 'AS No- ' +  this.GET_PRINT_ADMIN_DETAIL.accountability_num

                  this.info.prepared_by_data = ''
                  this.info.checked_by_data  = ''
                  this.info.noted_by_data    = ''

                  this.info.prepared_by_position = 'Admin Assistant'
                  this.info.checked_by_position  = 'Senior Admin Supervisor'
                  this.info.noted_by_position    = 'HR Manager'

                  this.info.signature_name       = 'Signature over Printed Name/Date'
                  this.info.acknowledgement      = 'ACKNOWLEDGEMENT and CERTIFICATION'
                  this.info.acknowledgement_text = `
                  By signing, I acknowledge the receipt of the following items listed above mentioned that endorsed, entrust or transferred to my custody.
                  Also, I agree to keep the same in good working condition and notify the Management should the property been damaged, lost or stolen, likewise return the same in good working condition.
                  If I am found negligent in my responsibility to safeguard the listed fixed asset/s and deemed to be damaged or lost, I authorize the Company to withhold/demand the value of the lost or damaged property from my salary to compensate the cost of the same.
                  I have read and understood the procedure which covers the issuance of the fixed asset/s and hereby abide and comply with the same policy.`

                  this.info.sheetVersion         = 'ADM-01-v02-2022'
                  this.info.sheetCopy            = `Copy 1 – ${this.copy.sheet_copy_1}   Copy 2 – ${this.copy.sheet_copy_2}   Copy 3 – ${this.copy.sheet_copy_3}`

                  // Insertion of Image and Title
                  let left = 10;
                  let top = 15;
                  const imgWidth = 40;
                  const imgHeight = 12;
                  let host = window.location.origin;

                  doc.addImage(`${host}/images/${this.info.logo}.png`, 'PNG', left, top, imgWidth, imgHeight);
                  doc.setFont("Gill", "bold");
                  var text = "ACCOUNTABILITY SHEET";
                  var textWidth = doc.getTextWidth(text);

                  doc.setFont("Gill");
                  doc.setFontSize(14);
                  doc.setFillColor(192,192,192);

                  doc.roundedRect(120, 10, textWidth + 20, 20, 5, 5, 'FD');
                  doc.setTextColor(0,0,0);
                  doc.text(text, 136 , 22);

                  // insertion of info properties and data
                  doc.setFont("Gill", "normal");
                  doc.setFontSize(10);

                  doc.text(this.info.name, 11, 40)
                  doc.setFont("Gill", "bold");
                  doc.setFontSize(14);
                  doc.text(this.sheet_data.name, 20, 47)
                  doc.setFont("Gill", "normal");
                  doc.setFontSize(10);
                  doc.text(this.info.typeOfAcct, 115, 40)
                  doc.text(this.info.reference_as, 170, 40)

                  doc.text(this.info.deptLoc, 11, 55)
                  doc.setFont("Gill", "bold");
                  doc.setFontSize(14);
                  doc.text(this.sheet_data.dept_loc, 20, 62)
                  doc.setFont("Gill", "normal");
                  doc.setFontSize(10);
                  // checkbox info
                  doc.setFontSize(12);
                  doc.text(this.info.transferOfAcct,122,48)
                  // doc.text(this.info.newAs,122,62)

                  //vertical lines
                  doc.line(110, 35, 110, 65);
                  const distinct_asset_no = [...new Set(this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem.map(e=>e.previous_accountability_num))];
                   distinct_asset_no.forEach((e,i)=>{
                    if(e)
                    {
                      doc.text(e +", ",(i > 3)?(130+ (i*10)):(170 + (i*10)),(i > 3)?50:46)
                    }


                  })

                  //horizontal lines for employee name & department
                  doc.line(10, 51, 110, 51);
                  //Transfer of Accountability lines
                  doc.line(210, 41, 169, 41);
                  doc.line(169, 41, 169, 65);
                  //END


                  // first box
                  var x_1 = 10;
                  var y_1 = 35;
                  var sideLength = 200;
                  doc.rect(x_1, y_1, sideLength, 30);

                  // checkbox 1
                  var cb1_x = 115;
                  var cb1_y = 45;
                  var cb_size = 3;

                  doc.rect(cb1_x, cb1_y, cb_size, cb_size);

                  // checkbox 2
                  var cb2_x = 115;
                  var cb2_y = 59;

                  // doc.rect(cb2_x, cb2_y, cb_size, cb_size);


                  // Insert Tables
                  doc.autoTable({
                              html: '#item_details_accountability',
                              startY: 66,
                              margin: {left: 10, right: 6},
                              theme: 'grid',
                              headStyles: {
                                  halign:'center',
                                  fillColor: '#ffffff',
                                  textColor: '#000000',
                                  lineWidth: .2,
                                  lineColor: '#000000',
                              },
                              columnStyles: {
                                  0: {cellWidth: 50, halign:'center',  textColor: '#000000'},
                                  1: {cellWidth: 50, halign:'center', textColor: '#000000'},
                                  2: {cellWidth: 50, halign:'center', textColor: '#000000'},
                                  3: {cellWidth: 50, halign:'center', textColor: '#000000'},
                              },
                              bodyStyles: {
                                  lineColor: '#000000',
                              },
                              willDrawCell: function (data) {
                                  var rows = data.table.body;
                                  if (data.row.index === rows.length - 1) {
                                  doc.setTextColor(255, 0, 0);
                                  }
                              },
                  });

                  let finalY = doc.lastAutoTable.finalY
                  // second box
                  doc.setDrawColor(0, 0, 0);
                  var x_2 = 10;
                  var y_2 = finalY + 1;
                  doc.rect(x_2, y_2, sideLength, 17);

                  // line seperator for signatories

                  doc.line(60, finalY + 1, 60, finalY + 18);
                  doc.line(110, finalY + 1, 110, finalY + 18);
                  doc.line(160, finalY + 1, 160, finalY + 18);

                  // Info for signatories
                  doc.setFontSize(10);
                  doc.text(this.info.prepared_by , 11 , finalY + 5)
                  doc.text(this.info.checked_by , 61 , finalY + 5)
                  doc.text(this.info.noted_by , 112 , finalY + 5)

                  // Input of names
                  doc.text(this.info.prepared_by_data , 23 , finalY + 11)
                  doc.text(this.info.checked_by_data , 95 , finalY + 11)
                  doc.text(this.info.noted_by_data , 164 , finalY + 11)

                  //Input of Position
                  doc.text(this.info.prepared_by_position , 20 , finalY + 16)
                  doc.text(this.info.checked_by_position , 70 , finalY + 16)
                  doc.text(this.info.noted_by_position , 126 , finalY + 16)

                  // AS No input
                  doc.setFont("Gill", "bold");
                  doc.setTextColor(255, 0, 0);
                  doc.setFontSize(16);
                  doc.text(this.info.asNo , 170 , finalY + 11)

                  // Acknowledgement box and title
                  doc.setFillColor(242, 242, 242);
                  doc.setDrawColor(0, 0, 0);
                  var x_3 = 10;
                  var y_3 = finalY + 18;
                  doc.rect(x_3, y_3, sideLength, 6,'FD');

                  doc.setFont("Gill", "italic");
                  doc.setTextColor(0, 0, 0);
                  doc.setFontSize(13);
                  doc.text(this.info.acknowledgement , 70 , finalY + 22.5)

                  // Acknowledgement info box
                  doc.setDrawColor(0, 0, 0);
                  var x_4 = 10;
                  var y_4 = finalY + 24;
                  doc.rect(x_4, y_4, sideLength, 50);

                  // Acknowledgement info text
                  doc.setFont("Gill","normal");
                  doc.setTextColor(0, 0, 0);
                  doc.setFontSize(9.8);
                  doc.setLineHeightFactor(1)
                  let lines = doc.splitTextToSize(this.info.acknowledgement_text,190)
                  doc.text(lines , 13 , finalY + 28,{align:"left"})


                  // Signature Line and Name
                  doc.line(120, finalY + 66, 205, finalY + 66);

                  doc.setFont("Gill","normal");
                  doc.setTextColor(0, 0, 0);
                  doc.setFontSize(10);
                  doc.text(this.info.signature_name , 145, finalY + 70.5)

                  // Signature Recipient Name and Date
                  doc.setFontSize(10);
                  doc.text(this.sheet_data.name + " / " + this.sheet_data.date , 140 , finalY + 65)

                  // Sheet Version and copy
                  doc.setFont("Gill","italic");
                  doc.setTextColor(0, 0, 0);
                  doc.setFontSize(9);
                  doc.text(this.info.sheetVersion , 10.5 , finalY + 78)
                  doc.text(this.info.sheetCopy , 124 , finalY + 78)



                  //---------------------------------------------------------------//
                  doc.autoPrint();
                  // doc.save('Accountability Sheet.pdf');
                  window.open(doc.output('bloburl'), '_blank');
      }
  },
  watch:{
      GET_PRINT_ACCOUNTABILITY:{
          handler(val){
             if(val){
              this.data = this.GET_PRINT_ADMIN_DETAIL.admin_accountabilityitem;
              this.renderPDF();
              this.$store.commit('PRINT_ADMIN',false);
             }
          },
          deep:true
      }
  }
}
</script>
<style lang="">

</style>