<template>
    <v-container fluid>
        <h3 class="page-title">Module Settings</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in items"
                        :key="item"
                        @click="tabs(i)"
                    >
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="(table, index) in hr_settings_component"
                        :key="index"
                    >
                        <keep-alive>
                            <component
                                :is="table.components"
                                :hr_settings_component="hr_settings_component"
                                :isActive_tab="table.status_text"
                            >
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent
            :cp="components"
            :scrollable="dialog_scrollable"
            :width="height(dialog_width)"
            :retain-focus="false"
        ></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import HRSettingsComponent from "@/views/main/modules/HR/Settings/HRSettingsComponent.vue";
import HRSettingsDialogComponent from "@/views/main/layouts/dialogs/HR/Settings/HRSettingsViewComponent.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        DialogComponent,
        HRSettingsDialogComponent,
        HRSettingsComponent,
    },
    data() {
        return {
            components: "",
            items: ["HR"],
            hr_settings_component: [
                {
                    components: HRSettingsComponent,
                    status_text: "hrSettings",
                    dialog: HRSettingsDialogComponent,
                    dialog_title: "HR Settings",
                    dialog_width: "70%",
                    scrollable: true,
                    dispatch: {
                        get: "GetHrSettings",
                        add: "AddHrSettings",
                        update: "UpdateHrSettings",
                        delete: "DeleteHrSettings",
                    },
                },
            ],
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable: true,
        };
    },
    computed: {
        ...mapGetters(["USERACCOUNT_LOAD"]),
    },
    mounted() {
        this.tabs(0);
    },
    methods: {
        tabs(key) {
            this.components = this.hr_settings_component[key].dialog;
            this.dialog_width = this.hr_settings_component[key].dialog_width;
            this.dialog_scrollable = this.hr_settings_component[key].scrollable;

            this.$store.commit("HR_SETTINGS_DIALOG_TITLE",this.hr_settings_component[key].dialog_title);
            this.$store.commit("HR_SETTINGS_TAB_IS_ACTIVE",this.hr_settings_component[key].status_text);
            this.$store.commit("HR_SETTINGS_DISPATCH",this.hr_settings_component[key].dispatch);
            this.$store.commit("HR_SETTINGS_GETTERS",this.hr_settings_component[key].getters);
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;
                case "xl":
                    return width;
            }
        },
    },
    watch: {},
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
