<template>
    <v-container fluid>
        <h3 class="page-title">Approvers</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark

                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in job_tab"
                        :key="i"
                        @click="()=>{
                            $store.commit('SELECTED_TAB',item.value);
                        }"
                    >
                        {{ item.text }}
                    </v-tab>
                </v-tabs>
                <v-card>
                    <v-card-text class="p-0">
                        <ApproversComponentVue/>
                    </v-card-text>
                </v-card>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ApproversComponentVue from './ApproversComponent.vue';

export default {
    // mixins:[PusherFunctionComponent],
    components: {
        ApproversComponentVue
    },
    data() {
        return {
            job_tab: [
                {value: 1 ,text: 'Admin'},
                {value: 4 ,text: 'Accounting'}
            ],
            tab: 0
        };
    },
    computed: {
        ...mapGetters([
            // "GET_ENTER_BILL_DIALOG",
            // 'PURCHASE_ORDER',
            // 'SELECTED_TAB',
            // 'ADMIN_PURCHASE_ORDER_STATUS',
            // 'STATUS',
            // 'ACTION'
        ]),
    },
    mounted() {
        this.tabs(0);
    },
    methods: {
        tabs(key) {

        },
        
    width(width) {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "100%"
          case 'sm': return "100%"
          case 'md': return "100%"
          case 'lg': return "100%"
          case 'xl': return width
        }
      },
    },
    watch: {
    },
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
