<template>
    <v-form ref="form" v-model="valid">
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col cols="3">
                        <v-btn v-if="userAccess.includes('create')" class="m-3" @click="mode()">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                        <v-spacer/>
                    <v-col cols="2" class="text-right my-auto pl-2">
                        <v-btn v-if="userAccess.includes('export')" :loading="exportLoad" :disabled="disableExport" @click="getAllTransactions('1')">
                            EXPORT
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.transaction_journal_type_id"
                            :items="GET_TRANSACTION_TYPE_SELECTION"
                            label="Type"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.vendor_id"
                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                            label="Vendor"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.company_id"
                            :items="GET_PV_COMPANIES_SELECTION"
                            label="Company"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                        />
                    </v-col>
                    <v-col lg="2">
                        <v-autocomplete
                            v-model="filters.store_branches_id"
                            :items="clonedStores"
                            label="Store Branch"
                            outlined
                            dense
                            auto-select-first
                            hide-details
                            clearable
                            no-data-text="No Stores Found!"
                        />
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn @click="()=>{
                                options.page = 1
                                getAllTransactions()
                            }"
                        >
                            FILTER
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="ml-0">
                    <v-col lg="2">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="menu.date_from = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col lg="2">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer />
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search by Transaction #"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllTransactions()"
                            @click:append="getAllTransactions()"
                        />
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <template v-slot:[`item.transaction_journal_type_id`]="{ item }">
                                {{ GET_TRANSACTION_TYPE_SELECTION.find(e=>e.value == item.transaction_journal_type_id)?.text }}
                            </template>
                            <template v-slot:[`item.total_amount`]="{ item }">
                                <span>
                                    &#x20b1;{{ item.total_amount | currency }}
                                </span>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                <span>{{ item.created_at | formatDate }}</span>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <a  class="ml-1 badge text-white" :style="`background-color:${ STATUS.TRANSACTION_JOURNAL_STATUS.find(e=>e.value === item.status)?.color }`">
                                    {{ STATUS.TRANSACTION_JOURNAL_STATUS.find(e=>e.value === item.status)?.text }}
                                </a>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view')" text icon color="orange" @click="mode('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit') && item.status == 1" text icon color="primary" @click="mode('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete') && item.status == 1" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="100%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">{{ TRANSACTION_JOURNAL.ACTION.charAt(0).toUpperCase() + TRANSACTION_JOURNAL.ACTION.slice(1) }} TJ{{ recordNumber }}</span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close')">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card elevation="0" dense>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                                v-model="form.transaction_journal_type_id"
                                                                :items="transaction_types"
                                                                :loading="type_loader"
                                                                :readonly="isDisabled()"
                                                                :disabled="!transaction_types.length"
                                                                :rules="required"
                                                                label="Select Transaction Type"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-spacer/>
                                                        <v-col cols="2">
                                                            <v-text-field
                                                                v-model="vendorDetails.billing_address_id"
                                                                :readonly="isDisabled()"
                                                                label="Billing Address"
                                                                outlined
                                                                dense
                                                            />
                                                                <!-- :rules="required" -->
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-menu
                                                                :close-on-content-click="true"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                                :disabled="isDisabled()"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        v-model="form.bill_due"
                                                                        v-on="on"
                                                                        :rules="required"
                                                                        append-icon="mdi-calendar"
                                                                        label="Bill Due"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                        />
                                                                    </template>
                                                                    <v-date-picker v-model="form.bill_due" />
                                                            </v-menu>
                                                        </v-col>
                                                            <!-- :rules="required" -->
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-autocomplete
                                                                v-model="form.vendor_id"
                                                                :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                label="Select Vendor"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="getAddressOfVendor(form.vendor_id)"
                                                            />
                                                        </v-col>
                                                        <v-spacer />
                                                        <v-col cols="2">
                                                            <v-text-field
                                                                v-model="vendorDetails.contact_person"
                                                                :readonly="isDisabled()"
                                                                label="Contact Person"
                                                                outlined
                                                                dense
                                                                />
                                                            </v-col>
                                                            <!-- :rules="required" -->
                                                        <v-col cols="3">
                                                            <v-text-field
                                                                v-model="vendorDetails.contact_number"
                                                                :readonly="isDisabled()"
                                                                label="Contact Number"
                                                                outlined
                                                                dense
                                                                />
                                                            </v-col>
                                                            <!-- :rules="required" -->
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.company_id"
                                                                :readonly="isDisabled()"
                                                                :items="GET_PV_COMPANIES_SELECTION"
                                                                :loading="GET_PV_COMPANIES_SELECTION.length == 0"
                                                                :rules="required"
                                                                label="Select Company"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertStore()"
                                                            />
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-autocomplete
                                                                v-model="form.store_branches_id"
                                                                :readonly="isDisabled()"
                                                                :items="branchItems"
                                                                :rules="required"
                                                                label="Select Branch"
                                                                no-data-text="No Stores Found!"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                                @change="insertCompany(form.store_branches_id)"
                                                            />
                                                        </v-col>
                                                        <v-spacer />
                                                        <v-col cols="2">
                                                            <v-autocomplete
                                                                v-model="form.terms"
                                                                :items="termItems"
                                                                :readonly="isDisabled()"
                                                                item-text="terms"
                                                                item-value="terms"
                                                                auto-select-first
                                                                label="Terms"
                                                                outlined
                                                                dense
                                                                />
                                                            </v-col>
                                                            <!-- :rules="required" -->
                                                        <v-col cols="3">
                                                            <v-menu
                                                                :close-on-content-click="false"
                                                                :disabled="isDisabled()"
                                                                transition="scale-transition"
                                                                max-width="290"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        v-model="form.created_at"
                                                                        v-on="on"
                                                                        :disabled="!isDisabled()"
                                                                        :readonly="isDisabled()"
                                                                        append-icon="mdi-calendar"
                                                                        label="Date Issued"
                                                                        readonly
                                                                        outlined
                                                                        dense
                                                                    />
                                                                </template>
                                                                    <v-date-picker v-model="form.created_at" />
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-textarea
                                                                v-model="form.remarks"
                                                                :readonly="isDisabled()"
                                                                :rules="required"
                                                                label="Remarks"
                                                                rows="2"
                                                                auto-grow
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-spacer/>
                                                        <v-col cols="1">
                                                            <v-autocomplete
                                                                v-model="fillCoaField"
                                                                v-show="autoFill.length > 0"
                                                                :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                item-value="id"
                                                                item-text="account_name"
                                                                placeholder="CoA"
                                                                class="small-font"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-col cols="1">
                                                            <v-autocomplete
                                                                v-model="fillDepartmentField"
                                                                v-show="autoFill.length > 0"
                                                                :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                placeholder="Department"
                                                                class="small-font"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-col cols="1">
                                                            <v-autocomplete
                                                                v-model="fillJobField"
                                                                v-show="autoFill.length > 0"
                                                                :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                placeholder="Job"
                                                                class="small-font"
                                                                item-text="text"
                                                                item-value="value"
                                                                auto-select-first
                                                                outlined
                                                                dense
                                                            />
                                                        </v-col>
                                                        <v-col cols="1" class="d-flex justify-center align-center">
                                                            <v-btn small v-show="autoFill.length > 0" @click="massUpdateFields()">
                                                                Mass Update
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="1" class="text-right" v-show="!isDisabled()">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" fab dark small color="success" style="height: 20px; width: 20px;" @click="addParticular()">
                                                                        <v-icon dark small style="font-size: 12px;">
                                                                            mdi-plus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                    <span>Add</span>
                                                            </v-tooltip>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" fab dark small color="error" class="ml-1" style="height: 20px; width: 20px;" @click="removeParticular()">
                                                                        <v-icon dark small style="font-size: 12px;">
                                                                            mdi-minus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                    <span>Delete</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px;">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="3%" class="text-left" v-if="TRANSACTION_JOURNAL.ACTION != 'view'">
                                                                                <v-row class="d-flex justify-space-between px-1 pl-3">
                                                                                    <v-tooltip top>
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-checkbox
                                                                                                    v-model="selectAllCheckbox"
                                                                                                    :true-value="1"
                                                                                                    :false-value="0"
                                                                                                    @change="selectAll(selectAllCheckbox)"
                                                                                                />
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Select All</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th width="15%">
                                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                                    <div class="pl-4">
                                                                                        COA
                                                                                    </div>
                                                                                    <v-tooltip top v-if="TRANSACTION_JOURNAL.ACTION != 'view'">
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-icon class="pr-6" @click="clearDropdown('coa_id')">mdi-close</v-icon>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Clear CoAs</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th class="text-left" width="23%">PARTICULARS</th>
                                                                            <th class="text-right" width="13%">AMOUNT</th>
                                                                            <th class="text-left" width="10%">RECOGNITION MONTH</th>
                                                                            <th class="text-left" width="15%">
                                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                                    <div class="pl-4">
                                                                                        DEPARTMENT
                                                                                    </div>
                                                                                    <v-tooltip top v-if="TRANSACTION_JOURNAL.ACTION != 'view'">
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-icon class="pr-6" @click="clearDropdown('department_id')">mdi-close</v-icon>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Clear Departments</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th class="text-left" width="23%">
                                                                                <v-row class="d-flex justify-space-between pt-2">
                                                                                    <div class="pl-4">
                                                                                        JOB
                                                                                    </div>
                                                                                    <v-tooltip top v-if="TRANSACTION_JOURNAL.ACTION != 'view'">
                                                                                        <template v-slot:activator="{on,attrs}">
                                                                                            <div v-bind="attrs" v-on="on">
                                                                                                <v-icon class="pr-6" @click="clearDropdown('job_id')">mdi-close</v-icon>
                                                                                            </div>
                                                                                        </template>
                                                                                            <span>Clear Jobs</span>
                                                                                    </v-tooltip>
                                                                                </v-row>
                                                                            </th>
                                                                            <th class="text-center" width="3%"/>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="particulars-container">
                                                                        <tr v-for="(item, index) in form.transaction_journal_items" :key="item.id"
                                                                            class="font-weight-medium text-gray py-2 particulars-container-row">
                                                                            <td v-if="TRANSACTION_JOURNAL.ACTION != 'view'">
                                                                                <v-row class="d-flex justify-center align-center">
                                                                                    <v-checkbox
                                                                                        v-model="item.autoFill"
                                                                                        :true-value="1"
                                                                                        :false-value="0"
                                                                                        class="p-0 ma-0 pt-4"
                                                                                        @change="handleCheckboxChange(index, item.autoFill)"
                                                                                    />
                                                                                </v-row>
                                                                            </td>
                                                                            <td>
                                                                                <v-autocomplete
                                                                                    v-model="item.coa_id"
                                                                                    :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                                    :loading="GET_CHART_OF_ACCOUNTS_SELECTION.length == 0"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    id="coa_id"
                                                                                    class="my-1 text-left small-font"
                                                                                    item-value="id"
                                                                                    item-text="account_name"
                                                                                    auto-select-first
                                                                                    ref="vautocomplete-coa"
                                                                                    outlined
                                                                                    dense
                                                                                    @keydown="newLine($event, false, false, index, 'particular', addParticular,null,'textarea-particular','vautocomplete-coa','')"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <v-textarea
                                                                                    v-model="item.particulars"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    id="particular"
                                                                                    @paste="(event) => onPaste(event, index, 'particulars')"
                                                                                    class="small-font"
                                                                                    rows="1"
                                                                                    ref="textarea-particular"
                                                                                    auto-grow
                                                                                    outlined
                                                                                    dense
                                                                                    @keydown="newLine($event, false, false, index, 'particular', addParticular,null,'textarea-amount','textarea-particular','vautocomplete-coa')"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <v-text-field
                                                                                    v-model="item.amount"
                                                                                    :rules="required"
                                                                                    :readonly="isDisabled()"
                                                                                    id="amount"
                                                                                    @paste="(event) => onPaste(event, index, 'amount')"
                                                                                    class="right-align-text small-font"
                                                                                    placeholder="0.00"
                                                                                    type="number"
                                                                                    hide-spin-buttons
                                                                                    outlined
                                                                                    ref="textarea-amount"
                                                                                    dense
                                                                                    @keydown.native="newLine($event, false, false, index, 'amount', addParticular, false,'textFields-recognition','textarea-amount','textarea-particular')"
                                                                                    @keypress="decimalOnly"
                                                                                >
                                                                                    <template v-slot:prepend-inner>
                                                                                        <v-icon small>mdi-currency-php</v-icon>
                                                                                    </template>
                                                                                </v-text-field>
                                                                            </td>
                                                                            <td>
                                                                                <v-menu
                                                                                    :close-on-content-click="true"
                                                                                    transition="scale-transition"
                                                                                    max-width="290"
                                                                                    :disabled="isDisabled()"
                                                                                >
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-text-field
                                                                                            v-model="item.recognition_month"
                                                                                            v-on="on"
                                                                                            :readonly="isDisabled()"
                                                                                            :rules="required"
                                                                                            id="recognition_month"
                                                                                            class="small-font"
                                                                                            append-icon="mdi-calendar"
                                                                                            readonly
                                                                                            outlined
                                                                                            dense
                                                                                            ref="textFields-recognition"
                                                                                            clearable
                                                                                            @paste="(event) => validateRecognitionMonth(event, form.transaction_journal_items, index)"
                                                                                            @keydown="newLine($event, false, false, index, 'recognition_month', addParticular,false, 'autocomplete-department','textFields-recognition','textarea-amount')"
                                                                                        />
                                                                                    </template>
                                                                                        <v-date-picker v-model="item.recognition_month" type="month" />
                                                                                </v-menu>
                                                                            </td>
                                                                            <td>
                                                                                <v-autocomplete
                                                                                    v-model="item.department_id"
                                                                                    :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                                    :loading="GET_DEPARTMENT_ACCOUNTING_LOAD.length == 0"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    class="small-font"
                                                                                    outlined
                                                                                    dense
                                                                                    auto-select-first
                                                                                    ref="autocomplete-department"
                                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addParticular, false,'autocomplete-job','autocomplete-department','textFields-recognition')"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <v-combobox
                                                                                    v-model="item.job_id"
                                                                                    :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                                    :readonly="isDisabled()"
                                                                                    :rules="required"
                                                                                    class="my-1 text-left small-font"
                                                                                    id="job_id"
                                                                                    item-text="text"
                                                                                    item-value="value"
                                                                                    outlined
                                                                                    dense
                                                                                    ref="autocomplete-job"
                                                                                    @keydown="newLine($event, true, true, index, 'coa_id', addParticular, false,'','autocomplete-job','autocomplete-department')"
                                                                                />
                                                                            </td>
                                                                            <td class="text-center" v-if="!isDisabled()">
                                                                                <v-tooltip top>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-btn v-on="on" fab dark x-small color="error" style="height: 20px; width: 20px;" @click="removeItem(index)">
                                                                                            <v-icon small>
                                                                                                mdi-minus
                                                                                            </v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                        <span>Remove</span>
                                                                                </v-tooltip>
                                                                            </td>
                                                                            <!-- <td class="text-center" v-else>
                                                                                <v-tooltip top>
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <v-icon v-on="on" :color="item.paid == 1 ? 'success' : 'error'">
                                                                                            mdi-circle
                                                                                        </v-icon>
                                                                                    </template>
                                                                                        {{ item.paid == 1 ? 'Paid' : 'Unpaid' }}
                                                                                </v-tooltip>
                                                                            </td> -->
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                            <v-container>
                                                <v-row class="py-2">
                                                    <v-col class="text-center">
                                                        <v-btn :disabled="!validToProceed" :loading="btnLoad"
                                                            v-if="TRANSACTION_JOURNAL.ACTION != 'view'"
                                                            @click="commitTransaction(TRANSACTION_JOURNAL.ACTION, TRANSACTION_JOURNAL.ACTION == 'create' ? 'addTransaction' : 'updateTransaction')"
                                                        >
                                                            {{ TRANSACTION_JOURNAL.ACTION == 'create' ? 'Create' : 'Update' }}
                                                        </v-btn>
                                                        <v-btn v-else-if="form.status === 1" @click="commitTransaction('approve','updateTransaction')">
                                                            APPROVE
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins: [SharedFunctionsComponentVue],
    data(){
        return{
            valid: false,
            userAccess :[],
            mainDialog: false,
            mainHeaders: [
                { text: 'TJ #', value:'transaction_number', sortable: false },
                { text: 'Type', value:'transaction_journal_type_id', sortable: false },
                { text: 'Company', value:'company_name', sortable: false },
                { text: 'Store Branch', value:'store_branch_name', sortable: false },
                { text: 'Vendor', value:'vendor_name', sortable: false },
                { text: 'Total Amount', value:'total_amount', align: 'right', sortable: false },
                { text: 'Transact Date', value:'created_at', align:'right', sortable: false },
                { text: 'Status', value:'status', align:'right', sortable: false },
                { text: 'Actions', value:'action', align: 'center', sortable: false }
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            form:{
                id: null,
                transaction_number: null,
                transaction_journal_type_id: null,
                vendor_id: null,
                company_id: null,
                store_branches_id: null,
                bill_due: null,
                terms: null,
                total_amount: null,
                remarks: null,
                status: 1,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                transaction_journal_items: []
            },
            transaction_journal_items_trash: [],
            btnLoad: false,
            transaction_types: [],
            vendorDetails:{
                billing_address_id: null,
                contact_number: '',
                contact_person: ''
            },
            branchesByCompany: [],
            filters:{
                transaction_journal_type_id: null,
                company_id: null,
                store_branches_id: null,
                vendor_id: null,
                date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:  this.$dayjs().endOf('month').format('YYYY-MM-DD'),
            },
            clonedStores: [],
            required: [
                v => !!v || 'This field is required'
            ],
            type_loader: false,
            result: [],
            autoFill: [],
            fillCoaField: null,
            fillDepartmentField: null,
            fillJobField: null,
            selectAllCheckbox: null,
            termItems: [],
            exportLoad: false,
            disableExport: false,
            menu: {
                date_from: false,
                date_to: false,
            },
            fetchStatus: false
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_DEPARTMENT_ACCOUNTING_LOAD',
            'GET_TRANSACTION_TYPE_SELECTION',
            'TRANSACTION_JOURNAL',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_COMPANY_SELECTION',
            'STATUS',
            'SELECTED_TAB',
            'GET_ACCOUNTING_JOB_SELECTION',
            'GET_TRANSACT_TYPES_SELECTION'
        ]),
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            if (!this.form.store_branches_id) this.form.store_branches_id = items[0].value
            return items
        },
        getDataTableHeaders() {
            const headers = [
                { text: 'Transaction #', value:'transaction_number', sortable: false },
                { text: 'Company', value:'company_name', sortable: false },
                { text: 'Store Branch', value:'store_branch_name', sortable: false },
                { text: 'Vendor', value:'vendor_name', sortable: false },
                { text: 'Total Amount', value:'total_amount', align: 'right', sortable: false },
                { text: 'Transact Date', value:'created_at', align:'right', sortable: false },
                { text: 'Cancel Remarks', value:'cancel_reason', sortable: false },
                { text: 'Status', value:'status', align:'right', sortable: false },
                { text: 'Actions', value:'action', align: 'center', sortable: false },
            ];

            if (![0, 1].includes(this.SELECTED_TAB))
                return headers.filter(header => header.value != 'cancel_reason');

            return headers;
        },
        recordNumber(){
            return this.form.transaction_number ? '# : ' + this.form.transaction_number : ''
        },
        validToProceed(){
            return this.valid
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getAllTransactions()
        this.getBranches()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        onPaste(evt, index, paste){
            evt.preventDefault();
            let pastedText = evt.clipboardData.getData("Text");
            if (pastedText.includes("\n")) {
                this.result = [] // para sa tuloy tuloy na pag paste ng hindi nabubura ang laman -> for future purposes
                let rows = pastedText.split("\r\n");
                for (let y = 0; y < rows.length; y++) {
                    let cells = rows[y].split("\t");
                    if (cells[0] !== '') {
                        this.result.push({
                            autofill: 0,
                            coa_id: null,
                            particulars: cells[0],
                            amount: cells[1].replace("," , ""),
                            recognition_month: this.$dayjs().format('YYYY-MM'),
                            department_id: null,
                            job_id: null
                        });
                    }
                }
                this.form.transaction_journal_items = this.result;
            } else {
                // Swal.fire('', 'Pasted Data format is not supported!', 'warning')
                this.form.transaction_journal_items[index][paste] = pastedText;
                return
            }
        },
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.valid = false
                    this.mainDialog = true
                    if (this.form.transaction_journal_items.length == 0) this.addParticular()
                    this.filterTransactionTypes()
                    document.addEventListener('keydown', this.handleKeyDown)
                    break;
                case 'close':
                    this.mainDialog = false
                    this.clearFields()
                    this.getAllTransactions()
                    document.removeEventListener('keydown', this.handleKeydown);
                    break;
            }
        },
        addParticular(){
            this.form.transaction_journal_items.push({
                autoFill: 0,
                coa_id: null,
                particulars: null,
                amount: null,
                recognition_month: this.$dayjs().format('YYYY-MM'),
                department_id: null,
                job_id: null
            })
        },
        removeParticular(){
            if (this.form.transaction_journal_items.length > 1) this.form.transaction_journal_items.pop()
        },
        removeItem(index){
            this.transaction_journal_items_trash.push(this.form.transaction_journal_items[index])
            this.form.transaction_journal_items.splice(index, 1)
            if (this.form.transaction_journal_items.length == 0) {
                this.addParticular()
            }
        },
        clearFields(){
            this.$refs.form.resetValidation()
            this.form = {
                id: null,
                transaction_number: null,
                transaction_journal_type_id: null,
                vendor_id: null,
                company_id: null,
                store_branches_id: null,
                bill_due: null,
                terms: null,
                total_amount: null,
                remarks: null,
                status: 1,
                created_at: this.$dayjs().format('YYYY-MM-DD'),
                transaction_journal_items: []
            }
            this.vendorDetails = {
                billing_address_id: null,
                contact_number: '',
                contact_person: ''
            }
            this.transaction_journal_items_trash = []
            this.autoFill = []
            this.selectAllCheckbox = null
            this.fillCoaField = null
            this.fillDepartmentField = null
            this.fillJobField = null
            this.$forceUpdate()
        },
        commitTransaction(action, url){
            // if (!this.$refs.form.validate()) {
            //     if (!this.checkRequiredFields()) return
            // } else {
            // }
            this.computeTotal()
            this.btnLoad = true
            Swal.fire({
                title: '',
                text: `Are you sure you want to ${action} transaction?`,
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    this.btnLoad = false
                    return
                }
                if (action == 'approve') {
                    let validUser = true;

                    await Swal.fire({
                        title: "Checking if you are authorized...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                            this.$store.dispatch(
                                'transactionJournalGet',
                                {url: `transaction-journal/check-user-authorized/${this.form.id}`}
                            ).then(() => {
                                Swal.close();
                            }).catch(error => {
                                Swal.close();
                                if (error.response.status == 406)
                                    Swal.fire({
                                        title: 'Error',
                                        text: 'You are not authorized to confirm this transaction journal.',
                                        icon: 'error',
                                    });
                                else Swal.fire('Error','','error');
                                validUser = false;
                            });
                        }
                    });

                    if (!validUser) return;

                    const result = await Swal.fire({
                        title: '',
                        text: `Do you also want to create a Enter Bill for Transaction?`,
                        icon: 'question',
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    })
                    if (result.isConfirmed) {
                            this.createEnterBill(
                                this.form.transaction_number,
                                this.form.id,
                                this.form.company_id,
                                this.form.store_branches_id,
                                this.GET_TRANSACT_TYPES_SELECTION.find(e=>e.model_name == 'TransactionJournal').value,
                                this.form.vendor_id,
                                this.form.bill_due,
                                this.form.total_amount,
                                this.form.transaction_journal_items
                            )
                        }
                    this.form.status = 4
                }
                this.dialogLoader = true
                let payload = {
                    form: this.form,
                    trash: this.transaction_journal_items_trash,
                    url: url
                }
                this.$store.dispatch('transactionJournalPost', payload).then(() => {
                    Swal.fire('', 'Transaction saved!', 'success');
                    this.getAllTransactions();
                    this.toggleDialog('close');
                    this.btnLoad = false
                    this.dialogLoader = false
                }).catch((error)=>{
                    console.error(error)
                    Swal.fire('', 'Transaction error!', 'error');
                    this.btnLoad = false
                    this.dialogLoader = false
                })
            })
        },
        checkRequiredFields(){
            let emptyFields = []
            let required = {
                'vendor_id': 'Vendor',
                'transaction_journal_type_id': 'Transaction Type',
                'company_id': 'Company',
                'store_branches_id': 'Store Branch',
                'bill_due': 'Bill Due',
                // 'terms': 'Terms',
                'remarks': 'Remarks'
            }
            // let requiredVendorDetails = {
            //     'billing_address_id': 'Billing Address',
            //     'contact_number': 'Contact Number',
            //     'contact_person': 'Contact Person'
            // }
            let requiredParticulars = {
                'coa_id': 'COA',
                'particulars': 'Particular',
                'amount': 'Amount',
                'recognition_month': 'Recognition Month',
                'department_id': 'Department',
                'job_id': 'Job'
            }
            Object.keys(required).forEach(e=>{
                if (!this.form[e]) {
                    emptyFields.push(required[e])
                }
            })
            // Object.keys(requiredVendorDetails).forEach(e=>{
            //     if (!this.vendorDetails[e]) {
            //         emptyFields.push(requiredVendorDetails[e])
            //     }
            // })
            let rowCount = 1
            this.form.transaction_journal_items.forEach(item => {
                Object.entries(item).forEach(([key, value]) => {
                    if (key != 'deleted_at' && key != 'paid' && key != 'autoFill' && (value == null || value == '')) {
                        emptyFields.push(`Row ${rowCount}: ${requiredParticulars[key]}`)
                    }
                })
                rowCount++
            })
            if (emptyFields.length > 0) {
                Swal.fire({
                    title: 'Please fill the following fields: ',
                    html: emptyFields.map(field => `${field}<br>`).join(''),
                    icon: 'error'
                })
                return false
            }
            return true
        },
        getAllTransactions(for_export = 0){
            if (this.fetchStatus) return
            this.fetchStatus = true
            if (!for_export) {
                this.tableLoad = true
                this.mainItems = []
                this.disableExport = true
            } else {
                this.exportLoad = true
            }
            let payload = {
                transaction_journal_type_id: this.filters.transaction_journal_type_id,
                company_id: this.filters.company_id,
                store_branches_id: this.filters.store_branches_id,
                vendor_id: this.filters.vendor_id,
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                tab: this.TRANSACTION_JOURNAL.TAB.tab_name,
                for_export: for_export,
                date_range:[this.filters.date_from,this.filters.date_to],
                url: 'getAllTransactions'
            }
            this.$store.dispatch('transactionJournalGet', payload).then(response=>{
                if (for_export == 1) {
                    this.exportTJ(response.data)
                } else {
                    this.mainItems = response.data.data.data
                    if (this.mainItems.length > 0) this.disableExport = false
                    this.totalItems = response.data.data.total
                    this.tableLoad = false
                    this.fetchStatus = false
                }
            })
        },
        mode(action = 'create', id = null){
            this.$store.commit('TRANSACTION_JOURNAL_ACTION', action)
            if (!id) {
                this.clearFields()
                this.toggleDialog('open')
            } else {
                this.dialogLoader = true
                this.btnLoad = true
                this.toggleDialog('open')
                this.$store.dispatch('transactionJournalPost', {id: id, url: 'getTransaction'}).then(response=>{
                    this.getAddressOfVendor(response.data.vendor_id)
                    this.getStoreBranches(2)
                    this.form = { ...response.data }
                    this.form.created_at = this.$dayjs(this.form.created_at).format('YYYY-MM-DD')
                    this.form.transaction_journal_items.forEach(e=>{
                        e.job_id = this.GET_ACCOUNTING_JOB_SELECTION.find(d => d.value == e.job_id);
                        e.recognition_month = this.$dayjs(e.recognition_month).format('YYYY-MM')
                        e.amount = parseFloat(e.amount).toFixed(2)
                    })
                    this.$store.dispatch('getAccountingJobSelection');
                    this.dialogLoader = false
                    this.btnLoad = false
                })
            }
        },
        isDisabled(){
            switch(this.TRANSACTION_JOURNAL.ACTION){
                case 'view':
                    return true
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: 'Are you sure you want to delete transaction?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('transactionJournalPost', { id: id, url: 'deleteTransaction' }).then(response => {
                        Swal.fire('', 'Transaction Deleted!', 'success')
                        this.getAllTransactions();
                    }).catch(error => {
                        if (error.response.status == 422) {
                            Swal.fire('', 'Error!', 'error')
                            console.log(error)
                        }
                        this.getAllTransactions()
                    })
                }
            })
        },
        filterTransactionTypes(){
            this.type_loader = true
            this.$store.dispatch('getTransactionTypeSelection').then(response => {
                let data = response
                if (!data.length) {
                    Swal.fire('No Transaction Types Found!', 'Create in the TYPES tab', 'warning')
                    this.type_loader = false
                    return
                }
                if (this.USERACCOUNT_LOAD.super_admin || this.USERACCOUNT_LOAD.department_id == 4) this.transaction_types = data
                else this.transaction_types = data.filter(e=>e.department_id == this.USERACCOUNT_LOAD.department_id)
                this.type_loader = false
            })
        },
        getAddressOfVendor(vendor_id){
            this.$store.dispatch('getAddressByVendor',{ id: vendor_id }).then(response=>{
                let address = response.data
                this.termItems = address
                this.form.terms = address[0].terms
                if (address.length > 0) {
                    this.vendorDetails.billing_address_id = address[0].address
                    this.vendorDetails.contact_number = address[0].contact_number
                    this.vendorDetails.contact_person = address[0].contact_person
                } else {
                    this.vendorDetails.billing_address_id = null
                    this.vendorDetails.contact_number = ''
                    this.vendorDetails.contact_person = ''
                }
            })
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        computeTotal() {
            this.form.total_amount = this.form.transaction_journal_items.reduce((total, item) => {
                return total + parseFloat(item.amount || 0)
            }, 0).toFixed(2)
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
        insertStore(){
            this.form.store_branches_id = this.branchItems[0].value
        },
        handleKeyDown(event){
            if (this.TRANSACTION_JOURNAL.ACTION == 'create') {
                if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
            }
            else if (Object.values(this.vendorDetails).every(v => v != null && v !== '')) {
                if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
            }
        },
        massUpdateFields(){
            if (this.fillCoaField || this.fillDepartmentField || this.fillJobField) {
                this.form.transaction_journal_items.map((e,i) => {
                    if (this.autoFill.includes(i)) {
                        if (!e.coa_id) e.coa_id = this.fillCoaField
                        if (!e.department_id) e.department_id = this.fillDepartmentField
                        if (!e.job_id) e.job_id = this.GET_ACCOUNTING_JOB_SELECTION.find(e => e.value == this.fillJobField);
                    }
                })
            }
        },
        selectAll(status){
            if (status) {
                this.form.transaction_journal_items.map((e,i) => {
                    this.handleCheckboxChange(i, status)
                })
            } else {
                this.form.transaction_journal_items.map((e,i) => {
                    e.autoFill = 0
                })
                this.autoFill = []
            }
        },
        handleCheckboxChange(selected_index,status){
            if (status) {
                if (!this.autoFill.includes(selected_index)) {
                    this.autoFill.push(selected_index)
                    this.form.transaction_journal_items.map((e,i) => {
                        if (this.autoFill.includes(i)) {
                            e.autoFill = 1
                        }
                    })
                }
            } else {
                let index = this.autoFill.indexOf(selected_index)
                if (index > -1) this.autoFill.splice(index, 1)
            }
        },
        clearDropdown(field){
            this.form.transaction_journal_items.forEach(e => {
                e[field] = null
            })
            this.$forceUpdate()
        },
        async exportTJ(exportData){
            let detailsTable = []

            exportData.forEach(e=>{
                e.transaction_journal_type_id = this.GET_TRANSACTION_TYPE_SELECTION.find(d=>d.value == e.transaction_journal_type_id).text
                e.total_amount = parseFloat(e.total_amount)
                e.created_at = this.$dayjs(e.created_at).format('YYYY-MM-DD')
                e.status = this.STATUS.TRANSACTION_JOURNAL_STATUS.find(el=>el.value == e.status).text
                e.transaction_journal_items.forEach(v=>{
                    v.amount = parseFloat(v.amount)
                    v.paid = v.paid == 1 ? 'Yes' : 'No'
                    detailsTable.push(v)
                })
            })
            let sprHeaderAndData = {
                ['TRANSACTION JOURNAL']: {
                    headers:[
                        { header: 'TJ #', key:'transaction_number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Type', key:'transaction_journal_type_id', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Company', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Store Branch', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Vendor', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Total Amount', key:'total_amount', align: 'right', width: 20, style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Transact Date', key:'created_at', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Status', key:'status', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
                ['TRANSACTION JOURNAL (DETAILED)']: {
                    headers:[
                        { header: 'TJ #', key:'transaction_number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Type', key:'transaction_journal_type_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Company', key:'company_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Store Branch', key:'store_branch_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Vendor', key:'vendor_name', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Chart of Account', key:'account_name', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Particulars', key:'particulars', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Amount', key:'amount', align:'right', width: 20, style: { numFmt: '#,##0.00', alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Recognition Month', key:'recognition_month', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Department', key:'department_name', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Job', key:'job_name', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                        { header: 'Paid', key:'paid', align:'right', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
                    ],
                    data: []
                },
            }
            sprHeaderAndData['TRANSACTION JOURNAL'].data = exportData
            sprHeaderAndData['TRANSACTION JOURNAL (DETAILED)'].data = detailsTable
            await this.exportExcel(sprHeaderAndData, 'TRANSACTION JOURNAL', 'F69F1A')
            this.exportLoad = false
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                if (val != 'fail') {
                    this.userAccess = val.map(e=>e.actions_code)
                }
            }
        },
        options:{
            handler(){
                this.getAllTransactions()
            },
            deep:true
        },
        SELECTED_TAB:{
            handler(){
                this.options.page = 1
                this.getAllTransactions()
            }
        },
        search:{
            handler(val){
                if (val) {
                    this.options.page = 1
                }
            },
            immediate: true
        },
        'form.terms':{
            handler(val){
                if(val != null && this.form.created_at != ''){
                    let computedDueDate = new Date(this.form.created_at)
                    if(computedDueDate != null){
                        computedDueDate.setDate(computedDueDate.getDate() + parseInt(val));
                        this.form.bill_due = computedDueDate.toISOString().substr(0, 10)
                    }else{
                        this.form.bill_due = this.form.created_at
                    }
                }else{
                    this.form.bill_due = null
                }
            }
        },
        'form.company_id':{
            handler(val){
                if (!val) {
                    this.form.store_branches_id = null
                }
            },
            immediate: true
        },
        'form.store_branches_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                }
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
.small-font {
    font-size: 14px;
}
::v-deep .right-align-text input {
    text-align: right;
}
</style>