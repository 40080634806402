<template>
    <div class="w-100">
        <v-card>
            <v-container class="w-100">
                <v-row dense no-gutters tag="div" style="align-items: right" class="mt-10">
                    <v-spacer></v-spacer>
                    <v-col md="3">
                        <v-text-field
                            v-model="search"
                            @change="searchInput()"
                            label="Search Item Name / Model"
                            append-icon="mdi-magnify"
                            dense
                            single-line
                            hide-details
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-data-table
                        :headers="headers"
                        :items="inventory"
                        :search="search"
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :multi-sort="true"
                        :disable-sort="true"
                        :options.sync="options"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                        }"
                        :server-items-length="totalItems"
                        loading-text="Loading... Please wait"
                        class="elevation-1 w-100"
                    ></v-data-table>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            headers: [
                { text: 'Model', value: 'model', align: 'start' },
                { text: 'Name', value: 'name', align: 'start' },
                { text: 'Category', value: 'item_category_text', align: 'start' },
                { text: 'UOM', value: 'item_uom_text', align: 'start' },
                { text: 'Type', value: 'types', align: 'start' },
                { text: 'Total Qty', value: 'total_qty', align: 'end' },
                { text: 'Available Qty', value: 'virtual_qty', align: 'end' },
                { text: 'Borrowed Qty', value: 'borrowed_qty', align: 'end' },
            ],
            itemsPerPageOptions: [10, 20, 30, 50],
            itemsPerPage: 10,
            search: '',
            options: {},
            totalItems: 0,
            inventory: [],
            loading: false,
            loaded: false,
        }
    },
    mounted() {
        this.getInventorySampleForm()
    },
    computed: {
        ...mapGetters([
            'GET_SF_DISPATCH',
            'GET_SF_ACTIVE_TAB'
        ])
    },
    methods: {
        getInventorySampleForm() {
            this.inventory = [];
            this.loading = true;
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
				url: 'getInventorySampleForm',
                tab: 'inventory'
            }
            this.$store.dispatch(this.GET_SF_DISPATCH.get, payload).then(response => {
                this.inventory = response.data.data.data
                this.loading = false
                this.totalItems = response.data.data.total;
            })
        },
        searchInput() {
            this.options.page = 1 ;
            this.getInventorySampleForm({ query: this.search });
        },
    },
    watch: {
        options: {
            handler(val) {
                this.getInventorySampleForm()
            },
            deep: true,
        },
        GET_SF_ACTIVE_TAB:{
            handler(val){
                this.getInventorySampleForm()
            }
        }
    }
}
</script>

<style scoped>

</style>
