<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
          <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
              <span class="headline">
                  <!-- Repair Form Quotations -->
              </span>
              <v-row class="m-0">
                  <v-col cols="pull-right-10 p-2">
                      <v-btn
                      text
                      icon
                      small
                      color="gray"
                      class="float-right"
                      @click="closeDialog()"
                      >
                      <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                  </v-col>
              </v-row>
          </v-card-title>
      </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
      return {

      }
  },

  computed: {
      ...mapGetters([
          'DIALOG',
          'ACTION',
          'GET_SP_AREA_DIALOG_VIEW',
          'GET_SPARE_PARTS_STAGING_AREA',
          'GET_REFRESH_SP_AREA',
          'GET_SPARE_PARTS_AREA_TAB',
          'GET_SP_AREA_DISPATCH',
          'GET_SP_AREA_DIALOG_TITLE',
      ]),
  },

  mounted() {

  },

  methods: {
      closeDialog() {
          this.$store.commit("DIALOG", false);
          this.$store.commit('REFRESH_SP_AREA', true)
          this.$store.commit('SP_AREA_DIALOG_VIEW', {});
      },
  },

  watch: {

  },

  components: {

  },
}
</script>

<style scoped>

</style>