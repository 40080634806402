<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-row class="mx-auto my-2 p-2">
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="type_id"
                            :items="GET_ITEM_TYPES.filter(f => !this.is_sp ? [1, 2].includes(f.value) : [3].includes(f.value))"
                            color="#b3d4fc"
                            label="Discount Type"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            multiple
                            small-chips
                            deletable-chips
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-model="category_id"
                            :items="GET_ALL_ITEM_CATEGORIES"
                            item-text="name"
                            item-value="id"
                            color="#b3d4fc"
                            label="Category"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            multiple
                            small-chips
                            deletable-chips
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1" v-if="!is_sp">
                        <v-select
                            v-model="has_new_models"
                            :items="dropdowns.has_new_models"
                            label="Has New Models"
                            dense
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col cols="1">
                        <v-btn @click="getAllItems()">Filter</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-chip small class="mx-1" color="black" outlined @click="getAllItems('default');">default</v-chip>
                        <v-chip small class="mx-1" color="primary" @click="getAllItems('out_of_stock_default');">low level stock (default)</v-chip>
                        <v-chip small class="mx-1" color="yellow" @click="getAllItems('low_level_stock');">low level stock</v-chip>
                        <v-chip small class="mx-1" color="red" text-color="white" @click="getAllItems('out_of_stock');">out of stock</v-chip>
                        <v-chip small class="mx-1" color="black" outlined v-if="userAccess.sp_price" @click="showSpPrice = !showSpPrice; toggleSpPriceHeader();">SP</v-chip>
                        <v-chip small class="mx-1" color="black" outlined v-if="GET_USER_COSTING" @click="showCost = !showCost; toggleCostHeader();">U</v-chip>
                    </v-col>
                </v-row>
                <v-card-title class="ma-0 pa-0 mx-2">
                    <v-row class="mx-auto">
                        <v-col sm="12" md="12" lg="2">
                            <v-autocomplete
                                v-model="warehouseType"
                                :items="warehouseTypes"
                                auto-select-first
                                hide-details
                                clearable
                                item-text="text"
                                item-value="id"
                                label="Warehouse Type"
                                outlined
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col sm="12" md="12" lg="3" class="ml-1 mr- 3">
                            <v-text-field
                                v-model="search"
                                label="Search Model/Name/Part#"
                                single-line
                                hide-details
                                append-icon="mdi-magnify"
                                @keydown.enter="getAllItems(null, 1)"
                                @click:append="searchInput(null, 1)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :items-per-page="10"
                        :expanded.sync="expanded"
                        :loading="!loaded"
                        :multi-sort="true"
                        :disable-sort="true"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                            showFirstLastPage: true,
                        }"
                        loading-text="Loading... Please wait"
                        show-expand class="w-100"
                        fixed-header
                        @item-expanded="loadDetails"
                        @update:page="getAllItems()"
                        @update:items-per-page="getAllItems()"
                    >
                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item)" @error="checkImage(item)" eager contain></v-img>
                        </template>

                        <template v-slot:[`item.model`]="{ item }">
                            <v-chip small v-if="item.active == 0" dark>{{ item.model }}</v-chip>
                            <v-chip small v-else-if="item.default_warehouse_stocks <= 5 && item.non_default_warehouse_stocks > 5" color="primary">{{ item.model }}</v-chip>
                            <v-chip small v-else-if="item.default_warehouse_stocks <= 5 && (item.non_default_warehouse_stocks >= 1 && item.non_default_warehouse_stocks <= 5)" color="yellow">{{ item.model }}</v-chip>
                            <v-chip small v-else-if="item.default_warehouse_stocks <= 3 && item.non_default_warehouse_stocks == 0" color="red" text-color="white">{{ item.model }}</v-chip>
                            <v-chip small v-else color="black" outlined>{{ item.model }}</v-chip>
                        </template>

                        <template v-slot:[`item.selling_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.selling_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.sp_price`]="{ item }">
                            <span class="text-nowrap" v-if="userAccess.sp_price">
                                &#x20b1;{{ item.sp_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.part_num`]="{ item }">
                            <span class="text-nowrap">
                               {{ item.part_num == 'null' ? '' : item.part_num }}
                            </span>
                        </template>

                        <template v-slot:[`item.cost_in_foreign`]="{ item }">
                            <span class="text-nowrap" v-if="GET_USER_COSTING">
                                &#x0024;{{ item.cost_in_foreign | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.created_at`]="{ item }">
                            <span class="text-nowrap">
                                {{ dateWithFormat(item.created_at, 'YYYY-MM-DD HH:mm') }}
                            </span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="orange" v-if="userAccess.view">
                                    <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                </v-btn>
                                <v-btn text icon color="blue" v-if="userAccess.edit">
                                    <v-icon class="btn-action" small @click="editItem(item.id)">mdi-square-edit-outline
                                    </v-icon>
                                </v-btn>

                                <v-btn text icon color="red" v-if="userAccess.delete">
                                    <v-icon class="btn-action" small @click="deleteItem(item.id)">mdi-delete</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="p-0">
                                <v-simple-table dense dark>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td class="side-borders-white text-center">S</td>
                                                <td class="side-borders-white text-center">T_SO</td>
                                                <td class="side-borders-white text-center">U_SO</td>
                                                <td class="side-borders-white text-center">P_SO</td>
                                                <td class="side-borders-white text-center">IS</td>
                                                <td class="side-borders-white text-center">SC</td>
                                                <td class="text-center" style="width: 85px;" v-for="(warehouse_item_stock, i) in item.warehouse_item_stocks" :key="i"><strong>{{ warehouse_item_stock.warehouse_code }}</strong></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td class="side-borders-white text-center">{{ thousandSeprator(item.total_stocks) }}</td>
                                                <td class="side-borders-white text-center">{{ thousandSeprator(parseInt(item.unserved_so) + parseInt(item.partial_so)) }}</td>
                                                <td class="side-borders-white text-center">{{ thousandSeprator(item.unserved_so) }}</td>
                                                <td class="side-borders-white text-center" @click="redirecttoSalesOrder(item.model)">{{ thousandSeprator(item.partial_so) }}</td>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <td class="side-borders-white text-center" v-bind="attrs" v-on="on" @click="redirect(item.model)">{{ thousandSeprator(item.incoming_stocks) }}</td>
                                                    </template>
                                                    <span v-if="item.incoming_stocks_containers.length > 0">
                                                        <tr>
                                                            <td class="px-1">Date</td>
                                                            <td class="px-1">C#</td>
                                                            <td class="px-1">Qty</td>
                                                        </tr>
                                                        <tr v-for="(item, i) in item.incoming_stocks_containers" :key="i">
                                                            <td class="px-1 text-center">{{ item.created_at }}</td>
                                                            <td class="px-1 text-center">{{ item.container_num }}</td>
                                                            <td class="px-1 text-center">{{ thousandSeprator(item.quantity) }}</td>
                                                        </tr>
                                                    </span>
                                                    <span v-else>No IS</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <td class="side-borders-white text-center" v-bind="attrs" v-on="on" @click="redirect(item.model)">{{ thousandSeprator(item.stock_cards) }}</td>
                                                    </template>
                                                    <span v-if="item.stock_card_packing_lists.length > 0">
                                                        <tr>
                                                            <td class="px-1">PL#</td>
                                                            <td class="px-1">Date</td>
                                                            <td class="px-1">Remain Qty</td>
                                                        </tr>
                                                        <tr v-for="(item, i) in item.stock_card_packing_lists" :key="i">
                                                            <td class="px-1 text-center">{{ item.packing_list_num }}</td>
                                                            <td class="px-1 text-center">{{ item.packing_list_date }}</td>
                                                            <td class="px-1 text-center">{{ thousandSeprator(item.quantity - item.quantity_served - item.quantity_canceled) }}</td>
                                                        </tr>
                                                    </span>
                                                    <span v-else>No SC</span>
                                                </v-tooltip>
                                                <td class="text-center" v-for="(warehouse_item_stock, i) in item.warehouse_item_stocks" :key="i">{{ thousandSeprator(warehouse_item_stock.quantity) }}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>

        <ItemsViewComponent
            :viewItemId="viewItemId"
            :viewSpecialPrice="userAccess.sp_price"
            :viewCost="GET_USER_COSTING"
            @closeView="closeView"
        ></ItemsViewComponent>
    </div>
</template>

<script>
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue";
import SharedFunctionsComponent from "../../../../Shared/SharedFunctionsComponent.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    components: {
        ItemsViewComponent,
    },
    props: [
        'itemsActionResult',
        'is_inactive_items',
        'is_sp',
        'successUpdate'
    ],
    data() {
        return {
            showSpPrice: false,
            showCost: false,
            expanded: [],
            actionResult: {
                model: "Items",
                action: "",
            },
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            search: "",
            awaitingSearch: false,
            headers: [],
            regHeaders: [
                { text: 'Image', align: 'left', value: 'image_path' },
                { text: 'Model', align: 'left', value: 'model' },
                { text: 'Name', align: 'left', value: 'name' },
                { text: 'Type', align: 'left', value: 'item_type_text' },
                { text: 'Category', align: 'left', value: 'item_category_text' },
                { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false },
                { text: 'New Models', align: 'left', value: 'new_models' },
                { text: 'SRP', align: 'right', value: 'selling_price', sortable: false },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            spHeaders: [
                { text: 'Image', align: 'left', value: 'image_path'},
                { text: 'Model', align: 'left', value: 'model', },
                { text: 'Part#', align: 'left', value: 'part_num', },
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Type', align: 'left', value: 'item_type_text', },
                { text: 'Category', align: 'left', value: 'item_category_text', },
                { text: 'UOM', align: 'left', value: 'item_uom_text', sortable: false },
                { text: 'SRP', align: 'right', value: 'selling_price', sortable: false },
                { text: 'Item Location', align: 'center', value: 'item_location', sortable: false },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            sellingPriceHeader: { text: 'SRP', align: 'right', value: 'selling_price', sortable: false },
            spPrice_header: { text: 'SP', align: 'right', value: 'sp_price', sortable: false },
            costInPhp_header: { text: 'P', align: 'right', value: 'cost_in_php', sortable: false },
            costInForeign_header: { text: 'U', align: 'right', value: 'cost_in_foreign', sortable: false },

            selectedHeaders: [],

            viewItemId: '',
            imgError: false,

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                sp_price: false,
                cost: false,
            },

            filter: '',
            type_id: [],
            category_id: [],
            salesorder_redirect: '',
            container_redirect: '',
            warehouse_user:[],
            warehouseTypes:[
                    { id:1, text: 'Actual'},
                    { id:2, text: 'Virtual'},
                    { id:0, text: 'All'},
                ],
            warehouseType: 1,

            has_new_models: -1,
            dropdowns: {
                has_new_models: [
                    { text: 'All', value: -1 },
                    { text: 'Yes', value: 1 },
                    { text: 'No', value: 0 },
                ],
            },
        }
    },
    created(){
        this.itemHeaders()
        this.changeTableHeaders()
    },
    methods: {
        itemHeaders() {
            if (this.is_sp) {
                this.headers = this.spHeaders
            } else {
                this.headers = this.regHeaders
            }
        },
        changeTableHeaders(){
            let temp_headers = []
            if (this.is_sp) {
                temp_headers = this.spHeaders
            } else {
                temp_headers = this.regHeaders
            }

            if (!this.showSpPrice) {
                this.headers = temp_headers.filter(s => {
                    return s.value != 'sp_price'
                })
            } else {
                this.itemHeaders()
            }
        },
        toggleSpPriceHeader() {
            if (this.showSpPrice) {
                this.headers.splice(this.headers.indexOf(this.sellingPriceHeader), 0, this.spPrice_header);
            } else {
                let indexToBeDeleted = this.headers.map((m) => { return m; }).findIndex((f) => f == this.spPrice_header);
                this.headers.splice(indexToBeDeleted, 1);
            }
        },
        toggleCostHeader() {
            if (this.showCost) {
                this.headers.splice(this.headers.indexOf(this.sellingPriceHeader), 0, this.costInForeign_header);
            } else {
                let indexToBeDeleted2 = this.headers.map((m) => { return m; }).findIndex((f) => f == this.costInForeign_header);
                this.headers.splice(indexToBeDeleted2, 1);
            }
        },
        async loadDetails(item) {
            await this.getWarehouseUser().then(response=>{
                this.warehouse_user = response.ITEMS.data[0].warehouses
            });
            let warehouses = []
            let default_warehouse = 16
            this.warehouse_user.forEach(e => {
                    warehouses.push(e.id)
            })
            warehouses.map(q =>{
                if(q != default_warehouse ){
                    warehouses.push(default_warehouse)
                }
            })
            let warehouses_with_default = [...new Set(warehouses)];
            let moduleName = this.$route.name
            if (item.value) {
                let payload = {
                    id: item.item.id,
                    warehouse_id: warehouses_with_default,
                    from: "sales",
                    module: moduleName,
                    warehouseType: this.warehouseType,
                    is_sp: this.is_sp,
                };
                this.$store.dispatch("getAllWarehouseItemStocks", payload).then((response) => {
                    let data = this.GET_WAREHOUSE_ITEM_STOCK;
                    item.item.warehouse_item_stocks = data.item_stocks;
                    item.item.total_stocks = data.total_stocks;
                    item.item.unserved_so = data.unserved_so;
                    item.item.partial_so = data.partial_so;
                    item.item.incoming_stocks = data.incoming_stocks;
                    item.item.incoming_stocks_containers = data.incoming_stocks_containers;
                    item.item.partial_so_items = data.partial_so_items;
                    item.item.stock_cards = data.stock_cards;
                    item.item.stock_card_packing_lists = data.stock_card_packing_lists;
                    this.$forceUpdate();
                });
            }
        },
        getAllItems(filter = null, from_search = 0) {
            if (from_search) {
                this.options.page = 1
            }

            this.items = [];
            this.loaded = false;
            this.expanded = [];
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                is_inactive_items: this.is_inactive_items,
                is_sp: this.is_sp,
                filter: filter,
                type_id: this.type_id,
                category_id: this.category_id,
                show_cost: this.GET_USER_COSTING,
                from_search: from_search,
                warehouseType: this.warehouseType,
                has_new_models: this.has_new_models,
            };

            this.$store.dispatch("getAllItems", payload).then((response) => {
                if (response.data.error) {
                    swal.fire({
                        title: response.data.error,
                        icon: 'warning',
                    })
                }
                this.items = this.GET_ALL_ITEMS.data;
                this.loaded = true;
                this.totalItems = this.GET_ALL_ITEMS.total;
            });
        },
        viewItem(id) {
            this.viewItemId = id;
        },
        editItem(id) {
            this.$emit("showEditModal", id);
        },
        deleteItem(id) {
            swal.fire({
                title: '',
                text: "Are you sure you want to Delete?",
                icon: 'question',
                showConfirmButton: true,
                confirmButtonText: "Confirm",
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: "397373",
                cancelButtonColor: "grey",
                reverseButtons: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("destroyItem", id).then((response) => {
                        let data = this.GET_DESTROY_ITEM;
                        if (data.error) {
                            swal.fire('', data.error, "warning");
                            return false;
                        }
                        swal.fire('', "Item Deleted", "success").then((value) => {
                            this.getAllItems();
                        });
                    })
                    .catch((error) => {
                        swal.fire("An Error Occured", error, "error");
                    });
                }
            });
        },
        closeView() {
            this.viewItemId = 0;
        },
        redirect(model) {
            // location.assign(`/containers?search-model=${model}&status=1`);
            let routes = this.$router.resolve({
                name: "warehousecontainer",
                params: {
                    id: this.container_redirect,
                },
                query: {
                    search_model: btoa(model),
                    status: btoa("-1"),
                },
            });

            window.open(routes.href, "_black");
        },
        redirecttoSalesOrder(model) {
            let routes = this.$router.resolve({
                name: "salesorder",
                params: {
                    id: this.salesorder_redirect,
                },
                query: {
                    search_model: btoa(model),
                    status: btoa("-1"),
                },
            });

            window.open(routes.href, "_black");
        },
        checkImagePath(item) {
            return item.image_path == null
                ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                : process.env.VUE_APP_API_HOST + "/" + item.image_path;
        },
        checkImage(item) {
            item.image_path = process.env.VUE_APP_API_HOST + "/images/items/no-image.png";
            return item.image_path;
        },
        async getWarehouseUser(){
            // let name = this.USERACCOUNT_LOAD.name
            let manager = this.USERACCOUNT_LOAD.manager
            let super_admin = this.USERACCOUNT_LOAD.super_admin
            await this.$store.dispatch("warehouseUser", {
                url: "warehouseUser",
                method: "get",
                // search_user: name,
                manager: manager,
                super_admin: super_admin,
                checkCurrentUser: true,
            })
            return this.WAREHOUSE_USER
        },
    },
    computed: {
        ...mapGetters([
            'GET_ALL_ITEMS',
            'GET_DESTROY_ITEM',
            'GET_WAREHOUSE_ITEM_STOCK',
            'USER_ACCESS',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_TYPES',
            'SIDENAV_LOAD',
            'WAREHOUSE_USER',
            'USERACCOUNT_LOAD',
            'GET_USER_COSTING',
        ]),
        _salesorder() {
            let sidenav = JSON.parse(localStorage.getItem("side_menu_bar"));
            sidenav.forEach(async (e) => {
                await e.sidebarlinks.forEach((el) => {
                    if (el.route_name == "salesorder") {
                        this.salesorder_redirect = el.link_id;
                    }
                });
            });
        },
        _warehousecontainers() {
            let sidenav = JSON.parse(localStorage.getItem("side_menu_bar"));
            sidenav.forEach(async (e) => {
                await e.sidebarlinks.forEach((el) => {
                    if (el.route_name == "warehousecontainer") {
                        this.container_redirect = el.link_id;
                    }
                });
            });
        },
    },
    mounted() {
        // this.search = (Uri.hasValueAndProperty('hash', 'model')) ? Uri.toObject('hash')['model'] : '';
        this._salesorder;
        this._warehousecontainers;
        this.$store.dispatch(
            'checkAccessUser',
            this.$router.currentRoute.params.id
        );
        this.getAllItems()
    },
    watch: {
        itemsActionResult: function (val) {
            if (this.itemsActionResult == "success") {
                this.getAllItems();
            }

            if (this.itemsActionResult == "updateWarehouseStocks") {
                this.getAllItems();
                this.$emit("warehouseStockSaved");
                this.expanded = [];
            }
        },
        successUpdate() {
            if (this.successUpdate) {
                this.getAllItems();
            }
        },
        USER_ACCESS: {
            handler(val) {
                val.forEach((e) => {
                    if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "delete") {
                        this.userAccess.delete = true;
                    }
                    if (e.actions_code == "show_special_price") {
                        this.userAccess.sp_price = true;
                    }
                    if (e.actions_code == "cost") {
                        this.userAccess.cost = true;
                    }
                });
            },
        },

    },
};
</script>
<style scoped>
.white-bg {
    background-color: white;
    color: black;
}

.side-borders-white {
    width: 85px;
    border-style: none solid;
    border-color: white;
}
</style>
