<template>
    <v-form v-model="valid" @submit.prevent="ACTION == 'Submit' ? submit() : update()">
        <v-card>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="11">
                    <v-card-title class="pa-0 pl-3">
                        <span class="text-h5">{{ actions == 'Add' ? 'Add' : ACTION }} Report Category Information</span>
                    </v-card-title>
                </v-col>
                <v-col cols="1">
                    <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="pa-0 ma-0"></v-divider>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-card-text>
                    <v-row class="d-flex justify-content-between align-center pl-3 pr-3">
                        <v-spacer />
                        <v-col cols="2">
                            <v-switch
                                v-model="report_category.fillables.active"
                                :label="`Active`"
                                :false-value="0"
                                :true-value="1"
                                :readonly="ACTION == 'View'"
                            ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row class="pl-3 pr-3">
                        <v-col lg="12">
                            <v-text-field 
                                v-model="report_category.fillables.name" 
                                label="Report Category Name" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                            >
                            </v-text-field>
                        </v-col>
                        <v-col lg="6">
                            <v-text-field 
                                v-model="report_category.fillables.code" 
                                label="Code" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                            >
                            </v-text-field>
                        </v-col>
                        <v-col lg="6">
                            <v-autocomplete 
                                v-model="report_category.fillables.route"
                                :items="route_list" 
                                label="Route" 
                                :readonly="ACTION == 'View'" 
                                outlined 
                                dense 
                                return-object
                                clearable
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                
                <v-row class="d-flex justify-content-between align-center" dense no-gutters>
                    <v-col class="pr-3" v-if="!ACTION.includes('View')" >
                        <v-btn class="float-right" @click="ACTION == 'Submit' ? submit() : update()" small>
                            <v-icon>mdi-plus</v-icon>
                            {{ ACTION == 'Edit' ? 'Update' : ACTION }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-row>
        </v-card>
    </v-form>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            valid: true,
            report_category: {
                fillables: {
                    name: '',
                    code: '',
                    active: 1,
                    route: '',
                }
            },
            route_list: [],
        }
    },

    methods: {
        closeDialog() {
            this.$store.commit('DIALOG', false)
            this.$store.commit('REPORT_CATEGORY_VIEW', {})
        },

        submit() {
            let warning = '';

            if (!this.report_category.fillables.name) warning += 'Please input a report category name.<br>';
            if (!this.report_category.fillables.code) warning += 'Please input a code.<br>';
            if (!this.report_category.fillables.route) warning += 'Please select a route.<br>';

            if (warning !== '') return swal.fire({
                title: 'Must meet the following before confirming:',
                html: warning,
                icon: "info",
            });

            swal.fire({
                title:'Add',
                text:'- Are you sure you want to Submit?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('reportsModulePost',{
                        url: 'addReportCategory',
                        fillables: this.report_category.fillables,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.closeDialog()
                        this.clearData()
                        this.$store.commit('REFRESH_CATEGORY_REPORT', true)
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                    })
                }
            })
        },

        update() {
            let warning = '';

            if (!this.report_category.fillables.name) warning += 'Please input a name.<br>';
            if (!this.report_category.fillables.code) warning += 'Please input a code.<br>';
            if (!this.report_category.fillables.route) warning += 'Please select a route.<br>';

            if (warning !== '') return swal.fire({
                title: 'Must meet the following before confirming:',
                html: warning,
                icon: "info",
            });

            swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons: true,
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('reportsModulePost',{
                        url: 'updateReportCategory',
                        fillables: this.report_category.fillables,
                        id: this.GET_REPORT_CATEGORY_VIEW.report_category_id,
                    }).then(response=>{
                        swal.fire('Success!','','success')
                        this.closeDialog()
                        this.clearData()
                        this.$store.commit('REFRESH_CATEGORY_REPORT', true)
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                    })
                }
            })
        },
        
        getReportCategoryData() {
            this.report_category.fillables.name                      = this.GET_REPORT_CATEGORY_VIEW.title
            this.report_category.fillables.code                      = this.GET_REPORT_CATEGORY_VIEW.code
            this.report_category.fillables.active                    = this.GET_REPORT_CATEGORY_VIEW.active
            this.report_category.fillables.route                     = this.GET_REPORT_CATEGORY_VIEW.route
        },

        clearData() {
            this.report_category.fillables.name                      = ''
            this.report_category.fillables.code                      = ''
            this.report_category.fillables.route                     = ''
        },
    },

    
    computed: {
        ...mapGetters([
            'DIALOGS',
            'ACTION',
            'GET_REPORT_CATEGORY_VIEW',
        ]),

        actions(){
            if(this.ACTION == 'Submit'){
                return 'Add'
            } else if(this.ACTION == 'Edit'){
                this.getReportCategoryData()
                return 'Edit'
            } else if(this.ACTION == 'View'){
                this.getReportCategoryData()
                return 'View'
            } 
        }
    },

    watch: {
        ACTION: {
            handler(val) {
                if(val == 'Submit') {
                    this.report_category.fillables.active = 1;
                }
            }
        }
    },

    mounted() {
        let find = 'reports';
        this.$router.options.routes.forEach(e=>{
          if(e.hasOwnProperty('children'))
          {
            e.children.forEach(el=>{
              if(el.path.match(find)){
                this.route_list.push(el.name)
              }
            })
          }
        })
    }
}
</script>

<style scoped>

</style>