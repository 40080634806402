<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <PaymentTransmittalTableComponent ></PaymentTransmittalTableComponent>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import PaymentTransmittalTableComponent from '@/views/main/modules/Accounting/PaymentTransmittal/PaymentTransmittalTableComponent.vue';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {

        }
    },

    computed: {
        ...mapGetters([
            // 'TESTER_CHECK_LISTS',
            // 'GET_ALL_LIST_TEMPLATE',
            // 'GET_REFRESH',
            // 'GET_TABS',
            // 'GET_HR_TAB_IS_ACTIVE'
        ]),
    },

    components: {
        PaymentTransmittalTableComponent
    },

    mounted() {
    },
    
    methods: {
        AddDialog() {
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Add");
        }
    },

    watch: {

    },
}
</script>

<style>

</style>