<template>
  <v-card class="pa-6">
        <v-card-text>
            <v-row v-if="['is_transfer'].includes(tab_name)">
                <v-col lg="1" class="d-flex justify-content-start">
                    <!-- <v-btn small @click="toggleDialog('TOGGLE_ADD_DIALOG',PARTS_TRANSFER.DIALOG.ADD.VALUE);$store.commit('DIALOG_ACTION','ADD')">
                        <v-icon>mdi-plus</v-icon> Add
                    </v-btn> -->
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="toggleDialog('TOGGLE_ADD_DIALOG',PARTS_TRANSFER.DIALOG.ADD.VALUE);$store.commit('DIALOG_ACTION','ADD')"
                                fab
                                text
                                small
                                class="mx-3"
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                            >
                            <v-icon>mdi-plus</v-icon>
                            </v-btn>

                        </template>
                        <span>Add</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="exportPartsTransfer()"
                                fab
                                text
                                small
                                class="mr-4"
                                color="#f69f1a"
                                v-bind="attrs"
                                v-on="on"
                            >
                            <v-icon>mdi-cloud-download</v-icon>
                            </v-btn>

                        </template>
                        <span>Export</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12">
                    <PartsTransferTable :tab_name="tab_name" @approve-multiple="updateMultiple"  ref="resetTable"/>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="PARTS_TRANSFER.DIALOG.ADD.VALUE" width="1000px" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span>{{ PARTS_TRANSFER.DIALOG.ACTION }} PARTS TRANSFER </span>
                        </v-card-title>
                        <v-card-subtitle>
                            <span v-if="!['ADD'].includes(PARTS_TRANSFER.DIALOG.ACTION)">Transfer#: {{ PARTS_TRANSFER.DATA_TABLE.SELECTED.data.series}}</span>
                        </v-card-subtitle>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="resetField()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="m-0">
                    <v-col v-if="!['ADD', 'EDIT'].includes(PARTS_TRANSFER.DIALOG.ACTION)" class="text-right pr-2">
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    :disabled="PARTS_TRANSFER.APPROVED_MULTIPLE"
                                    @click="printParts(form)"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red"
                                    fab
                                    small
                                    icon
                                    text
                                >
                                    <v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Prepare Print Out</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col lg="3">
                                <label for="exampleFormControlInput1" class="form-label">From</label>
                                <v-autocomplete
                                    v-model="form.source_warehouse_id"
                                    :items="selection.source_warehouse"
                                    :disabled="PARTS_TRANSFER.DIALOG.ACTION == 'VIEW'"
                                    label="Select warehouse"
                                    auto-select-first
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-spacer/>
                            <v-spacer/>
                            <v-col lg="3">
                                <label for="exampleFormControlInput1" class="form-label">TO</label>
                                <v-autocomplete
                                    v-model="form.destination_warehouse_id"
                                    :items="selection.source_warehouse"
                                    :disabled="PARTS_TRANSFER.DIALOG.ACTION == 'VIEW'"
                                    label="Select warehouse"
                                    auto-select-first
                                    dense

                                >

                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="form.remarks"
                                    :disabled="PARTS_TRANSFER.DIALOG.ACTION == 'VIEW'"
                                    outlined
                                    dense
                                    label="Remarks"
                               >

                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :lg="PARTS_TRANSFER.DIALOG.ACTION != 'VIEW' ? '6': '12'">
                                <v-card>
                                    <v-card-title>
                                        <v-row>
                                            <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                                <span style="color:rgb(32, 32, 26); font-size:12px" class="mt-3"> SELECTED</span>
                                            </v-col>
                                            <v-spacer/>
                                            <v-col lg="5" class="d-flex justify-content-center text-align-center">
                                                <v-text-field
                                                    label="Search"
                                                    class="mt-3"
                                                    dense

                                                ></v-text-field>
                                                <v-btn text fab >
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-card height="400px" style="overflow-y:auto" elevation="0" @scroll="handleScroll">
                                            <v-card-text>
                                                <v-row style="1">
                                                    <!-- <v-col :lg="PARTS_TRANSFER.DIALOG.ACTION != 'VIEW' ? '4': '2'" v-for="(items,index) in form.item_id" :key="index">
                                                        <div class="column-content">
                                                            <v-chip
                                                                class="w-100"
                                                                color="#CC6600"
                                                                label
                                                                text-color="white"
                                                                style="cursor:pointer"
                                                                @click="selectItem(items)"
                                                            >
                                                                {{ items.text }}
                                                            </v-chip>
                                                            <v-text-field dense class="centered-text-field" v-model="items.quantity" :disabled="PARTS_TRANSFER.DIALOG.ACTION == 'VIEW'">

                                                            </v-text-field>
                                                            <v-chip
                                                                class="w-100"
                                                                label
                                                                text-color="#000000"
                                                                @click="selectItem(items)"
                                                            >
                                                                {{ items.text }}
                                                            </v-chip>
                                                        </div>
                                                    </v-col> -->
                                                    <v-col :lg="PARTS_TRANSFER.DIALOG.ACTION != 'VIEW' ? '4': '2'" v-for="(items,index) in form.status == 2 ? selectedItemsInWhStock : form.item_id" :key="index">
                                                        <div class="column-content">
                                                            <v-chip
                                                                class="w-100"
                                                                color="#CC6600"
                                                                text-color="white"
                                                                style="cursor:pointer"
                                                                label
                                                                @click="selectItem(items)"
                                                            >
                                                                {{ items.text }}
                                                            </v-chip>
                                                            <v-text-field dense class="centered-text-field" v-model="items.quantity" :disabled="PARTS_TRANSFER.DIALOG.ACTION == 'VIEW'">

                                                            </v-text-field>
                                                            <v-tooltip v-if="form.status == 2" bottom color="#F69F1A">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-chip
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        class="w-100 centered-text-field"
                                                                        color="#F3F3F3"
                                                                        style="cursor:pointer"
                                                                        :text-color="items.wh_stock < items.quantity ? 'error' : 'success'"
                                                                        label
                                                                    >
                                                                        SOH: {{ items.wh_stock }}
                                                                    </v-chip>
                                                                </template>
                                                                <span>Stock On Hand</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col lg="6" v-if="PARTS_TRANSFER.DIALOG.ACTION != 'VIEW'">
                                <v-card>
                                    <v-card-title>
                                        <v-row>
                                            <v-col lg="3" class="d-flex justify-content-center text-align-center">
                                                <span style="color:rgb(118, 122, 1); font-size:12px" class="mt-3"> SELECTION</span>
                                            </v-col>
                                            <v-spacer/>
                                            <v-col lg="5" class="d-flex justify-content-center text-align-center">
                                                <v-text-field
                                                    label="Search"
                                                    class="mt-3"
                                                    dense
                                                    v-model="search"
                                                ></v-text-field>
                                                <v-btn text fab @click="getItems()">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-card height="400px" style="overflow-y:auto" elevation="0" @scroll="handleScroll">
                                            <v-card-text>
                                                <v-row style="1">
                                                    <v-col lg="6" v-for="(items,index) in selection.items" :key="index">
                                                        <v-chip
                                                            class="w-100"
                                                            :color="form.item_id.find(e=>e.value == items.value) ? '#FF9900': '#CC6600'"
                                                            label
                                                            text-color="white"
                                                            style="cursor:pointer"
                                                            @click="selectItem(items)"
                                                        >
                                                            <v-icon left>
                                                                mdi-wrench
                                                            </v-icon>
                                                            {{ items.text }}
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col  lg="6" v-for="items in ['a','b','c','d','e','f','g','h']" :key="items" >
                                                        <v-btn class="w-100 bg-pink" loading color="#CC6600" style="opacity:60%" v-if="!selection.loaded">
                                                            <v-chip
                                                            class="w-100"
                                                            color="#CC6600"
                                                            label
                                                            disabled
                                                            text-color="white"
                                                        >
                                                            <v-icon left>
                                                                mdi-wrench
                                                            </v-icon>
                                                        </v-chip>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-actions class="d-flex justify-content-center">
                    <v-tooltip top  v-if="![form.status == 3,form.status == 4,!['VIEW'].includes(PARTS_TRANSFER.DIALOG.ACTION)].includes(true)">
                        <template v-slot:activator="{ on, attrs }">
                                <span
                                class="mx-2"
                                dark
                                v-bind="attrs"
                                v-on="on">
                                    <v-btn  v-if="!PARTS_TRANSFER.APPROVED_MULTIPLE" class="mx-2" text fab @click="updateStatus(form)" :loading="!selection.loaded">
                                        <v-icon style="font-size:40px" :color="form.status  == 1 ? 'warning' : 'success'" >
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                    </v-btn>
                                </span>
                        </template>
                        <span>{{form.status == 1 ? 'Approve' : 'Confirm'}}</span>
                      </v-tooltip>
                      <v-tooltip top  v-if="![form.status == 3,!['EDIT'].includes(PARTS_TRANSFER.DIALOG.ACTION) ].includes(true)">
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            class="mx-2"
                            dark
                            v-bind="attrs"
                            v-on="on">
                                <v-btn class="mx-2" text fab @click="modifyTransfer('parts-transfer/upadte',form)">
                                    <v-icon style="font-size:40px" color="primary" >
                                        mdi-content-save
                                    </v-icon>
                                </v-btn>
                            </span>
                        </template>
                        <span>Save Details</span>
                      </v-tooltip>
                      <v-tooltip top v-if="PARTS_TRANSFER.DIALOG.ACTION == 'ADD'">
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="mx-2"
                                dark
                                v-bind="attrs"
                                v-on="on">
                                <v-btn class="mx-2" text @click="modifyTransfer('create-transfer',form)">
                                    <v-icon style="font-size:40px" color="success" >
                                        mdi-content-save
                                    </v-icon>
                                </v-btn>
                            </span>
                        </template>
                        <span>Create Transfer</span>
                      </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="PARTS_TRANSFER.DIALOG.ADD.VIEW" width="60%" persistent>
            <v-card>
                <v-row dense class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Transmittal Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                        <v-col cols="12">
                    <span>Transmittal#: {{transmittal_id[0]}}</span>
                    </v-col>
                    <v-col cols="12">
                    <span>Printed By: {{printedBy}} </span>
                    </v-col>
                    <v-col cols="12">
                    <span>Date Printed: {{date | formatDateTime}}</span>
                    </v-col>
                </v-card-text>
                <v-row dense no-gutters class="pa-1" v-if="userAccess.includes('pt_reprint')">
                    <v-col cols="12" class="d-flex flex-row-reverse">
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    fab
                                    text
                                    small
                                    class="mr-4"
                                    color="#f69f1a"
                                    @click="override.override_dialog = true"
                                >
                                <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                            </template>
                            <span>Print PDF</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-container>
                    <v-data-table
                        :headers="transmittal_headers"
                        :items="pt_items"
                        :items-per-page="10"
                        :loading="!selection.loaded"
                        >
                    </v-data-table>
                </v-container>
            </v-card>
        </v-dialog>
        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Re-Print Transmittal Form'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
  </v-card>
</template>

<script>
import PartsTransferTable from './PartsTransferTableComponent.vue';
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from '../../Shared/SharedFunctionsComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import Swal from 'sweetalert2'
import ItemExclusiveComponentVue from '../../layouts/dialogs/Sales/Items/ItemExclusiveComponent.vue';

export default {
    props:[
        'tab_name'
    ],
    mixins:[SharedFunctionsComponentVue],
    components:{
        PartsTransferTable,
        ConfirmOverrideComponentVue
    },
    data() {
        return{
            dataTable:{
                items: [],
                options:{},
            },
            transmittal_headers: [
                    { text: 'PT#', align: 'left', value: 'series',sortable: false},
                    { text: 'RF#', align: 'left', value: 'rf_number', sortable: false},
                    { text: 'Source', align: 'left', value: 'source', sortable: false},
                    { text: 'Destination', align: 'left', value: 'destination', sortable: false},
                    { text: 'Customer', align: 'left', value: 'customer_name', sortable: false },
                    { text: 'Release Date', align: 'left', value: 'release_date_time', sortable: false},
                    { text: 'Remarks', align: 'left', value: 'release_remarks', sortable: false},
                ],

            form:{
                source_warehouse_id:'',
                destination_warehouse_id:'',
                item_id:[],
                remarks:'',
                status:1,
                rf_number: '',
            },
            selection:{
                source_warehouse:[],
                destination_warehouse:[],
                items:[],
                itemsPerPage:102,
                scroll:{
                    scrollHeight: 832,
                },
                loaded:true
            },
            search:'',
            userAccess : [],
            selectedItems: [],
            selectedItemsInWhStock: [],
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SM', 'EX', 'IT', 'SR'],
                override_authenticated: false,
            },
            pt_items:[],
            transmittal_id: [],
            printedBy: '',
            date: ''

        }
    },
    computed:{
        ...mapGetters([
            'PARTS_TRANSFER',
            'USER_ACCESS',
            'GET_WH_ITEM_STOCKS',
        ]),

    },
    async mounted(){
        // this.list(),
        this.getItems()
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        // this.pt_items = this.PARTS_TRANSFER.DATA_TABLE.ITEMS;

    },
    methods:{
        list(status = 0){
            this.dataTable.loaded = false;
            this.dataTable.items = []
            this.$store.dispatch('partsTransferGet',{
                url:'parts-transfer',
                search:this.search,
                status: status,
            }).then(response=>{

                this.dataTable.items = response.data.parts
                this.dataTable.loaded = true;
            });
        },
        selectItem(item){
            if(this.PARTS_TRANSFER.DIALOG.ACTION == 'VIEW'){
                return false
            }

            if(!this.form.item_id.find(e => item.value == e.value)){
                this.form.item_id.push(item)
            }else{
                this.form.item_id = this.form.item_id.filter(e=>{
                    if(e.value != item.value){
                        return e
                    }
                })
            }
        },
        handleScroll(event) {
            if (event.target.scrollTop + event.target.clientHeight === event.target.scrollHeight) {
                this.selection.itemsPerPage += 102
                this.selection.scroll.scrollBottom += event.target.scrollWidth
                this.getItems()
            }
        },
        async getItems(){

            await this.$store.dispatch('itemselection',{
                service:1,
                types:[3],
                paginator:1,
                itemsPerPage:this.selection.itemsPerPage,
                active:1,
                search:this.search

            }).then(response=>{
                this.selection.items = response.data.map(e=>{
                    e.quantity = 1
                    e.item_id = e.value
                    return e
                })
            })
            this.$forceUpdate()
        },
        async modifyTransfer(url,form){
            if(!this.userAccess.includes('add')){
                Swal.fire('Error!','- No Access','error')
                return
            }
            let checker = [
                [!!form.source_warehouse_id,'Source Warehouse Required'],
                [!!form.destination_warehouse_id,'Destination Warehouse Required'],
                [form.item_id.length > 0,'Please check the item/s'],
                [form.source_warehouse_id != form.destination_warehouse_id,'Source and destination warehouse canot be the same']
            ]

            if(checker.find(e=>e[0] == false)){
                Swal.fire('Error!',`- ${checker.find(e=>e[0] == false)[1]}`,'error')
                return
            }
            await this.$store.dispatch('servicePost',{
                url:url,
                ...form,
                total_quantity:this.sumArraybyAttribute(form.item_id,'quantity'),
            }).then(response=>{
                if (url != 'parts-transfer/update-status') {
                    Swal.fire('Success!',response.data.message,'success')
                    this.resetField()
                }
            }).catch(err=>{
                console.log(err)
                Swal.fire('Error!','','error')
            })
        },

        updateStatus(form){
            this.selection.loaded = false;
            if(form.status == 1){
                if(!this.userAccess.includes('approve')){
                    Swal.fire('Error!','- No Access','error')
                    return
                }
            }

            if(form.status == 2){
                if(!this.userAccess.includes('confirm')){
                    Swal.fire('Error!','- No Access','error')
                    return
                }
            }

            let checker = [
                [!!form.source_warehouse_id,'Source Warehouse Required'],
                [!!form.destination_warehouse_id,'Destination Warehouse Required'],
                [form.item_id.length > 0,'Please check the item/s'],
                [form.source_warehouse_id != form.destination_warehouse_id,'Source and destination warehouse canot be the same']
            ]

            if(checker.find(e=>e[0] == false)){
                Swal.fire('Error!',`- ${checker.find(e=>e[0] == false)[1]}`,'error')
                return
            }

            if (form.status == 2){

                const noStockAvailable = this.checkWhStock(this.selectedItemsInWhStock);
                if (!!noStockAvailable)
                {
                    Swal.fire('Error', '-Parts no available stock.', 'error');
                    return
                } else {
                    Swal.fire({
                    title:'Approve',
                    text:'- Are you sure you want to approve?',
                    showCancelButton:true,
                    reverseButtons:true,
                    icon: 'question',
                    }).then(async action =>{
                        if(action.isConfirmed){
                            let status = form.status + 1;
                            await this.$store.dispatch('servicePost',{
                                url:'parts-transfer/update-status',
                                ...form,
                                status:status,
                            }).then(async response=>{
                                Swal.fire('Success!','','success')
                                this.resetField()
                            }).catch(err=>{
                                console.log(err.response.message)
                                Swal.fire('Error!','','error')
                            })
                        } else if (action.isDismissed) {
                            this.selection.loaded = true;
                        }
                    })
                }
            } else {
                Swal.fire({
                title:'Approve',
                text:'- Are you sure you want to approve?',
                showCancelButton:true,
                reverseButtons:true,
                icon: 'question',
                }).then(async action =>{
                    if(action.isConfirmed){
                        let status = form.status + 1;

                        await this.$store.dispatch('servicePost',{
                            url:'parts-transfer/update-status',
                            ...form,
                            status:status,
                        }).then(async response=>{
                            Swal.fire('Success!','','success')
                            this.resetField()
                        }).catch(err=>{
                            console.log(err.response.message)
                            Swal.fire('Error!','','error')
                        })
                    } else if (action.isDismissed) {
                        this.selection.loaded = true;
                    }
                })
            }
        },

        resetField(){
            this.toggleDialog('TOGGLE_ADD_DIALOG',this.PARTS_TRANSFER.DIALOG.ADD.VALUE)
            this.$store.commit('DIALOG_ACTION','')
            this.form = {
                source_warehouse_id:'',
                destination_warehouse_id:'',
                status:1,
                item_id:[],
                remarks:'',
                rf_number:'',
            }
            this.selectedItems = [];
            this.selectedItemsInWhStock = [];
            this.$refs.resetTable.list();
            this.selection.loaded = true;
        },
        closeDialog(){
        this.toggleDialog('TOGGLE_ADD_DIALOG_VIEW', this.PARTS_TRANSFER.DIALOG.ADD.VIEW)
        this.$store.commit('DIALOG_ACTION','')
        },

        printParts(form){
            let pdf = new this.$jspdf("p", "mm", "letter");
            // let date = new Date("YYYY");
            let black = "black";

            let transfer = form.series;
            let rf_number = form.rf_number;
            let fileName = `PARTS TRANSFER FORM.pdf`;

            pdf.setFontSize(12);

            pdf.text('Transfer#: ' + transfer, 160, 10, "left");

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            pdf.addImage(ingcoPhLogo, "png", 55, 25, 90, 20);

            pdf.setFont(undefined, "bold");
            pdf.text("PARTS TRANSFER FORM", 100, 53, "center");
            pdf.text(`FROM:`, 10, 65);
            pdf.text(`TO:`, 120, 65);
            pdf.text(`DATE:`, 10, 72);

            if (!!form.rf_number){
                pdf.text(`RF#:`, 120, 72);
            } else if (form.sc_disposal_id){
                pdf.text('DISPOSAL#:', 120, 72)
            } else {
                pdf.text(`BAKLAS#:`, 120, 72);
            }
            pdf.text(`REMARKS:`, 10, 79);

            pdf.setFont(undefined, "normal");
            pdf.text(this.selection.source_warehouse.find(e=>this.form.source_warehouse_id == e.value).text, 25, 65);
            pdf.text(this.selection.source_warehouse.find(e=>this.form.destination_warehouse_id == e.value).text, 130, 65);

            pdf.text(this.$dayjs().format("YYYY-MM-DD").toString(), 25, 72);
            if (!!form.rf_number){
                pdf.text(rf_number, 130, 72);
            } else if (!!form.sc_baklas_id) {
                pdf.text(form.baklas_number, 144, 72);
            } else if (!!form.sc_disposal_id) {
                let disposallNumber = form.sc_disposal_id.toString();
                pdf.text(disposallNumber, 148,72)
            } else {
                pdf.text('', 130, 72);
            }
            pdf.text(form.remarks, 35, 79);

            pdf.autoTable({
            theme: "plain",
            styles: {
            lineWidth: 0.1,
            lineColor: "black",
            },
            headStyles: {
            fillColor: "yellow",
            textColor: "black",
            textType: "bold",
            headerBackgroundColor: "#F7FF00",
            },
            bodyStyles: {
            fillColor: false,
            textColor: "#7E7E7E",
            },
            margin: { top: 85, left: 7, right: 6 },
            columnStyles: {
            0: { textColor: black },
            1: { textColor: black },
            2: { textColor: black },
            3: { textColor: black },
            },
            columns: [
            { header: "MODEL", dataKey: "text" },
            { header: "DESCRIPTION", dataKey: "selected_item" },
            { header: "ITEM LOCATION", dataKey: "item_location" },
            { header: "QTY", dataKey: "quantity" },
            ],
            body: this.form.item_id,
            });

            for (let page = 1; page <= pdf.internal.getNumberOfPages(); page++) {
                pdf.setPage(page);
                pdf.setTextColor("black");
                pdf.setFontSize(10);
                pdf.text(
                `Page ${page} of ${pdf.internal.getNumberOfPages()}`,
                14,
                pdf.internal.pageSize.height - 7
                );
            }

            if(this.form.item_id.length == 22) {
                pdf.addPage()
            }

            pdf.text("PREPARED BY :", 10, 200, "left");
            // pdf.text(this.formatDate(form.created_at), 10, 215, "left");
            pdf.text(this.$dayjs(form.created_at).format("YYYY-MM-DD").toString(), 10, 215);
            pdf.text("RECEIVED/DOUBLE CHECKED BY:", 10, 230, "left");
            pdf.text("______________________________", 10, 245, "left");
            pdf.text("CHECKED BY:", 145, 200, "left");
            pdf.text("______________________________", 145, 215, "left");
            pdf.text("DATE CODED:", 145, 230, "left");
            pdf.text("______________________________", 145, 245, "left");

            pdf.save(fileName);
        },

        checkWhStock(items) {
            let numOfItemsNoStock = 0;
            for (let item of items){
                if (item.wh_stock < item.quantity){
                    numOfItemsNoStock += 1;
                }
            }

            return numOfItemsNoStock;
        },

       exportPartsTransfer(){
            Swal.fire({
                title:                      'Exporting Data',
                allowOutsideClick:          false,
            })
            Swal.showLoading();
            let exportHeadersAndData = {
                ['PARTS TRANSFER DATA']: {
                    headers: [
                        {header:'PRP#', key:'series', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'TOTAL QUANTITY', width: 16,key:'total_quantity', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'CREATED BY', width: 20,key:'created_by', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'REMARKS', width: 25,key:'remarks', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'STATUS', width: 12,key:'status', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'DATE', width: 14,key:'created_at', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'MODEL', width: 20,key:'item_model', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'QUANTITY', width: 12,key:'quantity', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'SOURCE WAREHOUSE', width: 20,key:'source_code', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'DESTINATION WAREHOUSE', width: 25,key:'destination_code', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                        {header:'REASON FOR RETURN', width: 25,key:'return_reason', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    ],
                    data: []
                }
            }
            this.$store.dispatch('partsTransferGet',{
                url:'export-pt',
            }).then(response=>{
                exportHeadersAndData['PARTS TRANSFER DATA'].data = response.data;
                this.exportExcel(exportHeadersAndData, 'Parts Transfer', 'F69F1A');
                Swal.close()
            });
        },

        updateMultiple(data) {
            this.$store.commit('DATA_TABLE_LOADING', true);
            if (!this.userAccess.includes('approve')) {
                Swal.fire('Error!','- No Access','error');
                return
            } else {
                Swal.fire({
                title:'Approve',
                text:'- Are you sure you want to approve all?',
                showCancelButton:true,
                reverseButtons:true,
                icon: 'question',
                }).then(async (action) =>{
                    if(action.isConfirmed){
                        const promises = data.map( async (trans) => {
                            trans.status ++;
                            await this.modifyTransfer('parts-transfer/update-status',trans)
                        })
                        await Promise.all(promises);
                        Swal.fire("Sucess", "" , "success");
                        this.$store.commit('DATA_TABLE_LOADING', false);
                        this.$refs.resetTable.list();
                    } else  {
                        this.$store.commit('DATA_TABLE_LOADING', false);
                    }
                }).catch( e => {
                    console.log(e)
                    this.$store.commit('DATA_TABLE_LOADING', false)
                })
            }
        },
        printPdfTransmittal(){
                const doc = new this.$jspdf("p", "mm", "letter");
                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                doc.addImage(ingcoPhLogo, "png", 10, 12.7, 120, 20);
                doc.setFontSize(14);
                doc.line( 7, 35, 7, 10)
                doc.line( 153, 35, 153, 10)
                doc.line( 209, 35, 209, 10)
                doc.line(7, 10, 209, 10)
                doc.autoTable({
                    startY: 35,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        textColor: '#FF0000',
                        halign: 'center',
                        // cellWidth: 143
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth: 146, fontStyle:'bold'}, 1: {cellWidth:56, fontStyle:'bold'}},
                    columns: [
                        { header: 'TRANSMITTAL FORM', dataKey: 'TRANSMITTAL FORM' },
                        { header: `TRANSMITTAL#${this.transmittal_id[0]}`, dataKey: '' },
                    ],
                    body: [{}],
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#FFFFFF',
                        lineColor: '#FFFFFF',
                    },

                })
                // let lastAutoTableY = doc.lastAutoTable.finalY
                doc.autoTable({
                    startY: 42.5,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        halign: 'center',
                    },
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#000000',
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth:83}, 1: {cellWidth:15}, 2: {cellWidth:18}, 3: { cellWidth:30}, 4: {cellWidth:56, halign: 'center'}},
                    body: this.pt_items.reduce((row, detail)=>{
                        row.push(Object.assign({},{
                            pt:                     detail.series,
                            rf:                     detail.rf_number,
                            customer:               detail.customer_name,
                            date:                   detail.release_date_time,
                            remarks:                detail.release_remarks
                        }));
                        return row;
                    }, []),
                    columns: [
                        { header: 'CUSTOMER', dataKey: 'customer' },
                        { header: 'PT#', dataKey: 'pt' },
                        { header: 'RF#', dataKey: 'rf' },
                        { header: 'RELEASE DATE & TIME', dataKey: 'date' },
                        { header: 'REMARKS', dataKey: 'remarks'},
                    ],
                })
                doc.save('PT Print (Reprinted).pdf')
            },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.printPdfTransmittal();
                this.override.override_dialog = false;
                this.closeDialog();

            } else {
                this.override.override_dialog = false;
                this.closeDialog();
            }
        },
    },

    watch:{
        'PARTS_TRANSFER.DATA_TABLE.SELECTED':{
            handler(val){
                this.$forceUpdate()
                this.form = val.data
                this.selectedItems = val.data.item_id;
            },
            deep:true
        },

        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        },

        'GET_WH_ITEM_STOCKS': {
            handler(val){
                for (let item of this.selectedItems){
                    let combinedMatch = val.find( itemStock => itemStock.item_id === item.item_id);

                    if(combinedMatch){
                        this.selectedItemsInWhStock.push({...item, wh_stock: combinedMatch.quantity});
                    }
                }
            }
        },
        'dataTable.options': {
            handler() {
                this.list();
            },
            deep: true,
        },
        'PARTS_TRANSFER.DATA_TABLE.ITEMS':{
            handler(val){
                this.pt_items = val
                this.transmittal_id = val.map(e => e.transmittal_num)
                this.printedBy = val[0].printed_by
                this.date = val[0].release_date_time
            }
        },
        'PARTS_TRANSFER.DIALOG.ACTION': {
            handler(val) {
                if(val == 'ADD') {
                    this.$store.dispatch('getAllWarehouses',{
                        service:1,
                    }).then(response=>{

                        this.selection.source_warehouse = response.data

                        this.selection.destination_warehouse = response.data

                    })
                } else {
                    this.$store.dispatch('getAllWarehouses',{
                        service:1,
                        disposal: this.PARTS_TRANSFER.DATA_TABLE.SELECTED.data.sc_disposal_id ==  null ? null : 1,
                    }).then(response=>{

                        this.selection.source_warehouse = response.data

                        this.selection.destination_warehouse = response.data

                    })
                }
            }
        }
    }
}
</script>

<style>
.centered-text-field input {
    text-align: center; /* Center the text horizontally */
}
.column-content {
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
  }
</style>
