<template>
    <v-form ref="form" v-model="valid">
        <!-- Functions -->
        <v-card class="px-3 rounded-0">
            <v-card-subtitle>
                <v-row>
                    <v-col cols="3">
                        <v-btn v-if="userAccess.includes('create_type')" class="m-3" @click="$store.commit('SERVICE_PAYMENT_REQUEST_ACTION', 'create'); toggleDialog('open');">
                            <v-icon>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="byPassFilter">
                    <v-col cols="2" class="ml-4">
                        <v-autocomplete
                            v-model="filtered_departments"
                            :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                            label="Department/s"
                            outlined
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            auto-select-first
                            hide-details
                            clearable
                            no-data-text="No Departments Found!"
                        />
                    </v-col>
                    <v-col cols="1" class="pl-2">
                        <v-btn
                            @click="()=>{
                                options.page = 1
                                getAllTransactionTypes()
                            }"
                        >
                            FILTER
                        </v-btn>
                    </v-col>
                    <v-spacer/>
                </v-row>
                <v-row>
                    <v-spacer/>
                    <v-col cols="3" class="pull-right">
                        <v-text-field
                            v-model="search"
                            label="Search"
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllTransactionTypes()"
                            @click:append="getAllTransactionTypes()"
                        />
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <!-- Table -->
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="mainHeaders.filter(e=>e.visible != false)"
                            :items="mainItems"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="10"
                            :loading="tableLoad"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions
                            }"
                            dense
                        >
                            <template v-slot:[`item.active`]="{ item }">
                                <v-badge class="pl-2" :color="item.active == 1?'green':'red'"></v-badge>
                            </template>
                            <template v-slot:[`item.departments`]="{ item }">
                                <span v-for="(row, i) in item.departments" :key="i" class="pa-1">
                                    <v-chip x-small color="primary">{{ row }}</v-chip>
                                </span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn v-if="userAccess.includes('view_type')" text icon color="orange" @click="viewEdit('view', item.id)">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('edit_type')" text icon color="primary" @click="viewEdit('edit', item.id)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="userAccess.includes('delete_type')" text icon color="error" @click="destroy(item.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Dialog -->
            <v-dialog v-model="mainDialog" persistent scrollable width="100%">
                <v-card elevation="0" class="pa-0 m-0 overflow-hidden">
                    <v-card-title v-if="!dialogLoader">
                        <v-container class="pa-0">
                            <v-row class="m-0">
                                <v-col>
                                    <span class="headline">{{ SERVICE_PAYMENT_REQUEST.ACTION.charAt(0).toUpperCase() + SERVICE_PAYMENT_REQUEST.ACTION.slice(1) }} Type</span>
                                </v-col>
                                <v-col cols="pull-right-1">
                                    <v-btn text icon color="gray" class="float-right" @click="toggleDialog('close')">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card elevation="0" dense>
                                <v-card-text class="mt-3">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col :cols="byPassFilter ? 3 : 6">
                                                        <v-text-field
                                                            v-model="form.name"
                                                            :readonly="isDisabled()"
                                                            :rules="required"
                                                            required
                                                            label="Name"
                                                            outlined
                                                            dense
                                                        />
                                                    </v-col>
                                                    <v-col cols="3" v-if="byPassFilter">
                                                        <v-autocomplete
                                                            v-model="form.service_payment_request_departments"
                                                            :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                            :readonly="isDisabled()"
                                                            :rules="requiredArray"
                                                            required
                                                            multiple
                                                            small-chips
                                                            deletable-chips
                                                            auto-select-first
                                                            label="Department/s"
                                                            outlined
                                                            dense
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" dense>
                                <v-card-subtitle class="pa-2">
                                    <strong>
                                        Default Details:
                                            <!-- <span>
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            color="#F69F1A"
                                                            dark
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-file-question
                                                        </v-icon>
                                                    </template>
                                                    <span></span>
                                                </v-tooltip>
                                            </span> -->
                                    </strong>
                                </v-card-subtitle>
                                <v-card-text class="mt-3">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="3">
                                                        <v-autocomplete
                                                            v-model="form.vendor_id"
                                                            :items="GET_ADMIN_SUPPLIER_SELECTION"
                                                            :readonly="isDisabled()"
                                                            label="Select Vendor"
                                                            auto-select-first
                                                            outlined
                                                            dense
                                                        />
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-autocomplete
                                                            v-model="form.company_id"
                                                            :readonly="isDisabled()"
                                                            :items="GET_PV_COMPANIES_SELECTION"
                                                            :loading="GET_PV_COMPANIES_SELECTION.length == 0"
                                                            label="Select Company"
                                                            auto-select-first
                                                            outlined
                                                            dense
                                                            @change="insertStore()"
                                                        />
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-autocomplete
                                                            v-model="form.store_branches_id"
                                                            :readonly="isDisabled()"
                                                            :items="branchItems"
                                                            label="Select Branch"
                                                            no-data-text="No Branches Found!"
                                                            auto-select-first
                                                            outlined
                                                            dense
                                                            @change="insertCompany(form.store_branches_id)"
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="9">
                                                        <v-textarea
                                                            v-model="form.remarks"
                                                            :readonly="isDisabled()"
                                                            label="Remarks"
                                                            rows="2"
                                                            auto-grow
                                                            outlined
                                                            dense
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="9">
                                                        <v-switch
                                                            v-model="form.active"
                                                            label="Active"
                                                        >
                                                        </v-switch>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" dense>
                                <v-card-text class="mt-3">
                                    <v-row>
                                        <v-spacer/>
                                        <v-col cols="6" class="text-right" v-show="!isDisabled()">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" fab dark small color="success" style="height: 20px; width: 20px;" @click="addParticular()">
                                                        <v-icon dark small style="font-size: 12px;">
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                    <span>Add</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" fab dark small color="error" class="ml-1" style="height: 20px; width: 20px;" @click="removeParticular()">
                                                        <v-icon dark small style="font-size: 12px;">
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                    <span>Delete</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 283px;">
                                                            <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left" :width="byPassFilter ? '17%' : '0%'">{{ byPassFilter ? 'COA' : '' }}</th>
                                                                        <th class="text-left" width="55%">PARTICULARS</th>
                                                                        <th class="text-right" width="13%">AMOUNT</th>
                                                                        <th class="text-left" width="10%">RECOGNITION MONTH</th>
                                                                        <!-- <th class="text-left" width="15%">DEPARTMENT</th> -->
                                                                        <!-- <th class="text-left" width="35%">JOB</th> -->
                                                                        <th class="text-center"/>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="particulars-container">
                                                                    <tr v-for="(item, index) in form.service_payment_request_type_items" :key="item.id"
                                                                        class="font-weight-medium text-gray py-2 particulars-container-row">
                                                                        <td>
                                                                            <v-autocomplete
                                                                                v-if="byPassFilter"
                                                                                v-model="item.coa_id"
                                                                                :items="GET_CHART_OF_ACCOUNTS_SELECTION"
                                                                                :loading="GET_CHART_OF_ACCOUNTS_SELECTION.length == 0"
                                                                                :readonly="isDisabled()"
                                                                                id="coa_id"
                                                                                class="my-1 text-left small-font"
                                                                                item-value="id"
                                                                                item-text="account_name"
                                                                                auto-select-first
                                                                                outlined
                                                                                dense
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-textarea
                                                                                v-model="item.particulars"
                                                                                :readonly="isDisabled()"
                                                                                id="particular"
                                                                                class="small-font"
                                                                                rows="1"
                                                                                auto-grow
                                                                                outlined
                                                                                dense
                                                                                @keydown="newLine($event, false, false, index, 'particular', addParticular)"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                v-model="item.amount"
                                                                                :readonly="isDisabled()"
                                                                                id="amount"
                                                                                class="right-align-text small-font"
                                                                                placeholder="0.00"
                                                                                type="number"
                                                                                hide-spin-buttons
                                                                                outlined
                                                                                dense
                                                                                @keypress="decimalOnly"
                                                                                @keydown.native="newLine($event, false, false, index, 'amount', addParticular, false)"
                                                                            >
                                                                                <template v-slot:prepend-inner>
                                                                                    <v-icon small>mdi-currency-php</v-icon>
                                                                                </template>
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-menu
                                                                                :close-on-content-click="true"
                                                                                transition="scale-transition"
                                                                                max-width="290"
                                                                                :disabled="isDisabled()"
                                                                            >
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-text-field
                                                                                        v-model="item.recognition_month"
                                                                                        v-on="on"
                                                                                        :readonly="isDisabled()"
                                                                                        id="recognition_month"
                                                                                        class="small-font"
                                                                                        append-icon="mdi-calendar"
                                                                                        readonly
                                                                                        outlined
                                                                                        dense
                                                                                        clearable
                                                                                        @paste="(event) => validateRecognitionMonth(event, form.service_payment_request_type_items, index)"
                                                                                        @keydown="newLine($event, false, false, index, 'recognition_month', addParticular)"
                                                                                    />
                                                                                </template>
                                                                                    <v-date-picker v-model="item.recognition_month" type="month" />
                                                                            </v-menu>
                                                                        </td>
                                                                        <!-- <td>
                                                                            <v-autocomplete
                                                                                v-model="item.department_id"
                                                                                :items="GET_DEPARTMENT_ACCOUNTING_LOAD"
                                                                                :loading="GET_DEPARTMENT_ACCOUNTING_LOAD.length == 0"
                                                                                :readonly="isDisabled()"
                                                                                class="small-font"
                                                                                outlined
                                                                                dense
                                                                                @keydown="newLine($event, true, true, index, 'coa_id', addParticular)"
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <v-autocomplete
                                                                                v-model="item.job_id"
                                                                                :items="GET_ACCOUNTING_JOB_SELECTION"
                                                                                :loading="GET_ACCOUNTING_JOB_SELECTION.length == 0"
                                                                                :readonly="isDisabled()"
                                                                                class="small-font"
                                                                                outlined
                                                                                dense
                                                                                @keydown="newLine($event, true, true, index, 'job_id', addParticular)"
                                                                            />
                                                                        </td> -->
                                                                        <td class="text-center" v-show="!isDisabled()">
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-btn v-on="on" fab dark x-small color="error" style="height: 20px; width: 20px;" @click="removeItem(index)">
                                                                                        <v-icon small>
                                                                                            mdi-minus
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                    <span>Remove</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                        <v-container class="mt-5">
                            <v-row>
                                <v-col v-if="SERVICE_PAYMENT_REQUEST.ACTION == 'create'" class="text-center">
                                    <v-btn :disabled="!validToProceed" :loading="btnLoad" @click="commitTransaction('submit')">SUBMIT</v-btn>
                                </v-col>
                                <v-col v-if="SERVICE_PAYMENT_REQUEST.ACTION == 'edit'" class="text-center">
                                    <v-btn :loading="btnLoad" @click="commitTransaction('update')">UPDATE</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <div v-else class="text-center pa-2">
                        <v-progress-circular
                            :size="80"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
export default {
    mixins:[SharedFunctionsComponent],
    props:['userAccess'],
    data(){
        return{
            valid: true,
            mainDialog: false,
            mainHeaders: [
                { text: 'Name', value:'name', sortable: false, visible : true },
                { text: 'Department', value:'departments', sortable: false, visible : true },
                { text: 'Active', value:'active', sortable: false, visible : true },
                { text: 'Actions', value:'action', align: 'center', sortable: false, visible : true }
            ],
            mainItems: [],
            options: {},
            totalItems: 0,
            tableLoad: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            dialogLoader: false,
            form:{
                id: null,
                name: '',
                service_payment_request_departments: [],
                vendor_id: null,
                company_id: null,
                store_branches_id: null,
                remarks: null,
                service_payment_request_type_items: [],
                active:true
            },
            btnLoad: false,
            clonedStores: [],
            required: [ v => !!v || 'This field is required' ],
            requiredArray: [v => v.length > 0 || "This field is required"],
            service_payment_request_type_items_trash: [],
            filtered_departments: []
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_DEPARTMENT_ACCOUNTING_LOAD',
            'SERVICE_PAYMENT_REQUEST',
            'USERACCOUNT_LOAD',
            'GET_ADMIN_SUPPLIER_SELECTION',
            'GET_PV_COMPANIES_SELECTION',
            'GET_PV_STORE_BRANCHES_SELECTION',
            'GET_CHART_OF_ACCOUNTS_SELECTION',
            'GET_ACCOUNTING_JOB_SELECTION',
        ]),
        branchItems(){
            let items = this.clonedStores.filter(e=>e.company_id == this.form.company_id)
            if (!this.form.company_id) return this.clonedStores
            if (!this.form.store_branches_id) this.form.store_branches_id = items[0].value
            return items
        },
        byPassFilter(){
            if (
                this.USERACCOUNT_LOAD.president == 1 ||
                this.USERACCOUNT_LOAD.super_admin == 1 ||
                this.USERACCOUNT_LOAD.manager == 1 ||
                this.USERACCOUNT_LOAD.department_id == 4
            ) {
                return true
            } else {
                let departmentHeader = this.mainHeaders.find(e=>e.value == 'departments')
                departmentHeader.visible = false
                return false
            }
        },
        validToProceed(){
            return this.valid
        }
    },
    mounted(){
        this.getAllTransactionTypes()
        document.addEventListener('keydown', this.handleKeyDown)
        this.getBranches()
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods:{
        toggleDialog(action = 'close'){
            switch(action){
                case 'open':
                    this.mainDialog = true
                    this.checkUserCredentials()
                    if (this.form.service_payment_request_type_items.length == 0) this.addParticular()
                    break;
                case 'close':
                    document.removeEventListener('keydown', this.handleKeydown);
                    this.mainDialog = false
                    this.resetFields()
                    break;
            }
        },
        resetFields(){
            this.$refs.form.resetValidation()
            this.$store.commit('SERVICE_PAYMENT_REQUEST_ACTION', '')
            this.form = {
                id: null,
                name: '',
                service_payment_request_departments: [],
                vendor_id: null,
                company_id: null,
                store_branches_id: null,
                remarks: null,
                service_payment_request_type_items: [],
                active:true
            }
            this.service_payment_request_type_items_trash = []
        },
        checkRequiredFields(){
            let emptyFields = []
            if (!this.form.name) {
                emptyFields.push('Name')
            }
            if (this.form.service_payment_request_departments.length == 0) {
                emptyFields.push('Department/s')
            }

            if (emptyFields.length > 0) {
                Swal.fire('Please fill the following fields: \n', emptyFields.join(', '), 'warning')
                return false
            }
            return true
        },
        commitTransaction(action){
            if (this.SERVICE_PAYMENT_REQUEST.ACTION == 'create') {
                let duplicate = this.mainItems.some(item => item.name === this.form.name);
                if(duplicate){
                    Swal.fire('Service Payment Request Type Exists','','error');
                    return
                }
            }
            if (!this.$refs.form.validate()) {
                if (!this.checkRequiredFields()) return
                }
                let url = ''
                let msg = ''
                switch(action){
                    case 'submit':
                        url = 'create'
                        msg = 'submitted'
                        break;
                    case 'update':
                        url = 'update'
                        msg = 'updated'
                        break;
                }
                this.btnLoad = true
                Swal.fire({
                    title: '',
                    text: `Are you sure you want to ${action} service payment type?`,
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutSideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonColor: 'Confirm'
                }).then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            form: this.form,
                            trash: this.service_payment_request_type_items_trash,
                            url: url
                        }
                        this.$store.dispatch('servicePaymentRequestTypePost', payload).then(response => {
                            Swal.fire('', `Service Payment Type ${msg}!`, 'success')
                            this.getAllTransactionTypes()
                            this.toggleDialog('close')
                            this.btnLoad = false
                        }).catch(error => {
                            if (error.response.status == 422) {
                                Swal.fire('', 'Error!', 'error')
                                console.log(error)
                                this.btnLoad = false
                            }
                        })
                    } else {
                        this.btnLoad = false
                    }
                })
        },
        getAllTransactionTypes(){
            this.tableLoad = true
            this.mainItems = []
            let payload = {
                search: this.search,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                url: 'index',
                filtered_departments: this.filtered_departments,
            }
            this.$store.dispatch('servicePaymentRequestTypeGet', payload).then(response=>{
                this.mainItems = response.data.data.data
                this.totalItems = response.data.data.total
                this.tableLoad = false
            })
        },
        viewEdit(action, id){
            this.dialogLoader = true
            this.toggleDialog('open')
            this.action = this.$store.commit('SERVICE_PAYMENT_REQUEST_ACTION', action)
            let payload = {
                id: id,
                url: 'view'
            }
            this.$store.dispatch('servicePaymentRequestTypePost', payload).then(response=>{
                this.form = response.data
                this.form.service_payment_request_departments = this.form.service_payment_request_departments.map(e=>e.department_id)
                this.form.service_payment_request_type_items.forEach(e=>{
                    e.recognition_month = this.$dayjs(e.recognition_month).format('YYYY-MM')
                    e.amount = parseFloat(e.amount).toFixed(2)
                })
                this.dialogLoader = false
            })
        },
        isDisabled(){
            switch(this.SERVICE_PAYMENT_REQUEST.ACTION){
                case 'view':
                    return true
                    break;
            }
        },
        destroy(id){
            Swal.fire({
                title: '',
                text: 'Are you sure you want to delete Service Payment Type?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutSideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonColor: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id : id,
                        url : 'destroy'
                    }
                    this.$store.dispatch('servicePaymentRequestTypePost', payload).then(response => {
                        Swal.fire('', 'Service Payment Type Deleted!', 'success')
                        this.getAllTransactionTypes();
                    }).catch(error => {
                        if (error.response.status == 422) {
                            Swal.fire('', 'Error!', 'error')
                            console.log(error)
                        }
                        this.getAllTransactionTypes()
                    })
                }
            })
        },
        checkUserCredentials(){
            if (
                !this.USERACCOUNT_LOAD.super_admin &&
                !this.USERACCOUNT_LOAD.manager &&
                !this.USERACCOUNT_LOAD.president &&
                this.USERACCOUNT_LOAD.department_id != 4
            ) {
                this.form.service_payment_request_departments.push(this.USERACCOUNT_LOAD.department_id)
            }
        },
        handleKeyDown(event){
            if (this.mainDialog && !this.dialogLoader) this.closeDialogByEsc(event, this.toggleDialog)
        },
        async getBranches(){
            try{
                this.clonedStores = await this.getStoreBranches()
            } catch (error) {
                console.error(error)
            }
        },
        addParticular(){
            this.form.service_payment_request_type_items.push({
                coa_id: null,
                particulars: null,
                amount: null,
                recognition_month: this.$dayjs().format('YYYY-MM'),
                // department_id: null
            })
        },
        insertCompany(storeId){
            if (!storeId) return
            let store = this.clonedStores.find(e=>e.value == storeId)
            this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
        },
        insertStore(){
            this.form.store_branches_id = this.branchItems[0].value
        },
        removeParticular(){
            if (this.form.service_payment_request_type_items.length > 1) this.form.service_payment_request_type_items.pop()
        },
        removeItem(index){
            if (this.form.service_payment_request_type_items.length != 1) {
                this.service_payment_request_type_items_trash.push(this.form.service_payment_request_type_items[index])
                this.form.service_payment_request_type_items.splice(index, 1)
            }
            if (this.form.service_payment_request_type_items.length == 0) this.addParticular()
        },
        getDepartmentName(id){
            return this.GET_DEPARTMENT_ACCOUNTING_LOAD.find(e=>e.value == id).text
        }
    },
    watch:{
        options:{
            handler(){
                this.getAllTransactionTypes()
            },
            deep: true
        },
        search:{
            handler(val){
                if(val){
                    this.options.page = 1
                }
            },
            immediate: true
        },
        'form.company_id':{
            handler(val){
                if (!val) {
                    this.form.store_branches_id = null
                }
            },
            immediate: true
        },
        'form.store_branches_id':{
            handler(val){
                if (!val) {
                    this.form.company_id = null
                }
            },
            immediate: true
        },
    }
}
</script>
<style scoped>
    .small-font {
        font-size: 14px;
    }
    ::v-deep .right-align-text input {
        text-align: right;
    }
</style>
