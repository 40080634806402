<template>
    <v-container fluid style="display:none">
        <div id="ctrl_num_label" style="font-weight:bold">
            Control No.:
        </div>
        <div id="ctrl_num_value" style="font-weight:bold">
            {{ 'DM# ' + debit_memo.debit_memo_num }}
        </div>

        <div>
            <table id="details">
                <tbody>
                    <tr>
                        <td>SOA Month:</td>
                        <td>{{ debit_memo.soa_month_year}}</td>
                        <td colspan="2" style="font-weight:bold">Date Prepared</td>
                        <td>{{ debit_memo.debit_memo_date_long }}</td>

                    </tr>
                    <tr>
                        <td style="font-weight:bold">Customer:</td>
                        <td colspan="4">{{ debit_memo.customer_name }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight:bold">Address:</td>
                        <td colspan="4">{{ debit_memo.billing_address_text }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight:bold">Contact Person:</td>
                        <td>{{ debit_memo.billing_contact_person }}</td>
                        <td colspan="2" style="font-weight:bold">Contact Number:</td>
                        <td>{{ debit_memo.billing_contact_number }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="items">
                <thead>
                    <tr>
                        <td colspan="4" style="font-weight:bold; text-align:center">PARTICULARS</td>
                        <td style="font-weight:bold; text-align:center">AMOUNT</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in debit_memo_items" :key="index">
                        <td colspan="4">{{ item.remarks }}</td>
                        <td v-if="item.id != null">{{ item.total_amount | currency }}</td>
                        <td v-else>{{  }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="totals">
                <tbody>
                    <tr>
                        <td colspan="4">AMOUNT FOR DM</td>
                        <td>{{ debit_memo.total_amount | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table id="signatories">
                <tbody>
                    <tr>
                        <td>Processed by:</td>
                        <td><br><br>{{ debit_memo.created_by_name }}</td>
                        <td colspan="2">Checked by:</td>
                        <td><br><br>{{ debit_memo.acct_approved_by_name }}</td>
                    </tr>
                    <tr>
                        <td>Approved by:</td>
                        <td><br><br>{{ debit_memo.exec_approved_by_name }}</td>
                        <td colspan="2">Received by:</td>
                        <td><br><br>{{ }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div id="footer_1" style="font-size:10px">
            <span style="margin-right:16px"><i>WHITE - ACCTG COPY</i></span>
        </div>

        <div id="footer_2" style="font-size:10px">
            <span><i>PINK - CUSTOMER COPY</i></span>
        </div>
    </v-container>
</template>

<script>
export default {
    props: [
        'debit_memo',
        'debit_memo_items',
    ],
    data() {
        return {

        }
    },
    mounted(){
        this.print()
    },
    methods: {
        print(){
            let doc = new this.$jspdf('p', 'mm', 'letter');

            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = '/images/ingcoph-logo.png';
            doc.addImage(ingcoPhLogo, 'png', 7, 6, 40, 15);

            let debitMemoLogo = new Image();
            debitMemoLogo.src = '/images/debit-memo-logo.png';
            doc.addImage(debitMemoLogo, 'png', 118, 6, 85, 8);

            doc.setFont(undefined, 'bold').setFontSize(12).text(132,21,"Control No: DM# "+this.debit_memo.debit_memo_num).setFont(undefined, 'normal')

            doc.autoTable({
                html: '#details',
                theme: 'grid',
                margin: {top: 25, left: 4, right: 11},
                columnStyles: {
                    0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 72, halign:'left', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            doc.autoTable({
                html: '#items',
                theme: 'grid',
                margin: {left: 4, right: 11},
                startY: doc.autoTable.previous.finalY,
                headStyles: {
                    halign:'center',
                    fillColor: '#ffffff',
                    textColor: '#000000',
                    lineColor: '#000000',
                    lineWidth: .1,
                    fontSize: 8,
                },
                columnStyles: {
                    0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 101, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 40, halign:'right', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            doc.autoTable({
                html: '#totals',
                theme: 'grid',
                margin: {left: 4, right: 11},
                startY: doc.autoTable.previous.finalY,
                columnStyles: {
                    0: {cellWidth: 30, halign:'right', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'right', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'right', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 47, halign:'right', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 40, halign:'right', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            doc.autoTable({
                html: '#signatories',
                theme: 'grid',
                margin: {left: 4, right: 11},
                startY: doc.autoTable.previous.finalY,
                columnStyles: {
                    0: {cellWidth: 30, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 69, halign:'center', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 72, halign:'center', textColor: '#000000', fontSize: 8},
                },
                bodyStyles: {
                    lineColor: '#000000',
                }
            });

            let footerY = doc.autoTable.previous.finalY + 4

            doc.setFont(undefined, 'italic').setFontSize(8).text(4,footerY,"WHITE - ACCTG COPY").setFont(undefined, 'normal')
            doc.setFont(undefined, 'italic').setFontSize(8).text(43,footerY,"PINK - CUSTOMER COPY").setFont(undefined, 'normal')


            this.$store.dispatch('printDebitMemo',this.debit_memo.id).then(response => {
                if (response.data == 'success') {
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                    this.$emit('closeDialog')
                    this.$emit('getAllCreditMemos')
                }
            })
        },
    },
    watch: {
        'debit_memo_items': {
            handler(){
                while (this.debit_memo_items.length < 4) {
                    this.debit_memo_items.push(Object.assign({},{
                        id: null,
                        remarks: null,
                        total_amount: null,
                    }))
                }
            },
            immediate: true,
        }
    },
}
</script>
