<template>
    <div class="tabs-body-color">
        <v-container fluid class="pa-0">
            <v-row class="pt-4 pl-2" v-if="userAccess.create">
                <v-col cols="12" class="d-flex justify-content-between">
                    <div class="btn-toolbar pl-0">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                    <div class="pr-3">
                        <v-btn @click="openBatchDialog(false)"><v-icon>mdi-file-excel</v-icon>&nbsp;IMPORT FAS ITEMS</v-btn>
                        <!-- <v-btn @click="openBatchDialog(true)" class="pl-5"><v-icon>mdi-file-excel</v-icon>&nbsp;IMPORT FAS PRICE</v-btn> -->
                    </div>
                </v-col>
            </v-row>
            <v-row>
              <FasItemsTableComponent v-if="GET_ADMIN_FASITEM_STATUS == isActive_tab"></FasItemsTableComponent>
            </v-row>
        </v-container>
    
    </div>
</template>
<script>
import Swal from "sweetalert2";
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import FasItemsTableComponent from '@/views/main/modules/Admin/FASItems/FasItemsTableComponent.vue';
import { mapGetters } from 'vuex'
export default {
    components: {
        FasItemsTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            userAccess:{
                create:false
            }
        }
    },
    computed: {
        ...mapGetters(['USER_ACCESS','GET_ADMIN_FASITEM_STATUS'])
    },
    methods: {
        AddDialog(){
            this.$store.commit('ADD_FAS_TYPE','from_add');
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
        openBatchDialog(data){
            this.$store.commit('ADD_FAS_TYPE','from_import');
            this.$store.commit('IMPORT_FAS_PRICE_TYPE',data);
            this.$store.commit("DIALOG", true);
            // this.$store.commit("ACTION", "Submit");
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
            if(val != "fail"){
                this.userAccess.create = false;
                val.forEach(e => {
                if (e.actions_code == 'create') {
                    this.userAccess.create = true;
                }
                });
              }
            }
        },
    }
};
</script>
