<template>
    <v-container class="tabs-body-color p-0">
        <v-card elevation="0" rounded="0" class="p-3">
            <v-card-title class="d-flex justify-content-end align-items-end p-0 m-0">
                <v-row class="w-100 d-flex">
                    <v-col cols="6" class="pa-4">
                        <v-row>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.filters.employee_id"
                                    :items="GET_EMPLOYEE_DATA"
                                    label="Employee"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.filters.company_id"
                                    :items="GET_PV_COMPANIES_SELECTION"
                                    label="Company"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.filters.store_branch_id"
                                    :items="GET_STORE_BRANCHES_WITHOUT_ACCESS"
                                    label="Store Branch"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.filters.department_id"
                                    :items="GET_LIST_DEPARTMENT"
                                    label="Department"
                                    dense
                                    outlined
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-autocomplete
                                    v-model="form.filters.company_classification"
                                    :items="GET_COMPANY_CLASSIFICATION_SELECTION"
                                    label="Head Office"
                                    clearable
                                    dense
                                    outlined
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-4">
                        <v-row>
                            <v-btn class="mt-1" @click="search()">Filter</v-btn>
                            <v-spacer></v-spacer>
                            <!-- <v-btn>
                                IMPORT
                            </v-btn> -->
                            <v-btn @click="exportModule">
                                EXPORT
                            </v-btn>
                        </v-row>
                        <!-- <v-row>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex justify-end">
                                <v-card-subtitle>
                                    <v-row>
                                        <v-col cols="1" class="d-flex align-items-center pr-4 pt-2">
                                            <span class="badge bg-dark ml-1 text-white" style="cursor:pointer" @click="status('')">All</span>
                                            <span class="badge bg-primary ml-1 text-white" style="cursor:pointer" @click="status('1')">Paid</span>
                                            <span class="badge bg-danger ml-1 text-white" style="cursor:pointer" @click="status('0')">Unpaid</span>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-col>
                        </v-row> -->
                    </v-col>
                </v-row>
                <v-row class="w-25 p-0 m-0 d-flex justify-end align-end">
                    <v-spacer></v-spacer>
                    <v-col cols="2">
                        <v-row class="w-100 pb-8">
                            <v-col cols="11">
                                <v-text-field v-model="form.filters.search" label="Search CAF#" single-line hide-details @keydown.enter="search()"></v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-col class="pt-6">
                                    <v-icon @click="search()">mdi-magnify</v-icon>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table
                :headers="form.headers"
                :items="form.items"
                :loading="loading"
                :items-per-page="form.filters.itemsPerPage"
                :server-items-length="form.filters.totalItems"
                @update:options="onPageChange"
                class="w-100"
                dense
                loading-text="Loading...Please wait"
                disable-sort
            >
                <template v-slot:[`item.total_amount`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.total_amount | currency }}
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <span class="text-nowrap">
                        <v-chip
                            :style="`background-color:${STATUS.CASH_ADVANCE_STATUS.find(e=>e.value === item.status)?.color}`"
                            class="badge text-white mt-2"
                        >
                            {{STATUS.CASH_ADVANCE_STATUS.find(e=>e.value === item.status)?.text}}
                        </v-chip>
                    </span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                        <v-btn text icon color="orange" @click="dialog('View',item)" v-if="userAccess.includes('view')">
                            <v-icon class="btn-action" small>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" @click="dialog('Edit',item)" v-if="userAccess.includes('edit') && item.status != 2">
                            <v-icon class="btn-action" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" @click="deleteDialog(item)" v-if="userAccess.includes('delete')  && item.status != 2">
                            <v-icon class="btn-action" small>mdi-delete</v-icon>
                        </v-btn>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </v-container>

</template>

<script>
import { mapGetters } from 'vuex';
import swal from "sweetalert2";
import CashAdvanceViewComponent from '@/views/main/modules/Accounting/CashAdvance/CashAdvanceViewComponent.vue'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
    export default {
    components: {
        CashAdvanceViewComponent,
    },
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            form: {
                items:[],
                headers: [
                    {text: 'CAF#', align: 'left', value: 'doc_num', header:'CAF#' ,key:'doc_num'},
                    {text: 'Employee', align: 'center', value: 'employee_name', header:'Employee' ,key:'employee_name'},
                    {text: 'Company', align: 'center', value: 'company_name', header:'Company' ,key:'company_name'},
                    {text: 'Store Branch', align: 'center', value: 'store_branch_name', header:'Store Branch' ,key:'store_branch_name'},
                    {text: 'Department', align: 'center', value: 'department_name', header:'Department' ,key:'department_name'},
                    {text: 'Head Office', align: 'center', value: 'company_classifications', header:'Head Office' ,key:'company_classifications'},
                    {text: 'Status', align: 'center', value: 'status', header:'Status' ,key:'status'},
                    {text: 'Amount', align: 'right', value: 'total_amount', header:'Amount' ,key:'total_amount',formatAs: 'float', style: { numFmt: '#,##0.00' }},
                    {text: 'Actions', align: 'center', value: 'action'},
                ],
                item_headers: [
                    {header:'CAF#' ,key:'cash_advance_doc_num'},
                    {header:'Payable In Date' ,key:'payable_in_date', formatAs: 'date'},
                    {header:'Amount' ,key:'amount',formatAs: 'float', style: { numFmt: '#,##0.00' }},
                ],
                filters:{
                    page: 1,
                    itemsPerPage: 10,
                    totalItems: 0,
                    search:'',
                    employee_id: '',
                    store_branch_id: '',
                    company_id: '',
                    department_id: '',
                    company_classification: '',
                }
            },
            userAccess:[],
            loading: false
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.$store.dispatch('getStoreBranchesWithoutAccess');
        this.$store.dispatch('getPvCompanies');
        this.$store.dispatch('getDepartment');
        this.$store.dispatch('getEmployeeDataDropdown');
        this.$store.dispatch('getCompanyClassificationDropdown');
    },
    computed: {
        ...mapGetters([
            "USER_ACCESS",
            "ACTION",
            "SELECTED",
            "STATUS",
            "USERACCOUNT_LOAD",
            "COMPONENT_INFO",
            "SELECTED_TAB",
            "DIALOGS",
            "GET_EMPLOYEE_DATA",
            "GET_PV_COMPANIES_SELECTION",
            "GET_STORE_BRANCHES_WITHOUT_ACCESS",
            "GET_LIST_DEPARTMENT",
            "GET_COMPANY_CLASSIFICATION_SELECTION"
        ]),
    },
    methods: {
        exportModule(){
            this.form.filters.export = 'export';
            this.$store.dispatch("cashAdvanceGet",{
                url: "cash-advance",
                filter: {...this.form.filters, status: this.SELECTED_TAB},
            }).then( async response => {
                    let allItems = [];
                    allItems = response.data.data.data.flatMap(e => e.cash_advance_items);
                    let header = {
                        ['Cash Advance']: {
                            headers: this.form.headers,
                            data: response.data.data.data.map((e) => {
                                e.status = this.STATUS.CASH_ADVANCE_STATUS.find(d => d.value == e.status).text ?? '';
                                return e;
                            })
                        },
                        ['Cash Advance Items']: {
                            headers: this.form.item_headers,
                            data: allItems
                        },
                    }
                    await this.exportExcel(header, `Cash Advance`)
            }).catch(error => {
                console.error("Error fetching Cash Advance:", error)
                this.loading = false;
            });
        },
        async list(){
            this.form.items = [];
            this.loading = true;
            await this.$store.dispatch("cashAdvanceGet",{
                url: "cash-advance",
                filter: {...this.form.filters, status: this.SELECTED_TAB},
            }).then(response => {
                    this.form.items = response.data.data.data;
                    this.form.filters.totalItems = response.data.total;
                    this.loading = false;
            }).catch(error => {
                console.error("Error fetching Cash Advance:", error)
                this.loading = false;
            });
        },
        dialog(action, item){
            this.$store.commit('URL','getFiles');
            this.$store.commit('PAYLOAD',{
                ut: btoa('CashAdvanceUpload'),
                tcn: btoa('cash_advance_id'),
                ufp: btoa('cash_advance_files'),
            });
            this.$store.commit('DIALOG', true);
            this.$store.commit('ACTION', action);
            this.$store.commit('SELECTED',item);
        },
        deleteDialog(item){
            swal.fire({
            icon: "warning",
            text: "Are you sure you want to Delete?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#397373",
            cancelButtonColor: "grey",
            reverseButtons: true
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("cashAdvanceDelete", {
                    url: 'cash-advance',
                    id: item.id,
                    }).then(response=>{
                        swal.fire(response.data.msg,'',response.data.icon);
                        this.list();
                    });
                }
            });
        },
        search(){
            this.form.filters.page = 1;
            this.list();
        },
        onPageChange(newPage) {
            this.form.filters.page = newPage.page;
            if(newPage.itemsPerPage == -1){
                this.form.filters.itemsPerPage = this.form.filters.totalItems;
            } else {
                this.form.filters.itemsPerPage = newPage.itemsPerPage;
            }
            this.list();
        },
    },
    watch: {
        USER_ACCESS:{
            handler(val){
            if(val != "fail") this.userAccess = val.map(e=>e.actions_code);
            }
        },
        DIALOGS:{
            handler(val){
                if(!val){
                    this.list();
                }
            }
        },
        SELECTED_TAB:{
            handler(val){
                this.list();
            }
        }
    }
}
</script>
