<template>
    <div>
        <v-layout row class="mx-0">
            <v-flex lg4 class="pr-2">
                <v-card style="border-radius:unset">
                    <v-card-title>
                        <v-spacer />
                        <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                            clearable
                        >
                        </v-text-field>
                        <v-icon>mdi-magnify</v-icon>
                    </v-card-title>

                    <v-card-text>

                        <v-data-table
                            :headers="headers"
                            :items="technicianRfs"
                            :search="search"
                            :items-per-page="technicianRfs.length"
                            :loading="loading"
                            :no-data-text="noDataText"
                        >
                            <template v-slot:[`item.profiles`]="{ item }">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small @click="viewTechProfiles(item.id)">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn text icon color="primary">
                                    <v-icon class="btn-action" small @click="viewRepairForms(item.id)">mdi-arrow-right-thick</v-icon>
                                </v-btn>
                                <!-- <v-btn text icon color="blue" v-if="userAccess.edit">
                                    <v-icon class="btn-action" small @click="editBank(item.id)">fas fa-edit</v-icon>
                                </v-btn>
                                <v-btn text icon color="red" v-if="userAccess.delete">
                                    <v-icon class="btn-action" small @click="deleteBank(item.id)">fas fa-trash-alt</v-icon>
                                </v-btn> -->
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-flex>

            <v-flex lg8>
                <v-card style="border-radius:unset">
                    <v-card-title>
                        <v-spacer />
                        <v-text-field
                            v-model="searchRf"
                            label="Search"
                            single-line
                            hide-details
                            clearable
                        >
                        </v-text-field>
                        <v-icon>fas fa-search</v-icon>
                    </v-card-title>

                    <v-card-text>
                        <v-data-table
                            :headers="rfHeaders"
                            :items="repairForms"
                            :search="searchRf"
                            :items-per-page="repairForms.length"
                            :loading="rfLoading"
                            :no-data-text="noDataText"
                        >
                            <template v-slot:[`item.re_assign`]="{ item }">
                                <v-btn @click="showAssignTechDialog(item)">Select</v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <v-dialog v-model="assignTechDialog" persistent max-width="30%" content-class="unset-overflow">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Technician</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAssignTechDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-4 pb-4">
                    <div>Assign repair form to: </div>
                    <div>
                        <v-autocomplete
                            dense
                            outlined
                            :items="technicians"
                            v-model="seletedTechnician"
                            item-value="id"
                            item-text="name"
                            placeholder="Select Technician"
                        />
                    </div>
                </div>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn @click="assign()">Assign</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <TechnicianProfileDialogComponentVue :id="id" ref="techDialog" @closeTechProfile="closeTechProfile" />
    </div>
</template>

<script>
import TechnicianProfileDialogComponentVue from '../TechnicianManagerComponent/TechnicianProfileDialogComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            headers: [
                { text: 'Name', value: 'technician_name' },
                { text: 'Level', value: 'level' },
                { text: 'UAS', value: 'ure_count' },
                { text: 'PIN', value: 'pin_count' },
                { text: 'RET', value: 'ret_count' },
                { text: 'Profiles', value: 'profiles' },
                { text: 'RFs', value: 'action' },
            ],
            rfHeaders: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Model', value: 'model' },
                { text: 'Complaint / Symptom', value: 'defect_description' },
                { text: 'Warranty Type', value: 'warranty_type' },
                { text: 'Status', value: 'status' },
                { text: 'Crate#', value: 'crate_number' },
                { text: 'Loc#', value: 'location_number' },
                { text: 'Technician', value: 'tech_name' },
                { text: 'Date', value: 'created_at' },
                { text: 'Re-Assign', value: 're_assign' },
            ],
            technicianRfs: [],
            loading: true,
            search: '',
            repairForms: [],
            searchRf: '',
            rfId: '',
            noDataText: '',
            rfLoading: true,
            seletedTechnician: '',
            technicians: [],
            assignTechDialog: false,
            selectedRfToAssign: '',
            techId: '',
            id: ''
        }
    },
    components: {
        TechnicianProfileDialogComponentVue
    },
    async mounted() {
        await this.getTechnianRfs();
    },
    computed:{
        ...mapGetters([
            'GET_TECHNIAN_RFS',
            'GET_SELECTED_TECHNICIAN_RFS',
            'GET_ALL_TECHNICIANS',
        ])
    },
    watch: {
        technicianRfs() {
            if(this.technicianRfs.length > 0) {
                this.loading = false;
                this.techId = this.technicianRfs[0].id;
            } else {
                this.loading = false;
                this.noDataText = 'No Data';
            }
        },
        repairForms() {
            if(this.repairForms.length > 0) {
                this.loadrfLoadinging = false;
            } else {
                this.rfLoading = false;
                this.noDataText = 'No Data';
            }
        },
        techId() {
            if(!!this.techId) {
                this.repairForms = [];
                this.rfLoading = true;
                this.getSelectedTechnicianRfs();
            }
        },
        selectedRfToAssign() {
            if(!!this.selectedRfToAssign) {
                this.getAllTechnicians();
            }
        }
    },
    methods: {
        clearFields() {
            this.rfId = '';
            this.loading = true;
            this.noDataText = '';
            this.rfLoading = true;
            this.repairForms = [];
            this.technicianRfs = [];
            this.search = '';
            this.searchRf = '';
        },
        getTechnianRfs() {
            this.$store.dispatch('getTechnianRfs').then(response=>{
                this.technicianRfs = this.GET_TECHNIAN_RFS;
            });
        },
        getSelectedTechnicianRfs() {
            this.$store.dispatch('getSelectedTechnicianRfs',this.techId).then(response=>{
                this.repairForms = this.GET_SELECTED_TECHNICIAN_RFS;
            });
        },
        getAllTechnicians() {
            this.$store.dispatch('getAllTechnicians').then((response)=> {
                this.technicians = this.GET_ALL_TECHNICIANS;
            })
        },
        viewRepairForms(id) {
            this.techId = id;
        },
        showAssignTechDialog(item) {
            this.selectedRfToAssign = item.id;
            this.assignTechDialog = true;
        },
        closeAssignTechDialog() {
            this.selectedRfToAssign = '';
            this.seletedTechnician = '';
            this.assignTechDialog = false;
        },
        viewTechProfiles(id) {
            this.id = id;
            this.$refs.techDialog.showTechProfileDialog();
        },
        assign() {
            if(!!this.selectedRfToAssign && !!this.seletedTechnician) {
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Assign Technician?",
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(result => {
                    if(result.isConfirmed) {
                        let payload = {
                            rfId: this.selectedRfToAssign,
                            techId: this.seletedTechnician,
                        }
                        this.$store.dispatch('reAssigntechnician',payload).then(response => {
                            if (response.data.error) {
                                swal.fire('', response.data.msg, response.data.status)
                            } else {
                                this.closeAssignTechDialog();
                                this.clearFields();
                                this.getTechnianRfs();
                                swal.fire('', response.data.msg, 'success');
                            }
                        })
                    }
                })
            } else {
                swal.fire('','Please select technician', 'warning');
            }
        },
        closeTechProfile(confirm) {
            if(confirm) {
                this.id = '';
            }
        }
    }
}
</script>

<style>
</style>
