<template>
<v-card>
    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        BATCH ADDITION
        <v-row class="m-0">
        <v-col cols="pull-right-10 p-2">
          <v-btn
            text
            icon
            small
            color="gray"
            class="float-right"
            @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text style="height:250px;" class="over-flow-auto">
        <v-container fluid class="over-flow-auto" bg fill-height grid-list-md text-xs-center>
          <v-row  @dragover="dragover" @dragleave="dragleave" @drop="drop" class="dropzone-container overflow-hidden" v-if="form.batchAdditionItems.length == 0 && loaded">
            <v-col cols="12" class="text-center">
              <v-container  :class="have_img?'position-relative fluid d-flex justify-content-center':'d-none'">
              <div  class="position-relative fluid d-flex justify-content-end">
                <v-btn

                    text
                    icon
                    small
                    color="white"
                    :class="disabled_view?'d-none position-absolute':'position-absolute'"
                  >
                    <v-icon color="black">mdi-close-circle</v-icon>
                  </v-btn>
                </div>
              </v-container>
            
              <v-icon style="font-size:50px;" :class="have_img?'d-none':'d-block'">mdi-upload</v-icon>
              <p :class="have_img?'d-none':'d-block'"><span>Drag & Drop <p >or <label for="upload-file" style="color:blue;cursor:pointer;">browse</label> </p></span></p>
              <input type="file" id="upload-file" class="d-none" @change="ImportedExcel"
              ref="file"
              accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
            </v-col>
           </v-row>
           <V-row v-if="!loaded">
            <v-col cols="12" class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <p>{{loading_text}}</p>
              </v-col>
           </V-row>
            <v-row v-if="form.batchAdditionItems.length > 0">
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead class="pa-0">
                            <tr>
                            <th class="text-center">
                                Code
                            </th>
                            <th class="text-center">
                                QTY
                            </th>
                            <th class="text-center">
                                UOM
                            </th>
                            <th class="text-center" v-if="GET_IMPORT_FAS_PRICE">
                                PRICE
                            </th>
                            <th class="text-center">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(item,i) in form.batchAdditionItems" :key="i"
                            >
                                <td class="pa-0 pr-1"><v-text-field v-model="item.code" outlined dense @paste="addItems()" :title="item.code"></v-text-field></td>
                                <td class="pa-0 pr-1"><v-text-field v-model="item.qty" outlined dense :class="item.qty == 0?'bg-danger':''"></v-text-field></td>
                                <td class="pa-0">  <v-autocomplete
                                        v-model="item.uom"
                                        :items="GET_UOM_SELECTION"
                                        item-text="text"
                                        item-value="value"
                                        auto-select-first
                                        outlined
                                        label="UOM"
                                        required
                                        :disabled="disabled_view"
                                        dense
                                      ></v-autocomplete></td>
                                      <td class="pa-0 pr-1" v-if="GET_IMPORT_FAS_PRICE"><v-text-field v-model="item.price" outlined dense :class="item.price == 0?'bg-danger':''"></v-text-field></td>
                                <td><v-btn
                                    text
                                    icon
                                    small
                                    color="primary"
                                    class="float-right"
                                    @click="removeItem(i)">
                                    <v-icon>mdi-minus-circle</v-icon>
                                </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>

                </v-col>
            </v-row>
        </v-container>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="clearItems()"
      >
        CLEAR
      </v-btn>
      <v-btn
        color="primary"
        @click="saveFromEXCEL()"
      >
        SAVE DATA
      </v-btn>
    </v-card-actions>
</v-card>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Swal from "sweetalert2";
  import * as XLSX from "xlsx";
export default {
    data(){
        return{
        loaded:true,
        loading_text:'Loading....Please Wait',
        file:File,
        arrayBuffer:null,
        fileList:null,
        dialog:false,
        valid:true,
        // vendor:[],
        dialog_title:"",
        form: {
          batchType:'',
          batchAdditionItems:[],
        },
        price_history:[],
        search_classification:'',
        subClass:[],
        disabled_view: false,
        action:'',
        isDragging: false,
        files: [],
        have_img:false,
        duplicateVendorCollections:[],
            userAccess:{
                create:false
            }
        }
    },
    mounted(){
        this.form.batchType = this.GET_IMPORT_FAS_PRICE;
    },
     computed:{
        ...mapGetters(['GET_ADMIN_FASITEM_STATUS','GET_UOM_SELECTION','GET_NEW_ADMIN_FASITEM','GET_ADMIN_SUPPLIER_SELECTION','GET_IMPORT_FAS_PRICE']),
        },
    methods:{
        clearItems(){
        this.form.batchAdditionItems = [];
      },
    removeItem(i){
      this.form.batchAdditionItems.splice(i,1);
    },
     ImportedExcel(event) {
        this.loaded = false
        let that = this;
        that.file = that.$refs.file.files[0];
        let fileReader = new FileReader();
       
        setTimeout((el)=>{
         
          fileReader.readAsArrayBuffer(that.file);
          that.loading_text = 'Transferring data please wait...'
          fileReader.onload = (e) => {
          
          that.arrayBuffer = fileReader.result;
          let data = new Uint8Array(that.arrayBuffer);
          let arr = new Array();
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");
    
          let workbook = XLSX.read(bstr, { type: "binary" });
            
          let sheetName = '';
          // let first_sheet_name = workbook.SheetNames[1];
          if(this.form.batchType){
            sheetName = 'AMOUNT';
          }else{
            sheetName = 'BALANCE';
          }
          
         if(!this.form.batchType && workbook.Sheets['BALANCE'] == undefined){
          Swal.fire({
            icon:'error',
            title:'Sheet "' +sheetName +'" not found'
          })
          that.loaded = true
          return false;
         }else if (this.form.batchType && workbook.Sheets['AMOUNT'] == undefined){
            Swal.fire({
            icon:'error',
            title:'Sheet "' +sheetName +'" not found'
          })
          that.loaded = true
          return false;
         }
          let worksheet = workbook.Sheets[sheetName];
          let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          that.filelist = []; 
          let tmp_arr = [];
          let tmp_obj = {};
          let tmp_obj_arr = [];
          arraylist.forEach((e,i)=>{
            if(i != 0){
            //   Object.keys(e).filter(async(ele)=>{
            //        if(this.GET_ADMIN_SUPPLIER_SELECTION.find(sup=>sup.text === e[ele]) != undefined && (this.GET_ADMIN_SUPPLIER_SELECTION.find(sup=>sup.text === e[ele]).text == e[ele])){
            //             tmp_arr.push(ele);
            //          }
            //      })
                    // tmp_arr.filter(x=>{
                    //     if(Object.keys(e).find(find_attr => find_attr == x) == x){
                    //         // console.log((typeof e[x] === 'string' ?e[x]:'')+"-"+(typeof e[x] === 'number'?typeof e[x]:typeof e[x]));
                            
                    //         if(typeof e[x] === 'string'){
                    //             tmp_obj[e[x]]
                    //             tmp_obj_arr.push(e[x]);
                    //         }
                    //         if(Object.keys(e).includes('__EMPTY')){
                    //             tmp_obj_arr.find(j=>{
                    //            if(typeof e[x] === 'number'){
                    //             tmp_obj[j] = e[x];
                    //         }
                    //         })
                    //         }
                           
                    //         console.log(e) 
                    //     }
                    // })
                    // console.log(tmp_arr) 
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'pc/s'){
                 e.__EMPTY_1 = 'PC'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'box/es'){
                 e.__EMPTY_1 = 'BOX'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'pad/s'){
                 e.__EMPTY_1 = 'PAD'
              }
              if(e.__EMPTY_1 && (e.__EMPTY_1.replace(" ","") == 'roll/s' || e.__EMPTY_1.replace(" ","") == 'rolls')){
                 e.__EMPTY_1 = 'ROL'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'ream/s'){
                 e.__EMPTY_1 = 'REA'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'bottle/s'){
                 e.__EMPTY_1 = 'BOT'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'pack/s'){
                 e.__EMPTY_1 = 'PACK'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'gallon/s'){
                 e.__EMPTY_1 = 'GAL'
              }
              if(e.__EMPTY_1 && e.__EMPTY_1.replace(" ","") == 'kg/s'){
                 e.__EMPTY_1 = 'KG'
              }
             let uom_value = that.GET_UOM_SELECTION.find(el=>el.text == e.__EMPTY_1);
             if(e.__EMPTY != 0)
             {
                if(e.__EMPTY){
                    that.form.batchAdditionItems.push({
                    code:e.__EMPTY,
                    name:e.__EMPTY,
                    qty:!!e.__EMPTY_2?e.__EMPTY_2:0,
                    uom: uom_value!=undefined?uom_value.value:0,
                    price:this.form.batchType?0:0
                 })
                }
               }
              
            }
          })
          that.loaded = true
          that.loading_text = 'Loading....Please Wait'
         }
        
        },2000)
        clearInterval();
        },
        saveFromEXCEL(){
          if(this.form.batchAdditionItems.find(e=>e.qty == 0) != undefined && this.form.batchAdditionItems.find(e=>e.qty == 0))
          {
            Swal.fire({
              html: `Zero quantity found`,
              icon: "warning",
              allowOutsideClick: false,
            });
          }else{
            this.$store.dispatch('saveFromEXCEL',{batch_file:this.form.batchAdditionItems});
          }
        
        },
        dragover(e) {
          e.preventDefault();
          this.isDragging = true;
        },
        dragleave() {
          this.isDragging = false;
        },
        drop(e) {
          e.preventDefault();
          this.$refs.file.files = e.dataTransfer.files;
          this.ImportedExcel();
          this.isDragging = false;
        },
        closeDialog(){
            this.$store.commit("DIALOG", false);
          this.form.batchAdditionItems = [];
      },
    },
    watch:{
        GET_IMPORT_FAS_PRICE:{
            handler(val){
      
                this.form.batchType = val
            }
        },
        GET_NEW_ADMIN_FASITEM:{
            handler(val){
                if(val){
                    this.closeDialog();
                    Swal.fire("Success!", "Item Saved", "success");
                    this.clearItems();
                    this.$store.commit("NEW_ADMIN_FASITEM", false);
               }
            }
        },
    }
    }
</script>