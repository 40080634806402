<template>
  <div class="w-100">
    <v-app id="my_vue_app">
      <v-card>
        <v-dialog v-model="SUB_DIALOG" width="800px" persistent>
          <AssetManagementViewEditComponent @closeDialog="closeDialog"></AssetManagementViewEditComponent>
        </v-dialog>
        <v-card-title>
            <v-container fluid>
                  <v-row class="ml-0 mb-4" v-if="this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist'">
                      <v-col cols="12">
                          <v-tabs v-model="summary_tab" color="cyan" slider-color="cyan" class="col-12 p-0" show-arrows>
                              <v-tab
                                  v-for="(item) in admin_category"
                                  :key="item.value"
                                  ripple
                                  @click="()=>{
                                      filter.item_category = item.value;
                                  }"
                              >
                                  {{ item.text }}
                              </v-tab>
                          </v-tabs>
                      </v-col>
                  </v-row>
                <v-row>
                  <v-col cols="2" class="pl-2">
                      <v-autocomplete
                          v-model="filter.employee_id"
                          :items="GET_EMPLOYEE_DATA"
                          item-text="text"
                          item-value="value"
                          label="Employee"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                      <v-autocomplete
                          v-model="filter.location_id"
                          :items="GET_ADMIN_LOCATION"
                          item-text="text"
                          item-value="value"
                          label="Location"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="2" class="pl-2">
                      <v-autocomplete
                          v-model="filter.warehouse"
                          :items="inventory_dropdown"
                          item-text="text"
                          item-value="value"
                          label="Inventory"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col> -->
                  <!-- <v-col cols="2">
                      <v-autocomplete
                          v-model="filter.item_category"
                          :items="GET_ADMIN_CATEGORY_SELECTION"
                          label="Item Category"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col> -->
                  <v-col cols="2">
                      <v-autocomplete
                          v-model="filter.classification_id"
                          :items="GET_ADMIN_CLASSIFICATION_SELECTION"
                          item-text="text"
                          item-value="value"
                          label="Classification"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                      <v-autocomplete
                          v-model="filter.sub_classification_id"
                          :items="subClass"
                          item-text="text"
                          item-value="value"
                          label="Sub Classification"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                      <v-autocomplete
                          v-model="filter.status"
                          :items="statusses_dropdown"
                          label="Status"
                          auto-select-first
                          clearable
                          dense
                          outlined
                          required
                      ></v-autocomplete>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="d-flex justify-end px-6">
                      <v-btn  @click="getAllAdminAssets()">FILTER</v-btn>
                  </v-col>
              </v-row>
              <v-row class="d-flex justify-end pa-6">
                  <v-col cols="3">
                      <v-row>
                        <v-col class="d-flex justify-end">
                            <v-card-subtitle>
                                <v-row>
                                    <v-col cols="1" class="d-flex align-items-center pr-4 pt-2">
                                        <span class="badge bg-dark ml-1 text-white" :class="{ 'blurred': filter.check_if_accounted != 0 }" style="cursor:pointer" @click="status('0')">All</span>
                                        <span class="badge bg-primary ml-1 text-white" :class="{ 'blurred': filter.check_if_accounted != 1 }" style="cursor:pointer" @click="status('1')">Accounted</span>
                                        <span class="badge bg-danger ml-1 text-white" :class="{ 'blurred': filter.check_if_accounted != 2 }" style="cursor:pointer" @click="status('2')">Unaccounted</span>
                                    </v-col>
                                </v-row>
                            </v-card-subtitle>
                        </v-col>
                      </v-row>
                  </v-col>
              </v-row>
              <v-row class="d-flex justify-end px-6">
                <v-col cols="3">
                    <v-row>
                      <v-col cols="11">
                          <v-text-field
                              id="searchBar"
                              v-model="filter.search"
                              label="ITEM NAME / ASSET TAG"
                              single-line
                              hide-details
                              @keypress.enter="searchInput()"
                              @change="searchInput()"
                          >
                          </v-text-field>
                      </v-col>
                      <v-col cols="1" class="pt-5">
                          <v-icon @click="searchInput()">mdi-magnify</v-icon>
                      </v-col>
                    </v-row>
                </v-col>
              </v-row>
            </v-container>
        </v-card-title>
        <v-container fluid>
          <v-data-table
            v-model="selected_items"
            :headers="headers"
            :items="items"
            :search="search"
            :options.sync="options"
            :server-items-length="totalItems"
            :items-per-page="10"
            :loading="!loaded"
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
              }"
            loading-text="Loading... Please wait"
            fixed-header
            disable-sort
            show-expand
            single-expand
            @item-expanded="getItemId"
            >
            <!-- :show-select="['assetslist-basket','assetslist'].includes(GET_ADMIN_TAB_IS_ACTIVE)" -->
          <template v-slot:[`item.image_path`]="{ item }">
            <!-- {{ item.image_path }} -->
            <v-img :src="ImagePath(item)" class="img-thumbnail" width="50" height="50" eager contain></v-img>
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            <span>
                &#x20b1;{{ item.cost | currency }}
            </span>
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
              <span v-if="item.emp_id == 0">GENERAL ACCOUNTABILITY</span>
              <span v-else>{{ item.full_name }}</span>
          </template>
          <template v-slot:[`item.brandnew`]="{ item }">
              <span v-if="!item.brandnew">0</span>
              <span v-else>{{item.brandnew}}</span>
          </template>
          <template v-slot:[`item.repaired`]="{ item }">
              <span v-if="!item.repaired">0</span>
              <span v-else>{{item.repaired}}</span>
          </template>
          <template v-slot:[`item.pending`]="{ item }">
              <span v-if="!item.pending">0</span>
              <span v-else>{{item.pending}}</span>
          </template>
            <template v-slot:[`item.required_serial`]="{ item }">
                <span class="text-nowrap">
                    <v-chip
                        :style="`background-color:${STATUS.PRINT_STATUS.find(e=>e.value === parseInt(item.required_serial))?.color}`"
                        class="badge text-white mt-2"
                    >
                        {{STATUS.PRINT_STATUS.find(e=>e.value === parseInt(item.required_serial))?.text}}
                    </v-chip>
                </span>
            </template>
          <template v-slot:[`item.printed`]="{ item }">
              <span class="text-nowrap">
                  <v-chip
                      :style="`background-color:${STATUS.PRINT_STATUS.find(e=>e.value === item.printed)?.color}`"
                      class="badge text-white mt-2"
                  >
                      {{STATUS.PRINT_STATUS.find(e=>e.value === item.printed)?.text}}
                  </v-chip>
              </span>
          </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange" v-if="userAccess.view && ['assetslist-basket'].includes(GET_ADMIN_TAB_IS_ACTIVE)">
                <v-icon class="btn-action" small @click="ViewEditAssets(item,'View')"
                  >mdi-eye</v-icon
                >
              </v-btn>
              <v-btn text icon color="blue" v-if="userAccess.edit && !['assetslist-basket', 'assetslist'].includes(GET_ADMIN_TAB_IS_ACTIVE)">
                <v-icon class="btn-action" small @click="ViewEditAssets(item,'Update')"
                  >mdi-square-edit-outline</v-icon
                >
              </v-btn>
              <v-btn text icon color="red" v-if="userAccess.delete && !['assetslist-basket', 'assetslist'].includes(GET_ADMIN_TAB_IS_ACTIVE)">
                <v-icon class="btn-action" small @click="deleteAssets(item.asset_id)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
                <template v-slot:expanded-item="{ item }">
                  <td :colspan="headers.length + 1" class="p-0" v-if="!!item.items">
                      <v-data-table
                          :headers="itemsHeader"
                          :items="item.items.data"
                          dark
                          dense
                          @update:options="itemSync"
                          :server-items-length="item.items.total"
                          :items-per-page="item_options.itemsPerPage"
                          :loading="!items_loaded"
                          fixed-header
                          disable-sort
                          :footer-props="{
                            itemsPerPageOptions: item_itemsPerPageOptions,
                          }"
                          item-key="id"
                          :show-select="showMultiSelect()"
                          v-model="selected"
                      >
                          <template v-slot:[`item.price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.price | currency }}
                            </span>
                          </template>
                          <template v-slot:[`item.description`]="{ item }">
                              <div class="paragraph">{{ item.description }}</div>
                          </template>
                          <template v-slot:[`item.item_serial_number`]="{ item }">
                              <span v-if="item.item_serial_number">{{ item.item_serial_number }}</span>
                              <span v-else>{{ "< No Serial Number >" }}</span>
                          </template>
                          <template v-slot:[`item.no_serial`]="{ item }">
                              <span class="text-nowrap">
                                  <v-chip
                                      :style="`background-color:${STATUS.PRINT_STATUS.find(e=>e.value === parseInt(item.no_serial))?.color}`"
                                      class="badge text-white mt-2"
                                  >
                                      {{STATUS.PRINT_STATUS.find(e=>e.value === parseInt(item.no_serial))?.text}}
                                  </v-chip>
                              </span>
                          </template>
                          <template v-slot:[`item.action`]="{ item }">
                              <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="ViewEditAssets(item,'Update')"
                                  >mdi-eye</v-icon
                                >
                              </v-btn>
                          </template>
                      </v-data-table>
                  </td>
                </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from "sweetalert2";
import { mapGetters } from 'vuex';
import AssetManagementViewEditComponent from '@/views/main/modules/Admin/AssetManagement/AssetManagementViewEditComponent.vue';
export default {
  mixins: [SharedFunctionsComponentVue],
  props:{
        refresh_table:{
            default:()=>false,
            type:Boolean
        }
    },
  data() {
    return {
      summary_tab: 0,
      selected_items:[],
      subClass:[],
      inventory_dropdown:[],
      admin_category:[],
      loaded: false,
      totalItems: 0,
      options: {},
      item_options: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
      },
      items_loaded: false,
      items: [],
      itemsPerPageOptions: [1,5, 10, 15, 50, 100],
      item_itemsPerPageOptions: [1,5, 10, 15, 50, 100],
      itemsPerPage: 10,
      search: "",
      url:process.env.VUE_APP_API_HOST,
      filter:{
        search:"",
        warehouse: undefined,
        item_category: '',
        classification_id: undefined,
        sub_classification_id: undefined,
        status: null,
        check_if_accounted: undefined,
        export: null,
        item_id: '',
        location_id: null,
        employee_id: null
      },
      // item_category:[
      //   {value: 1, text: 'High Value Assets'},
      //   {value: 3, text: 'Low Value Assets'}
      // ],
      awaitingSearch: false,
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      show:{
        form:{},
        dialog:false,
        action:false
      },
      noImage:"images/noimage.png",
      itemsHeader: [
          { text: 'P.O #',value: 'po_num', align: 'left'},
          { text: 'Asset Tag',value: 'asset_code', align: 'left'},
          { text: 'Description',value: 'description', align: 'center'},
          { text: 'Accounted To',value: 'employee', align: 'center'},
          { text: 'Location',value: 'admin_location', align: 'center'},
          { text: 'Serial Number',value: 'item_serial_number', align: 'center'},
          { text: 'Status',value: 'status_name', align: 'center'},
          { text: 'Price',value: 'price', align: 'right'},
          { text: 'Action',value: 'action', align: 'center'},
      ],
      statusses_dropdown: [],
      selected: [],
    };
  },
  computed:{
    ...mapGetters([
        'USER_ACCESS',
        'GET_NEW_ADMIN_ASSETS_TABLE',
        'GET_ADMIN_DISPATCH',
        'GET_ASSETS_DATA',
        'GET_ADMIN_TAB_IS_ACTIVE',
        'GET_ADM_WAREHOUSE_CODE_DROPDOWN',
        'GET_ADMIN_CATEGORY_SELECTION',
        'ACTION',
        'GET_ADMIN_CLASSIFICATION_SELECTION',
        'GET_STATUS_SELECTION',
        'GET_EXPORT_ASSETS',
        'SELECTED',
        'SUB_DIALOG',
        'STATUS',
        'GET_ADMIN_LOCATION',
        'GET_EMPLOYEE_DATA',
        'DIALOGS'
    ]),

    headers() {
      this.statusses_dropdown = this.GET_STATUS_SELECTION;
      let tmp = JSON.parse(JSON.stringify(this.GET_STATUS_SELECTION)).filter(e => e.department_id == 1).map((e) =>{
        e.value = e.text,
        e.align = 'center'
        return e;
      });
      let Action = () => {
        if (this.GET_ADMIN_TAB_IS_ACTIVE != 'accountability-high' && this.GET_ADMIN_TAB_IS_ACTIVE != 'accountability-low') {
          return [{
            text: "Action", align: "center", value: "action", sortable: false
          }];
        } else {
          return [{}];
        }
      };

      let header = [
        { text: 'Picture', align: 'left', value: 'image_path' },
        { text: "Item Name", align: "center", value: "item_name" },
        { text: "Required Serial", align: "center", value: "required_serial" },
        { text: "Classification", align: "center", value: "classification" },
        { text: "Sub Classification", align: "center", value: "classification_sub" },
        { text: "Count", align: "center", value: "total_count" },
        { text: "", align: "left", value: "" },
        // ...Action(),
      ];

      header = [...header,...tmp];

      if (this.GET_ADMIN_TAB_IS_ACTIVE == 'accountability-high' || this.GET_ADMIN_TAB_IS_ACTIVE == 'accountability-low') {
        let index = header.indexOf(header.find(e => e.length == 0));
        header.splice(index, 1);
        let count = 1;
        header[2] = { text: "Employee Name", align: "left", value: "full_name" };
        return header;
      }

      if (this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist' || this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket' || this.GET_ADMIN_TAB_IS_ACTIVE ==  'fixed-assets' || this.GET_ADMIN_TAB_IS_ACTIVE == 'low-value-assets') {
        let index = header.indexOf(header.find(e => e.text == ''));
        header.splice(index, 1);
        return header;
      }
    },
    subClassification() {
      if (this.filter.classification_id) return this.GET_ADMIN_CLASSIFICATION_SELECTION.find(e => e.text == val)?.sub_class;

      const array = [];
      this.GET_ADMIN_CLASSIFICATION_SELECTION.forEach(e => {
        array.push(...e.sub_class);
      });

      return array;
    },
  },
  mounted() {
    this.getSearchFromUrl();
    this.getAllAdminAssets();
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.$store.dispatch('getAdminClassificationSelection');
    this.$store.dispatch('getStatus')
  },
  components:{
    AssetManagementViewEditComponent
  },
  methods: {
    showMultiSelect(){
        if(this.GET_ADMIN_TAB_IS_ACTIVE == 'fixed-assets' || this.GET_ADMIN_TAB_IS_ACTIVE == 'low-value-assets'){
            return true
        }else{
            return false
        }
    },
    getItemId(item){
      this.items_loaded = false;
      this.items.forEach((e, index) => {
          e.items.total = 0;
      });
        if(item.item){
            this.filter.item_id = item.item.item_id;
            this.fetchAllData()
        }

        return {value:false};
    },
    status(status){
        this.items.forEach((e) => {
            if(e.items.length > 0){
              e.items = [];
            }
        });
        this.filter.check_if_accounted = status;
        this.filter.item_id = '';
        this.getAllAdminAssets();
    },
    itemSync(newPage){
        this.items.forEach((e, index) => {
            e.items.data = [];
        });
        this.items_loaded = false;
        this.item_options.page = newPage.page;
        if(newPage.itemsPerPage == -1){
            this.item_options.itemsPerPage = this.item_options.totalItems;
        } else {
            this.item_options.itemsPerPage = newPage.itemsPerPage;
        }
    
        if(this.filter.item_id){
            this.fetchAllData();
        }
    },

    async fetchAllData(){
        let payload = {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
            search: this.filter.search,
            item_itemsPerPage: this.item_options.itemsPerPage,
            item_page: this.item_options.page,
            sortBy: this.options.sortBy,
            sortDesc: this.options.sortDesc,
            warehouse: this.filter.warehouse,
            accountability:this.GET_ADMIN_TAB_IS_ACTIVE,
            filter: this.filter,
            not_printed:this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket' ? 1 : null,
            has_accountability:this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist',
            url:'assetBasket',
            module: this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket' ? 'assetslist-basket' : 'assets',
            admin_category:  this.GET_ADMIN_TAB_IS_ACTIVE ? this.GET_ADMIN_TAB_IS_ACTIVE : null
        };

        await this.$store.dispatch('adminAssetManagementGet', payload).then((response) => {
              let data = response.data.data;
              if (this.GET_ADMIN_TAB_IS_ACTIVE == 'accountability-high') {
                this.items = data.filter(item => item.cost >= 5000);
              } else if (this.GET_ADMIN_TAB_IS_ACTIVE == 'accountability-low') {
                this.items = data.filter(item => item.cost < 5000);
              } else {
                this.items = data;
              }
              let allItems = [];
              allItems = this.items;
              let check_if_empty = false;
              allItems.forEach((e) => {
                if(e.items.data){
                    check_if_empty = true;
                }
              })
              if(this.items.length > 0){
                this.loaded = true;
              }
              if(check_if_empty){
                this.items_loaded = true;
              }
              this.totalItems = response.data.total;
              if(this.GET_EXPORT_ASSETS){
                  this.exportAssets();
                  this.$store.commit('EXPORT_ASSETS', false);
              } else {
                  this.$store.commit('EXPORT_ASSETS', false);
              }
              this.$store.commit("NEW_ADMIN_ASSETS_TABLE", false);
          }).catch((e)=>{
                this.items_loaded = true;
                this.loaded = true;
          });
    },
    getAllAdminAssets(filter = null) {
      this.items = [];
      this.loaded = false;
      this.fetchAllData();
    },
    searchInput(){
        this.getAllAdminAssets({ query: this.search });
    },
   async ViewEditAssets(item,action) {
            if(this.GET_ADMIN_TAB_IS_ACTIVE == 'assetslist-basket'){
              this.$store.commit('SELECTED',item.items);
            } else {
              this.$store.commit('SELECTED',[item]);
            }
            this.$store.commit('ACTION',action);
            this.$store.commit('SUB_DIALOG',true);
    },
    deleteAssets(id) {
      swal
        .fire({
          icon: 'question',
          text: "Are you sure you want to Delete?",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "#397373",
          reverseButtons: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(this.GET_ADMIN_DISPATCH.delete, id)
              .then((success) => {});
          }
        });
    },
    getSearchFromUrl() {
        if (Object.keys(this.$route.query).length != 0) {
            Object.keys(this.$route.query).forEach(e=>{
            this.filter.search = atob(this.$route.query[e]);
            })
            this.searchInput();
        }
    },
    ImagePath(item){
      let splited = item.image_path?item.image_path.split("/"):""
      if(splited == '' || splited[3] == ''){
        return this.url+'/'+this.noImage
      }else{
        return this.url+'/'+item.image_path
      }
    },
    exportAssets() {
        // let allItems = [];  
        let summary = this.items;
        let detailed = summary.flatMap(e => e.items.data);
        const exportSheets = {
            'SUMMARY': {
                headers: [
                    { header: "Item Name", align: "center", key: "item_name" },
                    { header: "Classification", align: "center", key: "classification" },
                    { header: "Sub Classification", align: "center", key: "classification_sub" },
                    { header: "Required Serial", align: "center", key: "required_serial" },
                    { header: "Count", align: "center", key: "total_count" },
                    { header: "Pending", align: "center", key: "Pending",formatAs: 'int'},
                    { header: "Repaired", align: "center", key: "Repaired",formatAs: 'int'},
                    { header: "Brand New", align: "center", key: "Brand New",formatAs: 'int'},
                ],
                data: summary.map((e) => {
                  e.required_serial = this.STATUS.PRINT_STATUS.find(d=>d.value === parseInt(e.required_serial))?.text
                  if(!e.brandnew){
                    e.brandnew = 0;
                  }
                  if(!e.pending){
                    e.pending = 0;
                  }
                  if(!e.repaired){
                    e.repaired = 0;
                  }
                  return e;
                }),
            },
            'DETAILED': {
                headers: [
                    { header: 'Item Name',key: 'name', align: 'left'},
                    { header: "Classification", align: "center", key: "classification" },
                    { header: "Sub Classification", align: "center", key: "classification_sub" },
                    { header: 'Asset Tag',key: 'asset_code', align: 'left'},
                    { header: 'Description',key: 'description', align: 'center'},
                    { header: 'Accounted To',key: 'employee', align: 'center'},
                    { header: 'Serial Number',key: 'item_serial_number', align: 'center'},
                    { header: 'No Serial',key: 'no_serial', align: 'center'},
                    { header: 'Status',key: 'status_name', align: 'center'},
                ],
                data: detailed.map((e) => {
                  e.no_serial = this.STATUS.PRINT_STATUS.find(d=>d.value === parseInt(e.no_serial))?.text
                  return e;
                }),
            },
        };
        this.exportExcel(exportSheets, `ADMIN ASSETS`,'F69F1A');
    },
    closeDialog(val){
        this.show = {
          form:{},
          dialog:false,
          action:false
        }
        this.getAllAdminAssets()
        this.show = val

    }
  },
  watch: {
    options: {
      handler(val) {
        if(val){
          this.getAllAdminAssets();
        }
      },
      deep: true,
    },
    GET_NEW_ADMIN_ASSETS_TABLE:{
        handler(val){
            if(val){
              this.getAllAdminAssets();
            }
        }
    },
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.edit = false;
          this.userAccess.delete = false;
          this.userAccess.view = false;
          this.userAccess.create = false;
          val.forEach((e) => {
            if (e.actions_code == "edit") {
              this.userAccess.edit = true;
            } else if (e.actions_code == "delete") {
              this.userAccess.delete = true;
            } else if (e.actions_code == "view") {
              this.userAccess.view = true;
            } else if (e.actions_code == "create") {
              this.userAccess.create = true;
            }
          });
        }
      },
    },
    GET_ADM_WAREHOUSE_CODE_DROPDOWN:{
        handler(val){
          // console.log(val)
            this.inventory_dropdown = val
        },
        immediate: true
    },
    GET_ADMIN_CATEGORY_SELECTION:{
        handler(val){
          // console.log(val)
            this.admin_category = val.filter(e => e.consumable != 1);
            this.admin_category.unshift({text: 'ALL', value: 0});
        },
        immediate: true
    },
    
    // 'filter.warehouse': {
    //   handler() {
    //     this.searchInput();
    //   },
    // },
    'filter.item_category': {
      handler() {
        this.searchInput();
      },
    },
    // 'filter.classification_id': {
    //         handler(val) {
    //             if (val) {
    //                 this.GET_ADMIN_CLASSIFICATION_SELECTION.filter((e) => {
    //                     if (e.value == val) {
    //                         this.subClass = e.sub_class;
    //                     }

    //                     if (!this.subClass.some(e => e.value == this.filter.sub_classification_id))
    //                       this.filter.sub_classification_id = undefined;
    //                 });
    //                 this.searchInput();
    //                 return;
    //             }

    //             this.subClass = [];
    //             this.GET_ADMIN_CLASSIFICATION_SELECTION.forEach(e => {
    //               this.subClass.push(...e.sub_class);
    //             });
    //             this.searchInput();
    //         },
    //     },
    'GET_ADMIN_CLASSIFICATION_SELECTION': {
            handler() {
                this.subClass = [];
                this.GET_ADMIN_CLASSIFICATION_SELECTION.forEach(e => {
                  this.subClass.push(...e.sub_class);
                });
            },
        },
    // 'filter.sub_classification_id': {
    //   handler() {
    //     this.searchInput();
    //   },
    // },
    // 'filter.status': {
    //   handler(val) {
    //     console.log(val)
    //   },
    // },
    'GET_EXPORT_ASSETS': {
      async handler(val) {
        if (!val) return;
        this.filter.export = 1;
        await this.getAllAdminAssets();
        this.filter.export = 0;
        // this.$store.commit('EXPORT_ASSETS', false);
      },
    },
    refresh_table:{
        handler(val){
            if(val)
                this.selected_items = []
                this.getAllAdminAssets()
        }
    },
    selected_items:{
        handler(val){
            this.$store.commit('SELECTED',val)
        }
    },
    selected:{
      handler(val){
        let array = [];
        val.find(e=>{
            array.push(
                {
                    asset_code:e.asset_code,
                    item_id:e.item_id,
                }
            )
        })
        this.$store.commit('ADMIN_ITEMS_COLLECTIONS',array);
      }
    },
    DIALOGS:{
        handler(val){
          if(!val){
            this.selected = [];
            // console.log('heto ako ngayon',val);
          }
        }
    }
  },
};

</script>
<style scoped>
.paragraph {
    white-space: pre !important;
}
</style>

<style>
.blurred {
  opacity: 0.5;
}
</style>
