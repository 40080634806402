<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card v-if="actions != 'Submit'">
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                <span class="text-h5">Department Schedules</span>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-col cols="12" sm="3" class="pa-0 pr-1">
                            <v-text-field
                                v-model="form.name"
                                label="Department Name"
                                readonly
                                outlined
                                required
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" class="pa-0 pr-1">

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-container fluid class="pa-0 ma-0">
                            <v-card class="pa-0">
                                <v-card-title class="pr-0 pt-1 pr-1 pb-0 border-bottom"  v-if="!disabled_view">
                                    <v-row class="m-0">
                                        <v-col cols="2" class="mt-2 pa-0">
                                            <h6>Template List</h6>
                                        </v-col>
                                        <v-spacer/>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="search"
                                                label="Search Template Name"
                                                outlined
                                                required
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!-- <v-col cols="2" class="pa-0">
                                            <v-checkbox
                                                v-model="filters.no_schedule_yet"
                                                :true-value="1"
                                                :false-value="0"
                                                label="No Schedule Yet"
                                                dense
                                            >
                                            </v-checkbox>
                                        </v-col> -->
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        :headers="datatable.deptheaders"
                                        :items="datatable.items"
                                        :loading="datatable.loading"
                                        :items-per-page="-1"
                                        :footer-props="{
										    itemsPerPageOptions: datatable.itemsPerPageOptions
                                        }"
                                        :search="search"
                                        disable-sort
                                    >
                                    <template v-slot:header.action="{header}">
                                        <v-col>
                                            Action
                                            <v-btn
                                                v-if="!disabled_view"
                                                @click="addTemplate()"
                                                style="height: 20px; width: 20px"
                                                class="ml-3"
                                                color="primary"
                                                small
                                                dark
                                                fab
                                            >
                                                <v-icon dark small style="font-size: 12px"
                                                >mdi-plus</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </template>
                                    <template v-slot:[`item.action`]="{item, index}">
                                        <span>
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{attrs, on}">
                                                    <v-btn
                                                        @click="viewSchedule(item)"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="#f69f1a"
                                                        small
                                                        text
                                                        icon
                                                    >
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>View</span>
                                            </v-tooltip>
                                        </span>
                                        <span v-if="!disabled_view">
                                            <span v-if="item.editActive == false">
                                                <v-tooltip bottom color="green">
                                                    <template v-slot:activator="{attrs, on}">
                                                    <v-btn
                                                            @click="item.editActive = true"
                                                            color="green"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            small
                                                            text
                                                            icon
                                                        >
                                                            <v-icon>mdi-clock-edit-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Edit</span>
                                                </v-tooltip>
                                            </span>
                                            <span v-else>
                                                <v-tooltip bottom color="blue">
                                                    <template v-slot:activator="{attrs, on}">
                                                        <v-btn
                                                            @click="checkFields(item)"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="blue"
                                                            small
                                                            text
                                                            icon
                                                            >
                                                            <v-icon>mdi-check-bold</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Save</span>
                                                </v-tooltip>
                                            </span>
                                            <span>
                                                <v-tooltip bottom color="red">
                                                    <template v-slot:activator="{attrs, on}">
                                                        <v-btn
                                                            @click="removeSchedule(index, item)"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="red"
                                                            small
                                                            text
                                                            icon
                                                        >
                                                            <v-icon>mdi-minus-circle</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Remove Schedule</span>
                                                </v-tooltip>
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.position_name`]="{item, index}">
                                        <span v-if="item.editActive == true">
                                            <v-autocomplete
                                                v-model="item.job_title_id"
                                                :items="dropdowns.position_lists"
                                                :disabled="item.by_position == 0 ? true : false"
                                                item-disabled="isDisabled"
                                                label="Position"
                                                item-value="value"
                                                item-text="text"
                                                auto-select-first
                                                outlined
                                                required
                                                dense
                                            ></v-autocomplete>
                                        </span>
                                        <span v-else>
                                            {{ getPositionName(item) }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.template_name`]="{item, index}">
                                        <span v-if="item.editActive == true">
                                            <v-autocomplete
                                                v-model="item.schedule_template_id"
                                                :items="dropdowns.schedule_template"
                                                :readonly="disabled_view"
                                                :rules="errors.required"
                                                @change="setTemplateData(item, index)"
                                                label="Schedule Template"
                                                item-value="value"
                                                item-text="text"
                                                auto-select-first
                                                outlined
                                                required
                                                dense
                                            ></v-autocomplete>
                                        </span>
                                        <span v-else>
                                            {{ item.template_name }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.schedule_type_name`]="{item, index}">
                                        <span v-if="item.editActive == true">
                                                <v-text-field
                                                    v-model="item.schedule_type_name"
                                                    outlined
                                                    readonly
                                                    background-color="grey lighten-2"
                                                    dense
                                                >
                                                </v-text-field>
                                        </span>
                                        <span v-else>
                                            {{ item.schedule_type_name }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.by_position`]="{item, index}">
                                        <span>
                                            <v-checkbox
                                                v-model="item.by_position"
                                                :true-value="1"
                                                :false-value="0"
                                                :disabled="!item.editActive"
                                                dense
                                            >
                                            </v-checkbox>
                                        </span>
                                    </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions
                class="text-center"
                style="border-top: 1px solid #dee2e6"
            >
                <v-row class="ma-1 pb-2">
                    <v-col cols="12" v-if="actions != 'View'">
                        <v-btn
                            @click="actions == 'Submit'? addScheduleTemplate() : updateDeptScheduleTemplate()"
                            :disabled="disabled_view"
                            :loading="loading.button"
                        >
                            <span>{{ actions }}</span>
                        </v-btn>
                        <span class="pr-12">&nbsp;</span>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="schedule_details.dialog" persistent width="50%">
            <v-card>
                <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
                    <span class="text-h5">Schedule Details</span>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                small
                                color="gray"
                                class="float-right"
                                @click="triggerScheduleDialog(false)"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                <v-container class="pb-0 my-2" fluid>
                    <v-row>
                        <v-container fluid class="pa-0 ma-0">
                            <v-card class="pa-0">
                                <v-card-text>
                                    <v-data-table
                                        :headers="schedule_details.datatable.headers"
                                        :items="schedule_details.datatable.items"
                                        :items-per-page="7"
                                        :footer-props="{
										    itemsPerPageOptions: schedule_details.datatable.itemsPerPageOptions
                                        }"
                                        disable-sort
                                    >
                                    <template v-slot:[`item.isRestDay`]="{item}">
                                        <span>{{ item.isRestDay == 1 ? 'Yes' : 'No' }}</span>
                                    </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-container>
                    </v-row>
                </v-container>
            </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            valid: false,
            overlay: false,
            disabled_view: false,
            errors: {
                required: [(v) => !!v || "Field is required"],
                number: [
                    (v) => !!v || "Field is required",
                    (v) => !isNaN(v) || "Input must be a number",
                ],
            },
            form: {},
            dropdowns: {
                schedule_type: [],
                days:[],
                employee_lists: [],
                schedule_template: [],
                department_lists: [],
                position_lists: [],
                level_lists: [],
                store_branches: [],
                employee_ids: []
            },
            component_dispatches:[
		        'getEmployeeScheduleType',
                'getEmployeeScheduleTemplates',
                'getDepartment',
                'getEmployeeJobTitleSelection',
                'getJobLevelSelection',
                'getStoreBranchesSelectionSelection'
			],
            loading:{
                positions:false,
                department: false,
                button: false,
                employee_lists: false
            },
            selected_days: [],
            datatable:{
                items:[],
                deptheaders:[
                    {text: 'Position Name', value: 'position_name'},
                    {text: 'Template Name', value: 'template_name'},
                    { text: 'Schedule Type', align: 'start', value: 'schedule_type_name'},
                    // { text: 'Monday', align: 'start',value:'Monday' },
                    // { text: 'Tuesday', align: 'start',value:'Tuesday' },
                    // { text: 'Wednesday', align: 'start',value:'Wednesday' },
                    // { text: 'Thursday', align: 'start', value: 'Thursday'},
                    // { text: 'Friday', align: 'start', value: 'Friday'},
                    // { text: 'Saturday', align: 'start', value: 'Saturday'},
                    // { text: 'Sunday', align: 'start', value: 'Sunday'},
                    { text: 'By Position', align: 'start', value: 'by_position'},
                    {text: 'Action', value: 'action', align: 'center', width: '12%'},
                ],
                loading: false,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
            },
            filters:{
                position_ids: [],
                department_ids: [],
                job_level_ids: [],
                store_branch_ids: [],
                employee_list: [],
                search: '',
            },
            defaults: {
                position_ids: [],
                department_ids: [],
                job_level_ids: [],
                store_branch_ids: [],
                employee_list: [],
                search: '',
            },
            tmp: true,
            hr_user_department_access: [],
            search: '',
            includedDates: [],
            filter_count: [],
            schedule_details: {
                dialog: false,
                datatable:{
                    headers: [
                        { text: 'Day', align: 'start',value: 'day' },
                        { text: 'Time In', align: 'start',value: 'time_in' },
                        { text: 'Time Out', align: 'start',value: 'time_out' },
                        { text: 'Rest Day', align: 'start',value: 'isRestDay' },
                    ],
                    items: [],
                    itemsPerPageOptions: [5, 10, 15, 50, 100],
                }
            }
        };
    },
    computed: {
        ...mapGetters([
            "ACTION",
            "GET_HR_DIALOG_TITLE",
            'GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION',
            "DIALOGS",
            "GET_HR_DISPATCH",
            "GET_HR_OTHER_TABS_DIALOG_VIEW",
            "GET_EMPLOYEE_DATA",
            "GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION",
            "GET_LIST_DEPARTMENT",
            "GET_EMPLOYEE_SCHEDULE",
            'GET_JOB_TITLE_SELECTION',
            'GET_JOB_LEVEL_SELECTION',
            'GET_HR_USER_DEPARTMENTS',
            'GET_STORE_BRANCHES_SELECTION'
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                this.disabled_view = false;
                this.isViewBtn = false;
                // let filtered_departments = this.dropdowns.department_lists.filter(x=> this.GET_HR_USER_DEPARTMENTS.includes(x.value))
                // this.dropdowns.department_lists = filtered_departments
                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disabled_view = false;
                this.isViewBtn = true;
                this.getDepartmentScheduleData();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disabled_view = true;
                this.isViewBtn = true;
                this.getDepartmentScheduleData();
                return "View";
            }
        },
        dateRangeText () {
            return this.form.effectivity_date.join(' ~ ')
        },
    },
    created() {
        this.initialForm();
    },
    mounted() {
        this.getAllDropdowns();

    },
    methods: {
        getAllDropdowns(){
            this.loading.positions = true
            this.loading.department = true
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
            })
            this.resetDaysSelection();
        },
        initialForm() {
            this.form = {
                by_position: 0,
                trash_ids: []
            };
        },
        height(width) {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "100%";
                case "sm":
                    return "100%";
                case "md":
                    return width;
                case "lg":
                    return width;z
                case "xl":
                    return width;
            }
        },
        closeDialog() {
            this.$store.commit("DIALOG", false);
            this.initialForm();
            this.$store.commit('HR_OTHER_TABS_DIALOG_VIEW', {})
        },
        updateDeptScheduleTemplate() {
            this.$refs.form.validate();

            let allSaved = this.datatable.items.every((x) => x.editActive == false);

            if(!allSaved){
                Swal.fire(
                    "Please save all schedule!",
                    "",
                    "warning"
                )
                return false
            }

            const schedule_template_swal_text 			    = this.scheduleTableChecker() ? '' : 'Schedule Template';
            const array_text =
            [
                schedule_template_swal_text,
            ]
            const filtered = array_text.filter(function (el) {
                return el.replace(',', '');
            })
            if (!this.scheduleTableChecker() ) {
                this.form.work_schedule_items = this.datatable.items;
                this.$store.dispatch(this.GET_HR_DISPATCH.update, this.form).then((response) => {
                    this.loading.button = false;
                    Swal.fire(response.icon.toUpperCase(),response.message,response.icon);
                    this.closeDialog();
                }).catch((err)=>{
                    Swal.fire({
                        text: 'Network Error',
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                    console.log(err);
                    this.loading.button = false;
                });
            }
            else {
                Swal.fire({
                    text: `Fill Required Fields (${filtered})`,
                    icon: 'warning',
                    allowOutsideClick: false,
                });
                this.loading.button = false;
            }
        },
        addTemplate(){
            this.datatable.items.push(
                {
                    editActive: true,
                    schedule_template_id: 0,
                    job_title_id: 0,
                    by_position: 0,
                }
            )
        },
        deleteWorkDays(index,item_id = -1){
            this.form.workDays.splice(index,1);
            this.form.employee_schedule_trash_ids.push(item_id)
        },
        resetDaysSelection(){
            this.dropdowns.days = this.$dayjs.weekdays(true).map(day => ({ name: day, isDisabled: false }));
        },
        getDepartmentScheduleData(){
            this.form.id                        = this.GET_HR_OTHER_TABS_DIALOG_VIEW.id;
            this.form.name                      = this.GET_HR_OTHER_TABS_DIALOG_VIEW.name;
            this.datatable.items                = this.GET_HR_OTHER_TABS_DIALOG_VIEW.work_schedule_items;

            this.dropdowns.position_lists       = this.GET_JOB_TITLE_SELECTION.filter(x => x.department_id == this.form.id);
        },
        setTemplateData(item, index) {
            let filtered_template = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION.find((e) => e.id == item.schedule_template_id);
            if(filtered_template){
                let template = {}
                Object.assign(template,{
                    id: item.id ?? 0,
                    editActive: true,
                    template_name: filtered_template.text,
                    schedule_type_name: filtered_template.schedule_type_name,
                    position_name: item.position_name,
                    job_title_id: item.job_title_id,
                    schedule_template_id: item.schedule_template_id,
                    by_position: item.by_position,
                })
                this.datatable.items.splice(index, 1, template)
            }
        },
        getPositionName(item){
            let positionName = this.GET_JOB_TITLE_SELECTION.find((x) => x.value == item.job_title_id);
            if(positionName){
                return positionName.text
            }
        },
        resetFilters(){
            Object.assign(this.filters, {
                position_ids: this.defaults.position_ids,
                department_ids: this.defaults.department_ids,
                job_level_ids: this.defaults.job_level_ids,
                store_branch_ids: this.defaults.store_branch_ids,
                employee_list: this.defaults.employee_list,
                search: this.defaults.search,
            })
        },
        removeSchedule(i, item){
            if(this.datatable.items.length != 1){
                Swal.fire({
                    text: `Are you sure you want to remove?`,
                    icon: 'question',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async(result) => {
                    if(result.isConfirmed) {
                        this.datatable.items.splice(i,1)
                        this.form.trash_ids.push(item.id);
                    }
                })
            }
        },
        scheduleTableChecker(){
            if(
                this.datatable.items.length == 0 &&
                this.datatable.items.every((e) => e.schedule_template_id)
                ){
                    return true
            }
            return false
        },
        checkFields(item){
            if(item.by_position == 1){
                if(item.job_title_id == 0 || !item.job_title_id){
                    Swal.fire(
                        "Please select a position!",
                        "",
                        "warning"
                    )
                    return false
                }else{
                    this.$set(item, 'editActive', false);
                }
            }else{
                if(item.job_title_id){
                    Swal.fire(
                        "Position field is only for record that is categorized by position!",
                        "Position selected will be removed.",
                        "info"
                    ).then((res)=>{
                        if(res.isConfirmed){
                            this.$set(item, 'job_title_id', 0);
                        }
                    })
                }
                if(item.schedule_template_id == 0 || !item.schedule_template_id){
                    Swal.fire(
                        "Please select a schedule template!",
                        "",
                        "warning"
                    )
                    return false
                }else{
                    this.$set(item, 'editActive', false);
                }
                this.$set(item, 'editActive', false);
            }
        },
        viewSchedule(item){
            let scheduleDetails = this.GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION.find((x) => x.value == item.schedule_template_id);
            if(!scheduleDetails){
                Swal.fire(
                    "Please select a schedule template first to view!",
                    "",
                    "warning"
                )
                return false;
            }
            this.triggerScheduleDialog(true)
            let details = scheduleDetails.work_schedule_template_details;
            this.schedule_details.datatable.items = scheduleDetails.work_schedule_template_details;
        },
        triggerScheduleDialog(value){
            this.schedule_details.dialog = value;
        },
        resetScheduleTemplateSelection(){
            this.dropdowns.schedule_template.forEach(el=>{
                el.isDisabled = false
            })
        }
    },
    watch: {
        DIALOGS: {
            async handler(val) {
                if (val && this.ACTION == 'Submit') {
                    this.initialForm();
                    this.resetFilters();
                }
                else if (val && this.ACTION != 'Submit'){
                    this.getDepartmentScheduleData();
                    this.tmp = true;
                    if(this.datatable.items.length == 0 && this.ACTION == 'Update'){
                        this.addTemplate();
                    }
                }
            },
        },
        GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_type = val;
            }
        },
        GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION:{
            handler(val){
                this.dropdowns.schedule_template = val;
            }
        },
        "datatable.items":{
            handler(val){
                if(val){
                    this.resetScheduleTemplateSelection();
                    let schedule_ids = []
                    schedule_ids = val.map((e)=> e.schedule_template_id);

                    this.dropdowns.schedule_template.forEach((x)=>{
                        if(schedule_ids.includes(x.value)){
                            x.isDisabled = true;

                        }
                    })
                }
            },
            deep: true
        },
        // GET_EMPLOYEE_DATA:{
        //     handler(val){
        //         if(val){
        //             this.datatable.loading = false;
        //             this.datatable.items = val;
        //         }
        //     }
        // },
        GET_LIST_DEPARTMENT:{
            handler(val){
                this.dropdowns.department_lists = val
            }
        },
        GET_JOB_TITLE_SELECTION:{
            handler(val){
                if(val){
                    this.dropdowns.position_lists = val
                }
            }
        },
        GET_JOB_LEVEL_SELECTION:{
            handler(val){
                if(val){
                    this.dropdowns.level_lists = val
                }
            }
        },
        GET_STORE_BRANCHES_SELECTION:{
            handler(val){
                if(val){
                    this.dropdowns.store_branches = val
                }
            }
        },
        filters: {
            handler(val) {
                this.filter_count = [];
                let filtered = this.GET_EMPLOYEE_DATA;
                this.datatable.items = this.GET_EMPLOYEE_DATA;


                // Filter By Level
                if (val.job_level_ids.length > 0) {
                    filtered = filtered.filter((e) => {
                        return val.job_level_ids.includes(e.job_level_id);
                    });
                    this.filter_count.push('level');
                }

                // Search Filter
                if (val.search) {
                    filtered = filtered.filter(item => item.name.toLowerCase().indexOf(val.search) > -1);
                }

                this.datatable.items = filtered;
            },
            deep: true
        },
        GET_HR_USER_DEPARTMENTS:{
            handler(val){
                // Filter Department Selection Based on HR User Departments
                this.hr_user_department_access = val;
                let filtered_departments = this.dropdowns.department_lists.filter(x=> this.hr_user_department_access.includes(x.value))
                this.dropdowns.department_lists = filtered_departments
            }
        }
    },
};
</script>
<style scoped>
    .modal_title {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
    }
</style>
