<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="2" class="pa-0">
                                <v-switch class="pa-0" label="Employee List View" true-value="1" false-value="0" v-model="toggles.isEmployeeList"></v-switch>
                            </v-col>
                            <v-col cols="2" class="pa-0"  v-if="toggles.isEmployeeList == 1">
                                <v-switch class="pa-0" label="With Corporate Email" true-value="1" false-value="0" v-model="toggles.withMail"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    v-model="filter.search"
                                    @change="searchInput()"
                                    clearable
                                    id="searchBar"
                                    label="Search"
                                    single-line
                                    hide-details
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="tableHeader"
                        :items="datatable.items"
                        :search="filter.search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="toggles.isEmployeeList == 0 ? viewWebMailData(item) : viewEmployeeEmails(item)"
                                    >mdi-eye</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="toggles.isEmployeeList == 0 ? editWebMailData(item) : editEmployeeEmails(item)"
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete && toggles.isEmployeeList == 0 ">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deleteWebMailData(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn>
                        </template>
                        <template v-slot:[`item.suspended_login`]="{item}">
                            <v-icon :color="`${item.suspended_login == 0 ? 'green':'red'}`">{{ item.suspended_login == 0 ? 'mdi-check-bold': 'mdi-minus-circle-outline' }}</v-icon>
                        </template>
                        <template v-slot:[`item.suspended_incoming`]="{item}">
                            <v-icon :color="`${item.suspended_incoming == 0 ? 'green':'red'}`">{{ item.suspended_incoming == 0 ? 'mdi-check-bold': 'mdi-minus-circle-outline' }}</v-icon>
                        </template>
                        <template v-slot:[`item.with_corporate_email`]="{item}">
                            <span :class="`badge badge-${item.with_corporate_email ? 'yes' :'no'}`">{{ item.with_corporate_email ? 'YES' :'NO' }}</span>
                        </template>
                    </v-data-table>
                </v-container>
                <EmployeeEmailListViewVue></EmployeeEmailListViewVue>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';
import EmployeeEmailListViewVue from '@/views/main/layouts/dialogs/WebMail/EmployeeEmailListView.vue';
export default {
    data(){
        return {
            loading:{
                datatable:false
            },
            search: "",
            filter:{
                search:"",
                department_id:'',
                job_title_id:'',
                job_level_id:'',
            },

            datatable:{

                cpanel:{
                    headers:[
                        { text: 'Account@Domain', align: 'start',value:'email' },
                        { text: 'Logging In', align: 'center', value: 'suspended_login'},
                        { text: 'Receiving Incoming Mail', align: 'center', value: 'suspended_incoming'},
                        { text: "Action", align: "center", value: "action" },
                    ],
                },
                employee:{
                    headers:[
                        { text: 'Name', align: 'left',value:'full_name' },
                        { text: 'Department', align: 'left',value:'department_name' },
                        { text: 'Position', align: 'left',value:'position_name' },
                        { text: 'With Corporate Email', align: 'center',value:'with_corporate_email' },
                        { text: "Action", align: "center", value: "action" },
                    ]
                },

                items:[],
                options:{},
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
                totalItems: 0,
            },

            toggles:{
                isEmployeeList:0,
                withMail:0
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },

            component_dispatches:[
                "credentials",
			] ,
        }
    },
    created(){
        this.$store.commit('IS_EMPLOYEE_LIST',this.toggles.isEmployeeList)
    },
    async mounted(){
        this.getAllDropdowns()
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_WEBMAIL_NEW_DATA_TRIGGER',
            'DIALOGS',
            'USERACCOUNT_LOAD',
            'GET_WEBMAIL_TAB_IS_ACTIVE',
            'GET_WEBMAIL_DISPATCH'
        ]),
        tableHeader(){
            if(this.toggles.isEmployeeList == 1){
                return this.datatable.employee.headers
            }
            else if(this.toggles.isEmployeeList == 0){
                return this.datatable.cpanel.headers
            }
        }
    },
    components:{
        EmployeeEmailListViewVue
    },
    methods:{
        getAllDropdowns(){
            this.component_dispatches.forEach(el=>{
                this.$store.dispatch(el)
			})
		},
        searchInput(){
            if(this.toggles.isEmployeeList == 1){
                this.getAllEmployeeData({ query: this.search })
            }
            else{
                this.getAllEmailList({ query: this.search });
            }
        },
        // Web Mail Functions -----------------------------------------------------------------------------------------------------------------------------------------
        async getAllEmailList(filter = null){
            this.items = [];
            this.loading.datatable = true;
            let payload = {
                page                        : this.datatable.options.page,
                itemsPerPage                : this.datatable.options.itemsPerPage,
                search                      : this.filter.search,
                filter_department_id        : this.filter.department_id,
                filter_job_title_id         : this.filter.job_title_id,
                filter_job_level_id         : this.filter.job_level_id,
                sortBy                      : this.datatable.options.sortBy,
                sortDesc                    : this.datatable.options.sortDesc,
                user_id                     : this.USERACCOUNT_LOAD.id,
                contract_type               : this.selected_contract,
                filter                      : filter,
                department_id               : this.USERACCOUNT_LOAD.department_id,
            };
            await this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.get, payload).then((response) => {
                this.datatable.items = response;
                this.loading.datatable = false;

                this.datatable.totalItems = response.total;
            });
        },
        viewWebMailData(item){
            let items = {}
            this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.show,item.email).then(response =>{
                items = response[0]
                this.$store.commit("WEBMAIL_VIEW", items);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            })
        },
        editWebMailData(item){
            let items = {}
            this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.show,item.email).then(response =>{
                items = response[0]
                this.$store.commit("WEBMAIL_VIEW", items);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "Update");
            })
        },
        deleteWebMailData(item){
            Swal.fire({
			icon: 'question',
			text: "Are you sure you want to Delete?",
			cancelButtonText: "Cancel",
			showCancelButton: true,
			allowOutsideClick: false,
			confirmButtonColor: "#397373",
			cancelButtonColor: "#397373",
			})
			.then((result) => {
			if (result.isConfirmed) {
                    this.$store.dispatch(this.GET_WEBMAIL_DISPATCH.delete,item.email).then(response =>{
                        Swal.fire(response.data.icon.toUpperCase(),response.data.message,response.data.icon);
                        this.getAllEmailList();
                    })
			    }
			});
        },
        // Employee Data Email Functions ------------------------------------------------------------------------------------------------------------------------
        async getAllEmployeeData(filter = null){
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page                : this.datatable.options.page,
                itemsPerPage        : this.datatable.options.itemsPerPage,
                search              : this.filter.search,
                sortBy              : this.datatable.options.sortBy,
                sortDesc            : this.datatable.options.sortDesc,
                filter              : filter,
                withEmail           : this.toggles.withMail
            };

            await this.$store.dispatch("getEmployeeDataWithEmail", payload).then((response) => {
                this.datatable.items = response.data.data;
                this.loading.datatable = false;

                this.datatable.totalItems = response.total;
            });
        },
        viewEmployeeEmails(data){
            let details = {
                employee_id : data.employee_id,
                full_name   : data.full_name,
                gmail       : data.gmail ? data.gmail.email : null,
                corporate   : data.corporate ? data.corporate.email : null,
                personal    : data.personal ? data.personal.email : null,
            }
            this.$store.commit('EMPLOYEE_EMAILS',details);
            this.$store.commit('EMPLOYEE_EMAIL_DIALOG',true);
            this.$store.commit('ACTION','VIEW');
        },
        editEmployeeEmails(data){
            let details = {
                employee_id : data.employee_id,
                full_name   : data.full_name,
                gmail       : data.gmail ? data.gmail.email : null,
                corporate   : data.corporate ? data.corporate.email : null,
                personal    : data.personal ? data.personal.email : null,
            }
            this.$store.commit('EMPLOYEE_EMAILS',details);
            this.$store.commit('EMPLOYEE_EMAIL_DIALOG',true);
            this.$store.commit('ACTION','Update');
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                if(this.toggles.isEmployeeList == 1){
                    this.getAllEmployeeData()
                }
                else{
                    this.getAllEmailList();
                }
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_WEBMAIL_NEW_DATA_TRIGGER:{
            deep:true,
            handler(val){
                if(val){
                    if(this.toggles.isEmployeeList == 1){
                        this.getAllEmployeeData()
                    }
                    else{
                        this.getAllEmailList();
                    }
                    this.$store.commit('WEBMAIL_NEW_DATA_TRIGGER',false)
                }
            }
        },
        'toggles.isEmployeeList':{
            handler(val){
                this.$store.commit('IS_EMPLOYEE_LIST',val);
                if(val == 1){
                    this.getAllEmployeeData()
                }
                else{
                    this.getAllEmailList();
                }
            }
        },
        'toggles.withMail':{
            handler(val){
                this.getAllEmployeeData()
            }
        },
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
