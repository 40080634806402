<template>
    <v-container>
        <ComponentHeader
                :button="true"
                :title="'Logistics Details'"
                :type="'Logistic'"
        ></ComponentHeader>
        <v-row>
            <v-container class="bg-bluish-gray mx-4 my-2">
                <v-card>
                    <v-card-text>
                        <v-row class="mx-auto" dense>
                            <v-col cols="12" sm="12" md="12" lg="11" xl="8">
                                <v-row class="mx-auto" dense>
                                    <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                                        <v-autocomplete
                                            v-model="filters.vehicles"
                                            :items="vehicles"
                                            label="Select Vehicle"
                                            background-color="white"
                                            item-text="text"
                                            item-value="id"
                                            dense
                                            outlined
                                            hide-details
                                            multiple
                                            clearable
                                            small-chips
                                            deletable-chips
                                            auto-select-first
                                            >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="remove(filters.vehicles, data.item)"
                                                >
                                                    {{ data.item.text.length > 35 ? getSelectedItem(data.item.text) : data.item.text }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                                        <v-autocomplete
                                            v-model="filters.transactions"
                                            :items="['PL', 'SC']"
                                            label="Select Transaction"
                                            background-color="white"
                                            dense
                                            outlined
                                            hide-details
                                            multiple
                                            clearable
                                            small-chips
                                            deletable-chips
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                                        <v-autocomplete
                                            v-model="filters.status"
                                            :items="statuses"
                                            label="Select Status"
                                            background-color="white"
                                            item-text="text"
                                            item-value="id"
                                            dense
                                            outlined
                                            hide-details
                                            multiple
                                            clearable
                                            small-chips
                                            deletable-chips
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="mx-auto" dense>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                                        <v-menu
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="filters.date_from"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    append-icon="mdi-calendar-clock"
                                                    label="Dispatch Date - Start"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.date_from" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="filters.date_to"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    append-icon="mdi-calendar-clock"
                                                    label="Dispatch Date - End"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.date_to" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                                        <v-autocomplete
                                            v-model="filters.customers"
                                            :items="customers"
                                            label="Select Customer"
                                            background-color="white"
                                            item-text="text"
                                            item-value="id"
                                            dense
                                            outlined
                                            hide-details
                                            multiple
                                            clearable
                                            small-chips
                                            deletable-chips
                                            auto-select-first
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="remove(filters.customers, data.item)"
                                                >
                                                    {{ data.item.text.length > 35 ? getSelectedItem(data.item.text) : data.item.text }}
                                                </v-chip>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="1" xl="4">
                                <v-row class="mx-xl-auto" dense>
                                    <v-col cols="12" class="d-flex justify-content-end" style="gap: 7px">
                                        <!-- <v-btn :loading="!loaded" style="margin-right: 3px" small @click="resetFieldInput()">Clear</v-btn> -->
                                        <!-- <v-btn small @click="getLogistics">Filter</v-btn> -->
                                        <v-btn :loading="loading.export" small @click="exportToExcel">Export</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-row>
    </v-container>

</template>

<script>
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    import { mapGetters } from 'vuex';
    export default {
        components: {
            ComponentHeader,
        },
        data(){
            return {
                filters:{
                    vehicles:[],
                    customers:[],
                    transactions:[],
                    status:[],
                    date_from: this.$dayjs().startOf("month").format("YYYY-MM-DD"),
                    date_to: this.$dayjs().format("YYYY-MM-DD"),
                },
                menu1: false,
                menu2: false,
                vehicles: [],
                customers: [],
                statuses:[
                    {id:0, text:'Saved'},
                    {id:1, text:'Confirmed'},
                    {id:2, text:'Dispatched'},
                ],
                loading: {
                    export: false,
                },
                exportHeaders: [
                    { header: 'DISPATCH NUM', key: 'id', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'VEHICLE', key: 'vehicle', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'DISPATCH MONTH', key: 'dispatch_month', width: 24, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'CUSTOMER', key: 'customer', width: 34, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                    { header: 'AMOUNT', key: 'amount', width: 24, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' } },
                    { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }}
                ],
            }
        },
        computed:{
            ...mapGetters([
                'GET_VEHICLES',
                'GET_CUSTOMERS_LIST_SELECTION'
            ])
        },
        methods: {
            getVehicles() {
                this.$store.dispatch('getVehicle').then(response => {
                    this.vehicles = this.GET_VEHICLES.map(e => { return { text: e.name + "( " + e.plate_number + " )", id: e.id } })
                })
            },
            getCustomers() {
                this.$store.dispatch('getAllCustomers').then(response => {
                    this.customers = this.GET_CUSTOMERS_LIST_SELECTION.map(e => {
                        return {
                            id: e.value,
                            text: e.text,
                        }
                    })
                })
            },
            async exportToExcel() {
                this.loading.export = true;
                let workbook = new this.$exceljs.Workbook();
                let worksheet = workbook.addWorksheet('Logistics')
                worksheet.columns = this.exportHeaders;

                let headerRow = worksheet.getRow(1);
                headerRow.font = { bold:true }
                for (let c=1;c<=worksheet.columns.length;c++) {
                    let currentCell = headerRow.getCell(c);
                    currentCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'F69F1A'},
                    }
                }

                let payload = {
                    filters: this.filters,
                    url: 'logistics-details-report'
                }
                await this.$store.dispatch('reportsPost', payload).then( response => {
                    let data = response.data;
                    data.forEach( e => {
                        e.amount = e.amount ? parseFloat(e.amount) : 0;
                        worksheet.addRow(e);
                    });

                })
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    this.$filesaver.saveAs(blob, 'Logistics Report.xlsx');
                })

                this.loading.export = false;
            },
            remove(items, item) {
                const index = items.indexOf(item.id)
                if (index >= 0) {
                    items.splice(index, 1)
                }
            },
            getSelectedItem(data) {
                return data.slice(0, 30) + '...';
            }
        },
        watch:{
            //
        },
        mounted(){
            this.getVehicles();
            this.getCustomers();
        },
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }

    .v-chip {
        height: auto !important;
        padding-top: 2px;
        padding-bottom: 2px;
    }
</style>
