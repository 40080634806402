<template>
<div>
    <v-dialog v-model="custPaymentDetailDialog" scrollable persistent max-width="75%" retain-focus>
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">View Customer Payment Detail</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="custPaymentDetailClose()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <div style="text-align:end" class="px-4 mb-3">
                <BaseFilesViewerVue :customerPaymentDetailId="customerPaymentDetailId" @openFilesViewer="openFilesViewer" @closeFilesViewer="closeFilesViewer"/>
            </div>

            <v-divider class="mt-0" />

            <v-card-text>
                <v-layout row class="mx-0 pa-4">
                    <v-flex lg4 class="pr-2">
                        <v-card class="pa-2">
                            <div class="d-flex pb-2">
                                <h6 class="mr-1">Reference#: {{ referenceNumber }}</h6>
                            </div>
                            <div class="d-flex pb-2">
                                <h6 class="mr-1">Payment Type: {{ paymentTypeName }}</h6>
                                <span v-if="paymentTypeName == 'Deposit'" class="font-weight-bold">{{ `(${depositType})` }}</span>
                            </div>
                            <div class="pb-2">
                                <h6>Payment Date: {{ paymentDate }}</h6>
                            </div>
                            <div class="pb-2">
                                <h6>Reference Number: {{ referenceNumber }}</h6>
                            </div>
                            <div class="pb-2">
                                <h6>Customer Name: {{ customerName }}</h6>
                            </div>
                            <div v-if="paymentTypeName == 'Cheque'" class="pb-2">
                                <h6>Bank: {{ bankName }}</h6>
                            </div>
                            <div class="pb-2">
                                <h6>Acknowledgment Receipt: {{ acknowledgmentReceipt }}</h6>
                            </div>
                            <div class="pb-2">
                                <h6>Remarks: {{ remarks }}</h6>
                            </div>
                            <div v-if="paymentTypeName == 'Cheque' && !!move_date_reason" class="pb-2">
                                <h6>Move Date Reason: {{ move_date_reason }}</h6>
                            </div>
                            <div v-if="paymentTypeName == 'Cheque' && !!replace_reason" class="pb-2">
                                <h6>Replace Reason: {{ replace_reason }}</h6>
                            </div>
                            <div v-if="paymentTypeName == 'Cheque' && !!re_deposit_date" class="pb-2">
                                <h6>Re Deposit Date: {{ re_deposit_date }}</h6>
                            </div>
                            <div v-if="paymentTypeName == 'Cheque' && !!bounce_reason" class="pb-2">
                                <h6>Bounce Reason: {{ bounce_reason }}</h6>
                            </div>
                            <div v-if="paymentTypeName == 'Cheque' && !!bounce_date" class="pb-2">
                                <h6>Bounce Date: {{ bounce_date }}</h6>
                            </div>

                            <v-divider />

                            <div v-if="paymentTypeName == 'Cash' || (paymentTypeName == 'Deposit' && deposit_type_id == 1)">
                                <h6 class="pb-2">Payment Breakdown:</h6>
                                <v-layout v-for="breakdown in cashDenominationBreakdown" :key="breakdown.code" row class="mx-0">
                                    <v-flex lg5>
                                        <div style="text-align:end">
                                            <span>{{ `${breakdown.value} x ${breakdown.quantity} = ` }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex lg7>
                                        <div style="text-align:end">
                                            <span>{{ breakdown.amount | currency }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-divider />
                                <div style="text-align:end">
                                    <h5>Total Amount: &#x20b1; {{ totalAmount | currency }}</h5>
                                </div>
                            </div>

                            <div lg6 v-else>
                                <div class="pb-2">
                                    <span>Payment Name:</span>
                                    <span>{{ paymentTypeName == 'Cheque' ? chequeName : depositAccountName  }}</span>
                                </div>
                                <div v-if="paymentTypeName == 'Cheque'" class="pb-2">
                                    <span>Cheque Number: </span>
                                    <span> {{ chequeAccountNumber }}</span>
                                </div>
                                <div v-if="paymentTypeName == 'Online'" class="pb-2">
                                    <span>Online Reference Number: </span>
                                    <span>{{ onlineTransactionNumber }}</span>
                                </div>
                                <div v-if="paymentTypeName == 'Deposit' && deposit_type_id == 2" class="pb-2">
                                    <span>Cheque Number: </span>
                                    <span>{{ accountNumber  }} </span>
                                </div>
                                <div v-if="paymentTypeName == 'Deposit' && deposit_type_id == 2" class="pb-2">
                                    <span>Deposit Date: </span>
                                    <span>{{ accountDate }} </span>
                                </div>
                                <div  v-if="paymentTypeName == 'Deposit' && deposit_type_id == 2" class="pb-2">
                                    <span>Cheque Date: </span>
                                    <span>{{ accountDate }}</span>
                                </div>
                                <div v-if="paymentTypeName == 'Cheque'" class="pb-2">
                                    <span>Cheque Date: </span>
                                    <span>{{ chequeDate }}</span>
                                </div>
                                <div class="pb-2">
                                    <span>Expected Deposit Date: </span>
                                    <span>{{ expectedDepositDate === '0000-00-00' ? accountDate : expectedDepositDate }}</span>
                                </div>
                                <div class="pb-2">
                                    <span>Payment Amount: &#x20b1; </span>
                                    <span>{{ totalAmount | currency }}</span>
                                </div>
                                <div class="pb-2">
                                    <span>Description: </span>
                                    <span>{{ paymentTypeName == 'Cheque' ? chequeDescription : depositAccountDescription }}</span>
                                </div>
                            </div>

                            <v-divider />

                            <div lg6 v-if="paymentTypeName == 'Cheque' || (paymentTypeName == 'Deposit' && deposit_type_id == 2)">
                                <div class="pb-2">
                                    <span>Deposit Date: </span>
                                    <span>{{ deposit_date }}</span>
                                </div>
                            </div>
                        </v-card>
                    </v-flex>

                    <v-flex lg8>
                        <v-card class="pa-2">
                            <h6>For Confirmation in SOA: </h6>
                            <v-data-table
                                :headers="forConfirmationSOAHeader"
                                :items="forConfirmationSOA"
                            >
                                <template v-slot:[`item.allocated_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.allocated_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.created_at`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ formatDate(item.created_at) }}
                                    </span>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <span class="text-nowrap" v-if="user_access.confirm_payment">
                                        <v-tooltip bottom color="green">
                                            <template v-slot:activator="{ on, attrs }" >
                                                <v-btn
                                                    @click="confirmAndAllocate(item.soa_id)"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="green"
                                                    icon
                                                    small
                                                >
                                                    <v-icon>mdi-cash-check</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Confirm & Allocate</span>
                                        </v-tooltip>
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card>

                        <v-card class="pa-2 mt-2">
                            <h6>Payment Allocations Breakdown: </h6>
                            <v-data-table
                                :headers="paymentAllocationsHeader"
                                :items="customerPaymentAllocations"
                            >
                                <template v-slot:[`item.allocated_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.allocated_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.created_at`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ formatDate(item.created_at) }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-flex>
                </v-layout>

                <v-divider />

                <!-- <div style="text-align:end" class="pb-4 px-4 mr-3 span-text-size">
                    <span><b>SOA IDS: </b></span>
                    <span>{{ soaIds }}</span>
                </div> -->
                <v-layout row class="pb-4 px-4 mx-0 mr-3 span-text-size">
                    <v-spacer />
                    <v-flex lg3>
                        <div style="text-align:end" >
                            <v-simple-table
                            dense
                            fixed-header
                            >
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">Details</th>
                                    <th class="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td class="text-left"><b>Gross Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ grossAmount | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>WHT Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ whtAmount | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>Other Adj Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ otherAdjAmount | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>Net Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ netAmount | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>Allocated Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ allocatedAmount | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>Remaining Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ remainingAmount | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>Soa Balance Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ balanceAmounts | currency }}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><b>New Balance Amount:</b></td>
                                    <td class="text-right">&#x20b1;{{ newAmount | currency }}</td>
                                </tr>
                                </tbody>
                            </template>
                            </v-simple-table>
                            <!-- <div>
                                <span><b>Gross Amount:</b></span>
                                <span> &#x20b1;{{ grossAmount | currency }}</span>
                            </div> -->
                            <!-- <div>
                                <span><b>WHT Amount:</b></span>
                                <span> &#x20b1;{{ whtAmount | currency }}</span>
                            </div> -->
                            <!-- <div>
                                <span><b>Other Adj Amount:</b></span>
                                <span> &#x20b1;{{ otherAdjAmount | currency }}</span>
                            </div> -->
                            <!-- <div>
                                <span><b>Net Amount:</b></span>
                                <span> &#x20b1;{{ netAmount | currency }}</span>
                            </div> -->
                            <!-- <div>
                                <span><b>Allocated Amount:</b></span>
                                <span> &#x20b1;{{ allocatedAmount | currency }}</span>
                            </div> -->
                            <!-- <div>
                                <span><b>Remaining Amount:</b></span>
                                <span> &#x20b1;{{ remainingAmount | currency }}</span>
                            </div> -->
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider class="mt-0" />

                <div class="pb-4">

                    <v-row class="mx-0 my-2">
                        <v-col cols="12">
                            <v-row class="ma-0 pa-0" v-if="!isUnidentified">
                                <!-- <v-col class="d-flex justify-content-center align-item-center" v-if="checkIfDepositedDate && isDeposited == 0 && bounceCount == 0 && status != 6 && paymentTypeName == 'Cheque'">
                                    <v-container class="text-center">
                                        <v-btn class="mx-1 text-center" @click="updateChequeDate('Deposit')">Deposited</v-btn>
                                    </v-container>
                                </v-col> -->
                                <v-col class="d-flex justify-content-center align-item-center" v-if="checkIfDepositedDate && isDeposited == 0 && bounceCount == 1 && status != 6 && ((paymentTypeName == 'Cheque') || (paymentTypeName == 'Deposit' && deposit_type_id == 2))">
                                    <v-container class="text-center">
                                        <v-btn class="mx-1 text-center" color='orange' @click="updateChequeDate('Re-Deposit')">Re-Deposit</v-btn>
                                    </v-container>
                                </v-col>
                                <v-col class="d-flex justify-content-center align-item-center" v-if="(checkIfDepositedDate && status != 6 && paymentTypeName == 'Cheque') || (paymentTypeName == 'Deposit' && deposit_type_id == 2)">
                                    <AddEditCustomerPaymentDetailComponentVue class="text-center" :customerId="customer_id" :fromSoa="fromSoa" :addEdit="addEdit" :balanceAmounts="balanceAmounts" :soa_id="soa_id" :origin='"viewPayment"' @getNewCpdId="getNewCpdId" :cpd_id="customerPaymentDetailId"/>
                                </v-col>
                                <v-col class="d-flex justify-content-center align-item-center" v-if="(isDeposited == 1 && bounceCount <= 1 && status != 6 && status != 11 && status != 3) && ((paymentTypeName == 'Cheque') || (paymentTypeName == 'Deposit' && deposit_type_id == 2))">
                                    <v-container class="text-center">
                                        <v-btn color='red' @click="bounceCheque()">Bounce</v-btn>
                                    </v-container>
                                </v-col>
                                <!-- <v-col class="d-flex justify-content-center align-item-center" v-if="bounceCount >= 2">
                                    <v-container class="text-center">
                                        <v-btn color='red' disabled>PAYMENT VOIDED</v-btn>
                                    </v-container>
                                </v-col> -->
                                <v-col class="d-flex justify-content-center align-item-center" v-if="!isUnidentified && status == 11 && user_access.reject_payment">
                                    <v-container class="text-center">
                                        <v-btn class="mx-1" color='red' @click="updateChequeDate('Reject')">Reject</v-btn>
                                    </v-container>
                                </v-col>
                                <!-- <v-col class="d-flex justify-content-center align-item-center" v-if="!isUnidentified && status == 11 && user_access.confirm_payment">
                                    <v-container class="text-center">
                                        <v-btn class="mx-1" @click="updateChequeDate('Confirm')">Confirm</v-btn>
                                    </v-container>
                                </v-col> -->
                                <v-col class="d-flex justify-content-center align-item-center" v-if="status == 10">
                                    <v-container class="text-center">
                                        <v-btn @click="allocateRemainingBalance()">Allocate Remaning Amount</v-btn>
                                    </v-container>
                                </v-col>
                                <!-- <v-col class="d-flex justify-content-center align-item-center" v-if="status == 0">
                                    <v-container class="text-center">
                                        <v-btn @click="updateCustPayment(7, 'Confirm')">Confirm</v-btn>
                                    </v-container>
                                </v-col> -->
                                <v-col class="d-flex justify-content-center align-item-center" v-if="isUnidentified">
                                    <v-container class="text-center">
                                        <v-btn @click="showAllocateUnidentifiedPayment()">Assign Payment</v-btn>
                                    </v-container>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" v-if="(paymentTypeName == 'Cheque') || (paymentTypeName == 'Deposit' && deposit_type_id == 2)">
                                <v-col class="d-flex justify-content-center align-item-center">
                                    <v-container class="text-center">
                                        <v-btn v-if="remainingAmount == 0" color="blue-grey" @click="toggleDepositDate()">Deposit Chq</v-btn>
                                        <v-btn v-if="status == 0" color="blue-grey" @click="updateCustPayment(5, 'Bounce')">Bounce</v-btn>
                                        <v-btn v-if="status == 0" color="red" @click="updateCustPayment(3, 'Reject')">Reject</v-btn>
                                    </v-container>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" v-else>
                                <v-col class="d-flex justify-content-center align-item-center">
                                    <v-container class="text-center">
                                        <v-btn v-if="status == 0" color="red" @click="updateCustPayment(3, 'Reject')">Reject</v-btn>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="unidentifiedPayment.dialog" width="50%">
        <v-card>
            <v-row class="m-0">
                <v-col cols="10">
                    <v-card-title>
                        <span class="headline">Allocate Unindentified Payment</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-2">
                    <v-btn text icon color="gray" class="float-right" @click="unidentifiedPayment.dialog = false; closeUnidentifiedDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="6">
                        <v-autocomplete
                        dense
                        outlined
                        :items="unidentifiedPayment.customerList"
                        v-model="unidentifiedPayment.selectedCustomer"
                        @change="getSoasByCustomerId()"
                        label="Select Customer"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                        dense
                        outlined
                        :items="unidentifiedPayment.soaList"
                        v-model="unidentifiedPayment.selectedSoa"
                        label="Select SOA"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="submitUnidentifiedPayment()">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="bounceCheckDialog" width="25%">
        <v-card>
            <v-row class="m-0">
                <v-col cols="10">
                    <v-card-title>
                        <span class="headline">Bounce Reason</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-2">
                    <v-btn text icon color="gray" class="float-right" @click="bounceCheckDialog = false;">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-card-text>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-dialog
                            ref="accountDateDialog"
                            v-model="bounceCheck.date_dialog"
                            :return-value.sync="bounceCheck.date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="bounceCheck.date"
                                readonly
                                dense
                                outlined
                                label="Bounce Date"
                                class="hide-text-field-details"
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="bounceCheck.date"
                                scrollable
                            >
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="bounceCheck.date_dialog = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.accountDateDialog.save(bounceCheck.date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12">
                        <v-dialog
                            ref="accountDateDialog2"
                            v-model="bounceCheck.re_deposit_date_dialog"
                            :return-value.sync="bounceCheck.re_deposit_date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="bounceCheck.re_deposit_date"
                                readonly
                                dense
                                outlined
                                label="Re-Deposit Date"
                                class="hide-text-field-details"
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="bounceCheck.re_deposit_date"
                                scrollable
                            >
                            <v-spacer />
                            <v-btn
                                text
                                color="primary"
                                @click="bounceCheck.re_deposit_date_dialog = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.accountDateDialog2.save(bounceCheck.re_deposit_date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                        rows="2"
                        dense
                        outlined
                        v-model="bounceCheck.reason"
                        label="Reason"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-btn @click="submitBounceCheck(1)">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="toggles.deposit_date" width="25%">
        <v-card>
            <v-card-title>
                <v-row no-gutters>
                    <v-col cols="11">
                        Select Deposit Date
                    </v-col>
                </v-row>
                <v-col cols="1" class="d-flex justify-end">
                    <v-btn text icon color="gray" class="float-right" @click="toggleDepositDate()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menus.deposit_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="deposit_date"
                                    v-on="on"
                                    id="depositDate"
                                    label="Deposit Date"
                                    append-icon="mdi-calendar-clock"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="deposit_date" @input="menus.deposit_date = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn @click="updateDepositDate()">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseFilesViewerVue from '../../Base/BaseFilesViewer.vue';
import swal from 'sweetalert2';
import AddEditCustomerPaymentDetailComponentVue from './AddEditCustomerPaymentDetailComponent.vue';
export default {
    data() {
        return {
            referenceNumber: '',
            customerName: '',
            paymentTypeName: '',
            paymentDate: '',
            bankName: '',
            remarks: '',
            totalAmount: parseFloat(0),
            allocatedAmount: parseFloat(0),
            remainingAmount: parseFloat(0),
            grossAmount: parseFloat(0),
            whtAmount: parseFloat(0),
            otherAdjAmount: parseFloat(0),
            netAmount: parseFloat(0),
            chequeName: '',
            chequeAccountNumber: '',
            accountNumber: '',
            onlineTransactionNumber: '',
            accountDate: '',
            chequeDate: '',
            expectedDepositDate:'',
            checkDescription: '',
            checkBankName: '',
            customerPaymentDetail: null,
            status: '',
            soaIds: '',
            cashDenominationBreakdown: [],
            chequeDescription: '',

            customerPaymentAllocations: [],
            paymentAllocationsHeader: [
                { text: 'SOA ID', value: 'soa_id' },
                { text: 'Allocated WHT', value: 'allocated_wht' },
                { text: 'Allocated Amount', value: 'allocated_amount' },
                { text: 'Allocated By', value: 'allocated_by' },
                { text: 'Date', value: 'created_at' },
            ],
            isDeposited:'',
            unidentifiedPayment:{
                dialog:false,
                customerList:[],
                selectedCustomer:'',
                soaList:[],
                selectedSoa:''
            },
            newCpdId:null,
            bounceCheckDialog:false,
            bounceCheck:{
                date:'',
                date_dialog:false,
                reason:'',
                re_deposit_date_dialog:false,
                re_deposit_date:''
            },
            // user_access:{
            //     confirm_payment:false,
            //     reject_payment:false
            // },
            move_date_reason:null,
            replace_reason:null,
            replace_date:null,
            re_deposit_date:null,
            bounce_reason:null,
            bounce_date:null,
            deposit_type_id:0,
            customer_id:0,
            bounceCount:0,
            acknowledgmentReceipt: '',
            deposit_date: this.$dayjs().format('YYYY-MM-DD'),
            toggles: {
                deposit_date: false,
            },
            menus: {
                deposit_date: false,
            },
            forConfirmationSOAHeader: [
                { text: 'SOA ID', value: 'soa_id' },
                { text: 'Allocated WHT', value: 'allocated_wht' },
                { text: 'Allocated Amount', align: 'end', value: 'allocated_amount' },
                { text: 'Allocated By', value: 'allocated_by' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            forConfirmationSOA: [],
            depositAccountName: '',
            depositAccountDescription: '',
        }
    },
    components: {
        BaseFilesViewerVue,
        AddEditCustomerPaymentDetailComponentVue
    },
    props: ['view','customerIds','fromSoa','addEdit','balanceAmounts','soa_id','isUnidentified','user_access','origin'],
    mounted(){
        this.getAllCustomer();
    },
    computed: {
        ...mapGetters([
            'GET_VIEW_CUSTOMER_PAYMENT_DETAIL',
            'GET_CUSTOMERS_LIST_SELECTION',
            'USER_ACCESS'
        ]),
        custPaymentDetailDialog() {
            this.$store.commit('custPaymentDetailEditChequeDate',false);
            return this.$store.state.accounting.customerPaymentDetail.custPaymentDetailDialog;
            // else if(this.origin == "editSoa"){
            //     return this.$store.state.accounting.customerPaymentDetail.custPaymentDetailDialog;
            // }
        },
        customerPaymentDetailId() {
            if(!!this.$store.state.accounting.customerPaymentDetail.customerPaymentDetailId){
                return this.$store.state.accounting.customerPaymentDetail.customerPaymentDetailId;
            }
        },
        dialogAction() {
            return this.$store.state.accounting.customerPaymentDetail.dialogAction;
        },
        customerId(){
            return this.$store.state.accounting.customerPaymentDetail.customerPaymentDetailId
        },
        checkIfDepositedDate(){
                // if(Date.now() >= new Date(this.expectedDepositDate)) {
                    return true
                // }
                // else {
                //     return false
                // }
        },
        newAmount(){
            let newAmount = this.balanceAmounts - this.remainingAmount
            return newAmount < 0 ? 0 : newAmount;
        },
        bounceFromViewing(){
            return this.$store.state.accounting.custPaymentDetailBounceFromViewing
        },
        depositType() {
            const id = this.deposit_type_id;
            let type = '';
            switch(id){
                case 0:
                    type = 'Unknown';
                    break;
                case 1:
                    type = 'Cash';
                    break;
                case 2:
                    type = 'Cheque';
                    break;
            }
            return type;
        }
    },
    watch: {
        customerPaymentDetail() {
            if(!!this.customerPaymentDetail) {
                const {
                    reference_number,
                    customer_name,
                    payment_type_name,
                    payment_date,
                    bank_name,
                    remarks,
                    total_amount,
                    remaining_amount,
                    allocated_amount,
                    cheque_name,
                    cheque_amount,
                    cheque_date,
                    cheque_account_number,
                    account_number,
                    online_transaction_number,
                    account_date,
                    chq_deposit_date,
                    acknowledgment_receipt,
                    cheque_description,
                    created_by,
                    status,
                    cash_denomination_breakdown,
                    soa_ids,
                    gross_amount,
                    wht_amount,
                    other_adj_amount,
                    net_amount,
                    expected_deposit_date,
                    deposit_date,
                    deposit,
                    bounce_count,
                    move_date_reason,
                    replace_reason,
                    replace_date,
                    re_deposit_date,
                    bounce_reason,
                    bounce_date,
                    deposit_type_id,
                    customer_id,
                    account_name,
                    account_description,
                } = this.customerPaymentDetail;

                this.status = status;

                // if(!!soa_ids) {
                    // this.soaIds = JSON.parse(soa_ids).toLocaleString();
                // }

                this.referenceNumber = reference_number;
                this.customerName = customer_name;
                this.paymentTypeName = payment_type_name;
                this.paymentDate = payment_date;
                this.bankName = bank_name;
                this.remarks = remarks;
                this.totalAmount = parseFloat(total_amount);
                this.remainingAmount = parseFloat(remaining_amount);
                this.allocatedAmount = parseFloat(allocated_amount);
                this.grossAmount = parseFloat(gross_amount);
                this.whtAmount = parseFloat(wht_amount);
                this.otherAdjAmount = parseFloat(other_adj_amount);
                this.netAmount = parseFloat(net_amount);

                if(!!soa_ids) {
                    this.soaIds = JSON.parse(soa_ids).toString();
                }
                this.depositAccountName = account_name
                this.depositAccountDescription = account_description
                this.chequeName = cheque_name;
                this.chequeDate = cheque_date;
                this.chequeAccountNumber = cheque_account_number;
                this.accountNumber = account_number;
                this.onlineTransactionNumber = online_transaction_number;
                this.accountDate = account_date;
                this.acknowledgmentReceipt = acknowledgment_receipt;
                this.chequeDescription = cheque_description;
                this.createdBy = created_by;
                this.expectedDepositDate = expected_deposit_date;
                this.deposit_date = deposit_date;
                this.isDeposited = deposit;
                this.bounceCount = bounce_count;

                this.cashDenominationBreakdown = JSON.parse(cash_denomination_breakdown);

                this.bounceCheck.date = this.chequeDate;
                this.move_date_reason = move_date_reason
                this.replace_reason = replace_reason
                this.replace_date = replace_date
                this.re_deposit_date = re_deposit_date
                this.bounce_reason = bounce_reason
                this.bounce_date = bounce_date
                this.deposit_type_id = deposit_type_id
                this.customer_id = customer_id
            }
        },
        customerPaymentDetailId() {
            if(!!this.customerPaymentDetailId) {
                this.getViewCustomerPaymentDetail();
            }
        },
        USER_ACCESS: {
            handler(val) {
            if(val != "fail"){
                val.forEach((e) => {
                if (e.actions_code == "confirm_payment") {
                    this.user_access.confirm_payment = true;
                }
                if (e.actions_code == "reject_payment") {
                    this.user_access.reject_payment = true
                }
                });
            }
            }
        },
    },
    methods: {
        clearFields() {
            this.customerName = '';
            this.paymentTypeName = '';
            this.bankName = '';
            this.remarks = '';
            this.totalAmount = 0;
            this.chequeName = '';
            this.chequeAccountNumber = '';
            this.chequeDate = '';
            this.checkBankName = '';
            this.chequeDescription = '';
            this.createdBy = '';
            this.move_date_reason = null
            this.replace_reason = null
            this.replace_date = null
            this.re_deposit_date = null
            this.bounce_reason = null
            this.bounce_date = null
            this.deposit_type_id = 0
            this.$store.commit('custPaymentDetailClearId');
            this.forConfirmationSOA = [];
        },
        custPaymentDetailClose() {
            this.$store.commit('custPaymentDetailClose');
            this.clearFields();
            this.$emit('closeViewPayment', this.soa_id);
        },
        getViewCustomerPaymentDetail() {
            this.customerPaymentAllocations = []
            this.$store.dispatch('getViewCustomerPaymentDetail',this.customerPaymentDetailId).then(response=>{
                this.customerPaymentDetail = this.GET_VIEW_CUSTOMER_PAYMENT_DETAIL;
                this.customerPaymentAllocations = this.GET_VIEW_CUSTOMER_PAYMENT_DETAIL.customer_payment_allocations;
                this.forConfirmationSOA = this.GET_VIEW_CUSTOMER_PAYMENT_DETAIL.forConfirmationSOA;
            });
        },
        updateCustPayment(status, action) {
            this.approveOnFlight = true;

            swal.fire({
                title: `Are you sure you want to ${action} Customer Payment?`,
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        customerPaymentDetailId: this.customerPaymentDetailId,
                        status: status,
                    }

                    this.$store.dispatch('updateCustPayment',payload).then(response=>{
                        if(response.data.error) {
                            swal.fire('' ,response.data.error, 'error').then(confirm=>{
                                this.approveOnFlight = false;
                            });
                        } else {
                            this.$store.commit('custPaymentDetailCloseAddEditDialog');
                            this.$store.commit('custPaymentDetailSuccessTrue');
                            this.custPaymentDetailClose();
                            swal.fire('' ,response.data.msg, 'success').then(confirm=>{
                                this.approveOnFlight = false;
                                this.$store.commit('custPaymentDetailSuccessFalse');
                            });
                        }
                    });
                }
                this.approveOnFlight = false;
            });
        },
        formatDate(date) {
            if(!!date) {
                return this.$dayjs(date).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        openFilesViewer() {
            if(this.view) {
                this.$store.commit('custPaymentDetailClose');
            }
        },
        closeFilesViewer() {
            if(this.view) {
                this.$store.commit('custPaymentDetailShow');
            }
        },
        updateChequeDate(action){
            let swalTitle = ''
            let swalText = 'Make sure that cheque amount is received'

            if(action == "Re-Deposit"){
                swalTitle = `Are you sure you want to ${action}?`
            }
            else if(action == "Deposit"){
                swalTitle = 'This cheque is not yet allocated, Do you want to allocate cheque amount?'
            }
            else if(action == "Confirm"){
                swalTitle = 'Are you sure you want to confirm payment?'
            }
            else if(action == 'Reject'){
                swalTitle = 'Are you sure you want to reject payment?'
                swalText = 'This action cannot be undone.'
            }
            swal.fire({
                title: swalTitle,
                text: swalText,
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                    id: this.customerPaymentDetailId,
                    new_cheque_date: this.expectedDepositDate,
                    action:action,
                    mode:'Deposited',
                    soa_id:this.soa_id
                }
                this.$store.dispatch('updateChequeDate',payload).then(response=>{
                    if(response.data.error) {
                        swal.fire('' ,response.data.error, 'error').then(confirm=>{
                            this.approveOnFlight = false;
                        });
                    } else {
                        let cheque_payload = {
                            customer_payment_detail_id:this.customerPaymentDetailId,
                            bounce: 0
                        }
                        this.$store.dispatch('storeChequeMovement',cheque_payload)
                        this.$store.commit('custPaymentDetailCloseAddEditDialog');
                        this.$store.commit('custPaymentDetailSuccessTrue');
                        this.custPaymentDetailClose();

                        swal.fire('' ,response.data.msg, 'success').then(confirm=>{
                            this.approveOnFlight = false;
                            this.$store.commit('custPaymentDetailSuccessFalse');

                        });
                    }
                })
                }
            });

        },
        bounceCheque(){
            swal.fire({
                title: "",
                text: "Are you sure you want bounce this cheque?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.bounceCheckDialog = true
                }
            })
        },
        submitBounceCheck(bounce = null){
            let payload = {
                id: 0,
                cancel_reason: this.bounceCheck.reason,
                soa_id: this.soa_id,
                cpd_id: this.customerPaymentDetailId,
                bounce_date: this.bounceCheck.date,
                re_deposit_date: this.bounceCheck.re_deposit_date,
                isBounce: bounce,
            }

            this.$store.dispatch('cancelCustomerPaymentAllocations',payload).then(response => {
                swal.fire(response.data.msg,'',response.data.status)

                let cheque_payload = {
                    customer_payment_detail_id:this.customerPaymentDetailId,
                    bounce_date:this.bounceCheck.date,
                    re_deposit_date:this.bounceCheck.re_deposit_date,
                    cancel_reason:this.bounceCheck.reason,
                    bounce: 1
                }
                this.$store.dispatch('storeChequeMovement',cheque_payload).then(res=>{
                    this.$store.commit('custPaymentDetailCloseAddEditDialog');
                    this.$store.commit('custPaymentDetailSuccessTrue');
                    this.custPaymentDetailClose();
                    this.$store.commit('custPaymentDetailClose');
                    this.$store.commit('custPaymentDetailClearId');
                    swal.fire("", response.data.msg, "success").then((value) => {
                        this.approveOnFlight = false;
                        this.bounceCheckDialog = false
                        this.$store.commit('custPaymentDetailSuccessFalse');
                        if(this.bounceFromViewing){
                            this.$store.commit('custPaymentDetailBounceFromViewing',false)
                            window.location.reload();

                        }
                    });
                })

            })
        },
        cancelPaymentAllocation(id,cpd_id){

        },
        showCustomerPaymentDetail(){

        },
        allocateRemainingBalance(){
            swal.fire({
                title: "",
                text: "Are you sure you want to allocate remaining amount?",
                icon: 'question',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            id: this.customerPaymentDetailId,
                        }
                        this.$store.dispatch('allocateRemainingBalance',payload).then(response=>{
                            let data = response.data
                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.$store.commit('custPaymentDetailCloseAddEditDialog');
                            this.$store.commit('custPaymentDetailSuccessTrue');
                            this.custPaymentDetailClose();
                            swal.fire("", response.data.msg, "success").then((value) => {
                                this.approveOnFlight = false;
                                this.$store.commit('custPaymentDetailSuccessFalse');
                            });
                            this.$store.commit('custPaymentDetailSuccessFalse');
                        })
                    }
                });
        },
        showAllocateUnidentifiedPayment(){
            this.unidentifiedPayment.dialog = true
        },
        async getAllCustomer(){
            await this.$store.dispatch('getAllCustomers').then((response)=>{ this.unidentifiedPayment.customerList = this.GET_CUSTOMERS_LIST_SELECTION })
        },
        getSoasByCustomerId(){
            let payload = {
                customer_id : this.unidentifiedPayment.selectedCustomer,
            }
            this.$store.dispatch('getSoasByCustomerId',payload).then(response=>{
                this.unidentifiedPayment.soaList = response.data
            })
        },
        submitUnidentifiedPayment(){
            if(!!this.unidentifiedPayment.selectedSoa && !!this.unidentifiedPayment.selectedCustomer){
                swal.fire({
                    title: "",
                    text: "Are you sure you want to allocate UNIDENTIFIED payment?",
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            soa_id: this.unidentifiedPayment.selectedSoa,
                            customer_id:this.unidentifiedPayment.selectedCustomer,
                            cpd_id:this.customerPaymentDetailId
                        }
                        this.$store.dispatch('assignUnidentifiedPayment',payload).then(response=>{
                            this.$store.commit('custPaymentDetailCloseAddEditDialog');
                            this.$store.commit('custPaymentDetailSuccessTrue');
                            swal.fire("", response.data.msg,response.data.icon).then((value) => {
                                this.approveOnFlight = false;
                                this.$store.commit('custPaymentDetailSuccessFalse');
                                this.closeUnidentifiedDialog();
                                this.custPaymentDetailClose();
                            });

                        })
                    }
                })
            }
            else{
                swal.fire('Please select One Customer and One SOA ID','','warning')
                return false
            }

        },
        closeUnidentifiedDialog(){
            this.unidentifiedPayment.selectedCustomer = '';
            this.unidentifiedPayment.customerList = [];
            this.unidentifiedPayment.soaList = []
            this.unidentifiedPayment.selectedSoa = '';
            this.unidentifiedPayment.dialog = false
        },
        getNewCpdId(new_id){
            this.newCpdId = new_id
        },
        toggleDepositDate() {
            this.toggles.deposit_date = !this.toggles.deposit_date
            if (!this.toggles.deposit_date) this.deposit_date = this.$dayjs().format('YYYY-MM-DD')
        },
        updateDepositDate() {
            this.$store.dispatch('updateDepositDate', {
                id: this.customerPaymentDetailId,
                deposit_date: this.deposit_date,
            }).then(response => {
                swal.fire({
                    title: response.data.msg,
                    icon: response.data.icon,
                    timer: 5000,
                }).then(result => {
                    this.toggleDepositDate()
                    this.custPaymentDetailClose()
                })

            })
        },
        confirmAndAllocate(soa_id){
            swal.fire({
                title: `Are you sure you want to Confirm & Allocate Payment?`,
                text: 'Make sure that all information are correct',
                icon: 'question',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        cpd_id: this.customerPaymentDetailId,
                        soa_id: soa_id,
                    }
                    this.$store.commit('custPaymentDetailSuccessTrue');
                    this.$store.dispatch('confirmAndAllocatePayment',payload).then(response=>{
                        this.$store.commit('custPaymentDetailSuccessFalse');
                        swal.fire(response.data.message ,'', response.data.icon);
                        this.getViewCustomerPaymentDetail()
                    });
                }
                this.approveOnFlight = false;
            });
        }
    },
}
</script>

<style>
.span-text-size{
    font-size: 16px;
}
</style>
