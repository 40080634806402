<template>
    <div class="w-100">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    v-model="filter.search"
                                    id="searchBar"
                                    label="Search Template Name"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :search="search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        no-data-text='No Data Found'
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions
                        }"
                        dense
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="viewScheduleTemplate(item)"
                                    >mdi-eye</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="editScheduleTemplate(item)"
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="deleteScheduleTemplate(item)"
                                    >mdi-delete</v-icon
                                >
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
export default {
    data(){
        return {
            loaded: false,
            totalItems: 0,
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:""
            },

            datatable:{
                headers:
                    [
                        { text: 'Template Name', align: 'start', value: 'name'},
                        { text: 'Schedule Type', align: 'start', value: 'schedule_type_names'},
                        { text: 'Monday', align: 'start',value:'Monday' },
                        { text: 'Tuesday', align: 'start',value:'Tuesday' },
                        { text: 'Wednesday', align: 'start',value:'Wednesday' },
                        { text: 'Thursday', align: 'start', value: 'Thursday'},
                        { text: 'Friday', align: 'start', value: 'Friday'},
                        { text: 'Saturday', align: 'start', value: 'Saturday'},
                        { text: 'Sunday', align: 'start', value: 'Sunday'},
                        { text: "Action", align: "center", value: "action" }
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
            },

            loading:{
                datatable:false
            },

            statuses:[
                {text:'all', value:0},
                {text:'approved', value:1},
                {text:'rejected', value:2},
                {text:'pending', value:3},
            ],

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                approve_reject_manpower_request:false
            },
            selected_status:'',
            isDepartmentView:0
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'GET_TIMEKEEPING_NEW_DATA',
            'USERACCOUNT_LOAD',
            'GET_HR_TAB_IS_ACTIVE',
            'GET_HR_DISPATCH'
        ]),
    },
    components:{
    },
    methods:{
        selectedStatus(data){
            this.selected_status = data.value
            this.getScheduleTemplates()
        },
        getScheduleTemplates(filter = null){
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page            : this.datatable.options.page,
                itemsPerPage    : this.datatable.options.itemsPerPage,
                search          : this.filter.search,
                sortBy          : this.datatable.options.sortBy,
                sortDesc        : this.datatable.options.sortDesc,
                status          : this.selected_status,
                filter          : filter,
            };
            this.$store.dispatch(this.GET_HR_DISPATCH.get, payload).then((response) => {
                this.datatable.items                    = response.data.data;
                this.datatable.totalItems               = response.total;
                this.loading.datatable                  = false;
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
            });
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getScheduleTemplates({ query: this.search });
        },
        viewScheduleTemplate(item){
            this.$store.dispatch('showWorkScheduleDetails', item).then((res)=>{
                console.log(res)
                this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", res);
                this.$store.commit("DIALOG", true);
                this.$store.commit("ACTION", "View");
            })
        },
        editScheduleTemplate(item){
            this.$store.dispatch('showWorkScheduleDetails', item).then((res)=>{
            this.$store.commit("HR_OTHER_TABS_DIALOG_VIEW", res);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Update");
            })
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        deleteScheduleTemplate(item){
            Swal.fire({
                    icon: 'question',
                    text: `Are you sure you want to Delete Template ?`,
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "#397373",
                })
                .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(this.GET_HR_DISPATCH.delete, item.id).then((success) => {
                        Swal.fire("Success!", "Schedule Template Deleted", "success");
                        });
                    }
                });
        },
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getScheduleTemplates();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_TIMEKEEPING_NEW_DATA:{
            handler(val){
                if(val && this.GET_HR_TAB_IS_ACTIVE == 'schedule_template'){
                    this.getScheduleTemplates();
                    this.$store.commit('TIMEKEEPING_NEW_DATA',false)
                }
            }
        },
        GET_HR_TAB_IS_ACTIVE:{
            handler(val){
                if(val  == 'schedule_template'){
                    this.getScheduleTemplates();
                }
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}
</style>
