<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-title>
                <v-row class="mx-auto d-flex justify-space-between">
                    <v-spacer/>
                    <v-col sm="12" md="12" lg="3" class="mx-1">
                        <v-text-field
                            v-model="search"
                            label="Search LB#"
                            single-line
                            hide-details
                            append-icon="mdi-magnify"
                            @keydown.enter="getAllLogistic()"
                            @click:append="getAllLogistic()"
                        ></v-text-field>

                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :items="dataTable.logistics"
                    :headers="lbHeaders"
                    :options.sync="dataTable.options"
                    :search="search"
                    :server-items-length="totalLogistics"
                    :loading="!loaded"
                    dense
                >
                    <template v-slot:item.target_total_amount="{ item }">
                        <span class="mt-1">
                            &#8369; {{ item.target_total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:item.dispatch_id="{ item }">
                        <span class="mt-1">
                            {{ item.dispatch_id }}
                        </span>
                    </template>

                    <template v-slot:item.delivery_date="{ item }">
                        <td>{{ deliveryDate(item) }}</td>
                    </template>

                    <template v-slot:item.rush="{ item }">
                        <v-avatar
                            :color="item.rush == 1 ? 'success' : 'error'"
                            size="15"
                        ></v-avatar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn icon text @click="viewLogistic('View',item)" color="orange" small>
                            <v-icon small >mdi-eye</v-icon>
                        </v-btn>
                        <v-btn v-if="userAccess.edit_lb && service == 0" icon text @click="editLogistic('Edit',item)" :disabled="item.confirm_dispatch" color="primary" small>
                            <v-icon small >mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";

export default {
    mixins: [SharedFunctionsComponentVue],
    components: {
    },
    props: [
        'tab',
        'rush',
        'service',
    ],
    data() {
        return {
            dataTable: {
                logisticHeaders:[
                    { text: 'LB#', sortable: false, value: 'series'},
                    { text: 'Vehicle', sortable: false, value: 'vehicle_name'},
                    { text: 'Delivery Date', sortable: false, value: 'delivery_date'},
                    { text: 'Dispatch', sortable: false, value: 'dispatch_id'},
                    { text: 'Target Total Amount', sortable: false, value: 'target_total_amount'},
                    { text: 'Rush', align: 'center', sortable: false, value: 'rush'},
                    { text: 'Actions', align: 'center', sortable: false, value: 'actions'},
                ],
                logistics: [],
                options: {},
            },
            loaded: false,
            totalLogistics: 0,
            search: '',
            userAccess: {
                edit_lb: false,
            },
        }
    },

    computed: {
        ...mapGetters([
            'GET_REFRESH_LOGISTIC',
            'USER_ACCESS',
        ]),

        lbHeaders() {
            if (this.tab == 'rush') {
                this.$store.commit('REFRESH_LOGISTIC', true)

                return this.dataTable.logisticHeaders
            } else if (this.tab == 'regular') {
                this.$store.commit('REFRESH_LOGISTIC', true)

                return this.dataTable.logisticHeaders
            } else {
                this.$store.commit('REFRESH_LOGISTIC', true)

                return this.dataTable.logisticHeaders
            }
        }
    },

    methods: {
        async getAllLogistic() {
            this.loaded = false
            this.dataTable.logistics = [];
            this.$store.commit('REFRESH_LOGISTIC', true)

            let payload = {
                itemsPerPage            : this.dataTable.options.itemsPerPage,
                page                    : this.dataTable.options.page,
                search                  : this.search,
                rush                    : this.rush,
                service                 : this.service,
                url                     : 'order-delivery-schedule/getLogistics'
            }
            await this.$store.dispatch('getDelivery',payload).then(response => {
                this.dataTable.logistics            = response.data.data.data;
                this.totalLogistics                 = response.data.data.total;
                this.loaded                         = true
                this.$store.commit('REFRESH_LOGISTIC', false)
            })
        },

        deliveryDate(item) {
            if (!!item) {
                return this.$dayjs(item.delivery_date).format('YYYY-MM-DD, hh:mm:ss ');
            }
            return '';
        },

        viewLogistic(action = 'View',item) {
            this.$store.commit('COMPONENT', true)
            this.$store.commit('LOGISTIC_VIEW', item)
        },

        editLogistic(action = 'Edit',item) {
            this.$store.commit('EDIT_COMPONENT', true)
            this.$store.commit('LOGISTIC_VIEW', item)
        },
    },

    watch: {
        'dataTable.options': {
            handler(val) {
                this.getAllLogistic()
            },
            deep: true,
        },

        'rush': {
            handler(val) {
                this.getAllLogistic()
            },
            immediate: true,
        },

        GET_REFRESH_LOGISTIC: {
            handler(val) {
                if(val) {
                    this.getAllLogistic()
                }
            },
        },

        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit_lb = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit_lb") {
                            this.userAccess.edit_lb = true;
                        }
                    });
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.getAllLogistic()
    }

}
</script>

<style>

</style>