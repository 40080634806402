import WebMailGroupComponent from '@/views/main/modules/WebMail/WebMailGroupComponent.vue'
import repository from "@/api/credentials";

const routes = [
    {
        path: "/hr/web-mail/:id",
        name: "webmail",
        component: WebMailGroupComponent,
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Employee Data',
        }
    },
];
export default routes;