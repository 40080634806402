<template>
  <v-container fluid>
    <h3 class="page-title">CHEQUE VOUCHER</h3>
    <v-container class="ma-0">
      <v-app id="item-tabs">
        <v-tabs
          v-model="tab"
          id="group-tab"
          color="cyan"
          slider-color="cyan"
          background-color="#424242"
          dark
          show-arrows
        >
          <v-tabs-slider color="cyan"></v-tabs-slider>
          <v-tab
            ripple
            v-for="(item, i) in items"
            :key="i"
            @click="tabs(i)"
          >
            {{ item.tab_name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, i) in items" :key="i">
            <keep-alive>
              <component :is="cheque_voucher_items.components" />
            </keep-alive>
          </v-tab-item>
        </v-tabs-items>
      </v-app>
    </v-container>
    <DialogComponent
      :cp="components"
      :scrollable="dialog_scrollable"
      :width="'42rem'"
      :retainfocus="false"
    ></DialogComponent>
  </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import ChequeVoucherComponent from "@/views/main/modules/Accounting/ChequeVoucher/ChequeVoucherComponent.vue";
import ChequeVoucherViewComponent from "@/views/main/layouts/dialogs/Accounting/ChequeVoucherViewComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DialogComponent,
    ChequeVoucherComponent,
    ChequeVoucherViewComponent,
  },
  
  data() {
    return {
      components: ChequeVoucherViewComponent,
      items: [
        {tab_name:"ALL",view:true},
        {tab_name:"Pending",view:true},
        {tab_name:"Released",view:true},
        {tab_name:"Cleared",view:true},
      ],
      cheque_voucher_items: {
        components: ChequeVoucherComponent,
        dialog: ChequeVoucherViewComponent,
        dialog_title:'Cheque Voucher',
        status_text:"ALL",
        dialog_width: "100%",
        scrollable:false,
      },
      dialog_width: "",
      loaded: false,
      options: {},
      itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
      itemsPerPage: 10,
      tab: null,
      dialog_scrollable: true,
    };
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
    this.dropDowns();
    this.tabs(0);
  },
  methods: {
    dropDowns() {
      this.$store.dispatch("getBankSelection",{international:0, type:'AP'});
      this.$store.dispatch("getAdminSupplierSelection");
    },
    tabs(key) {
      this.components = this.cheque_voucher_items.dialog;
      this.dialog_width = this.cheque_voucher_items.dialog_width;
      this.dialog_scrollable = this.cheque_voucher_items.scrollable;
      this.$store.commit('SELECTED_TAB',key);
    },
    width(width) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "100%"
        case 'sm': return "100%"
        case 'md': return "100%"
        case 'lg': return "100%"
        case 'xl': return width
      }
    },
  },
  watch: {},
};
</script>
<style>
#group-tab {
  padding: 0 !important;
}

.x-scroll {
  overflow-x: hidden;
}
</style>
