<template>
  <v-container fluid>
         <h3 class="page-title"> Payment Transmittal </h3>
         <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" :loaded="loaded" dark show-arrows>
					<v-tabs-slider color="cyan" :loaded="loaded"></v-tabs-slider>
                    <v-tab v-for="(item, index) in items" :key="item" @click="tabs(index)" :loaded="loaded">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item  v-for="(items,index) in payment_transmittal_component" :key="index">
                        <keep-alive>
                            <PaymentTransmittalComponent :is="items.components" :rfStatus="items.tabStatus" :payment_transmittal_component="payment_transmittal_component" :isActive_tab="items.status_text" :tab="index" :loaded="loaded"></PaymentTransmittalComponent>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent :cp="components"  :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false" :loaded="loaded"></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import PaymentTransmittalComponent from '@/views/main/modules/Accounting/PaymentTransmittal/PaymentTransmittalComponent.vue';
import PaymentTransmittalAddComponent from '@/views/main/modules/Accounting/PaymentTransmittal/PaymentTransmittalAddComponent.vue';

import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            components:'',
            items: ["PAYMENTS"],
            payment_transmittal_component:[
                {
                components: PaymentTransmittalComponent,
                status_text: "PAYMENTS",
                dialog: PaymentTransmittalAddComponent,
                dialog_title: 'PAYMENTS',
                dialog_width: "95%",
                tabStatus: 'PAYMENTS',
                scrollable:true,
                dispatch:{
                        get: ['getCollectionReport', 'getPaymentTransmittal', 'viewPaymentTransmittal'], 
                        add: ['submitPaymentTransmittal'],
                        update: ['updateConfirm', 'updatePaymentTransmittal'],
                        delete: ['deletePaymentTransmittal'],
                    },
                },
            ],

            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable:true,
        }
    },

    computed: {
        ...mapGetters([
            
        ])
    },

    components: {
        DialogComponent,
        PaymentTransmittalComponent,
        PaymentTransmittalAddComponent,
    },

    mounted() {
        this.tabs(0)
    },

    methods: {
        tabs(key) {
			this.components = this.payment_transmittal_component[key].dialog;
			this.dialog_width = this.payment_transmittal_component[key].dialog_width;
			this.dialog_scrollable = this.payment_transmittal_component[key].scrollable;
			
            this.$store.commit('PAYMENT_TRANSMITTAL_REFRESH', true);
			this.$store.commit('PAYMENT_TRANSMITTAL_DISPATCH',this.payment_transmittal_component[key].dispatch)
		},

        height(width) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs': return "100%"
				case 'sm': return "100%"
				case 'md': return width
				case 'lg': return width
				case 'xl': return width
			}
		},
    },

    watch: {

    },

}
</script>

<style>

</style>