<template>
    <v-card>
        <v-card-title>
            <h4>ITEM REPLACEMENT</h4>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col lg="1">
                    <v-tooltip top>
                        <template v-slot:activator="{on ,attr}">
                            <v-btn @on="on" :attr="attr"  color="#F69F1A" small @click="toggleDialog('TOGGLE_ADD_DIALOG',PARTS_REPLACEMENT.DIALOG.VALUE);$store.commit('DIALOG_ACTION','ADD')" class="mx-3">
                                <v-icon>
                                    mdi-plus
                                </v-icon>
                                add
                            </v-btn>
                        </template>
                    </v-tooltip>
                </v-col>
                <v-spacer/>
                <v-col lg=3>
                    <v-text-field label="search" append-icon="mdi-magnify" v-model="dataTable.search"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12">
                <v-data-table
                    :items="dataTable.items"
                    :headers="dataTable.headers"
                    :options.sync="dataTable.options"
                    :server-items-length="dataTable.totalItems"
                    :items-per-page="10"
                    :loading="!dataTable.loaded"
                    dense
                >
                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="orange" v-if="PARTS_REPLACEMENT.ACCESS.includes('edit_item_substitution')" @click="select('VIEW',item)">
                        <v-icon class="btn-action" small >mdi-eye</v-icon>
                    </v-btn>
                    <v-btn text icon color="blue" v-if="PARTS_REPLACEMENT.ACCESS.includes('edit_item_substitution')" @click="select('EDIT',item)">
                        <v-icon class="btn-action" small >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="PARTS_REPLACEMENT.ACCESS.includes('delete_item_substitution')">
                        <v-icon class="btn-action" small >mdi-delete</v-icon>
                    </v-btn>
                </template>
                </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>

        <v-dialog v-model="PARTS_REPLACEMENT.DIALOG.VALUE" width="1000px" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span>{{ PARTS_REPLACEMENT.DIALOG.ACTION }} PARTS REPLACEMENT </span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog('TOGGLE_ADD_DIALOG',PARTS_REPLACEMENT.DIALOG.VALUE); resetField()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row>
                        <v-col lg="3">
                            <!-- {{  }} -->
                            <v-autocomplete
                                :items="selection.spare_parts"
                                v-model="form.item_id"
                                label="Spare part"
                                auto-select-first
                                dense
                                outlined
                            >

                            </v-autocomplete>
                        </v-col>
                        <v-divider/>
                    </v-row>
                    <v-row>
                        <v-col lg="12">
                            <v-card>
                                <v-card-title>
                                    <v-row>
                                        <v-col lg="5" class="d-flex justify-content-center text-align-center">
                                            <v-text-field
                                                label="Search"
                                                class="m-3"
                                                dense
                                                v-model="filters.search"
                                            ></v-text-field>
                                        </v-col>
                                        <v-spacer/>
                                        <v-col lg="2" class="d-flex justify-content-center text-align-center">
                                            <!-- {{ form.substitution_item_id.length }} -->
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        class="mr-4"
                                                        fab
                                                        text
                                                        @click="filters.view = !filters.view"
                                                        small
                                                        :color="filters.view ? '#F69F1A ': '#CC6600'"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :loading="!form.loaded"

                                                    >
                                                    <!-- <v-icon>mdi-toolbox</v-icon> -->
                                                    <v-badge
                                                    color="#F69F1A "
                                                    :content="form.substitution_item_id.length">
                                                     <v-icon>mdi-toolbox</v-icon>
                                                    </v-badge>
                                                    </v-btn>
                                                </template>
                                                <span>View selected parts</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <v-card height="400px" style="overflow-y:auto" elevation="0" @scroll="handleScroll">
                                        <v-card-text>
                                            <v-row style="1">
                                                <v-col lg="3" v-for="(items,index) in selection.items" :key="index">
                                                    <v-chip
                                                        class="w-100"
                                                        :color="form.substitution_item_id.find(e=>e.value == items.value) ? '#F69F1A ': '#CC6600'"
                                                        label
                                                        text-color="white"
                                                        style="cursor:pointer"
                                                        @click="selectItem(items)"
                                                    >
                                                        <v-icon left>
                                                            mdi-wrench
                                                        </v-icon>
                                                        {{ items.text }}
                                                    </v-chip>
                                                </v-col>
                                                <v-col  lg="3" v-for="(number,index) in 9" :key="index" >
                                                    <v-btn class="w-100 bg-pink" loading color="#CC6600" style="opacity:60%" v-if="!selection.loaded">
                                                        <v-chip
                                                        class="w-100"
                                                        color="#CC6600"
                                                        label
                                                        disabled
                                                        text-color="white"
                                                    >
                                                        <v-icon left>
                                                            mdi-wrench
                                                        </v-icon>
                                                    </v-chip>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-center" v-if="PARTS_REPLACEMENT.ACCESS.includes('add_item_substitution') && ['EDIT','ADD'].includes(PARTS_REPLACEMENT.DIALOG.ACTION)">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attr}">
                            <v-btn fab text small color="success"
                            v-bind="attr"
                            v-on="on" @click="confirm()">
                                <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Confirm</span>
                    </v-tooltip>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
export default {
    mixins:[SharedFunctionsComponentVue],
    data(){
        return{
            form:{
                substitution_item_id:[],
                item_id:[],
                loaded:true
            },
            selection:{
                spare_parts:[],
                items:[],
                itemsPerPage:102,
                scroll:{
                    scrollHeight: 832,
                },
            },
            dataTable:{
                loaded:true,
                items:[],
                headers:[
                    { text: 'Model', value: 'model'},
                    { text: 'Name', value: 'name' },
                    { text: 'Part#', value: 'part_num'},
                    { text: "Action", align: "center", value: "action",sortable:false},
                ],
                search:'',
                options:{},
                totalItems:0
            },
            filters:{
                search:'',
                view:false
            },
            userAccess:[]
        }
    },
    mounted(){
        this.getItems()
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        this.$store.dispatch('getAllItems')
        this.$store.dispatch('itemselection',{types:[3]}).then(response=>this.selection.spare_parts = response);
    },
    methods:{
        list(){
            this.dataTable.loaded = false
            let payload = {
                    page: this.dataTable.options.page,
                    itemsPerPage: this.dataTable.options.itemsPerPage,
                    search: this.dataTable.search,
                }
            this.$store.dispatch('getAllSpareParts',payload).then(response=> {
                this.dataTable.items = response.data.data.data;
                this.dataTable.totalItems = response.data.totalItems
                this.dataTable.loaded = true
            })
        },
        select(action,item){
            this.$store.commit('DIALOG_ACTION',action)
            this.$store.commit('DATA_TABLE_SELECTED',item)
            this.form.item_id = item.id
            this.toggleDialog('TOGGLE_ADD_DIALOG',this.PARTS_REPLACEMENT.DIALOG.VALUE);
        },
        handleScroll(event) {
            if (event.target.scrollTop + event.target.clientHeight === event.target.scrollHeight) {
                this.selection.loaded = false
                this.selection.itemsPerPage += 102
                this.selection.scroll.scrollBottom += event.target.scrollWidth
                this.$forceUpdate()
                this.getItems()
            }
        },
        async getItems(){
            await this.$store.dispatch('itemselection',{
                service:1,
                types:[3],
                paginator:1,
                itemsPerPage:this.selection.itemsPerPage,
                active:1,
                search:this.filters.search

            }).then(response=>{
                this.selection.items = response.data.map(e=>{
                    e.item_id = e.value
                    return e
                })
                this.selection.loaded = true
            })

            this.$forceUpdate()
        },
        selectItem(item){
            if(this.PARTS_REPLACEMENT.DIALOG.ACTION == 'VIEW'){
                return false
            }
            if(!this.form.substitution_item_id.find(e => item.value == e.value)){
                this.form.substitution_item_id.push(item)
            }else{
                this.form.substitution_item_id = this.form.substitution_item_id.filter(e=>{
                    if(e.value != item.value){
                        return e
                    }
                })
            }
        },

        confirm(){
            swal.fire({
                title:'Add Replacement',
                text:'Are you sure you want to Add',
                icon:'info',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('servicePost',{
                        url:'parts-replacement/create',
                        ...this.form
                    }).then(response=>{
                        swal.fire('Success!','','success')
                    }).catch(err=>{
                        console.log(err)
                        swal.fire('Error!','','error')
                    })
                    this.resetField()
                }
            })
        },
        async selectedDetails(id){
            this.form.loaded = false
            this.$store.dispatch('serviceGet',{
                url:'parts-replacement',
                id:id
            }).then(response=>{
                this.form.substitution_item_id = response.data
            })
            this.form.loaded = true
        },
        resetField(){

            Object.assign(this.$data, this.$options.data.call(this));
            this.$store.dispatch('itemselection',{types:[3]}).then(response=>this.selection.spare_parts = response);
            this.getItems()
        }
    },
    computed:{
        ...mapGetters([
            'PARTS_REPLACEMENT',
            'USER_ACCESS',
            'GET_ITEM_SELECTION'
        ]),
    },
    watch:{
        'dataTable.options':{
            handler(val){
                this.list()
            }
        },
        'filters.search':{
            handler(val){
                this.getItems()
            }
        },
        'filters.view':{
            handler(val){
                if(val){
                    this.selection.items = this.form.substitution_item_id
                }else{
                    this.getItems()
                }
            }
        },
        'USER_ACCESS':{
            handler(val){
                if(val != "fail"){
                    this.$store.commit('CHECK_ACCESS',val.map(e=>e.actions_code));
                }
            }
        },
        'form.item_id':{
            handler(val){
                if(!!val){
                   this.selectedDetails(val)
                }
            },
            deep:true
        },
        'dataTable.search':{
            handler(val){
                this.list()
                this.options.page = 1
            }
        }
    }
}
</script>

<style>

</style>
