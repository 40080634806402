	<template>
	<v-card elevation="20">
		<v-col
		class="d-flex no-block align-items-center justify-content-start pa-2"
		v-if="loaded == true"
		>
		<!-- <buttons class="ml-2"
			v-if="cp_text != 'is_inactive'"
			:action="buttons.actions.create"
			:button_icon="buttons.icons.create"
			:color="buttons.colors.create"
			@click="adddialog"
		></buttons> -->

		<div class="row" v-if="cp_text != 'is_inactive'">
          <div class="col-12 d-flex no-block align-items-center">
              <div class="btn-toolbar">
                  <v-btn @click="adddialog()">
                      <v-icon>mdi-plus</v-icon>
                      Add
                  </v-btn>
              </div>
          </div>
        </div>
		</v-col>

		<v-card-title>
		<v-spacer></v-spacer>
		<v-text-field @change="searchInput()" v-model="search" label="Search" single-line hide-details>
		</v-text-field>
		<v-icon @click="searchInput()">'mdi-magnify</v-icon>
		</v-card-title>

		<v-data-table
			:headers="headers"
			:items="item"
			:search="search"
			:options.sync="options"
			:server-items-length="totalCustomers"
			:items-per-page="10"
			:multi-sort="true"
			:loading="!loaded"
			:footer-props="{
				itemsPerPageOptions: itemsPerPageOptions,
			}"
			class="w-100"
			loading-text="Loading... Please wait"
		>
		<template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
			v-if="userAccess.view"
			:action="buttons.actions.view"
			:button_icon="buttons.icons.view"
			:color="buttons.colors.view"
			:button_text="buttons.type.text"
			:icon="buttons.type.icon"
			@click="viewdialog(item)"
        ></buttons>
        <buttons
			v-if="userAccess.edit"
			:action="buttons.actions.edit"
			:button_icon="buttons.icons.edit"
			:color="buttons.colors.edit"
			:button_text="buttons.type.text"
			:icon="buttons.type.icon"
			@click="editdialogs(item)"
        ></buttons>
        <buttons
			v-if="userAccess.delete"
			:action="buttons.actions.delete"
			:button_icon="buttons.icons.delete"
			:color="buttons.colors.delete"
			:button_text="buttons.type.text"
			:icon="buttons.type.icon"
			@click="deleteCustomer(item.id)"
        ></buttons> -->
		<v-btn text icon color="orange"  >
			<v-icon class="btn-action" small @click="viewdialog(item)">mdi-eye</v-icon>
		</v-btn>
		<v-btn text icon color="blue" >
			<v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
		</v-btn>
		<v-btn text icon color="red"  >
			<v-icon class="btn-action" small @click="deleteCustomer(item.id)">mdi-delete</v-icon>
		</v-btn>
      </template>
		</v-data-table>
	</v-card>
	</template>
	<script>
	import Swal from "sweetalert2";
	import { mapGetters } from "vuex";
	// import buttons from "../../../layouts/Buttons/DialogButton.vue";
	// import buttons_components from "../../../../../assets/js/components-js.js";

	export default {
		props: ["cp_text","component_customer"],
		// components: { buttons, buttons_components },
		data() {
			return {
			// buttons: buttons_components.buttons,
			loaded: false,
			totalCustomers: 0,
			options: {},
			customers: [],
			itemsPerPageOptions: [5, 10, 20, 50, 100],
			itemsPerPage: 10,
			search: "",
			awaitingSearch: false,
			headers: [
				{ text: "Customer Code", align: "left", value: "customer_code" },
				{ text: "Customer Name", align: "left", value: "customer_name" },
				{ text: "Latitude", align: "left", value: "latitude" },
				{ text: "Longitude", align: "left", value: "longitude" },
				{ text: "Classification", align: "left", value: "classification_name" },
				{ text: "Actions", align: "center", value: "action", sortable: false },
			],
			json_data: [],
			json_meta: [
				[
				{
					key: "charset",
					value: "utf-8",
				},
				],
			],
			userAccess: {
				view: false,
				create: false,
				edit: false,
				delete: false,
			},
			status_text: this.cp_text,
			selected: {},
			item: [],
			};
		},
		mounted() {
			this.$store.dispatch("checkAccessUser",this.$router.currentRoute.params.id);
			this.$store.dispatch("getClassification")
			this.renderLegends()
			this.renderMap()
		},
		methods: {
			getAllCustomers() {
				const payload = {
					search:this.search,
					page: this.options.page,
					itemsPerPage: this.options.itemsPerPage,
					url: 'getAllCustomerMap',
					method: 'table',
					tab: 'marker',
				};
				this.loaded = false;
				this.$store.dispatch("getCustomerMaps", payload).then((response) => {
					let data = response.data.data
					this.item = data.data
					this.totalCustomers = data.total
					this.loaded = true;
				}).catch((error) => {
					console.log(error);
				});
			},
			searchInput() {
				this.getAllCustomers();
			},
			viewdialog(item){
				this.$store.commit("EDIT_CUSTOMER_MAPS", item);
				this.$store.commit("DIALOG", true);
				this.$store.commit("ACTION", "View");
			},
			editdialogs(item) {
				this.$store.commit("EDIT_CUSTOMER_MAPS", item);
				this.$store.commit("DIALOG", true);
				this.$store.commit("ACTION", "Update");
			},
			deleteCustomer(id) {
				Swal.fire({
					icon: 'question',
					text: "Are you sure you want to Delete?",
					cancelButtonText: "Cancel",
					showCancelButton: true,
					allowOutsideClick: false,
					confirmButtonColor: "#397373",
					cancelButtonColor: "#397373",
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						this.$store.dispatch("postCustomerMaps", {
							id: id,
							url: 'deleteCustomerMaps',
							tab:'marker',
						}).then((success) => {
							Swal.fire({
								text: "Delete Customer Successful!",
								icon: "success",
							});
						});
					}
				});
			},
			adddialog() {
				this.$store.commit("DIALOG", true);
				this.$store.commit("ACTION", "Submit");
			},
			async renderLegends(){
				let payload = {
					url: 'getAllMapMarker',
					method: 'map',
					tab: 'marker'
				}
				await this.$store.dispatch('getCustomerMaps',payload).then(response=>{
					this.$store.commit('LEGENDS',response.data)
				})
			},
			async renderMap(){
				const payload = {
					url: 'getAllCustomerMap',
					method: 'map',
					tab: 'marker'
				};
				await this.$store.dispatch("getCustomerMaps", payload)
			}
		},
		computed: {
			...mapGetters([
				"DIALOGS",
				"GET_ALL_CUSTOMER",
				"TOTAL_CUSTOMER",
				"ACTION",
				"USER_ACCESS",
				"GET_CLASSIFICATIONS_DROPDOWN",
				'GET_NEW_CUSTOMER_MAPS',
				'GET_TAB'
			]),
		},
		watch: {
			options: {
				handler() {
					this.getAllCustomers();
				},
			},
			deep: true,
			DIALOGS:{
				handler(val){
					this.getAllCustomers()
				}
			},
			USER_ACCESS: {
				handler(val) {
					if(val != "fail"){
						this.userAccess.edit = false;
						this.userAccess.delete = false;
						this.userAccess.view = false;
						this.userAccess.create = false;
						val.forEach(e => {
							if (e.actions_code == 'edit') {
								this.userAccess.edit = true;
							} else if (e.actions_code == 'delete') {
								this.userAccess.delete = true;
							} else if (e.actions_code == 'view') {
								this.userAccess.view = true;
							} else if (e.actions_code == 'create') {
								this.userAccess.create = true;
							}
						});
					}
				},
			},
			GET_NEW_CUSTOMER_MAPS: {
				handler(val) {
					if(val){
						this.getAllCustomers();
						this.$store.commit('NEW_CUSTOMER_MAPS', false)
					}
				}
			},
		},
	};
	</script>
