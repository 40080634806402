<template>
    <v-card flat>
        <span v-if="['is_transfer'].includes(tab_name)">
            <v-row class="d-flex mx-4">
                <v-col v-if="[1, 2].includes(selected_parts)">
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="selectedTransfer.length == 0"
                                color="#f69f1a"
                                class="mr-4"
                                small
                                text
                                fab
                                @click="showBatchApproveDialog()"
                            >
                            <v-icon>mdi-form-select</v-icon>
                            </v-btn>

                        </template>
                        <span>Batch Approve</span>
                    </v-tooltip>
                    <v-tooltip bottom color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="selectedTransfer.length == 0"
                                fab
                                text
                                small
                                class="mr-4"
                                color="#f69f1a"
                                @click="printPDF(1)"
                                v-if="userAccess.includes('pt_print')"
                            >
                            <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Print PDF</span>
                    </v-tooltip>
                </v-col>
                <v-col class="d-flex justify-end align-center mr-3">
                    <a :class="`ml-1 badge badge-all ${selected_parts === 0 ? 'selected' : 'non-selected'}`" @click="selected_parts = 0; dataTable.options.page = 1; list(0);">all</a>
                    <a :class="`ml-1 badge badge-saved ${selected_parts === 1 ? 'selected' : 'non-selected'}`" @click="selected_parts = 1; dataTable.options.page = 1; list(1);">saved</a>
                    <a :class="`ml-1 badge badge-approved ${selected_parts === 2 ? 'selected' : 'non-selected'}`" @click="selected_parts = 2; dataTable.options.page = 1; list(2);">approve</a>
                    <a :class="`ml-1 badge badge-purple ${selected_parts === 3 ? 'selected' : 'non-selected'}`" @click="selected_parts = 3; dataTable.options.page = 1; list(3);">confirm</a>
                    <a :class="`ml-1 badge badge-cancelled ${selected_parts === 4 ? 'selected' : 'non-selected'}`" @click="selected_parts = 4; dataTable.options.page = 1; list(4);">cancelled</a>
                </v-col>
            </v-row>
            <v-card-title>
                <v-row class="mx-auto">
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="12" lg="2" class="mr-2">
                        <v-text-field
                            label="Search Transfer#/Remarks"
                            v-model="search"
                            hide-details
                            single-line
                            clearable
                            @keydown.enter="dataTable.options.page = 1; list(selected_parts)"
                            @click:clear="search='';list(selected_parts)"
                            ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="12" lg="2" class="mr-2">
                        <v-text-field
                            label="Search Item Name / Model"
                            v-model="searchItem"
                            append-outer-icon="mdi-magnify"
                            hide-details
                            single-line
                            clearable
                            @keydown.enter="dataTable.options.page = 1; list(selected_parts)"
                            @click:append-outer="dataTable.options.page = 1; list(selected_parts)"
                            @click:clear="searchItem='';list(selected_parts)"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    v-model="selectedTransfer"
                    :items="dataTable.items"
                    :headers="dataTable.headers"
                    :options.sync="dataTable.options"
                    :server-items-length="totalItems"
                    :search="search"
                    :loading="!dataTable.loaded"
                    :show-select="[1, 2].includes(selected_parts)"
                    :footer-props="{
                    itemsPerPageOptions: itemsPerPageOptions,
                    }"
                    dense
                >
                <template v-slot:[`header.data-table-select`]="{ header }">
                    <v-menu offset-x>
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-icon
                                        class="ml-0 pa-0"
                                        color="primary"
                                        v-on="{ ...tooltip, ...menu }"
                                        v-bind="attrs"
                                        medium
                                    >
                                    mdi-view-list
                                    </v-icon>
                                </template>
                                <span>Select items</span>
                            </v-tooltip>
                        </template>
                        <v-list
                        color="blue-grey lighten-5"
                        outlined
                        dense
                        tile
                        >
                        <v-list-item
                        v-for="(item, index) in ['Page', 'All', 'Clear']"
                        :key="index"
                        light
                        dense
                        @click="selectedOption(item)"
                        >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-simple-checkbox style="display: none;"></v-simple-checkbox>
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                    <v-simple-checkbox v-if="[1, 2].includes(item.status) > 0"
                        :value="isSelected"
                        @input="select($event)"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <span class="text-nowrap">
                        <span class="text-nonwrap d-flex justify-content-center">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="view(item,'VIEW')">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="![3,4].includes(item.status) && userAccess.includes('edit')">
                                <v-icon class="btn-action" small @click="view(item,'EDIT')">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="![3,4].includes(item.status) && userAccess.includes('delete')">
                                <v-icon class="btn-action" small @click="cancel(item)">mdi-delete</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="[1, 2].includes(item.status) && userAccess.includes('cancel')">
                                <v-icon class="btn-action" small @click="cancelled(item)">mdi-cancel</v-icon>
                            </v-btn>
                        </span>
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <div v-if="!!dataTable.statuses[item.status]">

                        <span :class="dataTable.statuses[item.status].class">{{dataTable.statuses[item.status].text}}</span>
                    </div>
                    <div v-else>
                        <span class="badge badge-dark">not found</span>
                    </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                    <span>{{dateWithFormat(item.created_at,"YYYY-MM-DD")}}</span>
                </template>
                </v-data-table>
                <v-overlay :value="PARTS_TRANSFER.DATA_TABLE.LOADING" z-index="998">
                    <v-progress-circular
                        :size="80"
                        :width="12"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-overlay>
            </v-card-text>
        </span>
        <span v-else>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" class="d-flex flex-row ml-5">
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :loading="!dataTable.loaded"
                                    fab
                                    text
                                    small
                                    class="mr-4"
                                    color="#f69f1a"
                                    @click="exportTransmittal()"
                                >
                                <v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Export Excel</span>
                        </v-tooltip>
                        <v-tooltip bottom color="#f69f1a">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :loading="!dataTable.loaded"
                                    class="mr-4"
                                    color="#f69f1a"
                                    fab
                                    text
                                    small
                                    @click="getTransmittal()"
                                >
                                <v-icon>mdi-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>REFRESH</span>
                        </v-tooltip>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" lg="3" class="mr-4 pr-4">
                        <v-text-field
                            label="Search Transmittal#"
                            dense
                            v-model="transmittal.search"
                            append-icon="mdi-magnify"
                            @change="getTransmittal()"
                            @click:append="getTransmittal()"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg='12'>
                        <v-data-table
                            v-model="selectedTransfer"
                            :items="dataTable.transmittalRecords"
                            :headers="dataTable.transmittalHeaders"
                            :options.sync="dataTable.options"
                            :server-items-length="dataTable.totalTransmittalRecords"
                            :search="search"
                            :loading="!dataTable.loaded"
                            :show-select="[1, 2].includes(selected_parts)"
                            :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            dense
                        >
                            <template v-slot:[`item.date_printed`]="{ item }">
                                <span class="text-nowrap">
                                    {{ item.date_printed | formatDateTime }}
                                </span>
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                                <span class="text-nowrap">
                                    {{ item.id.toString().padStart(5, '0') }}
                                </span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <span class="text-nowrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewPartsTransferTransmittal(item)">mdi-eye</v-icon>
                                    </v-btn>
                                    <!-- <v-btn text icon color="blue" v-if="userAccess.edit">
                                        <v-icon class="btn-action" small @click="editCheckerForm(item.id)">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn text icon color="red" v-if="userAccess.delete">
                                        <v-icon class="btn-action" small @click="currentCheckerFormId = item.id; override.approve_override_delete_dialog = true;">mdi-delete</v-icon>
                                    </v-btn> -->
                                </span>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-overlay :value="PARTS_TRANSFER.DATA_TABLE.LOADING" z-index="998">
                        <v-progress-circular
                            :size="80"
                            :width="12"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </v-overlay>
                </v-row>
            </v-card-text>
        </span>

        <v-dialog v-model="batchApproveDialog" width="30%" persistent>
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <v-col cols="11">
                        List of Parts Transfers Items
                    </v-col>
                    <v-col cols="1">
                        <v-btn
                            color="gray"
                            text
                            icon
                            @click="closeBatchApproveDialog"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <v-simple-table
                        fixed-header
                        height="300px"
                        width="100%"
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    PRP#
                                </th>
                                <th class="text-left">
                                    Part Num
                                </th>
                                <th>
                                    Stock Available
                                </th>
                                <th class="text-left">
                                    Transfer Qty
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(item,index) in selectedPartsTransfersWithItemStocks.items"
                                :key="index"
                                >
                                    <td>{{ item.series }}</td>
                                    <td>{{ item.part_num }}</td>
                                    <td :class="item.stocks < item.transfer_quantity ? 'red' : ''">{{ item.stocks }}</td>
                                    <td>{{ item.transfer_quantity }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-row>
                        <v-chip
                            class="ml-4 mt-4"
                            color="#000000"
                            label
                            outlined
                        >
                            Total Parts Transfers:
                            <span class="red--text ml-2">{{ selectedPartsTransfersWithItemStocks.parts_transfers_count }}</span>
                        </v-chip>
                    </v-row>
                    <v-row>
                        <v-chip
                            class="ml-4 mt-2"
                            color="#000000"
                            label
                            outlined
                        >
                            Total Parts Transfers Items:
                            <span class="red--text ml-2"> {{ selectedPartsTransfersWithItemStocks.items.length }}</span>
                        </v-chip>
                    </v-row>
                </v-card-text>
                <v-flex class="d-flex justify-center py-4">
                    <v-tooltip top color="#f69f1a">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :loading="checkStocksLoading"
                                color="#f69f1a"
                                class="mr-4"
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                small
                                dark
                                fab
                                @click="approveMultiple()"
                            >
                            <v-icon>mdi-check-all</v-icon>
                            </v-btn>

                        </template>
                        <span>Approve</span>
                    </v-tooltip>
                </v-flex>
                <v-overlay :value="selectedPartsTransfersWithItemStocks.loading" z-index="998">
                    <v-flex class="d-flex flex-column justify-center align-center">
                        <v-progress-circular
                            :size="80"
                            :width="12"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </v-flex>
                </v-overlay>
                <v-dialog v-model="checkStocksLoading" width="500">
                    <v-card>
                        <v-card-title >
                            <span style="font-size:12px">
                                Checking for Stock Availability
                            </span>
                        </v-card-title>
                        <v-card-text>
                            <v-progress-linear
                                color="primary"
                                indeterminate
                                height="5"
                            >
                            </v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-dialog>
        <!-- VIEW INPUT DATE AND REMARKS -->
        <v-dialog v-model="before_print_dialog" persistent max-width="40%" scrollable>
            <v-card>
                <v-row dense class="m-0">
                    <v-card-title>
                        <span class="headline">Input Release Date and Remarks</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-center">
                                    PT#
                                </th>
                                <th class="text-center">
                                    Release Date
                                </th>
                                <th class="text-center">
                                    Release Remarks
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in selectedTransfer" :key="i">
                                    <td>
                                        <v-text-field
                                        v-model="item.series"
                                        :background-color="'#CFD8DC'"
                                        label="PT#"
                                        dense
                                        outlined
                                        readonly
                                        >
                                        </v-text-field>
                                    </td>
                                    <td>
                                        <v-menu
                                            v-model="item.menu4"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="290px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="item.complete_date"
                                                    v-on="on"
                                                    label="Release Date"
                                                    append-icon="mdi-calendar-clock"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    class="mx-2"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="item.complete_date" @change="applyToAll(item, 1)" @input:date="item.menu4 = false"></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <v-text-field
                                        v-model="item.remarks"
                                        label="Release Remarks"
                                        dense
                                        outlined
                                        @change="applyToAll(item, 2)"
                                        >
                                        </v-text-field>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-row dense>
                        <v-col cols="12" class="d-flex flex-row-reverse">
                            <v-btn
                            small
                            class="ma-2 pa-4"
                            @click="submitInput()"
                            >
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="'Cancel Parts Transfer'"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import SharedFunctionsComponentVue from '../../Shared/SharedFunctionsComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';

export default {
    props: [
        'tab_name',
    ],
    mixins:[SharedFunctionsComponentVue],
    components:{
        ConfirmOverrideComponentVue,
    },
    data() {
        return{
            search: "",
            dataTable:{

                items:[],
                headers:[
                    { text:'PT#',value:'series' },
                    { text:'Total Quantity',value:'total_quantity' },
                    { text:'Created by',value:'created_by' },
                    { text:'Remarks',value:'remarks' },
                    { text:'Note/Reason',value:'return_reason' },
                    { text:'Status',value:'status'},
                    { text:'Date',value:'created_at'} ,
                    { text:'Actions',value:'actions', align: 'center' },
                ],
                transmittalHeaders:[
                    { text: 'TRANSMITTAL#', align: 'left', value: 'series' },
                    { text: 'PRINTED BY', align: 'left', value: 'printed_by' },
                    { text: 'DATE PRINTED', align: 'left', value: 'date_printed' },
                    { text: 'Actions', align: 'center', value: 'action', sortable: false },
                ],
                options:{},
                loaded:true,
                statuses:{
                    1:{ text:'saved',class:'badge badge-saved'},
                    2:{ text:'approve',class:'badge badge-approved' },
                    3:{ text:'confirm',class:'badge badge-purple' },
                    4:{ text:'cancelled',class:'badge badge-cancelled' },

                },
                parts:[],
                transmittalRecords: [],
                totalTransmittalRecords: 0,
            },
            totalItems: 0,
            userAccess: [],
            selected_parts:0,
            selectedTransfer: [],
            batchApproveDialog: false,
            checkStocksLoading: false,
            option: {},
            itemsPerPageOptions: [10,20,30,40,50,100, -1],
            selectedPartsTransfersWithItemStocks: {
                items: [],
                parts_transfers_count: null,
                loading: false,
            },
            before_print_dialog: false,
            show_again_date: 0,
            show_again_remarks: 0,
            saved_tf: '',
            transmittal:{
                search: ''
            },
            searchItem:'',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['SR'],
                override_authenticated: false,
            },
            cancelItem:null,
        }
    },
    computed:{
        ...mapGetters([
            'PARTS_TRANSFER',
            'USER_ACCESS',

        ])
    },
    mounted(){
        this.list()
        // this.getTransmittal()
    },
    methods:{
        list(status = 0){
            if (!!this.selectedTransfer) {
                if (!!this.selectedTransfer[0]) {
                    if (this.selectedTransfer[0].status != this.selected_parts) {
                        this.selectedTransfer = [];
                    }
                }
            }
            this.dataTable.items = []
            this.$store.dispatch('partsTransferGet',{
                url:'parts-transfer',
                search:        this.search,
                status:        status,
                itemsPerPage:  this.dataTable.options.itemsPerPage,
                page:          this.dataTable.options.page,
                searchItem:    this.searchItem,
            }).then(response=>{
                this.dataTable.items        = response.data.data.data
                this.totalItems             = response.data.data.total
                this.dataTable.loaded       = true;
                if (status == 1 || status == 2) {
                    this.dataTable.parts = response.data.parts
                }
                // this.selected_parts = status;
            });
            this.dataTable.loaded = false;
            this.filled_date = 0;
            this.filled_remarks = 0;
        },
        async view(item,action){
            if(action == 'EDIT'){
                if(!this.userAccess.includes('edit')){
                    Swal.fire('Error!','- No Access','error')
                    return
                }
            }

            await this.$store.dispatch('partsTransferGet',{
                url:'parts-transfer/show',
                id:item.id,
                mutations:'DATA_TABLE_SELECTED'
            }).then(response=>{
                this.$store.commit('DIALOG_ACTION',action)
                this.toggleDialog('TOGGLE_ADD_DIALOG',this.PARTS_TRANSFER.DIALOG.ADD.VALUE)
            })
        },
        cancel(item){
            if(!this.userAccess.includes('delete')){
                Swal.fire('Error!','- No Access','error')
                return
            }
            Swal.fire({
                title:'Delete!',
                text:'- Are you sure you want to delete ?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('partsTransferGet',{
                        url:'parts-transfer/cancel-transfer',
                        ...item
                    }).then(async response =>{
                        if(!!item.sc_parts_order_id){
                            await this.$store.dispatch('serviceGet',{
                                url:'update-parts-order',
                                id:item.sc_parts_order_id,
                                status:'pending'
                            })
                        }

                        Swal.fire('Deleted!','','success')
                        this.list()
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            })


        },

        cancelled(item) {
            if (item.remarks.includes('Return Items')) {
                this.override.override_dialog = true
                this.cancelItem = item
                return
            }

            this.myFunction(item)
        },

        myFunction(item) {
            if(!this.userAccess.includes('cancel')){
                Swal.fire('Error!','- No Access','error')
                return
            }
            Swal.fire({
                title:'Cancel!',
                text:'- Are you sure you want to cancel ?',
                icon: 'question',
                showCancelButton:true,
                reverseButtons:true
            }).then(async confirm => {
                if(confirm.isConfirmed) {
                    await this.$store.dispatch('servicePost', {
                        url: 'cancel-parts-transfer',
                        ...item
                    }).then(response => {
                        Swal.fire('Cancelled!','','success')
                        this.cancelItem = null;
                        this.list()
                    }).catch(err => {
                        Swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
            })
        },

        searchInput() {
            this.list({
                search: this.search,
                page: 1,
                itemsPerPage: this.dataTable.options.itemsPerPage,
            });
        },

        fetchWarehouseItemStocks(items, whId) {
            let payload = {
                itemIds: items,
                warehouseId: whId,
                url: 'warehouse-item-stocks',
            }
            this.$store.dispatch('fetchWarehouseItemStocks', payload);
        },

        partsTransferItemWhStocks() {
            this.selectedPartsTransfersWithItemStocks.loading = true;
            const transfers = this.selectedTransfer.map( trans => trans.id);
            let payload = {
                partsTransferIds: transfers,
                url: 'parts_item_wh_stocks'
            }
            this.$store.dispatch('partsTransferGet', payload).then ( response => {
                this.selectedPartsTransfersWithItemStocks.items = response.data.items;
                this.selectedPartsTransfersWithItemStocks.parts_transfers_count = response.data.total_parts_transfer_selected;
                this.selectedPartsTransfersWithItemStocks.loading = false;
            }).catch( e => {
                console.log(e);
                this.selectedPartsTransfersWithItemStocks.loading = false
            })
        },

        showBatchApproveDialog() {
            this.partsTransferItemWhStocks();
            this.batchApproveDialog = true;
        },

        closeBatchApproveDialog() {
            this.batchApproveDialog = false;
            this.$store.commit('IS_APPROVED_MULTIPLE', false);
            this.selectedPartsTransfersWithItemStocks.items = [];
        },

        async approveMultiple() {
            let itemsAvailable = true;
            this.checkStocksLoading = true;
            if (this.option != 'All') {
                this.selectedTransfer.forEach(obj => {
                    const detail = this.dataTable.parts.find(e => obj.id === e.id);
                    if (!!detail) {
                        obj.item_id = detail.items;
                    }
                });
            }
            // Check if source warehouse has enough stock to transfer
            const promises = this.selectedTransfer.filter(trans => trans.status === 2)
                .map(async trans => {
                    const itemIds = trans.item_id.map(item => item.item_id);

                    try {
                    const response = await this.$store.dispatch('serviceGet', {
                        itemIds,
                        warehouseId: trans.source_warehouse_id,
                        url: 'warehouse-item-stocks'
                    });

                    const warehouseStocksDetails = response.data;

                    if (warehouseStocksDetails) {
                        trans.item_id.forEach(item => {
                        const whStocks = warehouseStocksDetails.find(stock => stock.item_id === item.item_id);

                        if (whStocks && whStocks.quantity < item.quantity) {
                            Swal.fire("- Warning", `PRP#: ${trans.series} - An item is not available in source WH.`, "warning");
                            itemsAvailable = false;
                            this.checkStocksLoading = false;
                        }
                        });
                    }
                    } catch (e) {
                    console.log(e);
                    }
                });

            await Promise.all(promises);

            if (itemsAvailable) {
                this.checkStocksLoading = false;
                this.$emit('approve-multiple',this.selectedTransfer);
                this.selectedTransfer = [];
                this.closeBatchApproveDialog();
            }
        },

        selectedOption(opt) {
            this.option = opt;
            if (opt === 'Page') {
                this.selectedTransfer = [];
                this.selectedTransfer = this.dataTable.items.slice();
            } else if (opt === 'All') {
                this.selectedTransfer = [];
                this.selectedTransfer = this.dataTable.parts.slice();
            } else if (opt === 'Clear') {
                this.selectedTransfer = [];
                this.show_again_date = 0;
                this.show_again_remarks = 0;
            }
        },

        checkWhStock(items) {
            let numOfItemsNoStock = 0;
            for (let item of items){
                if (item.wh_stock < item.quantity){
                    numOfItemsNoStock += 1;
                }
            }

            return numOfItemsNoStock;
        },

        printPDF(num){
            if(num == 1){
                this.before_print_dialog = true
                this.selectedTransfer.forEach(e => {
                    e.remarks = '',
                    e.complete_date = '';
                });
            }
            if(num !== 1){
                const doc = new this.$jspdf("p", "mm", "letter");
                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                doc.addImage(ingcoPhLogo, "png", 10, 12.7, 120, 20);
                doc.setFontSize(14);
                doc.line( 7, 35, 7, 10)
                doc.line( 153, 35, 153, 10)
                doc.line( 209, 35, 209, 10)
                doc.line(7, 10, 209, 10)
                doc.autoTable({
                    startY: 35,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        textColor: '#FF0000',
                        halign: 'center',
                        // cellWidth: 143
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth: 146, fontStyle:'bold'}, 1: {cellWidth:56, fontStyle:'bold'}},
                    columns: [
                        { header: 'TRANSMITTAL FORM', dataKey: 'TRANSMITTAL FORM' },
                        { header: `TRANSMITTAL#${this.saved_tf}`, dataKey: `` },

                    ],
                    body: [{}],
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#FFFFFF',
                        lineColor: '#FFFFFF',

                    },

                })
                // let lastAutoTableY = doc.lastAutoTable.finalY
                doc.autoTable({
                    startY: 42.5,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        halign: 'center',
                    },
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#000000',
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth: 63}, 1: {cellWidth:15}, 2: {cellWidth:18}, 3: { cellWidth:30}, 4: { cellWidth:30 }, 5: {cellWidth:46, halign: 'center'}},
                    body: this.selectedTransfer.reduce((row, detail)=>{
                        row.push(Object.assign({},{
                            pt:                     detail.series,
                            rf:                     detail.rf_number,
                            customer:               detail.customer_name,
                            date:                   detail.complete_date,
                            return_reason:          detail.return_reason,
                            remarks:                detail.remarks
                        }));
                        return row;
                    }, []),
                    columns: [
                        { header: 'CUSTOMER', dataKey: 'customer' },
                        { header: 'PT#', dataKey: 'pt' },
                        { header: 'RF#', dataKey: 'rf' },
                        { header: 'RELEASE DATE & TIME', dataKey: 'date' },
                        { header: 'Reason For Return', dataKey: 'return_reason' },
                        { header: 'REMARKS', dataKey: 'remarks'},
                    ],
                })
                doc.save('Parts Transfer Print.pdf')
            }
        },
        async applyToAll(data, num){
            if(data.complete_date !== null && data.complete_date !== '' && this.show_again_date == 0 && num === 1){
                await Swal.fire({
                    icon: "question",
                    text: "Do you want to apply this date on other selected PT?",
                    input: "checkbox",
                    inputPlaceholder: "Don't show this again",
                    inputValue: this.show_again_date,
                    reverseButtons:true,
                    showDenyButton:true,
                    returnInputValueOnDeny:true,
                    confirmButtonColor: "#397373",
                    denyButtonColor: "grey",
                    inputValidator: (r) =>{
                        this.show_again_date = r
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let selected = this.selectedTransfer.map(e => e);
                        selected.forEach((e) => {
                            if(e.complete_date === '') {
                                e.complete_date = data.complete_date;
                            }
                        });
                    }
                });
            }

            if(data.remarks !== null && data.remarks !== '' && this.filled_remarks == 0 && this.show_again_remarks == 0 && num === 2){
                await Swal.fire({
                    icon: "question",
                    text: "Do you want to apply this remarks on other selected PT?",
                    input: "checkbox",
                    inputPlaceholder: "Don't show this again",
                    inputValue: this.show_again_remarks,
                    allowOutsideClick: false,
                    reverseButtons:true,
                    confirmButtonColor: "#397373",
                    showDenyButton:true,
                    returnInputValueOnDeny:true,
                    denyButtonColor: "grey",
                    inputValidator: (r) =>{
                        this.show_again_remarks = r
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let selected = this.selectedTransfer.map(e => e);
                        selected.forEach((e) => {
                            if(e.remarks === '') {
                                e.remarks = data.remarks;
                            }
                        });
                    }
                });
            }
        },
        submitInput(){
             let payload = {
                url: '/savePtPrintDetails',
                items: this.selectedTransfer,
                from: 1
            }
            this.$store.dispatch('partsTransferPost', payload).then((response)=>{
                let res = response.data
                this.saved_tf = res.series
                this.printPDF(2);
                Swal.fire('Save and Print Successful', '', 'success')
                this.closeDialog();
            });
        },
        closeDialog(){
            this.before_print_dialog = false;
            this.show_again_date = 0;
            this.show_again_remarks = 0;
            this.selectedTransfer = [];
        },
        async getTransmittal(){
            this.dataTable.loaded = false
            this.dataTable.transmittalRecords = [];
            let payload = {
                url: 'getPtPrintDetails',
                from: 1,
                page: this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,
                search: this.transmittal.search.replace(/^0+/, '')
            }
            await this.$store.dispatch('partsTransferGet', payload).then((response)=>{

                let res = response.data.data

                this.dataTable.transmittalRecords = res.data
                this.dataTable.totalTransmittalRecords = res.total
                this.dataTable.loaded = true
            })
        },
        viewPartsTransferTransmittal(item) {
                this.$store.dispatch('partsTransferGet',{
                url:'viewPtPrintDetailItems',
                id: item.id,
                from: 1,
            }).then(response=>{
                this.$store.commit('DATA_TABLE', response.data)
                this.toggleDialog('TOGGLE_ADD_DIALOG_VIEW', this.PARTS_TRANSFER.DIALOG.ADD.VIEW)
            })
        },
         exportTransmittal(){
            let that = this;
            that.loaded = false;
            that.transmittalRecords = [];
            let payload = {
                url: 'exportTransmittal',
                print_type: 'PT'
            }
            let exportHeadersAndData = {
                ['Transmittal Sheet']: {
                    headers:[
                        { header: "Transmittal#", width: 12, key: "transmittal_num" },
                        { header: "PT#", width: 8, key: "series" },
                        { header: "RF#", width: 8, key: "rf_number" },
                        { header: "Customer Name", width: 25, key: "customer_name" },
                        { header: "Source", width: 11, key: "source" },
                        { header: "Destination", width: 11, key: "destination" },
                        { header: "Release Date", width: 13,  key: "release_date_time" },
                    ],
                }
            }
            that.$store.dispatch('partsTransferGet', payload).then((response) => {
                exportHeadersAndData['Transmittal Sheet'].data = response.data;
                this.exportExcel(exportHeadersAndData, `PT Transmittal`, 'F69F1A');
                that.getTransmittal();
                that.loaded = true;
            })
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.myFunction(this.cancelItem)
            } else {
                // swal.fire('Error!','- invalid password','error')
                this.override.override_dialog = false;
            }
        },
    },
    watch:{
        'PARTS_TRANSFER.DIALOG.ADD.VALUE':{
            handler(val){
                if(!val){
                    this.$store.commit('DIALOG_ACTION','')
                }
            }
        },

        'PARTS_TRANSFER.DIALOG.ADD.VIEW':{
            handler(val){
                if(!val){
                    this.$store.commit('DIALOG_ACTION','')
                }
            }
        },

        USER_ACCESS:{
            handler(val){
                if(val != "fail"){

                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        },

        'dataTable.options': {
            handler(val) {
                this.list(this.selected_parts);
                this.getTransmittal();
            },
            deep: true,
        },

        'PARTS_TRANSFER.DATA_TABLE.SELECTED.data': {
            handler(val) {
                let items = [];
                val.item_id.forEach( item => {
                    items.push(item.item_id);
                })
                this.fetchWarehouseItemStocks(items, val.source_warehouse_id);
            }
        },
        'search': {
            handler(val) {
                if (!val) {
                    this.dataTable.options.page = 1;
                    this.list(this.selected_parts);
                }
            }
        }
    }
}
</script>

<style scoped>

.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}

</style>
