<template>
    <v-container class="m-0 p-0">
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search_key"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    @change="() => {
                        data_table.options.page = 1;
                        getAll();
                    }"
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="data_table.headers"
                :items="GET_VAT_TYPES"
                :options.sync="data_table.options"
                :server-items-length="data_table.total_items"
                :loading="data_table.loading"
                :no-data-text="
                    search_key === ''
                        ? 'No VAT types found.'
                        : 'No VAT types match the search.'
                "
                @update:page="getAll()"
                @update:items-per-page="getAll()"
                >
                <template v-slot:[`item.percentage`]="{ item }">
                    {{ item.percentage }} %
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        v-if="userAccess.includes('view')"
                        color="orange"
                        text
                        icon
                        @click="viewOrUpdateVatType(item.id)"
                    ><v-icon small>mdi-eye</v-icon></v-btn>
                    <v-btn
                        v-if="userAccess.includes('edit')"
                        color="blue"
                        text
                        icon
                        @click="viewOrUpdateVatType(item.id, true)"
                    ><v-icon small>mdi-square-edit-outline</v-icon></v-btn>
                    <v-btn
                        v-if="userAccess.includes('delete')"
                        color="red"
                        text
                        icon
                        @click="deleteVatType(item.id)"
                    ><v-icon small>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            userAccess: [],

            search_key: undefined,

            data_table: {
                loading: false,
                options:undefined,
                total_items: undefined,

                headers: [
                    { text: 'Code', value: 'code', sortable: false },
                    { text: 'Name', value: 'name', sortable: false },
                    { text: 'Percentage', value: 'percentage', sortable: false },
                    { text: 'Actions', width: '10%', value: 'actions', align: 'center', sortable: false },
                ],
            },
        };
    },

    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        .then(response => {
            this.userAccess = this.USER_ACCESS.map(access => access.actions_code);
        });
        this.getAll();
    },

    computed: {
        ...mapGetters([
            'USER_ACCESS',
            'GET_RELOAD_DATA_TABLE',
            'GET_VAT_TYPES',
        ]),
    },

    methods: {
        viewOrUpdateVatType(id, update = false) {
            this.$store.commit('VAT_TYPE_VIEW_ID', id);
            this.$store.commit('DIALOG', true);
            this.$store.commit('ACTION', update ? 'Update' : 'View');
        },
        deleteVatType(id) {
            swal.fire({
                title: 'Warning!',
                text: 'Are you sure you want to delete this VAT type?',
                icon: 'question',
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                reverseButtons: true,
                showLoaderOnConfirm: true,

                preConfirm: async () => {
                    return this.$store.dispatch('deleteVatType', {url: `delete-vat-type/${id}`}).then(response => {
                        this.$store.commit('RELOAD_DATA_TABLE', true);

                        swal.fire({
                            title: 'Delete VAT Type Success',
                            icon: 'success',
                        });
                    }).catch(error => {
                        swal.fire({
                            title: 'Delete VAT Type Failed',
                            icon: 'error',
                        });
                    });
                },
            });
        },
        getAll() {
            const payload = {
                url: 'get-vat-types',
                search_key: this.search_key,
                ...this.data_table.options,
            };

            console.log(this.data_table.options);

            this.data_table.loading = true;
            this.$store.dispatch('getVatTypes', payload).then(response => {
                this.data_table.loading = false;
                this.data_table.total_items = response.total_items;
            });
        }
    },

    watch: {
        'GET_RELOAD_DATA_TABLE': {
            handler(val) {
                if (!val) return;

                this.getAll();
                this.$store.commit('RELOAD_DATA_TABLE', false);
            },
            immediate: true,
        },
    },
}
</script>