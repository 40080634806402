<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title">Auto FOC</h3>
        <v-row class="pt-2">
            <v-col lg12>
                <v-tabs color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows v-model="actions.tab">
                    <v-tab>
                        SP
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="actions.tab" style="background-color:transparent">
                    <v-tab-item class="px-4">
                        <v-card style="border-radius:0px">
                            <v-card-text>
                                <v-row class="pt-2">
                                    <v-col lg="3"  class="d-flex justify-content-center px-3">
                                        <v-text-field label="Search" v-model="dataTable.search" dense prepend-inner-icon="mdi-magnify">

                                        </v-text-field>
                                    </v-col>
                                    <v-spacer/>
                                    <v-col lg="1" class="d-flex justify-content-end" v-if="access.includes('update')">
                                        <v-btn class="mx-3" icon fab text>
                                            <v-icon :color="actions.has_changes ? 'blue' :''" @click="update">
                                                mdi-content-save
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="pt-2">
                                    <v-col lg12>
                                        <v-data-table
                                            v-model="dataTable.selected"
                                            :headers="dataTable.headers"
                                            :items="dataTable.items"

                                            item-key="value"
                                            :show-select="access.includes('update')"
                                            dense
                                        >
                                            <template v-slot:item.warranty_foc="{ item }">
                                                <v-avatar
                                                :color="item.warranty_foc == 1 ? 'success' : 'error'"
                                                size="15"
                                            ></v-avatar>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            dataTable:{
                loaded:true,
                items:[],
                options:{},
                total:0,
                search:'',
                headers:[
                    { text:'Part Number',width:'30%',value:'text' },
                    { text:'Name ',width:'30%',value:'name' },
                    { text:'FOC ',width:'30%',align:'center',value:'warranty_foc' },
                ],
                selected:[],
            },
            actions:{
                tab:0,
                has_changes:false
            },
            access:[]
        }
    },
    mounted(){
        this.list()
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    methods:{
        async list(){
            this.loaded = false
            await this.$store.dispatch('getItems',{
                search:this.dataTable.search,
                is_sp:true,
                is_inactive_items:true,

            }).then(response =>{

                this.dataTable.items = response.data.map(e=>{
                    e.warranty_foc = e.warranty_foc.toString();
                    return e
                })
                this.dataTable.selected = this.dataTable.items.filter(e=>{
                    if(e.warranty_foc == '1'){
                        return e.value
                    }
                })


                this.loaded = true
            })

            this.actions.has_changes = false
        },

        update() {
            swal.fire({
                title:'Warning!',
                text:'Are your sure you want to save changes?',
                showCancelButton:true,
                reverseButtons:true,
                icon: 'question',
            }).then (async action=>{

                if(action.isConfirmed){
                    if(!this.actions.has_changes){
                        swal.fire('','- Nothing to change','info')
                        return
                    }
                    this.loaded = false

                    await this.$store.dispatch('itemsPost',{
                        url:'update-items',
                        item_ids:this.dataTable.selected.map(e=>e.value),
                        toUpdate:{
                            warranty_foc:'1'
                        }
                    }).then(response=>{

                        swal.fire('Success!','','success')
                        this.actions.has_changes = false
                        this.list()
                    }).catch(err=>{
                        swal.fire('Error!','','error')
                        console.log(err)
                    })

                    this.loaded = true
                }
            })
        },
    },
    watch:{
        'dataTable.search':{
            handler(val){
                this.list(val.page)
            }
        },
        'dataTable.selected':{
            handler(val){

                this.actions.has_changes = true
            }
        },
        USER_ACCESS: {
				handler(val) {

					if(val != "fail"){
                        this.access = val.map(e=>e.actions_code)
					}
				},
			},
    }
}
</script>

<style>

</style>
