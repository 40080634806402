import store from '../../../store';
import api from '../../api'

export default {
    AdminCreateAssets(params){
        return api.post('/admin/adminassetsmanagement',params)
    },
    AdminUpdateAssets(params){
        return api.put(`/admin/adminassetsmanagement/${params.asset_id}`,params)
    },
    AdminDeleteAssets(params){
        return api.delete(`/admin/adminassetsmanagement/${params}`)
    },
    getAllAdminAssets(params){
        return api.get('/admin/getAllAdminAssets',{params})
    },
    getAllAdminAssetsList(params){
        return api.get('/admin/getAllAdminAssetsList',{params})
    },
    exportAssets(params){
        return api.get('/admin/exportAssets',{params})
    },
    getAdminAssetListEmployees(params){
        return api.get(`/admin/getAdminAssetListEmployees/${params}`)
    },
    classificationTreeView(){
        return api.get('/admin/classificationTreeView')
    },
    getAdminItems(params){
        return api.get(`/admin/getAdminItems`,{params})
    },
    findAssets(params){
        return api.get(`/admin/adminassetsmanagement/findAsset/${params}`);
    },
    getPRFAdminItems(params){
        return api.get(`/admin/getPRFAdminItems`,{params})
    },
    getWarehouseItemSelections(params){
        return api.get(`/admin/getWarehouseItemSelections`,{params});
    },
    adminAssetsbatchAddition(params){
        return api.post(`/admin/adminAssetsbatchAddition`,params);
    },
    adminAssetManagementGet(params){
        return api.get('admin/'+params.url,{params});
    },
    adminAssetManagementPost(params){
        return api.post('admin/'+params.url,params);
    },
    async adminAssetManagementUntagged(params){
        return await api.get('dropdown/get-untagged-admin-items-selection',params);
    },
    async adminAssetManagementPoUntagged(params){
        return await api.get('dropdown/get-untagged-po-admin-items-selection',params);
    },
    async getReceivedAdminPurchaseOrders(params){
        return await api.get('dropdown/get-received-admin-purchase-orders',params);
    },
    getPrfWithAssets(params){
        return api.get(`/admin/getPrfWithAssets`,{params})
    },
}

