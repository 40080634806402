<template>
    <v-form ref="form" v-model="valid">
        <v-card class="pa-2">
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span v-if="actions=='Create' ">Sample Form Details</span>
                            <span v-else>Sample Form Details (SF-{{form.sample_form_num}}) </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="2">
                        <v-btn text icon color="gray" class="float-right" @click="closeSampleFormDialog(); resetFields();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-container class="overflow-auto" fluid>
                    <v-row class="m-0">
                        <v-col cols="2" lg="6" class="ma-0 pa-0 pb-1 d-flex flex-row mb-1 ml-1">
                                <FileViewer id="fileViewer" :delete_url="'deleteSampleFormFiles'" :url="file_viewer_url_details" :uploads="file_previews" :action="ACTION" :folder_name="'sample_form_uploads'" class="mx-1 mt-1"  @removeAttachment="removeAttachment"></FileViewer>
                                <FileUploadComponent v-if="actions == 'Create' || (form.status == 0 && actions == 'Update')" id="attachment" ref="fileUpload" @reset="clearFileUpload" @uploadedData="uploadedData" :action="actions" :parent_id="form.id" ></FileUploadComponent>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="4" class="d-flex justify-content-end pa-2" v-if="actions !='Create'">
                            <v-btn
                                v-if="form.status == 2 || form.status == 1"
                                :disabled="form.status == 2 || form.status == 1 ? false : true"
                                @click="showOverride('qr')"
                                class="mr-2"
                            >
                                <span class="hidden-sm-and-down">Print QR</span>
                                <v-icon right color="white">
                                    mdi-qrcode
                                </v-icon>
                            </v-btn>
                            <v-btn
                                v-if="form.status == 2 || form.status == 1"
                                :disabled="form.status == 2 || form.status == 1 ? false : true"
                                @click="showOverride('pdf',1)"
                                class="mr-2"
                            >
                                <span class="hidden-sm-and-down">Print PDF</span>
                                <v-icon right color="white">
                                    mdi-file-pdf-box
                                </v-icon>
                            </v-btn>
                            <v-btn
                                v-if="form.status == 2 || form.status == 1"
                                :disabled="form.status == 2 || form.status == 1 ? false : true"
                                @click="showOverride('pdf',0)"
                            >
                                <span class="hidden-sm-and-down">Download PDF</span>
                                <v-icon right color="white">
                                    mdi-file-download
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="m-0">
                        <v-col cols="12">
                            <v-row class="d-flex justify-content-start">
                                <v-col cols="12" sm="4" md="4">
                                    <v-autocomplete
                                        v-if="actions == 'Create' || actions =='Update'"
                                        v-model="form.salesman_id"
                                        :items="GET_SALESMAN_LIST"
                                        :rules="rules.required"
                                        :disabled="actions == 'Approve' || actions == 'Confirm' ? true : false"
                                        label="Select Salesman"
                                        item-text="name"
                                        item-value="id"
                                        auto-select-first
                                        outlined
                                        dense
                                        hide-details
                                        clearable
                                    >
                                    </v-autocomplete>
                                    <v-text-field
                                        v-else
                                        v-model="form.salesman_name"
                                        color="black"
                                        hide-details
                                        disabled
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="form.remarks"
                                        :disabled="actions == 'Approve' || actions == 'Confirm'? true : false"
                                        label="Remarks"
                                        outlined rows="3"
                                        auto-select-first
                                        dense
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        v-if="form.status == 3"
                                        v-model="form.cancel_reason"
                                        :disabled="actions == 'Approve' || actions == 'Confirm'? true : false"
                                        label="Cancellation Reason"
                                        outlined rows="3"
                                        auto-select-first
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row style="margin-left:1px; margin-top:5px;">
                        <v-col v-if="actions != 'Approve'">
                            <v-btn
                                @click="openBatchDialog();"
                                :disabled="actions == 'Approve' || actions == 'Confirm'? true : false"
                                color="primary"
                                dark
                                small
                            >
                                Batch Addition
                            </v-btn>
                            <v-btn
                                @click="addSampleFormItem()"
                                :disabled="actions == 'Approve' || actions == 'Confirm'? true : false"
                                color="primary"
                                class="mx-1"
                                dark
                                small
                                plain
                            >
                                <v-icon >mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- Batch Addition -->
                    <v-dialog
                        v-model="batch_addition.dialog"
                        persistent
                        max-width="30%"
                        max-height="30%"
                        scrollable
                    >
                        <v-card>
                            <v-row class="m-0">
                                <v-col>
                                    <v-card-title>
                                        <span class="headline">Batch Addition</span>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn
                                        text
                                        icon
                                        color="gray"
                                        class="float-right"
                                        @click="
                                        closeBatchAdditionDialog();
                                        model = '';
                                        "
                                    >
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-text class="ma-0">
                                <span v-if="hasError">
                                    <span style="color: red">Red : </span>Model not
                                    found in the system</span
                                    >
                                <div
                                class="pt-4 pa-2"
                                style="
                                    height: auto;
                                    width: 100%;
                                    overflow-y: hidden;
                                    overflow-x: hidden;
                                "
                                >
                                    <v-row
                                        class="mx-0 pa-0 ma-0 overflow-auto"
                                        v-for="(
                                        text_area_field, i
                                        ) in text_area_fields"
                                        :key="i"
                                    >
                                        <v-col cols="5" class="pa-0 ma-0">
                                            <v-text-field
                                                v-model="text_area_field.model"
                                                @paste="onPaste"
                                                @click="$event.target.focus()"
                                                :class="{
                                                    notInModel: text_area_field.error,
                                                }"
                                                :autofocus="batch_addition.dialog"
                                                color="primary"
                                                label="Model"
                                                rows="1"
                                                dense
                                                outlined
                                                small
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" class="py-0 ma-0 pl-2">
                                            <v-text-field
                                                v-model="text_area_field.quantity"
                                                :class="{
                                                    notInModel: text_area_field.error,
                                                }"
                                                label="Quantity"
                                                color="primary"
                                                rows="1"
                                                dense
                                                outlined
                                                small
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="ma-0">
                                            <v-btn
                                                @click="clearItem(i)"
                                                class="ma-2"
                                                fab
                                                dark
                                                small
                                                color="red"
                                                style="height: 20px; width: 20px"
                                            >
                                                <v-icon
                                                dark
                                                small
                                                style="font-size: 12px"
                                                >
                                                mdi-minus
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="ml-3">
                                            <v-btn
                                                @click="manualAddItem()"
                                                dark
                                                small
                                                color="primary"
                                                style="height: 20; width: 40px"
                                            >
                                                <span> Add </span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-row>
                                    <div class="col-12">
                                        <v-btn
                                        @click="submittedBtn()"
                                        :disabled="isDisabledButton"
                                        :loading="batch_addition.loading"
                                        class="float-right ml-2"
                                        >
                                            <template v-slot:loader>
                                                <span class="custom-loader">
                                                <v-icon light color="white">mdi-cached</v-icon>
                                                </span>
                                            </template>
                                            Submit
                                        </v-btn>
                                        <v-btn
                                        @click="clearFields()"
                                        class="float-right"
                                        dark
                                        color="secondary"
                                        >
                                        Clear
                                        </v-btn>
                                    </div>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-row>
                        <v-col>
                            <v-simple-table dense fixed-header class="dynamic_list_table mt-5 overflow-auto" style="max-height:400px;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center mx-2" width="22%">Model</th>
                                            <th class="text-center mx-2" width="40%">Name</th>
                                            <th class="text-center mx-2" width="30%">Category</th>
                                            <th class="text-center mx-2" width="10%">UOM</th>
                                            <th class="text-center mx-2" width="10%">Type</th>
                                            <th class="text-center mx-2" width="10%" v-if="actions =='Confirm' && (form.status == 2)">QR</th>
                                            <th class="text-center mx-2" width="10%">Borrow Qty</th>
                                            <th class="text-center mx-2" width="10%" v-if="form.status == 2">Return Qty</th>
                                            <th class="text-center mx-2" width="10%">Available Stocks</th>
                                            <th class="text-center mx-2" width="3%"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <span v-for="(sampleFormItem, i) in  form.sampleFormItems" :key="i" class="d-contents">
                                            <tr>
                                                <td>
                                                    <v-autocomplete
                                                        v-if="actions == 'Create' || actions =='Update'"
                                                        v-model="sampleFormItem.item_id"
                                                        :readonly=" actions == 'Approve' || actions == 'Confirm' || form.status == 2 || form.status == 3 ? true : false "
                                                        :items="dropdowns.inventory_items"
                                                        :rules="rules.required"
                                                        @change="validateManualAddItem(i)"
                                                        auto-select-first
                                                        item-text="text"
                                                        item-value="value"
                                                        class="small mt-2"
                                                        placeholder="Select Model"
                                                        item-disabled="isDisabled"
                                                        hide-selected
                                                        hide-details
                                                        outlined
                                                        dense
                                                    >
                                                    </v-autocomplete>
                                                    <v-text-field
                                                        v-else
                                                        v-model="sampleFormItem.item_model"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions) || ( actions == 'Update' && form.status != 0 || stock_validate == false) ? true : false "
                                                        background-color="grey lighten-2"
                                                        color="black"
                                                        hide-details
                                                        outlined
                                                        dense
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="sampleFormItem.item_name"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions) || form.status == 2 || form.status == 3 ? true : false "
                                                        background-color="grey lighten-2"
                                                        color="black"
                                                        hide-details
                                                        outlined
                                                        dense
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="sampleFormItem.item_categories"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions) || form.status == 2 || form.status == 3 ? true : false "
                                                        background-color="grey lighten-2"
                                                        color="black"
                                                        hide-details
                                                        dense
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="sampleFormItem.item_uom"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions) || form.status == 2 || form.status == 3 ? true : false "
                                                        background-color="grey lighten-2"
                                                        class="centered-input"
                                                        color="black"
                                                        dense
                                                        hide-details
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="sampleFormItem.item_types"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions) || form.status == 2 ? true : false "
                                                        background-color="grey lighten-2"
                                                        class="centered-input"
                                                        color="black"
                                                        dense
                                                        hide-details
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td  style="text-align: center; vertical-align: middle;"
                                                v-if="actions =='Confirm' && (form.status == 2)"
                                                >
                                                    <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-img
                                                                    :src="sampleFormItem.qr_code_base64"
                                                                    @click="viewQR(sampleFormItem.qr_code_base64,sampleFormItem.item_model)"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    style=" cursor: pointer; "
                                                                    class="img-thumbnail"
                                                                    width="41"
                                                                    height="41"
                                                                    contain
                                                                >
                                                                </v-img>
                                                            </template>
                                                        <span>View QR</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="sampleFormItem.borrowed_qty"
                                                        @change="validateBorrowQty(i,sampleFormItem)"
                                                        :class="{'yellow-background': negativeDifference(sampleFormItem)}"
                                                        :readonly="['Approve','View','Confirm'].includes(actions)   ? true : false"
                                                        :rules="rules.required"
                                                        class="alignText centered-input"
                                                        type="number"
                                                        min="0"
                                                        outlined
                                                        dense
                                                        hide-details
                                                    ></v-text-field>
                                                </td>
                                                <td v-if="form.status == 2">
                                                    <v-text-field
                                                        v-model="sampleFormItem.remaining_qty"
                                                        @blur="validateBorrowQty(i)"
                                                        :class="{'yellow-background': negativeDifference(sampleFormItem)}"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions)  ? true : false"
                                                        :rules="rules.required"
                                                        class="alignText centered-input"
                                                        outlined
                                                        dense
                                                        hide-details
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="sampleFormItem.remain_stocks"
                                                        :readonly="['Update','Create','Approve','View','Confirm'].includes(actions) || form.status == 2 || form.status == 3 ? true : false "
                                                        class="alignText centered-input"
                                                        background-color="grey lighten-2"
                                                        color="black"
                                                        outlined
                                                        dense
                                                        hide-details
                                                    ></v-text-field>
                                                </td>
                                                <td class="text-center">
                                                    <v-btn
                                                        @click="removeAdjustmentItemByIndex(i,sampleFormItem.id)"
                                                        :disabled="actions =='Approve' || actions == 'Confirm' ? true : false"
                                                        v-if="['Update','Create'].includes(actions)"
                                                        style="height:20px; width:20px;"
                                                        color="primary"
                                                        fab
                                                        dark
                                                        small
                                                    >
                                                        <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </span>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions class="text-center" style="border-top:1px solid #dee2e6; padding-top: 10px;">
                    <v-row class="ma-1 pb-2" v-if="form.status != 2">
                        <v-col cols="4" class="d-flex justify-center" v-if="(actions == 'Approve' || actions == 'Confirm') && (form.status == 0 || form.status == 1)">
                            <v-btn
                                @click="openCancelReasonDialog()"
                                color="red"
                            >
                                Cancel
                            </v-btn>
                            <!-- <v-btn
                                @click="changeSampleFormStatus(3)"
                                color="red"
                            >
                                Cancel
                            </v-btn> -->
                        </v-col>
                        <v-spacer v-if="(actions == 'Approve' || actions == 'Confirm') || form.status == 1">

                        </v-spacer>
                        <v-col v-if="form.status != 3" :cols="(actions == 'Approve' || actions =='Confirm') && (form.status == 0 || form.status == 1)  ? 4 : 12" class="d-flex justify-center">
                            <v-btn
                                @click="actions == 'Create' || actions == 'Update'  ? submitSampleForm() : changeSampleFormStatus(form.status == 0 ? 1 : 2)"
                                :disabled="(form.approvable == false && (actions == 'Approve' || actions == 'Confirm') ) || (actions == 'Create' && stock_validate == false) ? true : false"
                            >
                                {{actions}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            <v-container fluid style="display: none">
                <div>
                <table id="sf_details">
                    <tbody>
                    <tr>
                        <td>Salesman:</td>
                        <td>{{form.requested_by_name}}</td>
                        <td>Date:</td>
                        <td>{{ form.borrowed_date | formatDate }}</td>
                    </tr>
                    <tr>
                        <td>Remarks:</td>
                        <td>{{ form.remarks }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                </div>

                <div>
                <table id="items_details">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>MODEL</th>
                        <th>NAME</th>
                        <th>CATEGORY</th>
                        <th>UNITS</th>
                        <th>BORROWED QTY</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in form.sampleFormItems" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.item_model }}</td>
                        <td>{{ item.item_name }}</td>
                        <td>{{ item.item_categories }}</td>
                        <td>{{ item.item_uom }}</td>
                        <td>{{ item.borrowed_qty }}</td>
                    </tr>
                    <tr>
                        <td colspan="7">***** NOTHING FOLLOWS *****</td>
                    </tr>
                    </tbody>
                </table>
                </div>

                <div>
                <table id="footer">
                    <tbody>
                    <tr>
                        <td>Prepared By</td>
                        <td>Requested By</td>
                        <td>Approved By</td>
                    </tr>
                    <tr>

                        <td> <br><br>{{ form.prepare_by_name }}</td>
                        <td> <br><br>{{ form.requested_by_name }}</td>
                        <td> <br><br>{{ form.approve_by_name }}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </v-container>
            <v-dialog v-model="override.override_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Print</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeOverrideDialog(); override_username = ''; override_user_password = '';">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <!-- <div class="col-sm-12 col-md-6">
                                <label for="override_username" class="float-left control-label col-form-label">Username</label>
                                <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                                <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                            <div class="col-sm-12 col-md-12">
                                <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override.override_user_password" autocomplete="new-password">
                                <!-- <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="confirmedOverridePrint()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="qr_dialog" width="30%">
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">QR Code</span>
                            </v-card-title>
                        </v-col>

                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeQRView()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="my-0" />

                    <div class="iframe-container d-flex justify-center align-center">
                        <v-img :src="qr_image" max-width="300"/>
                    </div>
                    <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;" v-if="form.printed_qr == 1">
                        <v-row class="ma-1 pb-2">
                            <v-col>
                                <v-btn
                                    @click="showOverrideQr()"
                                >
                                    <span>Print</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="cancellation.dialog" width="40%" persistent>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Cancel Reason</span>
                            </v-card-title>
                        </v-col>

                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeCancelReasonDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="my-0" />
                        <v-row class="pa-5">
                            <v-col>
                                <v-textarea v-model="cancellation.reason" outlined rows="3" no-resize label="Reason"></v-textarea>
                            </v-col>
                        </v-row>
                    <v-card-actions class="text-center" style="border-top:1px solid #dee2e6;">
                        <v-row class="ma-1 pb-2">
                            <v-col>
                                <v-btn
                                    @click="changeSampleFormStatus(3)"
                                >
                                    <span>Submit</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2';
import QRCode from 'qrcode';

import FileUploadComponent from '@/views/main/layouts/dialogs/Operations/SampleForm/FileViewer/FileWithPreviewUploadComponent.vue'
import FileViewer from '@/views/main/layouts/dialogs/Operations/SampleForm/FileViewer/SampleFormFileViewer.vue'
import Swal from 'sweetalert2';

export default {
    components: {
        FileViewer,
        FileUploadComponent
    },
    data() {
        return {
            valid:false,
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 15,
            search: '',
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: [],
                loading:false
            },
            loading: false,
            form:{
                salesman_id:'',
                sampleFormItems: [],
                remarks: '',
            },
            dialog: false,
            cancellation:{
                dialog:false,
                reason:''
            },
            max_adjustment_items_count: 999,
            dropdowns:{
                inventory_items: []
            },
            rules: {
                required: [ value => !!value || 'Required.' ],
            },
            item_details:'',
            item_data:{},
            icon: 'justify',
            override: {
                override_dialog: false,
                authenticated:false,
                override_user_password:"",
                errors: [],
                override_departments_allowed: ['SM', 'EX', 'IT'],
                override_authenticated: false,
                type:"",
                isPrint:false
            },
            qr_dialog:false,
            qr_image:'',
            file_viewer_url_details:{
                dispatch:"getUploadFiles",
                api:"getSFUploadedFiles"
            },
            text_area_fields: [],
            hasError: false,
            isDisabledButton: false,
            listOfNotInModels: [],
            stock_validate: false,
            file_previews: [],
            userAccess : []
        }
    },
    mounted() {
        this.$store.dispatch('getSalesman')
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        this.getInventoryItems()
    },
    created(){
        this.initialForm();
    },
    methods: {
        initialForm(){
            this.form = {
                salesman_id:'',
                sampleFormItems: [],
                remarks: '',
                files: {
                    attachments:[],
                    config:{}
                },
                sf_trash_ids: []
            }

            this.stock_validate = false;
        },
        sampleFormDialog() {
            this.initialForm();
            // if (this.form.sampleFormItems.length == 0) {
            //     this.addSampleFormItem()
            // }
        },
        getInventoryItems() {
            let payload = {
                url: 'getAllInventoryItems'
            }
            this.$store.dispatch('getInventorySampleFormItems', payload).then(response => {
                this.dropdowns.inventory_items = this.GET_SAMPLE_FORM_ITEMS
            }).catch((err)=>{
                swal.fire(
                    'Network Error',
                    'error'
                )
                console.log(err);
            })
        },
        closeSampleFormDialog() {
            this.$store.commit("DIALOG", false);
            this.$store.commit('FILE_PREVIEWS',[]);
            this.clearFileUpload();
        },
        submitSampleForm() {
            switch(this.actions){
                case 'Create':
                    if(!this.userAccess.includes('create_issue')){
                        Swal.fire('Error!','- No Access','error')
                        return
                    }
                    break;
                case 'Update':
                    if(!this.userAccess.includes('update_issue')){
                        Swal.fire('Error!','- No Access','error')
                        return
                    }
                    break;
            }
            if(this.actions == 'Create'){
                this.$refs.form.validate();
                if(this.form.files.attachments.length == 0){
                    swal.fire(
                        '',
                        'Please Fill Upload Files',
                        'warning'
                    )
                }
                else if (this.form.sampleFormItems.length > 0 && this.form.salesman_id) {
                    swal.fire({
                        title: 'Are you sure you want to Borrow?',
                        text: 'Make sure that all information are correct',
                        icon: 'question',
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                        confirmButtonText: 'Confirm',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                url                     : 'addNewSampleForm',
                                id                      : this.form.id ?? 0,
                                salesman_id             : this.form.salesman_id,
                                remarks                 : this.form.remarks,
                                sample_items            : this.form.sampleFormItems,
                                sf_trash_ids            : this.form.sf_trash_ids,
                                access                  : this.USER_ACCESS.find(e=>{
                                                                if(this.actions == 'Create'){
                                                                    return e.actions == 'create_issue'
                                                                }
                                                                else{
                                                                    return e.actions == 'update_issue'
                                                                }
                                                            })
                            }
                            this.$store.dispatch('addNewSampleForm', payload).then(response => {
                                swal.fire({
                                    icon: response.data.icon,
                                    title: response.data.icon.toUpperCase(),
                                })

                                const data = new FormData();
                                data.append("id",response.data.id);

                                if (!!this.form.files && this.form.files.attachments.length > 0) {
                                    let files = this.form.files.attachments;
                                    let payload = {
                                        url:"uploadfilesSF",
                                        data: data,
                                        config: this.form.files.config,
                                    };
                                    for (let i = 0; i < _.compact(files).length; i++) {
                                        data.append("files[]", files[i]);
                                    }
                                    this.$store.dispatch('uploadfilesSF',payload)
                                }
                                this.resetFields()
                                this.$store.commit("DIALOG", false);
                            }).catch((err)=>{
                                swal.fire({
                                    title:'Error!',
                                    text: '- ' + err.response.data.message,
                                    icon:'error'
                                    }
                                )
                            })
                        }
                    })
                }
                else {
                    swal.fire(
                        '',
                        'Please Fill Required Fields',
                        'warning'
                    )
                }
            }
            else{
                if (this.form.sampleFormItems.length > 0 && this.form.salesman_id) {
                swal.fire({
                        title: 'Sample Form Will Be Updated',
                        text: 'Make sure that all information are correct',
                        icon: 'warning',
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                        confirmButtonText: 'Confirm',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                url                 : 'addNewSampleForm',
                                id                  : this.form.id ?? 0,
                                salesman_id         : this.form.salesman_id,
                                remarks             : this.form.remarks,
                                sample_items        : this.form.sampleFormItems,
                                sf_trash_ids        : this.form.sf_trash_ids,
                                access              : this.USER_ACCESS.find(e=>{
                                                            if(this.actions == 'Create'){
                                                                return e.actions == 'create_issue'
                                                            }
                                                            else{
                                                                return e.actions == 'update_issue'
                                                            }
                                                        })
                            }
                            this.$store.dispatch('addNewSampleForm', payload).then(response => {
                                swal.fire({
                                    icon: response.data.icon,
                                    title: response.data.icon.toUpperCase(),
                                })

                                const data = new FormData();
                                data.append("id",response.data.id);

                                if (!!this.form.files && this.form.files.attachments.length > 0) {
                                    let files = this.form.files.attachments;
                                    let payload = {
                                        url:"uploadfilesSF",
                                        data: data,
                                        config: this.form.files.config,
                                    };
                                    for (let i = 0; i < _.compact(files).length; i++) {
                                        data.append("files[]", files[i]);
                                    }
                                    this.$store.dispatch('uploadfilesSF',payload).catch((err)=>{
                                        console.log(err);
                                        swal.fire({
                                            icon: 'Network Error',
                                            title: 'error',
                                        })
                                    })
                                }
                                this.resetFields()
                                this.$store.commit("DIALOG", false);
                            }).catch((err)=>{
                                swal.fire({
                                    title:'Error!',
                                    text: '- ' + err.response.data.message,
                                    icon:'error'
                                    }
                                )
                            })
                        }
                    })
                }
                else {
                    swal.fire(
                        '',
                        'Please Fill Required Fields',
                        'warning'
                    )
                }
            }

        },
        resetFields() {
            this.errors = [];
            this.form.sampleFormItems = [];
            this.form.salesman_id = "";
            this.form.remarks = "";
        },
        addSampleFormItem() {
            if (this.form.sampleFormItems.length < this.max_adjustment_items_count) {
                this.form.sampleFormItems.push({
                    item_model: '',
                    item_name: '',
                    categories: '',
                    uom: '',
                    types: '',
                    borrowed_qty: 0,
                    remain_stocks: '',
                })
            }
        },
        validateBorrowQty(i,data){
            if(this.ACTION =='Submit' || this.ACTION == 'Update'){
                if(this.form.sampleFormItems[i].borrowed_qty > this.form.sampleFormItems[i].remain_stocks) {
                    swal.fire({
                        title: 'Warning',
                        text: 'Selected Model has Insufficient Stocks',
                        icon: 'warning',
                        allowOutsideClick: false,
                    })
                    // this.form.sampleFormItems[i].borrowed_qty = 0;
                }
                else if(this.form.sampleFormItems[i].borrowed_qty <= 0){
                    swal.fire({
                        title: 'Invalid Input',
                        text: 'Please Input a valid quantity',
                        icon: 'warning',
                        allowOutsideClick: false,
                    })
                    this.form.sampleFormItems[i].borrowed_qty = 0;
                }

                this.item_data.borrowed_qty = this.form.sampleFormItems[i].borrowed_qty;
                this.form.sampleFormItems[i].qr_code_base64 = null;
                this.generateQR(this.item_data,i)
            }
            // this.checkIfCreateable();
        },
        closeBatchAdditionDialog() {
            this.batch_addition.dialog = false;
        },
        // async validateManualAddItem(i,item_id = {}){
        //     let payload = {
        //         item_id: this.form.sampleFormItems[i] ? this.form.sampleFormItems[i].item_id : item_id.item_id,
        //         manualAdd: true,
        //         url: 'getSelectedInventoryItem'
        //     }
        //     await this.$store.dispatch('getSelectedItems', payload).then(response => {
        //         this.item_data = response.data
        //         this.form.sampleFormItems[i].item_model         = this.form.sampleFormItems[i].item_id;
        //         this.form.sampleFormItems[i].item_name          = this.item_data.name;
        //         this.form.sampleFormItems[i].item_categories    = this.item_data.item_categories;
        //         this.form.sampleFormItems[i].item_uom           = this.item_data.item_uom;
        //         this.form.sampleFormItems[i].item_types         = this.item_data.item_types;
        //         this.form.sampleFormItems[i].remain_stocks      = this.item_data.virtual_qty;
        //         this.form.sampleFormItems[i].borrowed_qty       = item_id.quantity;
        //         this.item_data.borrowed_qty                     = item_id.quantity
        //         this.generateQR(this.item_data,i)
        //     }).catch((err)=>{
        //         console.log(err)
        //         swal.fire({
        //             title: 'Warning',
        //             text: `Model : ${item_id.model.toUpperCase()} is not available in Warehouse.`,
        //             icon: "warning"
        //         })
        //         this.form.sampleFormItems.splice(i, 1);
        //     })
        // },
        async validateManualAddItem(i, item_id = {}) {
            let payload = {
                item_id: this.form.sampleFormItems[i].item_id ? this.form.sampleFormItems[i].item_id : item_id.item_id,
                manualAdd: true,
                url: 'getSelectedInventoryItem',
            };
            let stock_checker = 0;

            await this.$store.dispatch('getSelectedItems', payload).then((response) => {
                this.item_data = response.data;
                // Check if the item_model already exists
                let existingModelIndex = this.form.sampleFormItems.findIndex(
                    (item) => item.item_model === this.form.sampleFormItems[i].item_id
                );

                // If the item_model exists, add the borrowed_qty to that model
                if (existingModelIndex !== -1) {
                    this.form.sampleFormItems[existingModelIndex].borrowed_qty = parseInt(this.form.sampleFormItems[existingModelIndex].borrowed_qty) + parseInt(item_id.quantity);
                } else {
                    // else, create a new entry with the item_data
                    this.$set(this.form.sampleFormItems, i, {
                        ...this.form.sampleFormItems[i],
                        item_model: this.form.sampleFormItems[i].item_id,
                        item_name: this.item_data.name,
                        item_categories: this.item_data.item_categories,
                        item_uom: this.item_data.item_uom,
                        item_types: this.item_data.item_types,
                        remain_stocks: this.item_data.virtual_qty,
                        borrowed_qty: item_id.quantity,
                    });
                }

                if(this.form.sampleFormItems[i].borrowed_qty > this.item_data.virtual_qty){
                    stock_checker ++;
                }

                this.stock_validate = stock_checker > 0 ? false : true
                //  If item is duplicated , duplicated index is given else , the normal data from parameter
                this.item_data.borrowed_qty = existingModelIndex !== -1 ? this.form.sampleFormItems[existingModelIndex].borrowed_qty : item_id.quantity ;
                this.generateQR(this.item_data, i);
            }).catch((err) => {
                console.log(err);
                swal.fire({
                    title: 'Warning',
                    text: `Model : ${item_id.model.toUpperCase()} is not available in Warehouse.`,
                    icon: 'warning',
                });
                this.form.sampleFormItems.splice(i, 1);
            });
        },
        // async generateQRBatch(data){
        //     this.item_details = data;
        //     this.item_data = data;
        //     delete this.item_details.remain_stocks
        //     delete this.item_details.qr_code_base64
        //     delete this.item_details.item_id

        //     for (const key in data) {
        //         const formattedKey = key
        //             .split('_')
        //             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        //             .join(' ');
        //         this.item_details += `${formattedKey}: ${this.item_data[key]}\n`;
        //     }
        //     console.log(this.item_data)
        //     console.log(this.item_details)
        //     return await new Promise((resolve,reject)=>{
        //            QRCode.toDataURL(this.item_details, (error, base64) => {
        //             if (error) {
        //                 reject(base64)
        //                 console.error(error);
        //             } else {
        //             resolve(base64)
        //             }
        //         });
        //     })
        // },
        async generateQRBatch(data) {
            const propertiesToRemove = ['remain_stocks', 'qr_code_base64', 'item_id'];

            // Create a copy of the data object without the properties to be removed
            const cleanedData = { ...data };
            propertiesToRemove.forEach(prop => delete cleanedData[prop]);

            // Initialize the formatted string with the model and item name
            let itemDetailsString = ``;

            // Add the remaining key-value pairs to the formatted string
            for (const key in cleanedData) {
                const formattedKey = key
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                itemDetailsString += `${formattedKey}: ${cleanedData[key]}\n`;
            }

            // Use the itemDetailsString as needed
            return await new Promise((resolve,reject)=>{
                   QRCode.toDataURL(itemDetailsString, (error, base64) => {
                    if (error) {
                        reject(base64)
                        console.error(error);
                    } else {
                    resolve(base64)
                    }
                });
            })
        },

        openBatchDialog() {
            this.batch_addition.dialog = true;
            this.isDone = true;
            this.text_area_fields = [];

            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                model: "",
                quantity: "",
                error: false,
                });
            }
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.hasError = false;
        },
        closeAddEditDialog() {
            this.$store.commit('DIALOG', false);
        },
        removeAdjustmentItemByIndex(index,id) {
            if (this.form.sampleFormItems.length >= 0) {
                this.form.sampleFormItems.splice(index, 1);
                if(this.actions == 'Update'){
                    this.form.sf_trash_ids.push(id)
                }
            }
        },
        getAllData(){
            this.form.id                    = this.GET_VIEW_DATA.id;
            this.form.salesman_id           = this.GET_VIEW_DATA.salesman_id;
            this.form.remarks               = this.GET_VIEW_DATA.remarks;
            this.form.status                = this.GET_VIEW_DATA.status;
            this.form.sampleFormItems       = this.GET_VIEW_DATA.sample_form_items
            this.form.sample_form_num       = this.GET_VIEW_DATA.sample_form_num
            this.form.borrowed_date         = this.GET_VIEW_DATA.borrowed_date
            this.form.prepare_by_name       = this.GET_VIEW_DATA.prepare_by_name
            this.form.requested_by_name     = this.GET_VIEW_DATA.requested_by_name
            this.form.approve_by_name       = this.GET_VIEW_DATA.approve_by_name
            this.form.printed_pdf           = this.GET_VIEW_DATA.printed_pdf
            this.form.printed_qr            = this.GET_VIEW_DATA.printed_qr
            this.form.approvable            = this.GET_VIEW_DATA.approvable
            this.form.salesman_name         = this.GET_VIEW_DATA.salesman_name
            this.form.cancel_reason         = this.GET_VIEW_DATA.cancel_reason
            this.$store.commit('FILE_VIEWER_ID',this.GET_VIEW_DATA.id)
            this.$store.commit('FILE_PREVIEWS',this.GET_VIEW_DATA.sample_form_files);
        },
        changeSampleFormStatus(status){
            let items = [];

            // let access = this.USER_ACCESS.find(e=>{
            //     let statuses = [{approve_issue:'approve'},{confirm_issue:'confirm'},{cancel_issue:'cancel'}]
            //     return statuses[status][e.actions_code];
            // })

            switch(status){
                case 1:
                    if(!this.userAccess.includes('approve_issue')){
                        Swal.fire('Error!','- No Access','error')
                        return
                    }
                    break;
                case 2:
                    if(!this.userAccess.includes('confirm_issue')){
                        Swal.fire('Error!','- No Access','error')
                        return
                    }
                    break
                case 3:
                    if(!this.userAccess.includes('cancel_issue')){
                        Swal.fire('Error!','- No Access','error')
                        return
                    }
                    break
            }

            if (status == 2) {
                items = this.form.sampleFormItems.map((e) => {
                    return {
                        id:e.id,
                        item_id: e.item_id,
                        borrowed_qty: e.borrowed_qty,
                    };
                });
                swal.fire({
                    title: `Are you sure you want to Confirm?`,
                    text: 'Available Stocks per item will be deducted.',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                         let payload = {
                            url                         : 'changeStatusSampleForm',
                            sample_form_id              : this.form.id,
                            sample_form_status          : status,
                            sample_form_items           : items,
                            cancel_reason               : this.cancellation.reason,
                        }
                    // 0 - Saved
                    // 1 - Approved
                    // 2 - Confirmed
                    // 3 - Cancelled

                        this.$store.dispatch(this.GET_SF_DISPATCH.changestatus,payload).then((res)=>{
                            swal.fire({
                                title   : res.data.icon.toUpperCase(),
                                icon    : res.data.icon
                            })
                        }).catch((err)=>{
                            swal.fire({
                                title:'Error!',
                                text: '- ' + err.response.data.message,
                                icon:'error'
                                }
                            )
                        })
                    }
                })
            }
            else{
                let payload = {
                    url                         : 'changeStatusSampleForm',
                    sample_form_id              : this.form.id,
                    sample_form_status          : status,
                    sample_form_items           : items,
                    cancel_reason               : this.cancellation.reason,

                }
                this.$store.dispatch(this.GET_SF_DISPATCH.changestatus,payload).then((res)=>{
                    swal.fire({
                        title   : res.data.icon.toUpperCase(),
                        icon    : res.data.icon
                    })
                }).catch((err)=>{
                    console.log(err)
                })
            }

        },
        showOverride(type,print){
            this.override.type = type;
            if(this.form.printed_qr == 1){
                this.override.override_dialog = true;
            }
            if(this.form.printed_pdf == 1){
                this.override.override_dialog = true;
                this.override.isPrint = print;
            }
            if(this.override.type == 'qr' && this.form.printed_qr == 0){
                this.printQR();
            }
            if(this.override.type == 'pdf' && this.form.printed_pdf == 0){
                this.renderPDF(print)
            }
        },
        showOverrideQr(){
            this.override.type = 'qr_individual';
            if(this.form.printed_qr == 1){
                this.override.override_dialog = true;
            }
            else{
                this.printQRIndividual();
            }
        },
        // async printQR() {
        //     let pdf = new this.$jspdf('l', 'mm', 'letter');
        //     let qrCodeWidth = 30;
        //     let qrCodeHeight = 30;
        //     let qrCodeMarginX = 10;
        //     let qrCodeMarginY = 10;
        //     let maxCols = 4; // Display up to 4 columns per row
        //     let itemsPerPage = 12; // Number of items per page
        //     let pdf_height = 120;
        //     let items = this.form.sampleFormItems;

        //     pdf = new this.$jspdf('l', 'mm', items.length > 4 ? [150, 120] : [(37.5 * items.length),40]);


        //     let totalPages = Math.ceil(items.length / itemsPerPage);
        //     let additional_space = itemsPerPage > 12 ? 20 : 10;

        //     for (let pageNum = 1; pageNum <= totalPages; pageNum++) {

        //         let startIndex = (pageNum - 1) * itemsPerPage;
        //         let endIndex = Math.min(pageNum * itemsPerPage, items.length);
        //         let numRows = Math.ceil((endIndex - startIndex) / maxCols);

        //         if (pageNum > 1) {
        //             pdf.addPage([150,(40 * numRows)],'l');
        //         }

        //         for (let i = startIndex; i < endIndex; i++) {
        //             let item = items[i];
        //             let currentX = (i - startIndex) % maxCols * (qrCodeWidth + qrCodeMarginX);
        //             let currentY = Math.floor((i - startIndex) / maxCols) * (qrCodeHeight + qrCodeMarginY);

        //             let image = item.qr_code_base64;
        //             pdf.addImage(image, 'png', currentX, currentY, qrCodeWidth, qrCodeHeight);

        //             pdf.setFontSize(6);
        //             let text = this.form.sample_form_num;
        //             let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        //             let textX = currentX + (qrCodeWidth - textWidth) / 2;
        //             let textY = currentY + qrCodeHeight + qrCodeMarginY - 8;
        //             pdf.text(text, textX, textY);

        //             pdf.setFontSize(6);
        //             text = item.item_model;
        //             textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        //             textX = currentX + (qrCodeWidth - textWidth) / 2;
        //             textY = currentY + qrCodeHeight + qrCodeMarginY - 4;
        //             pdf.text(text, textX, textY);
        //         }
        //     }

        //     // pdf.save("Sample Form Items QR Codes.pdf");
        //     window.open(pdf.output("bloburl"), "_blank");

        //     this.setPrint('qr_code');
        // },
        async printQR() {
            let pdf = new this.$jspdf('l', 'mm', [45,45]);
            let qrCodeWidth = 35;
            let qrCodeHeight = 35;
            let qrCodeMarginX = 5;
            let qrCodeMarginY = 2;
            let items = this.form.sampleFormItems;

            for (let i = 0; i < items.length; i++) {
                if (i > 0) {
                    pdf.addPage();
                }

                let item = items[i];

                let image = item.qr_code_base64;
                pdf.addImage(image, 'png', qrCodeMarginX, qrCodeMarginY, qrCodeWidth, qrCodeHeight);

                pdf.setFontSize(8);
                let text = 'SF-' + this.form.sample_form_num;
                let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                let textX = qrCodeMarginX + (qrCodeWidth - textWidth) / 2;
                let textY = qrCodeMarginY + qrCodeHeight + 1;
                pdf.text(text, textX, textY);

                pdf.setFontSize(8);
                text = item.item_model;
                textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
                textX = qrCodeMarginX + (qrCodeWidth - textWidth) / 2;
                textY = qrCodeMarginY + qrCodeHeight + 5;
                pdf.text(text, textX, textY);
            }

            // pdf.save("Sample Form Items QR Codes.pdf");
            window.open(pdf.output("bloburl"), "_blank");

            this.setPrint('qr_code');
        },
        async printQRIndividual() {
            let pdf = new this.$jspdf('l', 'mm', 'letter');
            let qrCodeWidth = 30;
            let qrCodeHeight = 30;
            let qrCodeMarginY = 10;

            pdf = new this.$jspdf('l', 'mm', [50, 50]);

            let currentX = (pdf.internal.pageSize.width - qrCodeWidth) / 2;
            let currentY = qrCodeMarginY;

            let image = this.qr_image;
            pdf.addImage(image, 'png', currentX, currentY, qrCodeWidth, qrCodeHeight);

            pdf.setFontSize(6);
            let text = this.form.sample_form_num;
            let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            let textX = currentX + (qrCodeWidth - textWidth) / 2;
            let textY = currentY + qrCodeHeight + qrCodeMarginY - 8;
            pdf.text(text, textX, textY);

            text = this.qr_model;
            textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            textX = currentX + (qrCodeWidth - textWidth) / 2;
            textY = textY + 4; // Adjust the Y position to separate model from the form number
            pdf.text(text, textX, textY);

            swal.fire({
                title:"Print Success",
                icon:"success"
            })

            window.open(pdf.output('bloburl'), '_blank');
        },
        async renderPDF(isPrint) {
            var doc = new this.$jspdf("p", "mm", "letter");
            let table_height = 0 ;
            doc.setFont(undefined, 'bold').setFontSize(13).text(162, 13, "Sample Form")
            doc.setFont(undefined, 'bold').setFontSize(11).text(162, 18, "SF#: " +this.form.sample_form_num)
            doc.setFont(undefined, "bold").setFontSize(13).text(6, 13, "INGCOPH Traders Inc.\n");


            doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "130-A 6th St. 7th Ave, East Grace Park, Caloocan City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539")

            let sf_details_top = 40 ;

            doc.autoTable({
                html: "#sf_details",
                theme: "plain",
                margin: { top: sf_details_top, left: 6, right: 6 },
                columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 134 },
                2: { cellWidth: 20 },
                3: { cellWidth: 25 },
                },
            });
            let itemsTableStartY = sf_details_top + 10; // Add a buffer for spacing

            // Render the items_details table and let it flow to new pages automatically
            doc.autoTable({
                html: "#items_details",
                startY: doc.autoTable.previous.finalY + 3,
                margin: { top: 35 ,left: 6, right: 6, bottom: 20 },
                theme: "grid",
                headStyles: {
                    halign: "center",
                    fillColor: "#ffffff",
                    textColor: "#000000",
                    lineWidth: 0.2,
                    lineColor: "#000000",
                },
                columnStyles: {
                    0: {  halign: "center", textColor: "#000000" },
                    1: {  halign: "center", textColor: "#000000" },
                    2: {  halign: "center", textColor: "#000000" },
                    3: {  halign: "center", textColor: "#000000" },
                    4: {  halign: "center", textColor: "#000000" },
                    5: {  halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                pageBreak:'auto',
                didDrawPage:(d) => table_height = d.cursor.y,
            });


            if(table_height > 200){
                doc.addPage()
            }

            var footer_y =  230;

            doc.autoTable({
                html: "#footer",
                theme: "plain",
                startY: footer_y,
                margin: { left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 40, halign: "center", textColor: "#000000" },
                    1: { cellWidth: 40, halign: "center", textColor: "#000000" },
                    2: { cellWidth: 40, halign: "center", textColor: "#000000" },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, "S");
                },
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            const totalPages = doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i); // Set the current page
                doc.setFont(undefined, 'bold').setFontSize(13).text(162, 13, "Sample Form")
                doc.setFont(undefined, 'bold').setFontSize(11).text(162, 18, "SF#: " +this.form.sample_form_num)
                doc.setFont(undefined, "bold").setFontSize(13).text(6, 13, "INGCOPH Traders Inc.\n");
                doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "130-A 6th St. 7th Ave, East Grace Park, Caloocan City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539")

                let pageNumber = "Page " + i + " of " + totalPages;
                doc.setFontSize(7);
                doc.setTextColor("#000000");
                doc.text(190, 265, pageNumber);
            }
            if (isPrint == false) {
                doc.save("Sample Form " + this.form.sample_form_num + ".pdf");
                this.closeOverrideDialog();
            }
            if (isPrint == true) {
                doc.autoPrint();
                this.setPrint('pdf')
                window.open(doc.output("bloburl"), "_blank");
            }
        },
        resetInventoryItemsSelection(){
            this.dropdowns.inventory_items.forEach(el=>{
                el.isDisabled = false
            })
        },
        setPrint(type){
            let payload = {
                id: this.form.id,
                url: 'printSampleForm',
                type: type
            }
            this.$store.dispatch('printSampleForm',payload).then(response=>{
                swal.fire({
                    icon: response.data.icon,
                    title: response.data.icon.toUpperCase(),
                })
                this.$store.dispatch('viewSampleForm', this.form.id).then(response => {
                    this.$store.commit('VIEW_DATA',response.data)
                });
            }).catch((err)=>[
                console.log(err)
            ])
        },
        viewQR(image,model){
            this.qr_dialog = true
            this.qr_image = image
            this.qr_model = model
        },
        closeQRView(){
            this.qr_dialog = false
        },
        uploadedData(data) {
            if (!data) {
                this.form.files = null;
            }
            if(this.form.files.attachments.length == 0){
                this.file_previews = data.preview;
                this.form.files = data;
            }
            else{
                this.form.files.attachments.push(...data.attachments);
            }
        },
        removeAttachment(data){
            // Remove file attachment based on the index from file preview
            this.form.files.attachments.splice(data, 1);
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.form.files = null;
            }
            this.form.files = null;
            if(this.ACTION == 'Submit'){
                this.$refs.fileUpload.reset();
            }
        },
        confirmedOverridePrint() {
             if (!this.override.override_user_password) {
                this.override_user_id = -1
                this.override.override_dialog = false
            } else {
                let payload = {
                    departments_allowed:        this.override.override_departments_allowed,
                    override_user_password:     this.override.override_user_password,
                }
                this.$store.dispatch('confirmOverride',payload).then(response => {
                    let data = this.GET_CONFIRM_OVERRIDE

                    this.override.override_user_password = ''

                    if (data.error) {
                        this.override_user_id = -1
                        swal.fire("", data.error, "warning")
                        return false
                    }

                    this.override_user_id = data.user_id
                    this.override.override_authenticated = true
                    if (this.override.override_authenticated) {
                        this.override.override_dialog = this.override.override_authenticated;
                        if(this.override.type == 'qr'){
                            this.printQR();
                            this.override.override_dialog = false;
                        }
                        if(this.override.type == 'qr_individual'){
                            this.printQRIndividual();
                            this.$store.commit('DIALOG',false);
                            this.override.override_dialog = false;
                        }
                        else{
                            this.renderPDF(this.override.isPrint)
                            this.override.override_dialog = false;
                        }
                    }
                    else {
                        this.override.override_dialog = this.override.override_authenticated;
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        closeOverrideDialog(){
            this.override.override_dialog = false
        },
        // validateInputModel(index) {
        //     if (this.listOfNotInModels.length != 0) {
        //             let payload = {
        //                 items: this.text_area_fields.map((e) => {
        //                     return e.model.toUpperCase();
        //                 }),
        //                 isChecking: true,
        //             };
        //             this.$store.dispatch("getItemsByModel", payload).then((response) => {
        //                 response.data = response.data.map((e) => {
        //                     return e.model;
        //                 });
        //             if (
        //                 response.data.includes(
        //                 this.text_area_fields[index].model.toUpperCase()
        //                 )
        //             ) {
        //                 this.isDisabledButton = false;
        //                 this.text_area_fields[index].error = false;
        //             } else {
        //                 this.text_area_fields[index].error = true;
        //                 this.isDisabledButton = true;
        //             }
        //         });
        //     } else {
        //         let payload = {
        //             items: this.text_area_fields.map((e) => {
        //                 return e.model.toUpperCase();
        //             }),
        //             isChecking: true,
        //         };
        //         this.$store.dispatch("getItemsByModel", payload).then((response) => {
        //             response.data = response.data.map((e) => {
        //                 return e.model;
        //             });
        //             if (
        //                 response.data.includes(
        //                 this.text_area_fields[index].model.toUpperCase()
        //                 )
        //             ) {
        //                 this.text_area_fields[index].error = false;
        //             } else {
        //                 this.text_area_fields[index].error = true;
        //                 this.listOfNotInModels.push(
        //                 this.text_area_fields[index].model.toUpperCase()
        //                 );
        //                 this.isDisabledButton = true;
        //             }
        //         });
        //     }
        // },
        onPaste(evt) {
            let missingModelindexes = [];
            let missingModels = [];
            this.batch_addition.data = [];
            this.batch_addition.loading = true
            var pastedText = evt.clipboardData.getData("Text");
            if (!pastedText) {
                return false;
            } else {
                try{
                    var rows = pastedText.split("\r\n");
                    var cells = "";
                    for (var y in rows) {
                        if (y != rows.length - 1) {
                            cells = rows[y].split("\t");
                            const model = cells[0].trim();
                            const qty = cells[1].trim();
                            // Check if the model already exists in text_area_fields
                            const existingItemIndex = this.text_area_fields.findIndex(item => item.model === model);
                            if (existingItemIndex !== -1) {
                                // If the model already exists, update the quantity
                                this.text_area_fields[existingItemIndex].quantity = String(Number(this.text_area_fields[existingItemIndex].quantity) + Number(qty));
                            } else {
                                // If the model is not a duplicate, add a new entry
                                this.text_area_fields.push({
                                    model: model,
                                    quantity: qty,
                                    error: false,
                                    name:"",
                                    item_categories:"",
                                    item_uom:"",
                                    item_types:"",
                                    virtual_qty:""
                                });
                            }
                        }
                    }
                    let payload = {
                        items: this.text_area_fields,
                        items_models: this.text_area_fields.map((e) => {
                            return e.model;
                        }),
                        isChecking: true,
                        url :'batchSelectedItem'
                    };
                    this.$store.dispatch("checkSFItems", payload).then((response) => {
                        this.batch_addition.data = response.data.result;
                        this.batch_addition.loading = false
                    })
                    .catch((err)=>{
                        console.log(err);
                        missingModels = err.response.data.missing_models;
                        missingModelindexes = err.response.data.missing_models_indexes;

                        swal.fire({
                            title: 'Warning',
                            text: `Model/s : ${missingModels} is not found in the Warehouse.`,
                            icon: 'warning',
                        });

                        missingModelindexes.forEach(el=>{
                            this.form.sampleFormItems.splice(el, 1);
                            this.text_area_fields.splice(el,1);
                        })
                        this.batch_addition.loading = false
                    });
                    this.isDone = false;
                    this.closeBatchAdditionDialog();
                    setTimeout(this.myFunction, 1);
                }
                catch (error) {
                    swal.fire({
                        title   : 'Warning',
                        text    : 'Please Check Your Copied values!',
                        icon    : 'warning'
                    })
                    this.batch_addition.loading = false
                }
            }
        },
        manualAddItem() {
            this.text_area_fields.push({
                model: "",
                quantity: "",
                error: false,
            });
        },
        myFunction() {
            this.text_area_fields.splice(0, 1);
            this.batch_addition.dialog = true;
        },
        // async submittedBtn() {
        //     this.form.sampleFormItems.push(...this.batch_addition.data);

        //     for(let i in this.form.sampleFormItems){
        //         await this.generateQRBatch(this.form.sampleFormItems[i]).then(response=>{
        //             this.form.sampleFormItems[i].qr_code_base64 = response
        //         })
        //     }
        //     this.batch_addition.dialog = false;
        //     this.batch_addition.loading = false

        // },
        async submittedBtn() {
            let duplicate_models = []
            const newItems = this.batch_addition.data.filter(newItem => {
                const isDuplicate = this.form.sampleFormItems.some(existingItem => existingItem.item_id === newItem.item_id);
                if (isDuplicate) {
                    duplicate_models.push(newItem.model);

                    swal.fire(
                        "Duplicate Model",
                        `Duplicate Model/s: ${duplicate_models}`,
                        "warning"
                    );
                }
                return !isDuplicate;
            });

            for (let i in newItems) {
                await this.generateQRBatch(newItems[i]).then(response => {
                    newItems[i].qr_code_base64 = response;
                });
            }

            this.form.sampleFormItems.push(...newItems);
            this.batch_addition.dialog = false;
            this.batch_addition.loading = false;
        },
        generateQR(data,i){
            this.item_details = '';
            delete data.virtual_qty;

            for (const key in data) {
                const formattedKey = key
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                this.item_details += `${formattedKey}: ${this.item_data[key]}\n`;
            }

            QRCode.toDataURL(this.item_details, (error, base64) => {
                if (error) {
                    console.error(error);
                } else {
                    this.form.sampleFormItems[i].qr_code_base64 = base64
                }
            });
        },
        clearItem(i) {
        // Remove Batch Addition Data from Array
            let temp = [];
            if (this.text_area_fields.length == 1) {
                this.clearFields();
            } else {
                if (this.listOfNotInModels.length > 1) {
                } else {
                this.isDisabledButton = false;
                }
                this.text_area_fields.forEach((e, index) => {
                if (i != index) {
                    temp.push(e);
                    let checkingOfError = this.listOfNotInModels.includes(
                    this.text_area_fields[i].model
                    );
                    if (checkingOfError) {
                    this.listOfNotInModels.pop();
                    }
                }
                });
                this.text_area_fields = temp;
            }
        },
        clearFields() {
        //  Clear Fields in Batch Addition
            this.isDone = true;
            this.text_area_fields = [];
            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                model: "",
                quantity: "",
                error: false,
                });
            }
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.isDisabledButton = false;
            this.hasError = false;
            this.batch_addition.loading = false;
        },
        checkIfCreateable(){
        // Count if there are borrowing stocks that is greater than the remaining stocks in virtual warehouse.
            let not_acceptable_count = 0;
            if(this.form.sampleFormItems){
                this.form.sampleFormItems.forEach((e,i)=>{
                    if(e.borrowed_qty == 0 || e.borrowed_qty == undefined){
                        not_acceptable_count ++
                    }
                })
                this.stock_validate = not_acceptable_count > 0 ?  false : true ;
            }
        },
        openCancelReasonDialog(){
            this.cancellation.dialog = true
        },
        closeCancelReasonDialog(){
            this.cancellation.dialog = false
        }
    },
    computed: {
        ...mapGetters([
            'GET_SALESMAN_LIST',
            'GET_SAMPLE_FORM_ITEMS',
            'GET_VIEW_DATA',
            'ACTION',
            'GET_SF_DISPATCH',
            'DIALOGS',
            'GET_CONFIRM_OVERRIDE',
            'GET_ITEMS_BY_MODELS',
            'USER_ACCESS'
        ]),
        actions(){
            if(this.ACTION == 'Submit'){
                this.checkIfCreateable();
                return 'Create'
            }
            else if(this.ACTION == 'View'){
                this.getAllData();
                return 'View'
            }
            else if(this.ACTION == 'Update'){
                this.checkIfCreateable();
                this.getAllData();
                return 'Update'
            }
            else if(this.ACTION == 'Approve'){
                this.getAllData();
                return this.form.status == 0 ? 'Approve' : 'Confirm'
            }
        },
        negativeDifference() {
            return function(sampleFormItem) {
                return (sampleFormItem.remain_stocks - sampleFormItem.borrowed_qty < 0) && this.form.status != 2;
            };
        },
    },
    watch: {
        form:{
            handler(val){
                this.resetInventoryItemsSelection()
                let item_ids = []
                if(val.sampleFormItems){
                    item_ids = val.sampleFormItems.map(x=> x.item_id)
                    item_ids.forEach(async x=>{
                        await this.dropdowns.inventory_items.forEach(async els=>{
                            if(x == els.value){
                                els.isDisabled = true
                            }
                        })
                    })
                }
            },
            deep:true,
        },
        DIALOGS:{
            handler(val){
                if(val){
                    this.$refs.form.resetValidation()
                    this.sampleFormDialog()
                }
            }
        },
        USER_ACCESS:{
            handler(val){
                if(val != "fail"){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            }
        }
    }
}
</script>

<style scoped>
 .yellow-background {
    background-color: #f8bb86;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

.centered-input >>> input {
    text-align: center
}

</style>
