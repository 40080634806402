<template>
    <v-dialog v-model="dialogState" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
              <span class="text-h5">{{ GET_DELIVERY_ADDRESSES_ACTION }} Admin Delivery Adresses</span>
              <v-row class="m-0">
                <v-col cols="pull-right-10 p-2">
                  <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container fluid class="py-5">
                <v-row>
                    <v-col>
                        <v-radio-group row mandatory v-model="form.location_type">
                            <v-radio label="Head Office" value="headoffice" :disabled="disabled_view"></v-radio>
                            <v-radio label="Stores" value="stores" :disabled="disabled_view"></v-radio>
                        </v-radio-group> 
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="form.code"
                        required
                        :rules="rules.code"
                        label="Code"
                        outlined
                        dense
                        :disabled="disabled_view">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="form.name"
                        required
                        :rules="rules.name"
                        label="Name"
                        outlined
                        dense
                        :disabled="disabled_view">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="form.address"
                        required
                        :rules="rules.address"
                        label="Address"
                        outlined
                        dense
                        :disabled="disabled_view">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="form.contact_number"
                        label="Contact Number"
                        outlined
                        dense
                        :disabled="disabled_view">
                        </v-text-field>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
                <v-row class="ma-1">
                    <v-col cols="12" v-if="GET_DELIVERY_ADDRESSES_ACTION != 'View' ">
                    <v-btn class="float-center" @click="GET_DELIVERY_ADDRESSES_ACTION == 'Add' ? addAdminDeliverAddresses() : updateAdminDeliverAddresses()" :disabled="!valid">
                            <span>{{ GET_DELIVERY_ADDRESSES_ACTION == 'Add' ? 'Submit' : 'Update' }}</span>
                    </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
    </v-dialog>
  </template>
  <script>
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
  
  export default {
        mixins: [
            SharedFunctionsComponentVue,
        ],
        props:{
            dialogState: {
                type: Boolean,
                default:()=>''
            }
        },
        data() {
            return {
                valid:true,
                form: {
                    code: '',
                    name: '',
                    address:'',
                    contact_number:'',
                    location_type:''
                },
                item: '',
                clonedStores: [],
                default_delivery_address: {
                    code: '',
                    name: '',
                    address:'',
                    contact_number:'',
                    location_type:''
                },
                rules: {
                    code: [(v) => !!v || "Code is Required"],
                    name: [(v) => !!v || "Name is Required"],
                    address: [(v) => !!v || " Address is Required"],
                },
                loading:{
                    submit:'',
                    edit:'',
                }
            }
        },
        computed: {
            ...mapGetters([
                'GET_DELIVERY_ADDRESSES_ACTION',
                'GET_SELECTED_DELIVERY_ADDRESSES',
            ]),
            disabled_view() {
                if (this.GET_DELIVERY_ADDRESSES_ACTION == 'View') {
                    return true;
                }
                return false;
            }
        },
        methods: {
            addSubClass(){
                this.form.sub_class.push({
                    code:'',
                    name:''
                })
            },
            deleteSubClass(i){
                this.form.sub_class_trash.push(
                    {
                        id:this.form.sub_class[i].id,
                        name:this.form.sub_class[i].name
                    }
                    );
                    this.form.sub_class.splice(i,1);
            },
            getAllAdminDeliveryAdresses(){
                this.form.name = this.GET_SELECTED_DELIVERY_ADDRESSES.name
                this.form.code = this.GET_SELECTED_DELIVERY_ADDRESSES.code
                this.form.address = this.GET_SELECTED_DELIVERY_ADDRESSES.address
                this.form.contact_number = this.GET_SELECTED_DELIVERY_ADDRESSES.contact_number
                this.form.location_type = this.GET_SELECTED_DELIVERY_ADDRESSES.location_type
                // if (this.SELECTED_ADMIN_CLASSIFICATION.type) {
        
                // }
                // this.form.classification_type = this.SELECTED_ADMIN_CLASSIFICATION.type
                // this.form.asset_tag_assign = this.SELECTED_ADMIN_CLASSIFICATION.asset_tag_assign
                // this.form.location_type = this.SELECTED_ADMIN_CLASSIFICATION.location_type
                // if(this.USERACCOUNT_LOAD.super_admin == 1 && this.GET_ADMIN_TAB_IS_ACTIVE == 'status')
                // {
                //     // this.form.department_id = this.SELECTED_ADMIN_CLASSIFICATION.id
                //     this.form.department_id = this.SELECTED_ADMIN_CLASSIFICATION.department_id
                // }
                // this.item = this.SELECTED_ADMIN_CLASSIFICATION;
            },
            closeDialog() {
                this.$emit('closeDialog', false)
                this.$store.commit('SELECTED_DELIVERY_ADDRESSES', this.default_delivery_address);
                this.resetfields();
                // this.$store.commit('ACTION', '')
            },
            addAdminDeliverAddresses() {
                this.$refs.form.validate()
                const code_swal_text = this.form.code ? '' : 'Code';
                const name_swal_text = this.form.name ? '' : 'Name';
                const address_swal_text = this.form.address ? '' : 'Address';
                let array_text = [code_swal_text, name_swal_text, address_swal_text]
                // array_text = array_text.concat(this.swal_required)
                let filtered = array_text.filter(function (el) {
                    return el.replace(",", "");
                });
                if (this.valid) {
                    this.loading.submit = true;
                    let payload = {
                        deliveryAddress: this.form,
                        url: 'admin-delivery-addresses'
                    }
                    this.$store.dispatch('adminDeliveryAddressesPost', payload).then( response => {
                        this.$emit('successTransaction');
                        Swal.fire(response.data);
                        this.closeDialog();
                        this.loading.submit = false;
                    })
                } else {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: 'warning',
                        allowOutsideClick: false,
                    });
                    return;
                }
            },
            updateAdminDeliverAddresses() {
                this.$refs.form.validate()
                const code_swal_text = this.form.code ? '' : 'Code';
                const name_swal_text = this.form.name ? '' : 'Name';
                const address_swal_text = this.form.address ? '' : 'Address';
                let array_text = [code_swal_text, name_swal_text, address_swal_text]
                let filtered = array_text.filter(function (el) {
                    return el.replace(",", "");
                });
                if (this.valid) {
                    this.loading.submit = true;
                    let payload = {
                        deliveryAddress: this.form,
                        url: `admin-delivery-addresses/${this.GET_SELECTED_DELIVERY_ADDRESSES.id}`
                    }
                    this.$store.dispatch('adminDeliveryAddressesPut', payload).then( response => {
                        this.$emit('successTransaction');
                        Swal.fire(response.data);
                        this.closeDialog();
                        this.loading.submit = false;
                    })
                } else {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: 'warning',
                        allowOutsideClick: false,
                    });
                    return;
                }
            },
            resetfields() {
                this.form = this.default_delivery_address;
            },
            coaAccountName(id) {
                return id != 0 ? this.GET_CHART_OF_ACCOUNTS_SELECTION.find(e=>e.id == id).account_name : '';
            },
            async getBranches(){
                try{
                    this.clonedStores = await this.getStoreBranches()
                } catch (error) {
                    console.error(error)
                }
            },
            insertCompany(storeId){
                if (!storeId) return
                let store = this.clonedStores.find(e=>e.value == storeId)
                this.form.company_id = this.GET_PV_COMPANIES_SELECTION.find(e=>e.value == store.company_id).value
                this.$store.dispatch('getAdminLocationSelection', {store_branch_id: storeId})
            },
            insertStore(){
                this.form.store_branch_id = this.branchItems[0].value
            },
        },
        watch: {
            'GET_SELECTED_DELIVERY_ADDRESSES': {
                handler(val) {
                    if (val) {
                        this.form.name = this.GET_SELECTED_DELIVERY_ADDRESSES.name
                        this.form.code = this.GET_SELECTED_DELIVERY_ADDRESSES.code
                        this.form.address = this.GET_SELECTED_DELIVERY_ADDRESSES.address
                        this.form.contact_number = this.GET_SELECTED_DELIVERY_ADDRESSES.contact_number
                        this.form.location_type = this.GET_SELECTED_DELIVERY_ADDRESSES.location_type
                    }
                }
            },
        },
        mounted(){
            // 
        },
    };
</script>
<style scoped>
th {
background: white;
position: sticky;
top: 0; /* Don't forget this, required for the stickiness */
box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
</style>
  