<template>
  <v-container class="ma-0 pa-2">
    <v-container>
      <!-- <div class="row">
                <div class="col-12 d-flex no-block align-items-center">
                    <h4 class="page-title">Stock Variances</h4>
                </div>
            </div> -->
      <h4 class="page-title">Stock Variances</h4>
      <div class="row bg-light mx-0 mt-5">
        <div class="row pa-2">
          <div class="col-sm-5 col-md-3" id="date_from">
            <!-- <label class="float-left control-label col-form-label">Date From</label> -->
            <v-menu
              v-model="date.menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date.date_from"
                  readonly
                  append-icon="mdi-calendar-clock"
                  v-on="on"
                  label="Date From"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date.date_from"
                @input="date.menu2 = false"
              ></v-date-picker>
            </v-menu>
            <span
              v-for="errors in errors.date_from"
              class="text-warning"
              v-bind:key="errors"
              >{{ errors }}</span
            >
          </div>
          <div class="col-sm-5 col-md-3" id="date_to">
            <!-- <label class="float-left control-label col-form-label">Date To</label> -->
            <v-menu
              v-model="date.menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date.date_to"
                  readonly
                  append-icon="mdi-calendar-clock"
                  v-on="on"
                  label="Date To"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date.date_to"
                @input="date.menu3 = false"
              ></v-date-picker>
            </v-menu>
            <span
              v-for="errors in errors.date_to"
              class="text-warning"
              v-bind:key="errors"
              >{{ errors }}</span
            >
          </div>
          <div class="col-sm-6 col-md-6 d-flex justify-content-end">
            <v-btn
              @click="
                getStockVariances();
                loaded = false;
              "
            >
              Filter
            </v-btn>
          </div>
        </div>
        <div class="row" v-show="loaded">
          <div class="col-md-12 col-sm-12 col-lg-12">
            <v-tabs
              v-model="stockVariances.tabs.tabBystatus"
              color="cyan"
              background-color="#424242"
              dark
              slider-color="cyan"
            >
              <v-tab
                v-for="status in stockVariances.status.selection"
                :key="status.value"
                @click="searchStatus(status.value)"
              >
                {{ status.text }}
              </v-tab>
              <v-tabs-items v-model="stockVariances.tabs.tabBystatus">
                <v-tab-item
                  v-for="status in stockVariances.status.selection"
                  :key="status.value"
                >
                  <div>
                    <div>
                      <v-data-table
                        :headers="stockVariances.dataTable.headers"
                        :options.sync="stockVariances.dataTable.options"
                        :items="stockVariances.dataTable.data"
                        :server-items-length="
                          stockVariances.dataTable.totalItems
                        "
                        style="width: 100%"
                      >
                        <template
                          v-slot:[`item.actions`]="{ item }"
                          class="d-flex justify-content-around bg-primary"
                        >
                          <span>
                            <v-tooltip top v-if="item.status == 0">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  v-on="on"
                                  v-bind="attrs"
                                  @click="updateStockVariance(item, 0, true)"
                                >
                                  RTSW
                                </v-btn>
                              </template>
                              <span>RETURN TO SERVING WAREHOUSE</span>
                            </v-tooltip>
                          </span>

                          <v-tooltip top v-if="item.status == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                v-on="on"
                                v-bind="attrs"
                                @click="updateStockVariance(item, 1, true)"
                              >
                                ATR
                              </v-btn>
                            </template>
                            <span>ADD TO RECEIVING</span>
                          </v-tooltip>

                          <v-tooltip top v-if="item.status == 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                v-on="on"
                                v-bind="attrs"
                                @click="updateStockVariance(item, 2, true)"
                              >
                                LOST
                              </v-btn>
                            </template>
                            <span>ITEM WAS LOST</span>
                          </v-tooltip>

                          <v-tooltip top v-if="item.status == 1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                v-on="on"
                                :disabled="item.status == 3"
                                v-bind="attrs"
                                @click="
                                  user.hasAccessOps.approve
                                    ? updateStockVariance(item, item.type)
                                    : showOverride(item, false)
                                "
                              >
                                APPROVE ({{ stockVariances.type[item.type] }})
                              </v-btn>
                            </template>
                            <span>APPROVE REQUEST</span>
                          </v-tooltip>
                          <v-tooltip
                            top
                            v-if="item.status == 2 || item.status == 3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                v-on="on"
                                :disabled="item.status == 3"
                                v-bind="attrs"
                                @click="
                                  user.hasAccessAu.approve
                                    ? updateStockVariance(item, item.type)
                                    : showOverride(item, true)
                                "
                              >
                                <span v-if="item.status == 2"
                                  >AETC ({{ stockVariances.type[item.type] }})
                                </span>
                                <span v-if="item.status == 3">{{
                                  stockVariances.type[item.type]
                                }}</span>
                              </v-btn>
                            </template>
                            <span>APPROVE AUDIT TO COMPLETE</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.status`]="{ item, index }">
                          <span
                            :class="stockVariances.status.classes[item.status]"
                            >{{
                              stockVariances.status.selection[item.status + 1]
                                .text
                            }}</span
                          >
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </div>
        </div>
      </div>

      <v-progress-linear
        indeterminate
        color="green"
        v-if="!loaded"
      ></v-progress-linear>
    </v-container>
    <ConfirmOverrideComponentVue
      :approve_override_dialog="override.dialog"
      :departments_allowed="override.departments_allowed"
      :heading="override.heading"
      @closeConfirmOverride="closeConfirmOverride"
    ></ConfirmOverrideComponentVue>
  </v-container>
</template>
<script>
import swal from "sweetalert2";
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";
export default {
  components: {
    ConfirmOverrideComponentVue,
  },
  data() {
    return {
      stockVariances: {
        selectedStockVariance: {
          item: null,
          type: null,
        },
        dataTable: {
          headers: [
            { text: "SRF#", width: "7%", value: "SRF" },
            { text: "STF#", width: "7%", value: "STF" },
            {
              text: "SW",
              align: "start",
              width: "5%",
              value: "serving_warehouse",
            },
            {
              text: "RW",
              align: "start",
              width: "5%",
              value: "requesting_warehouse",
            },
            { text: "ITEM MODEL", align: "start", value: "item_model" },
            {
              text: "ITEM NAME",
              align: "start",
              width: "280px",
              value: "item_name",
            },
            {
              text: "REQ MB(uom)",
              width: "7%",
              align: "start",
              value: "requested_uom_quantity_with_master_box",
            },
            {
              text: "PREP MB(uom)",
              width: "7%",
              align: "start",
              value: "prepared_uom_quantity_with_master_box",
            },
            {
              text: "REC MB(uom)",
              width: "7%",
              align: "start",
              value: "received_uom_quantity_with_master_box",
            },
            {
              text: "VARIANCE (uom)",
              width: "7%",
              align: "start",
              width: "100px",
              value: "variance",
            },
            { text: "STATUS", value: "status" },
            { text: "ACTION", align: "center", width: "250", value: "actions" },
          ],
          data: [],
          options: {},
          totalItems: 0,
        },
        tabs: {
          tabByWarehouse: -1,
          tabByWarehouseSelection: [],
          tabBystatus: -1,
        },

        filter: 0,

        status: {
          selection: [
            { text: "ALL STATUS", value: 0 },
            { text: "PENDING", value: 1 },
            { text: "FOR APPROVAL", value: 2 },
            { text: "AUDIT APPROVAL", value: 3 },
            { text: "COMPLETED", value: 4 },
          ],
          classes: [
            "badge badge-preparing",
            "badge badge-submitted",
            "badge badge-warning",
            "badge badge-success",
            "badge badge-completed",
          ],
          selected: [],
        },

        type: ["RTSW", "ATR", "LOST"],
      },

      date: {
        menu3: null,
        menu2: null,
        date_from: this.$dayjs().startOf("month").format("YYYY-MM-DD"),
        date_to: this.$dayjs().endOf("month").format("YYYY-MM-DD"),
      },

      errors: [],
      loaded: false,

      user: {
        hasAccessAu: false,
        hasAccessOps: false,
      },

      override: {
        dialog: false,
        heading: "Approve",
        departments_allowed: ["AU"],
        authenticated: false,
        action: "",
        selectedItem: null,
        type: null,
      },
    };
  },

  mounted() {
    let that = this;

    that.getStockVariances();
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
  },

  methods: {
    updateStockVariance(transaction, type, checking = false) {
      let that = this;

      swal
        .fire({
          title: "Update",
          text: "Are you sure you want to update ?",
          showConfirmButton: true,
          confirmButtonText: "Confirm",
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          icon: 'question',
        })
        .then((confirm) => {
          if (confirm.isConfirmed) {
            transaction.type = type;

            that.loaded = !that.loaded;
            that.stockVariances.selectedStockVariance.item = transaction;
            that.stockVariances.selectedStockVariance.type = type;

            this.$store
              .dispatch("updateStockVarianceStatus", { transaction })
              .then((response) => {
                if (response.status == 200) {
                  that.loaded = !that.loaded;
                  if (response.data.status == 0) {
                    swal.fire(
                      "WAITING FOR APPROVAL",
                      "Please consult your head for approval",
                      "success"
                    );
                  } else if (response.data.status == 1) {
                    swal.fire("WAITING FOR APPROVAL", "", "success");
                  } else if (response.data.status == 2) {
                    swal.fire("RESOLVED", "", "success");
                  }
                  that.getStockVariances(
                    that.stockVariances.dataTable.options.page
                  );
                } else {
                  that.loaded = !that.loaded;
                }
              })
              .catch((e) => {
                console.log(e);
                that.loaded = !that.loaded;
              });
          }
        });
    },

    async getStockVariances(page = 1) {
      let that = this;
      that.stockVariances.dataTable.options.page = page;

      await this.$store
        .dispatch("saveStockVariance")
        .then((response) => {})
        .catch((e) => {
          console.log(e);
          that.loaded = !that.loaded;
        });
      let payload = {
        filter: that.stockVariances.filter,
        page: page,
        itemsPerPage: that.stockVariances.dataTable.options.itemsPerPage,
        date_from: that.date.date_from,
        date_to: that.date.date_to,
      };

      await this.$store
        .dispatch("getAllStockVariance", payload)
        .then((response) => {
          let data = response.data[0];
          if (data.total == 0) {
            swal.fire("No Data", "", "warning");
            that.loaded = true;
            that.stockVariances.dataTable.data = [];
            that.stockVariances.dataTable.totalItems = 0;
            return false;
          } else {
            that.stockVariances.dataTable.data = data.data;
            that.stockVariances.dataTable.totalItems = data.total;
          }
        });

      this.$store
        .dispatch("serviceGetCurrentUser", {
          access: "AU",
        })
        .then((response) => {
          response.data.access_controls.forEach((e) => {
            if (e.department == "AU") {
              e.access_control = JSON.parse(e.access_control);
              that.user.hasAccessAu = e.access_control.stockVariance;
            }
          });
        });

      this.$store
        .dispatch("serviceGetCurrentUser", {
          access: "OP",
        })
        .then((response) => {
          response.data.access_controls.forEach((e) => {
            if (e.department == "OP") {
              e.access_control = JSON.parse(e.access_control);
              that.user.hasAccessOps = e.access_control.stock_variance;
            }
          });
        });

      that.loaded = true;
    },

    searchStatus(val) {
      let that = this;

      that.loaded = false;
      that.stockVariances.filter = val;
      that.getStockVariances();
    },

    closeConfirmOverride(user_id, authenticated = false) {
      let that = this;

      if (authenticated) {
        that.updateStockVariance(
          that.override.selectedItem,
          that.override.type
        );
        that.override.dialog = false;
      } else {
        that.override.dialog = false;
        that.override.selectedItem = null;
        that.override.type = null;
      }
    },

    showOverride(item, type) {
      let that = this;
      that.override.dialog = true;
      that.override.selectedItem = item;
      that.override.type = type;
    },
  },
  watch: {
    "stockVariances.dataTable.options": {
      handler(val) {
        let that = this;

        that.loaded = false;
        that.getStockVariances(val.page);
      },
    },
  },
};
</script>
