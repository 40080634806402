<template>
<v-dialog  v-model="TESTER_CHECK_LISTS.DIALOGS.VIEW.VALUE" :retain-focus="false" persistent width="500" height="100px">
    <v-card>
        <v-card-title class="d-flex justify-content-between px-0">
            <h4 class="mx-3">{{ actions }} Item Details</h4>
            <v-btn text @click="toggleDialog('VIEW')" fab><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col lg="6">
                    <v-text-field v-if="this.TESTER_CHECK_LISTS.ACTION != 'view' && this.TESTER_CHECK_LISTS.ACTION != 'edit'" v-model="checList.fillables.model" label="Model" outlined dense disabled>

                    </v-text-field>
                </v-col>
                <v-col lg="6">
                    <v-text-field v-if="this.TESTER_CHECK_LISTS.ACTION != 'view' && this.TESTER_CHECK_LISTS.ACTION != 'edit'" v-model="checList.fillables.item_name" label="Name" outlined dense disabled>
                        
                    </v-text-field>
                </v-col>
                <v-col  lg="12">
                    <v-autocomplete
                        :items="GET_ALL_LIST_TEMPLATE"
                        v-model="checList.fillables.id"
                        item-value="id"
                        item-text="name"
                        label="Selecte Template"
                        dense
                        outlined
                        @change="getLists()" :loading="!checList.loaded"
                        :disabled="TESTER_CHECK_LISTS.ACTION == 'view'"
                    >   

                    </v-autocomplete>
                </v-col>
                <v-col lg="6">
                    <v-text-field v-if="this.TESTER_CHECK_LISTS.ACTION != 'view' && this.TESTER_CHECK_LISTS.ACTION != 'edit'" v-model="checList.fillables.name" label="Title" outlined dense disabled :loading="!checList.loaded">

                    </v-text-field>
                </v-col>
                <v-col :lg="checList == 0 ? '6' : '12'">
                    <v-text-field v-model="checList.fillables.code" label="Code" outlined dense :disabled="this.TESTER_CHECK_LISTS.ACTION == 'view'" :loading="!checList.loaded">

                    </v-text-field>
                </v-col>
                <v-col lg="12">
                    <v-text-field v-model="checList.fillables.description" label="Description" outlined dense :disabled="this.TESTER_CHECK_LISTS.ACTION == 'view'" :loading="!checList.loaded">

                    </v-text-field>
                </v-col>


                <v-col lg="12">
                    <v-text-field
                        v-if="this.TESTER_CHECK_LISTS.ACTION == 'edit'"
                        v-model="checList.placeholder"
                        label="List"
                        append-icon="mdi-plus"
                        @keypress.enter="updateList(checList.placeholder,1)"
                        @click:append="updateList(checList.placeholder,1)"
                        :disabled="this.TESTER_CHECK_LISTS.ACTION == 'view'"
                        outlined 
                        dense
                        >

                    </v-text-field>
                </v-col>
                <v-col>
                    <v-card class="w-100 " height="300" style="overflow-y: scroll;">
                        <v-card-text >
                            <v-col class="min" cols="12">
                                <v-chip v-for="(item,index) in checList.list" :key="index" class="my-2 mr-2 " @click:close="updateList('',2,index)" close label color="#F69F1A" outlined>{{item}}</v-chip>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-end" >
            <v-btn small @click="updateList('',3)" v-if="actions != 'View'"><v-icon>mdi-delete</v-icon>Clear All</v-btn>
            <v-btn small @click="GET_TABS == 0 ? updateItemList() : update()" v-if="actions != 'View'">
                <!-- v-if="!TESTER_CHECK_LISTS.ACTION == 'view'" -->
                <v-icon>mdi-content-save</v-icon> Update
            </v-btn>
        </v-card-actions>
    </v-card>
    <!-- <DialogComponent :cp="components" :scrollable="dialog_scrollable" :width="height(dialog_width)" :retain-focus="false"> </DialogComponent> -->
</v-dialog>

</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
// import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
export default {
    props:{
        tab:
        {
            default:()=>1,
            type:Number
        }
    },
    data:()=>({
        checList:{
            fillables:{
                name:'',
                description:'',
                code:'',
                id:'',
                item_name:'',
                model:''
            },
            list:[],
            placeholder:'',
            loaded:true,
            deleted_checklist:[],
        },
    }),
    computed:{
        ...mapGetters([
            'TESTER_CHECK_LISTS',
            'GET_ALL_LIST_TEMPLATE',
            'GET_REFRESH',
            'GET_TABS',
        ]),
        actions(){
            if(this.TESTER_CHECK_LISTS.ACTION == 'view'){
                this.getData()
                // console.log(this.getData())
                return 'View'
            }
            else if(this.TESTER_CHECK_LISTS.ACTION == 'edit'){
                this.getData()
                return 'Edit'
            }
        }
    },

    mounted(){
        this.$store.dispatch('getListTemplate',{code:'SR'})
    },
    methods:{
        
        toggleDialog(dialog){
            if(!this.TESTER_CHECK_LISTS.DIALOGS[dialog]){
                return false
            }

            this.$store.commit(this.TESTER_CHECK_LISTS.DIALOGS[dialog].mutations,!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE)
            // console.log(!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE)
            if(!this.TESTER_CHECK_LISTS.DIALOGS[dialog].VALUE){
                Object.assign(this.$data, this.$options.data.call(this));
            }
        },
        updateItemList(){
            if(!this.checList.fillables.id){
                Swal.fire('Error!','- Please select template','error')
                return false
            }
            
            Swal.fire({
                title:'Warning!',
                icon: 'question',
                text:'- Are you sure you want to update?',
                showCancelButton:true,
                reverseButtons:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('itemsPost',{
                        url:'update-item-check-list',
                        ...this.checList.fillables,
                        list: this.checList.list,
                    }).then(response=>{
                        Swal.fire('Success!','','success')
                        this.toggleDialog('VIEW')
                    }).catch(err=>{
                        Swal.fire('Error!','','error')
                        console.log(err)
                    })
                }
                // console.log(1)
            })
        },
        getLists(){
            this.checList.loaded = false
            this.$store.dispatch('dropdownGet',{
                url:'list-template/'+this.checList.fillables.id,
                // list: this.checList
            }).then(response=>{
                this.checList.list = this.GET_TABS == 0 ? response.data.lists.map(e=>e.name) : response.data.lists.map(e=>e.name)
                Object.assign(this.checList.fillables,{
                    ...response.data.item,
                })

                this.checList.loaded = true
            })
        },
        getData(){
            this.checList.fillables = this.TESTER_CHECK_LISTS.SELECTED 
        },
        
        updateList(name,action = '',index = 0){

            if(!action){
            Swal.fire('Error!',' - Check List id not found','error')
            return false
            }

            this.checList.placeholder = ''
            // console.log(name)
            switch(action){
            case 1:
                // console.log(name)
                if(!name){
                    return
                }
                this.checList.list.push(name);
                break;
            case 2:
                this.checList.list = this.checList.list.filter((e,i)=>i!=index);
                break;
            case 3:
                this.checList.list = [];
                break;
            }
        },
        update(){
            // console.log("wow")
            // debugger
            Swal.fire({
                title:'Update',
                text:'- Are you sure you want to Update?',
                icon: 'question',
                showCancelButton:true
            }).then(action=>{
                if(action.isConfirmed){
                    this.$store.dispatch('servicePost',{
                        url:'update-tester-lists',
                        fillables: this.checList.fillables,
                        list: this.checList.list
                    }).then(response=>{

                        Swal.fire('Success!','','success')
                        this.toggleDialog('VIEW')
                        // this.toggleDialog('CHECK_LIST_TEMPLATE')
                    }).catch(err=>{
                        Swal.fire('Error!',err.response.data.message,'error')
                    })
                }
            })
            // itemsPost

        },
        // delete(){
        //     if (this.checList.deleted_checklist > 0){
        //         id = this.checList.deleted_checklist[0],
        //         name =  this.checList.fillables.name
        //     }
        // }, 
       
    },
    watch:{
        'TESTER_CHECK_LISTS.SELECTED':{
            handler(val){
                // console.log(val)
                if(!!val){
                    this.checList.fillables = val
                    this.getLists()
                }
            }
        },
        'TESTER_CHECK_LISTS.DIALOGS.VIEW.VALUE':{
            handler(val){
                if(val){
                    this.checList.fillables = val
                    this.getLists()
                }
            }
        },
       
    },
    components:{
    },

}
</script>

<style scoped>
.min {
   min-width: 20px !important;
}
</style>
