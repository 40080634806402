<template>
    <v-container fluid>
        <ComponentHeader :button="true" class="ma-n4" title='Pending Order Stock Analysis' type='Sales'></ComponentHeader>
        <v-card>
            <v-card-text class="bg-bluish-gray">
                <v-row class="mx-auto" dense>
                    <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                        <v-row class="mx-auto" dense>
                            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-autocomplete
                                    v-model="filters.customer_ids"
                                    :items="GET_CUSTOMERS_SELECTION"
                                    label="Select Customer"
                                    background-color="white"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    deletable-chips
                                    auto-select-first
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-autocomplete
                                    v-model="filters.salesman_ids"
                                    :items="GET_SALESMAN_SELECTION"
                                    label="Select Salesman"
                                    background-color="white"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    deletable-chips
                                    auto-select-first
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="2" xl="2">
                                <v-autocomplete
                                    v-model="filters.item_type_ids"
                                    :items="GET_ITEM_TYPES"
                                    label="Select Discount Type"
                                    background-color="white"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    deletable-chips
                                    auto-select-first
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                                <v-autocomplete
                                    v-model="filters.item_category_ids"
                                    :items="GET_ITEM_CATEGORIES"
                                    label="Select Item Category"
                                    background-color="white"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    deletable-chips
                                    auto-select-first
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-autocomplete
                                    v-model="filters.item_ids"
                                    :items="GET_ITEM_SELECTION"
                                    label="Select Item Model"
                                    background-color="white"
                                    dense
                                    outlined
                                    hide-details
                                    multiple
                                    small-chips
                                    deletable-chips
                                    auto-select-first
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                        <v-row class="mx-auto" dense>
                            <v-col cols="12" class="d-flex justify-content-end" style="gap: 7px">
                                <v-btn :loading="!loaded" style="margin-right: 3px" small @click="resetFieldInput()">Clear</v-btn>
                                <v-btn :loading="!loaded" small @click="list(1)">Export</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mx-auto my-auto" dense>
                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="mx-3">
                        <v-menu
                            v-model="filters.date_from_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    v-on="on"
                                    label="Date From"
                                    append-icon="mdi-calendar-clock"
                                    readonly
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="mx-3">
                        <v-menu
                            v-model="filters.date_to_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    v-on="on"
                                    label="Date To"
                                    append-icon="mdi-calendar-clock"
                                    readonly
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row class="mx-auto" dense>
                    <v-col cols="12">
                        <!-- <v-data-table
                            :headers="headers"
                            :items="items"
                            :items-per-page="10"
                            :loading="!loaded"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                            }"
                            :options.sync="options"
                            dense
                        >
                        <template v-slot:[`item.can_serve_amount`]="{ item }">
                            <span class="text-nowrap">
                                ₱ {{ item.can_serve_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.cannot_serve_amount`]="{ item }">
                            <span class="text-nowrap">
                                ₱ {{ item.cannot_serve_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.item_amount`]="{ item }">
                            <span class="text-nowrap">
                                ₱ {{ item.item_amount | currency }}
                            </span>
                        </template>
                        </v-data-table> -->
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
    },
    data() {
        return {
            errors: [],
            loaded: true,
            filters: {
                customer_ids: [],
                salesman_ids: [],
                item_ids: [],
                item_type_ids: [1, 2],
                item_category_ids: [],
                date_from: this.$dayjs().startOf('month').subtract(3, 'month').format('YYYY-MM-DD'),
                date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                date_from_menu: false,
                date_to_menu: false,
            },
            items:[],
            options: {},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 20,
            headers: [
                { text: 'SO #', align: 'start', value: 'so_num', },
                { text: 'CUSTOMER', align: 'start', value: 'company_name', },
                { text: 'SO DATE', align: 'start', value: 'so_date', },
                { text: 'ITEM MODEL', align: 'end', value: 'model', },
                { text: 'ACTUAL STOCKS', align: 'end', value: 'actual_stocks', },
                { text: 'PENDING QTY', align: 'end', value: 'pending_qty', },
                { text: 'STOCKS CONSUMPTION', align: 'end', value: 'stocks_consumption', },
                { text: 'ITEM AMOUNT', align: 'end', value: 'item_amount', },
                { text: 'CAN SERVE', align: 'end', value: 'can_serve_qty', },
                { text: 'CAN SERVE AMOUNT', align: 'end', value: 'can_serve_amount', },
                { text: 'CANNOT SERVE', align: 'center', value: 'cannot_serve_qty', },
                { text: 'CANNOT SERVE AMOUNT', align: 'center', value: 'cannot_serve_amount', },
            ],
        }
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch("getAllsalesmanSelection", {})
        this.$store.dispatch('getAllitemSelectionDefault')
        this.$store.dispatch('getItemTypes')
        this.$store.dispatch('getItemCategories')
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_SALESMAN_SELECTION',
            'GET_ITEM_SELECTION',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ]),
    },
    methods: {
        async list(exportToExcel = 0) {
            this.loaded = false
            this.items = []

            await this.$store.dispatch('salesReportsPost', {
                customer_ids: this.filters.customer_ids,
                salesman_ids: this.filters.salesman_ids,
                item_ids: this.filters.item_ids,
                item_type_ids: this.filters.item_type_ids,
                item_category_ids: this.filters.item_category_ids,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                url: 'getPendingOrderStockAnalysis',
                export: exportToExcel == 1 ? 1 : 0,
            }).then(response => {
                let data = response.data.stock_analysis
                let item_stocks = {}

                for (let e of data) {
                    e.so_date = this.$dayjs(e.so_date).format('YYYY-MM-DD')
                    e.so_total_amount = parseFloat(e.so_total_amount)
                    e.selling_price = parseFloat(e.selling_price)
                    e.amount = parseFloat(e.amount)
                    e.item_total_amount = parseFloat(e.item_total_amount)

                    e.pending_qty = parseInt(e.pending_qty)
                    e.incoming_container_qty = parseInt(e.incoming_container_qty)
                    e.total_stocks = parseInt(e.total_stocks)
                    e.delivery_date = e.delivery_date ? this.$dayjs(e.delivery_date).format('YYYY-MM-DD') : ''

                    if (!Object.hasOwn(item_stocks, e.model)) {
                        item_stocks[e.model] = e.total_stocks
                    }

                    item_stocks[e.model] -= e.pending_qty
                    e.stocks_allocation = item_stocks[e.model]
                    e.with_stocks_amount = e.stocks_allocation > 0 ? e.item_total_amount : ((e.pending_qty - Math.abs(e.stocks_allocation)) > 0 ? (e.pending_qty - Math.abs(e.stocks_allocation)) * e.amount : 0)
                    e.without_stocks_amount = e.item_total_amount - e.with_stocks_amount
                }

                if (exportToExcel) {
                    const workbook = new this.$exceljs.Workbook()
                    const stock_analysis = workbook.addWorksheet(`Stock Analysis`)

                    let stock_analysis_columns = [
                        { header: 'CUSTOMER', key: 'company_name' },
                        { header: 'SO#', key: 'order_num' },
                        { header: 'PO#', key: 'customer_po_num' },
                        { header: 'SO DATE', style: { numFmt: 'yyyy-mm-dd' }, key: 'so_date' },
                        { header: 'REMARKS', key: 'remarks' },
                        { header: 'SALESMAN', key: 'salesman' },
                        { header: 'TERMS', key: 'terms' },
                        { header: 'DELIVERY DATE', style: { numFmt: 'yyyy-mm-dd' }, key: 'delivery_date' },
                        { header: 'STATUS', key: 'status_text' },
                        { header: 'SO TOTAL AMOUNT', style: { numFmt: '#,##0.00' }, key: 'so_total_amount' },
                        { header: 'ITEM MODEL', key: 'model' },
                        { header: 'ITEM NAME', key: 'name' },
                        { header: 'UOM', key: 'uom' },
                        { header: 'TYPE', key: 'type' },
                        { header: 'CATEGORY', key: 'category' },
                        { header: 'PENDING QTY', style: { numFmt: '#,##0' }, key: 'pending_qty' },
                        { header: 'TOTAL STOCKS', style: { numFmt: '#,##0' }, key: 'total_stocks' },
                        { header: 'STOCKS ALLOCATION', style: { numFmt: '#,##0' }, key: 'stocks_allocation' },
                        { header: 'SRP', style: { numFmt: '#,##0.00' }, key: 'selling_price' },
                        { header: 'DISC PRICE', style: { numFmt: '#,##0.00' }, key: 'amount' },
                        { header: 'SO ITEM PENDING', style: { numFmt: '#,##0.00' }, key: 'item_total_amount' },
                        { header: 'WITH STOCKS (AMOUNT)', style: { numFmt: '#,##0.00' }, key: 'with_stocks_amount' },
                        { header: 'WITHOUT STOCKS (AMOUNT)', style: { numFmt: '#,##0.00' }, key: 'without_stocks_amount' },
                        { header: 'FOC', key: 'foc' },
                        { header: 'NEW MODELS', key: 'new_models' },
                        { header: 'NEW MODEL STOCKS', style: { numFmt: '#,##0' }, key: 'new_model_stocks' },
                        { header: 'INCOMING CONTAINERS', key: 'incoming_containers' },
                        { header: 'TOTAL INCOMING', style: { numFmt: '#,##0' }, key: 'incoming_container_qty' },
                        { header: 'CLUSTER', key: 'clusters' },
                        { header: 'REGION', key: 'region' },
                        { header: 'CUSTOMER TYPE', key: 'customer_type' },
                    ]

                    stock_analysis.columns = stock_analysis_columns
                    stock_analysis.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
                        cell.font = {
                            bold: true
                        }
                    })
                    stock_analysis.insertRows(1, [[], []])
                    stock_analysis.addRows(data)

                    stock_analysis.columns.forEach(column => {
                        var maxLength = 0

                        if (!['customer_po_num', 'remarks'].includes(column.key)) {
                            column['eachCell']({ includeEmpty: true }, function (cell, rowNumber) {
                                if (rowNumber == 2) {
                                    if (['item_total_amount', 'with_stocks_amount', 'without_stocks_amount'].includes(column.key)) {
                                        cell.value = { formula: `SUBTOTAL(9,${column.letter}3:${column.letter}${stock_analysis.lastRow.number})`, result: undefined }
                                        cell.font = {
                                            bold: true
                                        }
                                    }
                                }
                                var columnLength = cell.value ? cell.value.toString().length : 10
                                if (columnLength > maxLength) {
                                    maxLength = columnLength
                                }
                                column.width = maxLength < 10 ? 10 : maxLength
                            })

                            if (['with_stocks_amount', 'without_stocks_amount'].includes(column.key)) {
                                let cell = stock_analysis.getCell(`${column.letter}1`)

                                cell.value = { formula: `${column.letter}2/U2` }
                                cell.numFmt = '0.00%'
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF00' },
                                    bgColor: { argb: 'FF000000' },
                                }
                                cell.font = {
                                    bold: true
                                }
                            }
                        }
                    })

                    stock_analysis.autoFilter = {
                        from: {
                            row: 3,
                            column: 1,
                        },
                        to: {
                            row: stock_analysis.lastRow.number,
                            column: stock_analysis_columns.length,
                        },
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: 'applicationi/xlsx',
                        })
                        this.$filesaver.saveAs(
                            blob,
                            `Pending Order Stock Analysis ${this.filters.date_from} - ${this.filters.date_to}.xlsx`
                        )
                    })
                }

                this.loaded = true
            }).catch(error => {
                this.loaded = true
            })
        },
    },
}
</script>
