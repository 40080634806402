import CashAdvance from "@/views/main/modules/Accounting/CashAdvance/CashAdvanceGroupComponent.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/cash-advance/:id",
    name: "cashadvance",
    component: CashAdvance,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Cash Advance',
    }
}]

export default routes;
