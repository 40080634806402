<template>
    <v-container fluid>
        <h3 class="page-title">PURCHASE REQUISITION</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs
                    v-model="tab"
                    id="group-tab"
                    color="cyan"
                    slider-color="cyan"
                    background-color="#424242"
                    dark
                    show-arrows
                >
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab
                        ripple
                        v-for="(item, i) in items"
                        :key="i"
                        @click="tabs(i)"
                        v-if="item.view"
                    >
                        {{ item.tab_name }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(item, i) in items" :key="i">
                        <keep-alive>
                            <component
                                :is="purchase_request_tabs_items.components"
                                :purchase_request_tabs_items="
                                    purchase_request_tabs_items
                                "
                                :isActive_tab="item.tab_name"
                            >
                            </component>
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-app>
        </v-container>
        <DialogComponent
            :cp="components"
            :scrollable="dialog_scrollable"
            :width="width(dialog_width)"
            :retainfocus="false"
        ></DialogComponent>
    </v-container>
</template>

<script>
import DialogComponent from "@/views/main/layouts/dialogs/Dialog.vue";
import PurchaseRequisitionComponent from "@/views/main/modules/Admin/PurchaseRequest/PurchaseRequisitionComponent.vue";
import PurchaseRequisitionViewComponent from "@/views/main/layouts/dialogs/Admin/PurchaseRequisitionViewComponent.vue";
import { mapGetters } from "vuex";
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent.vue';
export default {
    mixins:[PusherFunctionComponent],
    components: {
        DialogComponent,
        PurchaseRequisitionViewComponent,
        PurchaseRequisitionComponent,
    },
    data() {
        return {
            form: {
                code: "",
                name: "",
                sub_class: [],
                sub_class_trash: [],
            },
            components: "",
            items: [
                // {tab_name:"ALL",view:true},
                {tab_name:"DEPARTMENT CONFIRMATION",view:true},
                {tab_name:"PENDING",view:true},
                {tab_name:"CONFIRMED",view:true},
                {tab_name:"APPROVED",view:true},
                {tab_name:"REJECTED",view:true},
                // {tab_name:"RECEIVED",view:true},
                // {tab_name:"OVERDUE",view:true},
            ],
            purchase_request_tabs_items: {
                components: PurchaseRequisitionComponent,
                dialog: PurchaseRequisitionViewComponent,
                dialog_title:'Purchase Requisition',
                dialog_width: "50%",
                scrollable:true,
                dispatch:{
                    get:'getAllPurchaseRequest',
                    add:'CreatePurchaseRequest',
                    update:'UpdatePurchaseRequest',
                    delete:'DeletePurchaseRequest'
                },
            },
            dialog_width: "",
            loaded: false,
            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            dialog_scrollable: true,
        };
    },
    computed:{
        ...mapGetters(['GET_NEW_ADMIN_OTHER_TABLE','GET_NEW_PURCHASE_REQUEST_TABLE','GET_LIST_DEPARTMENT','USERACCOUNT_LOAD'])
    },
    mounted(){
        this.callPusher()
        this.dropDowns()
        this.tabs(0);
        this.ServerPusher()
    },
    methods:{
        dropDowns(){
            this.$store.dispatch('getDepartment')
            this.$store.dispatch('getitemUomSelection');
            this.$store.dispatch("getEmployeeDetails");
        },
        tabs(key) {
            this.components = this.purchase_request_tabs_items.dialog;
            this.dialog_width = this.purchase_request_tabs_items.dialog_width;
            this.dialog_scrollable = this.purchase_request_tabs_items.scrollable;
            this.$store.commit( "PURCHASE_REQUEST_DISPATCH_STATUS", this.items[key] );
            this.$store.commit( "PURCHASE_REQUEST_DISPATCH", this.purchase_request_tabs_items.dispatch );
        },
        ServerPusher(){
            window.Echo.channel('ingco-channel').listen('RealtimePRFNumber',(e)=>{
                let splited = e.prf_number.split("-");
                let dept = this.GET_LIST_DEPARTMENT.find(e=>e.value == this.USERACCOUNT_LOAD.department_id)
                splited[0] = dept?dept.text+"D":splited[0];
                let prf_num = splited[0] + "-" + splited[1] + "-" + splited[2];
                this.$store.commit('PURCHASE_REQUEST_NUMBER',prf_num)
            })
            window.Echo.channel('ingco-channel-2').listen('PRFMessage',(e)=>{
                // console.log(this.USERACCOUNT_LOAD)
                if(this.USERACCOUNT_LOAD.id == e.data.userID){
                // console.log(e.data.action)
                this.$store.commit('NEW_PURCHASE_REQUEST_TABLE',true);
                }
            })
        },
        width(width) {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "100%"
                case 'sm': return "100%"
                case 'md': return "100%"
                case 'lg': return width
                case 'xl': return width
            }
        },
    },
    watch: {
        refresher: {
            handler(val) {
                // if(val){
                //   this.$store.state.purchaseRequest.purchase_request_refresh = true
                // }
                // this.refresher = false
            },
        },
        GET_NEW_PURCHASE_REQUEST_TABLE: {
            handler(val) {
                if (val) {
                    this.ServerPusher();
                }
            },
        },
    },
}
</script>
<style>
    #group-tab {
        padding: 0 !important;
    }
</style>
